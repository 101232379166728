/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import PowerPMRINTREventEvent from '@/model/src/events/PowerPMRINTREventEvent'
import PowerPMRINTREventChecker from '@/model/src/checkers/PowerPMRINTREventChecker'
import ITDA_PMS_PMC_INTR_EVENT from '@/model/gen/sfrSpec/ITDA_PMS_PMC_INTR_EVENT'
import ITDA_PMS_PMC_INTR_EVENT_RISE from '@/model/gen/sfrSpec/ITDA_PMS_PMC_INTR_EVENT_RISE'
import ITDA_PMS_PMC_INTR_EVENT_FALL from '@/model/gen/sfrSpec/ITDA_PMS_PMC_INTR_EVENT_FALL'
export default class PowerPMRINTREvent extends Item {
    FID_POWERPMRINTREVENT_NAME = 0
    FID_POWERPMRINTREVENT_INDEX = 1
    FID_POWERPMRINTREVENT_LAST = 1

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerPMRINTREvent"}))
        
        this.setEvent(new PowerPMRINTREventEvent(this))
        this.setChecker(new PowerPMRINTREventChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "PowerPMRINTREvent"
        }
    if (this.properties.name === undefined) {
        this.properties.name = ""
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerPMRINTREvent"
    }

    getSchema() {
        return {"name": {"type": "string", "readOnly": true, "default": "", "category": "IP", "multiple": false, "hidden": false}, "index": {"type": "integer", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "readOnly": true, "default": "", "category": "IP", "multiple": false, "hidden": false}, "index": {"type": "integer", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_PMS_PMC_INTR_EVENT
    }
    getSFRSpec(type) {
        if (type === "EVENT") {
            return ITDA_PMS_PMC_INTR_EVENT
        }
        if (type === "EVENT_R") {
            return ITDA_PMS_PMC_INTR_EVENT_RISE
        }
        if (type === "EVENT_F") {
            return ITDA_PMS_PMC_INTR_EVENT_FALL
        }
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMRINTREVENT_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_POWERPMRINTREVENT_NAME
    }
    

    setIndex(index, event=true) {
        if ((index !== null) && (index !== undefined)) {
            if (index.constructor !== Number) {
                throw `index(${index}) must be Number type`
            }
        }
        const newVal = (index === null || index === undefined) ? undefined : index
        const oldVal = this.properties.index
        this.properties.index = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMRINTREVENT_INDEX, newVal, oldVal)
        }
    }
    getIndex(pure = false) {
        return this.properties.index
        
    }
    getFidIndex() {
        return this.FID_POWERPMRINTREVENT_INDEX
    }
    

    getFidLast() {
        return this.FID_POWERPMRINTREVENT_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERPMRINTREVENT_NAME:
                return "name"

            case this.FID_POWERPMRINTREVENT_INDEX:
                return "index"

            default:
                return ""
        }
    }


}