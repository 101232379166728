/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import UPFPowerDomainEvent from '@/model/src/events/UPFPowerDomainEvent'
import UPFPowerDomainChecker from '@/model/src/checkers/UPFPowerDomainChecker'
export default class UPFPowerDomain extends Node {
    FID_UPFPOWERDOMAIN_NAME = 100
    FID_UPFPOWERDOMAIN_PRIMARYPOWERSOURCE = 101
    FID_UPFPOWERDOMAIN_ISOLATIONCELLS = 102
    FID_UPFPOWERDOMAIN_LAST = 102

    constructor(obj) {
        super(Object.assign(obj,{itemType:"UPFPowerDomain"}))
        
        this.setEvent(new UPFPowerDomainEvent(this))
        this.setChecker(new UPFPowerDomainChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "Power Domain"
        }
    if (this.properties.name === undefined) {
        this.properties.name = "PD_NONAME"
    }
    if (this.properties.isolationCells === undefined) {
        this.properties.isolationCells = []
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "UPFPowerDomain"
    }

    getSchema() {
        return {"name": {"type": "string", "readOnly": true, "default": "PD_NONAME", "category": "IP", "multiple": false, "hidden": false}, "primaryPowerSource": {"type": "item", "label": "Primary Power", "desc": "Primary Power", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}, "isolationCells": {"type": "item", "label": "Isolation Cell, Level Shifter, Enable Level Shifter", "multiple": true, "hidden": true, "default": [], "category": "IP", "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "readOnly": true, "default": "PD_NONAME", "category": "IP", "multiple": false, "hidden": false}, "primaryPowerSource": {"type": "item", "label": "Primary Power", "desc": "Primary Power", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}, "isolationCells": {"type": "item", "label": "Isolation Cell, Level Shifter, Enable Level Shifter", "multiple": true, "hidden": true, "default": [], "category": "IP", "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "UPFPowerSource", "auto": false, "searchable": false, "title": "Power PAD", "headers": [{"title": "PAD Name", "value": "name", "align": "start", "sortable": true}], "category": "IP"}, {"name": "UPFGround", "auto": false, "searchable": false, "title": "Ground PAD", "headers": [{"title": "PAD Name", "value": "name", "align": "start", "sortable": true}], "category": "IP"}, {"name": "UPFPowerSwitch", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "UPFIsolationCell", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "UPFLevelShifter", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "UPFEnableLevelShifter", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "UPFRetention", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "UPFHardMacro", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "UPFPowerDomainInstance", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "UPFSRAM", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFPOWERDOMAIN_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_UPFPOWERDOMAIN_NAME
    }
    

    setPrimaryPowerSource(primaryPowerSource, event=true) {
        if ((primaryPowerSource !== null) && (primaryPowerSource !== undefined)) {
            if (!(primaryPowerSource instanceof Item) && (primaryPowerSource.constructor !== String)) {
                throw `primaryPowerSource(${primaryPowerSource}) must be Item or String type`
            }
        }
        const newVal = (primaryPowerSource === null || primaryPowerSource === undefined) ? undefined : (primaryPowerSource.constructor === String) ? primaryPowerSource : primaryPowerSource.getId()
        const oldVal = this.properties.primaryPowerSource
        this.properties.primaryPowerSource = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFPOWERDOMAIN_PRIMARYPOWERSOURCE, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getPrimaryPowerSource(pure = false) {
        return pure ? this.properties.primaryPowerSource : this.getRefItem(this.properties.primaryPowerSource)
        
    }
    getFidPrimaryPowerSource() {
        return this.FID_UPFPOWERDOMAIN_PRIMARYPOWERSOURCE
    }
    

    setIsolationCells(isolationCells, event=true) {
        if ((isolationCells !== null) && (isolationCells !== undefined)) {
            if (isolationCells.constructor !== Array) {
                throw `isolationCells(${isolationCells}) must be Array type`
            }
        }
        const newVal = (isolationCells === null || isolationCells === undefined) ? [] : isolationCells.map((o) => {
            if (!o || o.constructor === String) {
                return o
            } else if (this.toRaw(o) instanceof Item) {
                return o.getId()
            }
        })
        const oldVal = this.properties.isolationCells
        this.properties.isolationCells = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFPOWERDOMAIN_ISOLATIONCELLS, newVal.map((id) => this.getRefItem(id)), oldVal.map((id) => this.getRefItem(id)))
        }
    }
    getIsolationCells(pure = false) {
        return pure ? this.properties.isolationCells : this.properties.isolationCells.map((id) => this.getRefItem(id))
        
    }
    getFidIsolationCells() {
        return this.FID_UPFPOWERDOMAIN_ISOLATIONCELLS
    }
    

    getFidLast() {
        return this.FID_UPFPOWERDOMAIN_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_UPFPOWERDOMAIN_NAME:
                return "name"

            case this.FID_UPFPOWERDOMAIN_PRIMARYPOWERSOURCE:
                return "primaryPowerSource"

            case this.FID_UPFPOWERDOMAIN_ISOLATIONCELLS:
                return "isolationCells"

            default:
                return ""
        }
    }


    newUPFPowerSource(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFPowerSource", properties, title, domain)
    }
    remUPFPowerSource(obj) {
        return this.removeChild(obj)
    }
    getUPFPowerSources() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFPowerSource")
            return res
        })
    }
    newUPFGround(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFGround", properties, title, domain)
    }
    remUPFGround(obj) {
        return this.removeChild(obj)
    }
    getUPFGrounds() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFGround")
            return res
        })
    }
    newUPFPowerSwitch(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFPowerSwitch", properties, title, domain)
    }
    remUPFPowerSwitch(obj) {
        return this.removeChild(obj)
    }
    getUPFPowerSwitchs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFPowerSwitch")
            return res
        })
    }
    newUPFIsolationCell(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFIsolationCell", properties, title, domain)
    }
    remUPFIsolationCell(obj) {
        return this.removeChild(obj)
    }
    getUPFIsolationCells() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFIsolationCell")
            return res
        })
    }
    newUPFLevelShifter(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFLevelShifter", properties, title, domain)
    }
    remUPFLevelShifter(obj) {
        return this.removeChild(obj)
    }
    getUPFLevelShifters() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFLevelShifter")
            return res
        })
    }
    newUPFEnableLevelShifter(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFEnableLevelShifter", properties, title, domain)
    }
    remUPFEnableLevelShifter(obj) {
        return this.removeChild(obj)
    }
    getUPFEnableLevelShifters() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFEnableLevelShifter")
            return res
        })
    }
    newUPFRetention(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFRetention", properties, title, domain)
    }
    remUPFRetention(obj) {
        return this.removeChild(obj)
    }
    getUPFRetentions() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFRetention")
            return res
        })
    }
    newUPFHardMacro(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFHardMacro", properties, title, domain)
    }
    remUPFHardMacro(obj) {
        return this.removeChild(obj)
    }
    getUPFHardMacros() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFHardMacro")
            return res
        })
    }
    newUPFPowerDomainInstance(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFPowerDomainInstance", properties, title, domain)
    }
    remUPFPowerDomainInstance(obj) {
        return this.removeChild(obj)
    }
    getUPFPowerDomainInstances() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFPowerDomainInstance")
            return res
        })
    }
    newUPFSRAM(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFSRAM", properties, title, domain)
    }
    remUPFSRAM(obj) {
        return this.removeChild(obj)
    }
    getUPFSRAMs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFSRAM")
            return res
        })
    }
}