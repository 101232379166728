/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:34.620490
 */
export default {
    "startOffset": "0x0A00",
    "size": "0x200",
    "alignSize": "0x8",
    "sfrs": [
        {
            "name": "CTRL_FREQMON",
            "alignOffset": "0x0",
            "description": "Clock Component Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_ENABLE_FREQMON",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "",
                    "initValue": "0x0",
                    "nc": "SFR_ENABLE_FREQMON"
                },
                {
                    "name": "SFR_ENABLE_ACG",
                    "index": 12,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "",
                    "initValue": "0x0",
                    "nc": "SFR_ENABLE_ACG"
                },
                {
                    "name": "SFR_BUSY",
                    "index": 20,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "",
                    "initValue": "0x0",
                    "nc": "SFR_BUSY"
                },
                {
                    "name": "SFR_FAULT_HT",
                    "index": 21,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "",
                    "initValue": "0x0",
                    "nc": "SFR_FAULT_HT"
                },
                {
                    "name": "SFR_FAULT_LT",
                    "index": 22,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "",
                    "initValue": "0x0",
                    "nc": "SFR_FAULT_LT"
                },
                {
                    "name": "SFR_FAULT_GD",
                    "index": 23,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "",
                    "initValue": "0x0",
                    "nc": "SFR_FAULT_GD"
                },
                {
                    "name": "SFR_DBG_INFO",
                    "index": 24,
                    "size": 8,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Debug Information",
                    "initValue": "0x0",
                    "nc": "SFR_DBG_INFO"
                }
            ]
        }
    ]
}