/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import ConfigurationEvent from '@/model/src/events/ConfigurationEvent'
import ConfigurationChecker from '@/model/src/checkers/ConfigurationChecker'
export default class Configuration extends Item {
    FID_CONFIGURATION_NAME = 0
    FID_CONFIGURATION_SELECTED = 1
    FID_CONFIGURATION_VOLTAGE = 2
    FID_CONFIGURATION_LAST = 2

    constructor(obj) {
        super(Object.assign(obj,{itemType:"Configuration"}))
        
        this.setEvent(new ConfigurationEvent(this))
        this.setChecker(new ConfigurationChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "Configuration"
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    if (this.properties.selected === undefined) {
        this.properties.selected = false
    }
    if (this.properties.voltage === undefined) {
        this.properties.voltage = 0
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "Configuration"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "selected": {"type": "boolean", "default": false, "hidden": true, "category": "IP", "multiple": false, "readOnly": false}, "voltage": {"type": "integer", "hidden": true, "default": 0, "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "selected": {"type": "boolean", "default": false, "hidden": true, "category": "IP", "multiple": false, "readOnly": false}, "voltage": {"type": "integer", "hidden": true, "default": 0, "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "ConfigurationElement", "auto": false, "searchable": false, "title": "Element List", "headers": [{"title": "Element Name", "align": "start", "filterable": true, "value": "itemRef", "sortable": true}, {"title": "Element Value", "align": "start", "filterable": true, "value": "value", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CONFIGURATION_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_CONFIGURATION_NAME
    }
    

    setSelected(selected, event=true) {
        if ((selected !== null) && (selected !== undefined)) {
            if (selected.constructor !== Boolean) {
                throw `selected(${selected}) must be Boolean type`
            }
        }
        const newVal = (selected === null || selected === undefined) ? undefined : selected
        const oldVal = this.properties.selected
        this.properties.selected = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CONFIGURATION_SELECTED, newVal, oldVal)
        }
    }
    getSelected(pure = false) {
        return this.properties.selected
        
    }
    getFidSelected() {
        return this.FID_CONFIGURATION_SELECTED
    }
    

    setVoltage(voltage, event=true) {
        if ((voltage !== null) && (voltage !== undefined)) {
            if (voltage.constructor !== Number) {
                throw `voltage(${voltage}) must be Number type`
            }
        }
        const newVal = (voltage === null || voltage === undefined) ? undefined : voltage
        const oldVal = this.properties.voltage
        this.properties.voltage = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CONFIGURATION_VOLTAGE, newVal, oldVal)
        }
    }
    getVoltage(pure = false) {
        return this.properties.voltage
        
    }
    getFidVoltage() {
        return this.FID_CONFIGURATION_VOLTAGE
    }
    

    getFidLast() {
        return this.FID_CONFIGURATION_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CONFIGURATION_NAME:
                return "name"

            case this.FID_CONFIGURATION_SELECTED:
                return "selected"

            case this.FID_CONFIGURATION_VOLTAGE:
                return "voltage"

            default:
                return ""
        }
    }


    newConfigurationElement(properties={}, title = "", domain = "") {
        return this.createChild(this, "ConfigurationElement", properties, title, domain)
    }
    remConfigurationElement(obj) {
        return this.removeChild(obj)
    }
    getConfigurationElements() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ConfigurationElement")
            return res
        })
    }
}