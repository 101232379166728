import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number) => {
    const STROKE = 1;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const points = [
      { x: 0, y: 0 },
      { x: width, y: height / 2 },
      {
        x: 0,
        y: height,
      },
    ];

    svg
      .append("polygon")
      .attr("points", points.map((p) => `${p.x},${p.y}`).join(" "))
      .attr("fill", "red");

    const strokePoints: { x: number; y: number }[] = [];
    points.forEach((point: { x: number; y: number }, idx: number) => {
      if (idx === 0) {
        strokePoints.push({ x: point.x + STROKE / 2, y: point.y + STROKE });
      } else if (idx === 1) {
        strokePoints.push({ x: point.x - STROKE, y: point.y });
      } else if (idx === 2) {
        strokePoints.push({ x: point.x + STROKE / 2, y: point.y - STROKE });
      }
    });

    svg
      .append("polygon")
      .attr("points", strokePoints.map((p) => `${p.x},${p.y}`).join(" "))
      .attr("fill", "white");
  },
};
