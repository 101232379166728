import { reactive } from "vue";
import { Item, ItemType, Domain, Module, DomainKeys } from "../types";
import ConfigurationRepository from "./configuration";
import SFRRepository from "./sfr";
import {
  DomainToItemMap,
  IDToItemMap,
  ExternalSpec,
  ExternalSpecGeneric,
  ExternalSpecHM,
  ExternalSpecPLL,
  GarbageToItemMap,
  GarbageType,
  GarbageLevel,
  GarbageKeys,
} from "./types";
import ItemHandler from "../src/ItemHandler";

export default class Repository {
  private static instance: Repository;
  public domainToItemMap: DomainToItemMap = {};
  public garbageToItemMap: GarbageToItemMap = {};
  private topItem: Item | undefined;
  private currentItem: Item | undefined;
  private currentNodeItem: Item | undefined;
  private currentDomain: DomainKeys | undefined;
  private currentModule: Module | undefined;
  private loading = false;

  private sfr: SFRRepository = SFRRepository.getInstance();
  private configuration: ConfigurationRepository =
    ConfigurationRepository.getInstance();

  private externalSpec: ExternalSpec = {};

  private constructor() {
    this.clearMaps();
    this.clear();
  }

  public static getInstance(): Repository {
    if (!Repository.instance) {
      Repository.instance = new Repository();
    }
    return Repository.instance;
  }

  public clear(domain?: DomainKeys): void {
    const topItem = this.getTopItem();
    if (!topItem) return;
    if (domain) {
      for (const cItem of topItem.getChildren()) {
        if (cItem.domain === domain) {
          ItemHandler.removeSyncItem(cItem.getId());
          this.getSFR().clear(this.getSFRBlocks(cItem));
        }
      }
      this.currentItem =
        this.currentItem?.domain === domain ? undefined : this.currentItem;
      this.currentNodeItem =
        this.currentNodeItem?.domain === domain
          ? undefined
          : this.currentNodeItem;
      this.currentDomain =
        this.currentDomain === domain ? undefined : this.currentDomain;
    } else {
      for (const cItem of topItem.getChildren()) {
        ItemHandler.removeSyncItem(cItem.getId());
        this.getSFR().clear(this.getSFRBlocks(cItem));
      }
      this.currentItem =
        this.currentItem === topItem ? this.currentItem : undefined;
      this.currentNodeItem = undefined;
      this.currentDomain = undefined;
      this.currentModule = undefined;
      this.sfr.clear();
    }
    this.configuration.clear();
  }

  private getSFRBlocks(moduleFolder: Item): Item[] {
    return moduleFolder.getChildren().reduce((acc: Item[], module: Item) => {
      const sfrBlock = module
        .getChildren()
        .find((o: Item) => o.getItemType() === ItemType.SFRBlock);
      if (sfrBlock) {
        acc.push(sfrBlock);
      }
      return acc;
    }, []);
  }

  private clearMaps() {
    Domain.getKeys().forEach(
      (key: DomainKeys) => (this.domainToItemMap[key] = {})
    );
    GarbageType.getKeys().forEach(
      (key: GarbageKeys) => (this.garbageToItemMap[key] = {})
    );
  }

  public setExternalSpec(
    pll: ExternalSpecPLL[],
    hm: ExternalSpecHM[],
    generic: ExternalSpecGeneric[]
  ) {
    pll.forEach((spec: ExternalSpecPLL) => {
      spec.sfrs.forEach((sfr: any) => {
        sfr.fields.forEach((field: any) => {
          if (!field.name.startsWith("SFR_")) {
            field.name = `SFR_${field.name}`;
          }
        });
      });
    });
    this.externalSpec = {
      pll: pll,
      hm: hm,
      generic: generic,
    };
  }

  public getExternalPLLSpec(): ExternalSpecPLL[] {
    return this.externalSpec.pll ? this.externalSpec.pll : [];
  }

  public getExternalHMSpec(): ExternalSpecHM[] {
    return this.externalSpec.hm ? this.externalSpec.hm : [];
  }

  public getExternalGenericSpec(): ExternalSpecGeneric[] {
    return this.externalSpec.generic ? this.externalSpec.generic : [];
  }

  public getExternalSpec(
    type: "pll" | "hm" | "generic"
  ): ExternalSpecPLL[] | ExternalSpecHM[] | ExternalSpecGeneric[] | [] {
    const res = this.externalSpec[type];
    if (res) {
      return res;
    } else {
      return [];
    }
  }

  public getDomains(): string[] {
    return Object.keys(this.domainToItemMap);
  }

  public getIdToItemMap(domain?: DomainKeys): IDToItemMap {
    if (!domain) {
      domain = this.getCurrentDomain() as DomainKeys;
    }
    const res = domain ? this.domainToItemMap[domain] : undefined;
    return res || {};
  }

  public getAllItemMap(): IDToItemMap {
    const res = {};
    for (const idToItemMap of Object.values(this.domainToItemMap)) {
      Object.assign(res, idToItemMap);
    }
    return res;
  }

  public addItem(item: Item): void {
    item = reactive(item);
    if (item.getItemType() === ItemType.Project) {
      this.setTopItem(item);
      return;
    }

    const idToItemMap = this.getIdToItemMap(item.domain);
    if (idToItemMap) {
      idToItemMap[item.getId()] = item;
    } else {
      throw new Error(
        `${item} does not exist in ${item.domain}, ${this.domainToItemMap}`
      );
    }
  }

  public remItem(item: Item): void {
    const idToItemMap = this.getIdToItemMap(item.domain);
    if (idToItemMap) {
      delete idToItemMap[item.getId()];
    } else {
      throw new Error(
        `${item} does not exist in ${item.domain}, ${this.domainToItemMap}`
      );
    }
    if (this.getTopItem() === item) {
      this.setTopItem(undefined);
      return;
    }
  }

  public addGarbage(item: Item, type: GarbageType): GarbageLevel {
    const map = this.garbageToItemMap[type.getKey()];
    if (!map) {
      throw new Error(`Please check the garbage map. ${type.getKey()}`);
    }
    switch (type.getLevel()) {
      case GarbageLevel.FATAL:
        this.remItem(item);
        map[item.getId()] = item;
        console.error("[GABAGE][FATAL]", type.getTitle());
        break;
      case GarbageLevel.WARN:
      case GarbageLevel.INFO:
        console.warn("[GABAGE][WARN]", type.getTitle());
        break;
      default:
        break;
    }
    return type.getLevel();
  }

  public getItemByID(id: string): Item | undefined {
    if (this.getTopItem()?.getId() === id) {
      return this.getTopItem();
    }

    for (const idToItemMap of Object.values(this.domainToItemMap)) {
      const item = idToItemMap[id];
      if (item) {
        return item;
      }
    }
  }

  public setTopItem(item: Item | undefined): void {
    this.topItem = item;
  }

  public getTopItem(): Item | undefined {
    return this.topItem;
  }

  public setCurrentItem(item: Item | undefined): void {
    this.currentItem = item;
  }

  public getCurrentItem(): Item | undefined {
    return this.currentItem;
  }

  public setCurrentNodeItem(item: Item | undefined): void {
    this.currentNodeItem = item;
  }

  public getCurrentNodeItem(): Item | undefined {
    return this.currentNodeItem;
  }

  public setCurrentDomain(domain: DomainKeys | undefined): void {
    this.currentDomain = domain;
  }

  public getCurrentDomain(): DomainKeys | undefined {
    return this.currentDomain;
  }

  public setCurrentModule(item: Module): void {
    this.currentModule = item;
  }

  public getCurrentModule(): Module | undefined {
    return this.currentModule;
  }

  public startLoading() {
    this.loading = true;
  }

  public endLoading() {
    this.loading = false;
  }

  public isLoading(): boolean {
    return this.loading;
  }

  public getConfiguration(): ConfigurationRepository {
    return this.configuration;
  }

  public getSFR(): SFRRepository {
    return this.sfr;
  }
}
