import * as ITDANodeRenderer from "./renderers";
import { NodeType } from "../types";
import ITDANodeClock from "./ITDANodeClock";
import API from "@/api/internal";
import CanvasAPI from "../api";
import { ItemType } from "ITDAModelTypes";

export default class ITDALABELREF extends ITDANodeClock {
  constructor(name = "") {
    super(name, NodeType.ITDALABELREF);
    this.render = ITDANodeRenderer.ITDALABELREF.render;
  }

  async data(
    inputs: Record<`CLK_INPUT`, any>
  ): Promise<Record<`CLK_OUTPUT`, any>> {
    let frequency = this.getFrequencyInitValue();
    const item = API.getItem(this.getItemID());
    let labelRefItem;
    let labelItem;
    if (item && item.getItemType() === ItemType.SDCPhantom) {
      labelRefItem = item.getOriginItem();
    } else {
      labelRefItem = item;
    }

    if (labelRefItem.getType() === "external") {
      const configElem = API.getCMUConfigurationElements(labelRefItem);
      if (configElem.length) {
        labelItem =
          configElem[0].getValue() && API.getItem(configElem[0].getValue());
      }
    } else {
      labelItem = item?.getItemLabel();
    }

    if (!labelItem) return { CLK_OUTPUT: frequency };
    if (labelItem) {
      const labelNodeId = labelItem.getNodeID();
      const labelDiagramId = API.getParentDiagram(labelItem)?.getId();
      const dataflow = CanvasAPI.getDataFlowPlugin(labelDiagramId);
      if (API.getSelectedCMUConfiguration(labelItem) && dataflow) {
        const labelOut = await dataflow.getInstance().fetch(labelNodeId);
        frequency = labelOut.CLK_OUTPUT;
      }
    }
    return { CLK_OUTPUT: frequency };
  }
}
