import ITDACustomEditor from "./plugins/utils/ITDACustomEditor";
import * as ITDACommon from "./common";

import {
  ITDAAreaPlugin,
  ITDACommentPlugin,
  ITDAConnectionPlugin,
  ITDADockPlugin,
  ITDAHistoryPlugin,
  ITDARenderPlugin,
  ITDADataFlowPlugin,
  ITDAConnectionReRoutePlugin,
  ITDAScopesPlugin,
} from "./plugins";
import { ITDACanvasType } from "./plugins/types";

import ITDACanvas from "./ITDACanvas";
import { ITDAMODULE, ITDANode } from "./nodes";
import {
  DiagramType,
  ImportConnectionType,
  ImportNodeType,
  NodeType,
} from "./types";
import Factory from "./utils/factory";

export default class ITDAEditor extends ITDACanvas {
  private keys: { [key: string]: boolean } = {};
  private diagramType: DiagramType;
  constructor(id: string, container: HTMLElement, diagramType: DiagramType) {
    super(id, ITDACanvasType.Editor);
    this.res = new ITDACustomEditor<ITDACommon.Schemes>();
    this.diagramType = diagramType;

    const area = new ITDAAreaPlugin(this.id, container);
    new ITDADataFlowPlugin(this.id);
    new ITDACommentPlugin(this.id);
    // new ITDAConnectionPathPlugin(this.id);
    new ITDAConnectionPlugin(this.id);
    new ITDAConnectionReRoutePlugin(this.id);
    new ITDARenderPlugin(this.id);
    new ITDAScopesPlugin(this.id);
    const dock = new ITDADockPlugin(this.id);
    new ITDAHistoryPlugin(this.id);

    this.repo.setup(this.id);

    dock.register(this.diagramType);
    // area.simpleNodesOrder();
    dock.render();
    area.addCustomBackground();

    area.zoomAt(this.res.getNodes());

    window.addEventListener("keydown", this.shortcutKeyDownHandler.bind(this));
    window.addEventListener("keyup", this.shortcutKeyUpHandler.bind(this));
  }

  private shortcutKeyDownHandler(e: KeyboardEvent) {
    if (this.repo.getEditor(this.repo.getCurrentID()) !== this) {
      return;
    }

    const isTextField =
      (e.target instanceof HTMLInputElement && e.target.type === "text") ||
      e.target instanceof HTMLTextAreaElement ||
      (e.target instanceof HTMLElement && e.target.isContentEditable);

    if (isTextField) {
      return;
    }

    this.keys[e.code] = true;
    if (this.checkDeleteFunction()) {
      e.preventDefault();
      this.getNodes().forEach((node: ITDANode) => {
        switch (node.getType()) {
          case NodeType.ITDAQCHINFO:
          case NodeType.ITDASEQSTART:
          case NodeType.ITDASEQBACK:
          case NodeType.ITDASEQLABEL:
          case NodeType.ITDASEQLABELREF:
          case NodeType.ITDAMODULEUPF:
            break;
          default:
            {
              if (node.selected) {
                if (node.parent) {
                  const parent = this.getNode(node.parent);
                  if (parent instanceof ITDAMODULE) {
                    this.removeNode(node.id);
                  }
                } else {
                  this.removeNode(node.id);
                }
              }
            }
            break;
        }
      });

      if (e.code === "Backspace") {
        this.keys[e.code] = false;
      }
    }
  }

  private shortcutKeyUpHandler(e: KeyboardEvent) {
    if (this.keys[e.code]) {
      this.keys[e.code] = false;
    }
  }

  use() {
    this.getInstance().use(this.repo.getAreaPlugin(this.id).getInstance());
    this.getInstance().use(this.repo.getDataFlowPlugin(this.id).getInstance());
  }

  event() {
    this.getInstance().addPipe(async (context: any) => {
      if (["nodecreate"].includes(context.type)) {
        if ("data" in context && context.data instanceof ITDANode) {
          const res = await this.emit({
            type: context.type,
            options: {
              node: context.data,
            },
          });
          return res ? context : undefined;
        }
      }
      if (["nodecreated", "noderemoved"].includes(context.type)) {
        if ("data" in context && context.data instanceof ITDANode) {
          await this.emit({
            type: context.type,
            options: {
              node: context.data,
            },
          });
        }
      }
      if (context.type === "connectioncreated") {
        if (context.data instanceof ITDACommon.ITDAConnection) {
          const source = this.getInstance().getNode(context.data.source);
          const target = this.getInstance().getNode(context.data.target);

          if (target.getType() === NodeType.ITDALABEL) {
            target.update();
          }

          await this.emit({
            type: context.type,
            options: {
              data: context.data,
            },
          });
        }
      } else if (context.type === "connectionremoved") {
        if (context.data instanceof ITDACommon.ITDAConnection) {
          await this.emit({
            type: context.type,
            options: {
              data: context.data,
            },
          });
        }
      }
      return context;
    });
  }

  getInstance(): ITDACustomEditor<ITDACommon.Schemes> {
    return this.res as ITDACustomEditor<ITDACommon.Schemes>;
  }

  getDiagramType(): DiagramType {
    return this.diagramType;
  }

  addNode(node: ITDANode): Promise<boolean> {
    return this.getInstance().addNode(node);
  }

  removeNode(id: string): Promise<boolean> {
    return this.getInstance().removeNode(id);
  }

  getNode(id: string): ITDANode {
    return this.getInstance().getNode(id);
  }

  getNodes(): ITDANode[] {
    return this.getInstance().getNodes();
  }

  addConnection(
    connection: ITDACommon.ITDAConnection<ITDANode, ITDANode>
  ): Promise<boolean> {
    return this.getInstance().addConnection(connection);
  }

  removeConnection(id: string): Promise<boolean> {
    return this.getInstance().removeConnection(id);
  }

  getConnection(id: string): ITDACommon.ITDAConnection<ITDANode, ITDANode> {
    return this.getInstance().getConnection(id);
  }

  getConnections(): ITDACommon.ITDAConnection<ITDANode, ITDANode>[] {
    return this.getInstance().getConnections();
  }

  filterConnections(selectedTypes: string[]) {
    const connections = this.getConnections();
    connections.forEach((connection) => {
      const connectionView = this.repo
        .getAreaPlugin(this.getID())
        .getConnectionView(connection.id);
      const sourceNode = this.getNode(connection.source);
      const sourceNodeOutput = sourceNode.outputs[connection.sourceOutput];
      const outputSocket = sourceNodeOutput?.socket as ITDACommon.ITDASocket;
      const isVisible = selectedTypes.includes(
        outputSocket?.getType().getKey() || ""
      );

      if (connectionView) {
        connectionView.element.style.visibility = isVisible
          ? "visible"
          : "hidden";
      }
      this.repo
        .getConnectionReRoutePlugin(this.getID())
        .filterPins(connections, selectedTypes);
    });
  }

  async importNodes(nodes: ImportNodeType[], event = false) {
    for (const n of nodes) {
      const node = Factory.createNode(n.type) as ITDANode;
      node.import(n);
      await this.addNode(node);

      const nodeView = node.getNodeView();
      if (nodeView) {
        await nodeView.translate(n.view.position.x, n.view.position.y);
      }

      const height = n.height;
      const width = n.width;
      if (height && width && nodeView) {
        await nodeView.resize(n.width, n.height);
      }
    }
    if (event) {
      await this.emit({
        type: "nodeimported",
        options: {
          editor: this,
        },
      });
    }
  }

  async importConnections(connections: ImportConnectionType[]) {
    await Promise.all(
      connections.map((conn: ImportConnectionType) => {
        const sourceNode = this.getNode(conn.source);
        const targetNode = this.getNode(conn.target);
        if (sourceNode && targetNode) {
          const c = new ITDACommon.ITDAConnection(
            sourceNode,
            conn.sourceOutput,
            targetNode,
            conn.targetInput
          );
          if (conn.itemID) {
            c.setItemID(conn.itemID);
          }
          return this.addConnection(c);
        }
      })
    );
  }

  checkDeleteFunction() {
    return (
      this.keys["Delete"] ||
      /** for MacOS */
      (this.keys["MetaLeft"] && this.keys["Backspace"]) ||
      (this.keys["AltLeft"] &&
        this.keys["MetaLeft"] &&
        this.keys["Backspace"]) ||
      (this.keys["ShiftLeft"] &&
        this.keys["MetaLeft"] &&
        this.keys["Backspace"])
    );
  }
}
