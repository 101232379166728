<template>
  <v-checkbox
    v-model="model"
    color="primary"
    :label="props.table ? undefined : Utils.getLabel(props.propKey, props.prop)"
    :disabled="Utils.isDisabledCheckbox(props.item, props.propKey, props.prop)"
    :hide-details="!props.errorMessages"
    :error-messages="props.errorMessages"
    :class="getCheckboxStyle(props.propKey)"
    @update:modelValue="
      Utils.update(props.item, props.propKey, Boolean, $event)
    ">
  </v-checkbox>
</template>

<script setup lang="ts">
import { defineProps, ref } from "vue";
import Utils from "@/components/item/utils";
import { ItemPropsType } from "ITDAModelTypes";
const props = defineProps<ItemPropsType>();

const model = ref(Utils.getValue(props.item, props.propKey));
const getCheckboxStyle = (propKey: string) => {
  switch (propKey) {
    case "gated":
      return "gated-checkbox-style";
    default:
      return "";
  }
};
</script>

<style lang="css" scoped>
.gated-checkbox-style {
  margin-left: 1.5rem;
}
</style>
