/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:34.620490
 */
export default {
    "startOffset": "0x220",
    "size": "0x8",
    "alignSize": "0x8",
    "sfrs": [
        {
            "name": "RESET_CTRL",
            "alignOffset": "0x0",
            "description": "Soft Reset Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_SW_RESET",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Software Reset Trigger for {{name}}\nWhen this Field is set to 1, NMI_IN__SFR_SW_RESET becomes High\nIf NMI_ENABLE__SFR_SW_RESET = 1, PMR PC branches to START_NMI",
                    "initValue": "0x0",
                    "nc": "SFR_SW_RESET"
                }
            ]
        },
        {
            "name": "RESET_FUNC",
            "alignOffset": "0x4",
            "description": "Soft Reset Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_SOFT_RESET",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "SOC Soft Reset Assert Signal for {{name}}\nWhen this Field is set to 1, the entire SOC under {{name}} is reset by Soft Reset",
                    "initValue": "0x0",
                    "nc": "SFR_SOFT_RESET"
                }
            ]
        }
    ]
}