export class SourceType {
  private index: number;
  private key: keyof typeof SourceType;
  private color: string;

  static POWERSOURCE = new SourceType(0, "POWERSOURCE", "none");
  static GROUND = new SourceType(0, "GROUND", "lightgrey");

  private constructor(
    index: number,
    key: keyof typeof SourceType,
    color: string
  ) {
    this.index = index;
    this.key = key;
    this.color = color;
  }

  getColor(): string {
    return this.color;
  }
}

export class ModuleType {
  private index: number;
  private key: keyof typeof ModuleType;
  private color: string;

  static DUT = new ModuleType(0, "DUT", "none");
  static UPF = new ModuleType(0, "UPF", "none");

  private constructor(
    index: number,
    key: keyof typeof ModuleType,
    color: string
  ) {
    this.index = index;
    this.key = key;
    this.color = color;
  }

  getColor(): string {
    return this.color;
  }
}

export class InstanceType {
  private index: number;
  private key: keyof typeof InstanceType;
  private color: string;
  private parent?: string;

  static IP = new InstanceType(0, "IP", "ghostwhite");
  static PMD = new InstanceType(0, "PMD", "ghostwhite");
  static BLOCK = new InstanceType(0, "BLOCK", "white");
  static CMU = new InstanceType(0, "CMU", "skyblue");
  static PMC = new InstanceType(0, "PMC", "pink");
  static PMIF = new InstanceType(0, "PMIF", "ghostwhite");
  static SDC = new InstanceType(0, "SDC", "white");
  static POWERDOMAIN = new InstanceType(0, "POWERDOMAIN", "white");
  static HARDMACRO = new InstanceType(0, "HARDMACRO", "pink");

  private constructor(
    index: number,
    key: keyof typeof InstanceType,
    color: string
  ) {
    this.index = index;
    this.key = key;
    this.color = color;
    // this.parent = parent;
  }

  static getKeys(): string[] {
    return Object.keys(this);
  }

  static getValues(): InstanceType[] {
    return Object.values(this);
  }

  static getEntries(): [string, InstanceType][] {
    return Object.entries(this);
  }

  static getObjByKey(key: keyof typeof InstanceType): InstanceType | undefined {
    return this[key] as InstanceType;
  }

  getKey(): keyof typeof InstanceType {
    return this.key;
  }

  getColor(): string {
    return this.color;
  }
}

export class NodeType {
  private index: number;
  private key: keyof typeof NodeType;
  private title: string;
  private width: number;
  private height: number;
  private subType: any;
  private color: string | undefined;

  // Node의 width, height는 port 1개 기준으로 입력값을 받도록 설정
  static ITDATestA = new NodeType(0, "ITDATestA", "TEST_A", 180, 180);
  static ITDATestB = new NodeType(0, "ITDATestB", "TEST_B", 180, 180);
  static ITDABUFFER = new NodeType(
    0,
    "ITDABUFFER",
    "BUFFER",
    60,
    40,
    "lightgreen"
  );
  static ITDADIVIDER = new NodeType(
    0,
    "ITDADIVIDER",
    "DIVIDER",
    100,
    60,
    "bisque"
  );
  static ITDAFREQMON = new NodeType(
    0,
    "ITDAFREQMON",
    "FREQMON",
    60,
    40,
    "crimson"
  );
  static ITDAGATE = new NodeType(0, "ITDAGATE", "GATE", 60, 40, "ghostwhite");
  static ITDALABEL = new NodeType(0, "ITDALABEL", "LABEL", 60, 40, "gold");
  static ITDALABELREF = new NodeType(
    0,
    "ITDALABELREF",
    "LABEL_REF",
    60,
    40,
    "gold"
  );
  static ITDAGFMUX = new NodeType(0, "ITDAGFMUX", "GFMUX", 30, 60, "skyblue");
  static ITDANMDIVIDER = new NodeType(
    0,
    "ITDANMDIVIDER",
    "NMDIVIDER",
    100,
    60,
    "aquamarine"
  );
  static ITDAPLLCTRL = new NodeType(
    0,
    "ITDAPLLCTRL",
    "PLLCTRL",
    160,
    120,
    "cyan"
  );
  static ITDAQCH = new NodeType(0, "ITDAQCH", "QCH", 60, 40, "lightblue");
  static ITDAQCHINFO = new NodeType(
    0,
    "ITDAQCHINFO",
    "QCHINFO",
    80,
    40,
    "white"
  );
  static ITDAREFCLKMUX = new NodeType(
    0,
    "ITDAREFCLKMUX",
    "REFCLKMUX",
    30,
    60,
    "cyan"
  );
  static ITDASOURCE = new NodeType(
    0,
    "ITDASOURCE",
    "SOURCE",
    60,
    40,
    "lightgreen"
  );
  static ITDADFTOCCBUFFER = new NodeType(
    0,
    "ITDADFTOCCBUFFER",
    "OCC BUFFER",
    40,
    40,
    "transparent"
  );
  static ITDAAPM = new NodeType(0, "ITDAAPM", "APM", 80, 80, "white");
  static ITDAREFCLKEN = new NodeType(
    0,
    "ITDAREFCLKEN",
    "REFCLKEN",
    80,
    80,
    "white"
  );
  static ITDAPSW = new NodeType(0, "ITDAPSW", "PSW", 80, 80, "white");
  static ITDAPWREN = new NodeType(0, "ITDAPWREN", "PWREN", 80, 80, "white");
  static ITDAISOEN = new NodeType(0, "ITDAISOEN", "ISOEN", 80, 80, "white");
  static ITDAMEM = new NodeType(0, "ITDAMEM", "MEM", 80, 80, "white");
  static ITDARETENTION = new NodeType(
    0,
    "ITDARETENTION",
    "RETENTION",
    80,
    80,
    "white"
  );
  static ITDAOTP = new NodeType(0, "ITDAOTP", "OTP", 80, 80, "white");
  static ITDAHANDSHAKE = new NodeType(
    0,
    "ITDAHANDSHAKE",
    "HANDSHAKE",
    80,
    80,
    "white"
  );
  static ITDACLINK = new NodeType(0, "ITDACLINK", "CLINK", 80, 80, "white");
  static ITDAPCH = new NodeType(0, "ITDAPCH", "PCH", 80, 160, "white");
  static ITDARESET = new NodeType(0, "ITDARESET", "RESET", 80, 80, "white");
  static ITDAUSERDEFOUT = new NodeType(
    0,
    "ITDAUSERDEFOUT",
    "USERDEFOUT",
    80,
    80,
    "white"
  );
  static ITDAUSERDEFIN = new NodeType(
    0,
    "ITDAUSERDEFIN",
    "USERDEFIN",
    80,
    80,
    "white"
  );
  static ITDAINFORM = new NodeType(0, "ITDAINFORM", "INFORM", 80, 80, "white");
  static ITDATIMEOUT = new NodeType(
    0,
    "ITDATIMEOUT",
    "TIMEOUT",
    80,
    80,
    "white"
  );
  static ITDAPMRCTRL = new NodeType(
    0,
    "ITDAPMRCTRL",
    "PMRCTRL",
    80,
    80,
    "white"
  );
  static ITDALATCH = new NodeType(0, "ITDALATCH", "LATCH", 80, 80, "white");
  static ITDAINTRSFR = new NodeType(
    0,
    "ITDAINTRSFR",
    "INTRSFR",
    80,
    80,
    "white"
  );
  static ITDAINTREXT = new NodeType(
    0,
    "ITDAINTREXT",
    "INTREXT",
    80,
    80,
    "white"
  );
  static ITDATIMER = new NodeType(0, "ITDATIMER", "TIMER", 80, 80, "white");
  static ITDAPMDGRP = new NodeType(0, "ITDAPMDGRP", "PMDGRP", 120, 40, "white");

  static ITDASEQSTART = new NodeType(
    0,
    "ITDASEQSTART",
    "START",
    120,
    40,
    "#F2F2F2"
  ); // gray
  static ITDASEQEND = new NodeType(0, "ITDASEQEND", "END", 120, 40, "#F2F2F2"); // gray
  static ITDASEQWRITE = new NodeType(
    0,
    "ITDASEQWRITE",
    "WRITE",
    120,
    40,
    "#34D399"
  ); // green
  static ITDASEQREADWAIT = new NodeType( // red
    0,
    "ITDASEQREADWAIT",
    "READWAIT",
    120,
    40,
    "#F87171"
  );
  static ITDASEQWAIT = new NodeType(
    0,
    "ITDASEQWAIT",
    "WAIT",
    120,
    40,
    "#38BDF8"
  ); // blue
  static ITDASEQIF = new NodeType(0, "ITDASEQIF", "IF", 120, 40, "#FB923C"); // orange
  static ITDASEQGOTO = new NodeType(
    0,
    "ITDASEQGOTO",
    "GOTO",
    120,
    40,
    "#A78BFA"
  ); // violet
  static ITDASEQCALL = new NodeType(
    0,
    "ITDASEQCALL",
    "CALL",
    120,
    40,
    "#FBBF24"
  ); // yellow
  static ITDASEQLABEL = new NodeType(0, "ITDASEQLABEL", "", 120, 40, "#F2F2F2");
  static ITDASEQLABELREF = new NodeType(
    0,
    "ITDASEQLABELREF",
    "",
    120,
    40,
    "#F2F2F2"
  );
  static ITDASEQBACK = new NodeType(
    0,
    "ITDASEQBACK",
    "BACK",
    120,
    40,
    "#F2F2F2"
  ); // gray  // 6칸 4칸

  static ITDAMODULEUPF = new NodeType(
    0,
    "ITDAMODULEUPF",
    "",
    1000,
    1000,
    undefined,
    ModuleType.UPF
  );

  static ITDAINSTANCEIP = new NodeType(
    0,
    "ITDAINSTANCEIP",
    "IP",
    80,
    80,
    undefined,
    InstanceType.IP
  );
  static ITDAINSTANCEPMD = new NodeType(
    0,
    "ITDAINSTANCEPMD",
    "PMD",
    80,
    80,
    undefined,
    InstanceType.PMD
  );
  static ITDAINSTANCESDC = new NodeType(
    0,
    "ITDAINSTANCESDC",
    "Instance",
    80,
    40,
    undefined,
    InstanceType.SDC
  );
  static ITDAINSTANCESDCPHANTOMGROUP = new NodeType(
    0,
    "ITDAINSTANCESDCPHANTOMGROUP",
    "Instance",
    80,
    40,
    undefined,
    InstanceType.SDC
  );
  static ITDAINSTANCEBLOCK = new NodeType(
    0,
    "ITDAINSTANCEBLOCK",
    "Block",
    120,
    120,
    undefined,
    InstanceType.BLOCK
  );
  static ITDAINSTANCECMU = new NodeType(
    0,
    "ITDAINSTANCECMU",
    "CMU",
    120,
    120,
    undefined,
    InstanceType.CMU
  );
  static ITDAINSTANCEPMC = new NodeType(
    0,
    "ITDAINSTANCEPMC",
    "PMC",
    120,
    120,
    undefined,
    InstanceType.PMC
  );
  static ITDAINSTANCEPMIF = new NodeType(
    0,
    "ITDAINSTANCEPMIF",
    "PMIF",
    120,
    120,
    undefined,
    InstanceType.PMIF
  );
  static ITDAINSTANCEPOWERDOMAIN = new NodeType(
    0,
    "ITDAINSTANCEPOWERDOMAIN",
    "POWER DOMAIN",
    120,
    120,
    undefined,
    InstanceType.POWERDOMAIN
  );
  static ITDAINSTANCEHARDMACRO = new NodeType(
    0,
    "ITDAINSTANCEHARDMACRO",
    "HARD MACRO",
    120,
    120,
    undefined,
    InstanceType.HARDMACRO
  );
  static ITDAUPFPOWERSOURCE = new NodeType(
    0,
    "ITDAUPFPOWERSOURCE",
    "POWER SOURCE",
    80,
    80,
    undefined,
    SourceType.POWERSOURCE
  );

  static ITDAUPFGROUND = new NodeType(
    0,
    "ITDAUPFGROUND",
    "UPF GROUND",
    80,
    80,
    undefined,
    SourceType.GROUND
  );

  static ITDAUPFPHANTOMPOWERSOURCE = new NodeType(
    0,
    "ITDAUPFPHANTOMPOWERSOURCE",
    "",
    40,
    40,
    undefined,
    SourceType.POWERSOURCE
  );

  static ITDAUPFPHANTOMGROUND = new NodeType(
    0,
    "ITDAUPFPHANTOMGROUND",
    "",
    40,
    40,
    undefined,
    SourceType.GROUND
  );

  static ITDAUPFLEVELSHIFTER = new NodeType(
    0,
    "ITDAUPFLEVELSHIFTER",
    "LEVEL SHIFTER",
    120,
    120,
    "white"
  );

  static ITDASDCCLOCKGROUP = new NodeType(
    0,
    "ITDASDCCLOCKGROUP",
    "",
    150,
    40,
    undefined
  );

  // static ITDASDC = new NodeType(0, "ITDASDC", "Instance", 80, 80);

  private constructor(
    index: number,
    key: keyof typeof NodeType,
    title: string,
    width: number,
    height: number,
    color?: string,
    subType?: any
  ) {
    this.index = index;
    this.key = key;
    this.title = title;
    this.width = width;
    this.height = height;
    this.color = color;
    this.subType = subType;
  }

  static getKeys(): string[] {
    return Object.keys(this);
  }

  static getValues(): NodeType[] {
    return Object.values(this);
  }

  static getEntries(): [string, NodeType][] {
    return Object.entries(this);
  }

  static getObjByKey(key: keyof typeof NodeType): NodeType | undefined {
    return this[key] as NodeType;
  }

  getKey(): keyof typeof NodeType {
    return this.key;
  }

  getTitle() {
    return this.title;
  }

  getWidth() {
    return this.width;
  }

  getHeight() {
    return this.height;
  }

  getSubType() {
    return this.subType;
  }

  getColor() {
    return this.color ? this.color : "transparent";
  }
}

export class DiagramType {
  private index: number;
  private key: string;
  private title: string;

  static CMU = new DiagramType(0, "CMU", "CMU");
  static IP = new DiagramType(0, "IP", "IP");
  static PMD = new DiagramType(0, "PMD", "PMD");
  static PMC = new DiagramType(0, "PMC", "PMC");
  static SEQ = new DiagramType(0, "SEQ", "SEQ");
  static DUT = new DiagramType(0, "DUT", "DUT");
  static SDC = new DiagramType(0, "SDC", "SDC");
  static UPF = new DiagramType(0, "UPF", "UPF");

  private constructor(index: number, key: string, title: string) {
    this.index = index;
    this.key = key;
    this.title = title;
  }

  static getKeys(): string[] {
    return Object.keys(this);
  }

  static getValues(): DiagramType[] {
    return Object.values(this);
  }

  static getEntries(): [string, DiagramType][] {
    return Object.entries(this);
  }

  static getObjByKey(key: keyof typeof DiagramType): DiagramType | undefined {
    return this[key] as DiagramType;
  }

  getKey(): string {
    return this.key;
  }

  getTitle(): string {
    return this.title;
  }
}

export interface BoundingBoxType {
  top: number;
  left: number;
  right: number;
  bottom: number;
  width: number;
  height: number;
}

export interface NodeCustomProps {
  width?: number;
  height?: number;
  resizable?: boolean;
  parent?: string;
  itemID?: string;
}

export interface ImportNodeType {
  parent?: string;
  itemID: string;
  itemOptions: Record<string, any>;
  type: NodeType;
  width: number;
  height: number;
  view: {
    position: {
      x: number;
      y: number;
    };
  };
}

export interface ImportConnectionType {
  itemID?: string;
  source: string;
  sourceOutput: string;
  target: string;
  targetInput: string;
}
