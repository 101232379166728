/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import UPFPowerCtrlUnitEvent from '@/model/src/events/UPFPowerCtrlUnitEvent'
import UPFPowerCtrlUnitChecker from '@/model/src/checkers/UPFPowerCtrlUnitChecker'
export default class UPFPowerCtrlUnit extends Node {
    FID_UPFPOWERCTRLUNIT_NAME = 100
    FID_UPFPOWERCTRLUNIT_HIERARCHY = 101
    FID_UPFPOWERCTRLUNIT_MODULE = 102
    FID_UPFPOWERCTRLUNIT_LAST = 102

    constructor(obj) {
        super(Object.assign(obj,{itemType:"UPFPowerCtrlUnit"}))
        
        this.setEvent(new UPFPowerCtrlUnitEvent(this))
        this.setChecker(new UPFPowerCtrlUnitChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "Power Control Unit"
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    if (this.properties.hierarchy === undefined) {
        this.properties.hierarchy = ""
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "UPFPowerCtrlUnit"
    }

    getSchema() {
        return {"name": {"type": "string", "readOnly": true, "default": "NONAME", "category": "IP", "multiple": false, "hidden": false}, "hierarchy": {"type": "string", "label": "Hierarchy Path", "default": "", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "module": {"type": "item", "label": "PMR Module reference", "desc": "PMR Module reference", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "readOnly": true, "default": "NONAME", "category": "IP", "multiple": false, "hidden": false}, "hierarchy": {"type": "string", "label": "Hierarchy Path", "default": "", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "module": {"type": "item", "label": "PMR Module reference", "desc": "PMR Module reference", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFPOWERCTRLUNIT_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_UPFPOWERCTRLUNIT_NAME
    }
    

    setHierarchy(hierarchy, event=true) {
        if ((hierarchy !== null) && (hierarchy !== undefined)) {
            if (hierarchy.constructor !== String) {
                throw `hierarchy(${hierarchy}) must be String type`
            }
        }
        const newVal = (hierarchy === null || hierarchy === undefined) ? undefined : hierarchy
        const oldVal = this.properties.hierarchy
        this.properties.hierarchy = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFPOWERCTRLUNIT_HIERARCHY, newVal, oldVal)
        }
    }
    getHierarchy(pure = false) {
        return this.properties.hierarchy
        
    }
    getFidHierarchy() {
        return this.FID_UPFPOWERCTRLUNIT_HIERARCHY
    }
    

    setModule(module, event=true) {
        if ((module !== null) && (module !== undefined)) {
            if (!(module instanceof Item) && (module.constructor !== String)) {
                throw `module(${module}) must be Item or String type`
            }
        }
        const newVal = (module === null || module === undefined) ? undefined : (module.constructor === String) ? module : module.getId()
        const oldVal = this.properties.module
        this.properties.module = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFPOWERCTRLUNIT_MODULE, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getModule(pure = false) {
        return pure ? this.properties.module : this.getRefItem(this.properties.module)
        
    }
    getFidModule() {
        return this.FID_UPFPOWERCTRLUNIT_MODULE
    }
    

    getFidLast() {
        return this.FID_UPFPOWERCTRLUNIT_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_UPFPOWERCTRLUNIT_NAME:
                return "name"

            case this.FID_UPFPOWERCTRLUNIT_HIERARCHY:
                return "hierarchy"

            case this.FID_UPFPOWERCTRLUNIT_MODULE:
                return "module"

            default:
                return ""
        }
    }


}