import ItemType from "./src/ItemType";
export { ItemType };
// export { ItemType };

import {
  DB,
  DBSchema,
  ExternalSpecPLL,
  ExternalSpecHM,
  ExternalSpecGeneric,
} from "./store/types";
export { DB, DBSchema, ExternalSpecPLL, ExternalSpecHM, ExternalSpecGeneric };
declare module "ITDAModelTypes" {
  export class Error {
    getItemId(): string;
    getType(): string;
    getDescription(): string;
    getReferences(): Error[];
  }

  export class Mapper {
    getSource(): Item;
    getTarget(): Item;
  }
}

type PropType =
  | "string"
  | "integer"
  | "boolean"
  | "enum"
  | "item"
  | "textarea"
  | "color";
type PropTarget = string;
type defaultType = number | string | boolean | Array<any>;

interface PropertyBase {
  type: PropType;
  label?: string;
  desc?: string;
  multiple?: boolean;
  hidden?: boolean;
  readOnly?: boolean;
  default?: defaultType;
}

interface PropertyEnum extends PropertyBase {
  type: "enum";
  targets: Array<PropTarget>;
}

interface PropertyItemBase extends PropertyBase {
  type: "item";
}

interface PropertyItemWithTargets extends PropertyItemBase {
  multiple: true;
  targets: PropTarget[];
}

interface PropertyItemWithoutTargets extends PropertyItemBase {
  multiple: false;
  targets?: never;
}

type PropertyItem = PropertyItemWithTargets | PropertyItemWithoutTargets;

interface PropertyWithoutTargets extends PropertyBase {
  type: Exclude<PropType, "enum" | "item">;
  targets?: never;
}

type Property = PropertyEnum | PropertyItem | PropertyWithoutTargets;

interface ItemSchema {
  [key: string]: Property;
}

type PropertyValue = string | number | boolean | Array<string> | undefined;

interface ItemProperties {
  [key: string]: PropertyValue;
}

interface NodeProperties {
  [key: string]: PropertyValue;
}

interface Item {
  title: string;
  desc?: string;
  id: string;
  hidden: boolean;
  view: {
    diagram: boolean;
    table: boolean;
  };
  properties: ItemProperties;
  children: Array<Item> | null;
  [key: string]: any;
}

interface ItemErrorMessage {
  [type: string]: {
    [fid: number]: Error;
  };
}

interface ItemErrorMsaageMap {
  [id: string]: ItemErrorMessage;
}

export {
  ItemErrorMsaageMap,
  ItemProperties,
  ItemSchema,
  Item,
  Property,
  PropertyValue,
  NodeProperties,
};

export interface ChildSpec {
  name: string;
  auto: boolean;
  hidden?: boolean;
  interface?: string;
  properties?: {
    type: string;
  };
  title: string;
  searchable?: boolean;
  headers?: ChildTableHeader[];
}

export interface ChildTableHeader {
  title?: string;
  value: string;
  align?: "start" | "end" | "center";
  valueType: { type: PropType };
}

export interface ItemViewSpec {
  diagram: boolean;
  table: boolean;
}

export type DomainKeys = keyof typeof Domain;
export class Domain {
  private index: number;
  private key: DomainKeys;
  private title: string;

  static Clock = new Domain(0, "Clock", "IP");
  static Power = new Domain(1, "Power", "IP");
  static DFT = new Domain(2, "DFT", "IP");
  static Wrapper = new Domain(3, "Wrapper", "IP");
  static DUT = new Domain(4, "DUT", "SOC");
  static Voltage = new Domain(5, "Voltage", "SOC");
  static SDC = new Domain(6, "SDC", "SYN");
  static UPF = new Domain(7, "UPF", "SYN");

  private constructor(index: number, key: DomainKeys, title: string) {
    this.index = index;
    this.key = key;
    this.title = title;
  }

  static getKeys(): DomainKeys[] {
    return Object.keys(this) as DomainKeys[];
  }

  static getValues(): Domain[] {
    return Object.values(this);
  }

  static getEntries(): [string, Domain][] {
    return Object.entries(this);
  }

  static getObjByKey(key: DomainKeys): Domain | undefined {
    return this[key] as Domain;
  }

  getKey() {
    return this.key;
  }

  getTitle() {
    return this.title;
  }
}
export class Module {
  private index: number;
  private key: string;
  private title: string;

  static CMU = new Module(0, "CMUFolder", "CMU");
  static PMD = new Module(0, "PowerPMDFolder", "PMD");
  static PMC = new Module(0, "PowerPMCFolder", "PMC");
  static WrapperDFT = new Module(0, "WrapperDFTFolder", "DFT");
  static DUT = new Module(0, "ModuleFolder", "DUT");
  static Voltage = new Module(0, "Voltage", "Voltage");
  static SDC = new Module(0, "SDCModuleFolder", "SDC");
  static UPF = new Module(0, "UPFModuleFolder", "UPF");

  private constructor(index: number, key: string, title: string) {
    this.index = index;
    this.key = key;
    this.title = title;
  }

  static getKeys(): string[] {
    return Object.keys(this);
  }

  static getValues(): Module[] {
    return Object.values(this);
  }

  static getEntries(): [string, Module][] {
    return Object.entries(this);
  }

  static getObjByKey(key: keyof typeof Module): Module | undefined {
    return this[key] as Module;
  }

  getKey() {
    return this.key;
  }

  getTitle() {
    return this.title;
  }
}

export const enum RefItemElementType {
  STRING = "string",
  INTEGER = "integer",
  BOOLEAN = "boolean",
  ENUM = "enum",
  CHIP = "chip",
  TEXTAREA = "textarea",
}

/* Vuetify component의 variant property */
export const VariantLevel = {
  LEVEL0: "LEVEL0",
  LEVEL1: "LEVEL1",
  LEVEL2: "LEVEL2",
} as const;
export type VariantLevel = (typeof VariantLevel)[keyof typeof VariantLevel];

export interface RefItemElementTarget {
  key: string;
  item: Item;
  closable: boolean;
  disabled?: boolean;
}

export interface RefItemElement {
  icon?: string;
  variant?: VariantLevel;
  disabled: boolean;
  type: RefItemElementType;
  propKey: string;
  label: string;
  multiple?: boolean;
  model?: string | string[];
  targets?: RefItemElementTarget[];
  event: RefItemEvent;
}

export type RefItemEvent = (
  item: Item,
  elem: RefItemElement,
  value: string | string[]
) => void;

export interface ItemPropsType {
  item: Item;
  propKey: string;
  prop: Property;
  itemRef?: RefItemElement;
  table?: boolean;
  config?: boolean;
  errorMessages?: string;
}

export interface ItemConfigSchema {
  prop: string;
  label: string;
  type: string;
}

export interface ConfigurationMapType {
  [nodeID: string]: ConfigurationBase & { config?: ConfigurationFreqeuncy[] };
}

export interface ConfigurationFreqeuncy {
  id: string;
  name: string;
  freq: number;
  props: {
    [key: string]: string | number | undefined;
  };
  extSource?: {
    itemID: string;
    sdcID: string;
    config: string;
  };
}

export interface ConfigurationBase {
  itemID: string;
  sdcID: string;
  nodeType?: string;
}

export type ValidationResult = string | boolean;
export type ValidationRule =
  | ValidationResult
  | PromiseLike<ValidationResult>
  | ((value: any) => ValidationResult)
  | ((value: any) => PromiseLike<ValidationResult>);
