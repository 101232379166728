import { Context } from "../types";
import Canvas from "@/canvas";
import { NodeType } from "@/canvas/types";
import { Item, ItemType } from "ITDAModelTypes";
import API from "@/api/internal";
import Utils from "../utils";
import Store from "@/components/index";

export const itemupdated = async (context: Context): Promise<boolean> => {
  const item = context.options.item;
  const editor = Canvas.getEditor();
  if (item.isNodeType() && !item.getOriginItem) {
    const diagram = API.getParentDiagram(item);
    const editor = Canvas.getEditor(diagram?.getId());
    const node = editor?.getNode(item.getNodeID());
    if (node) {
      switch (node.getType()) {
        case NodeType.ITDAINSTANCESDC:
          if (context.options.propKey === "module") {
            if (item.getModule()) {
              await Utils.SDCPostProcess(item);
              await Utils.arrangeSDCPhantomGroups(item);
            }
          }
          break;
        case NodeType.ITDAGFMUX:
        case NodeType.ITDAINSTANCEPMD:
        case NodeType.ITDAPMDGRP:
          Utils.createNodePorts(node, item);
          break;
        case NodeType.ITDALABEL:
        case NodeType.ITDALABELREF:
          if (context.options.propKey === "type") {
            node.update();
          }
          if (context.options.propKey === "itemLabel") {
            const diagram = API.getParentDiagram(item);
            if (diagram) {
              Canvas.getDataFlowPlugin(diagram.getId()).process();
            }
          }
          break;
        case NodeType.ITDASEQWRITE:
        case NodeType.ITDASEQREADWAIT:
        case NodeType.ITDASEQIF:
        case NodeType.ITDASEQWAIT:
          break;
        case NodeType.ITDASEQLABEL:
          node.update();
          item
            .getLabelRefs()
            .forEach((labelRef: Item) =>
              editor.getNode(labelRef.getNodeID())?.update()
            );
          break;
        case NodeType.ITDASEQLABELREF:
          node.update();
          break;
        case NodeType.ITDAINSTANCEBLOCK:
        case NodeType.ITDAINSTANCECMU:
        case NodeType.ITDAINSTANCEPMC:
        case NodeType.ITDAINSTANCEPMIF:
          if (context.options.propKey === "upper") {
            Utils.updateParentID(item, context.options.newVal);
            await Utils.updateParentScope(context.options.newVal);
            await Utils.updateParentScope(context.options.oldVal);
          }
          break;
        case NodeType.ITDASDCCLOCKGROUP:
          if (context.options.propKey === "color") {
            const instance = item.getParent();
            instance.getSDCPhantomGroups().forEach((grp: Item) => {
              grp.getSDCPhantoms().forEach((pt: Item) => {
                const node = editor.getNode(pt.getNodeID());
                if (pt.getClockGroup() === item) {
                  node?.getCtrlSDC().setBackgroundColor(item.getColor());
                  node?.getCtrlSDC().update();
                }
              });
            });
          }
          node.update();
          break;
        default:
          break;
      }
    }
  } else {
    switch (item.getItemType()) {
      case ItemType.ClockPLLCtrlEXT:
      case ItemType.ClockDividerEXT:
      case ItemType.ClockMultiplexerEXT:
      case ItemType.ClockRefCLKMultiplexerEXT:
      case ItemType.ClockNMDividerEXT:
      case ItemType.ClockGateEXT:
      case ItemType.ClockFreqmonEXT:
        editor.getNode(item.getParent().getNodeID()).update();
        break;
      case ItemType.SDCPhantom:
        if (context.options.propKey === "ClockPoint") {
          Utils.setSDCValue(item);
          const node = editor.getNode(item.getNodeID());
          node
            .getCtrlSDC()
            ?.setBackgroundColor(
              item.getClockGroup()?.getColor() || "transparent"
            );
          Store.updateComponent("NAV_RIGHT_PROPERTY");
        }
        break;
      case ItemType.Configuration:
        if (context.options.propKey === "selected") {
          const cmu = item.getParent(ItemType.ClockCMU);
          if (cmu) {
            const config = API.getSelectedCMUConfiguration(item);
            if (config) {
              const diagrams = cmu.getClockDiagramFolder().getClockDiagrams();
              for (let i = 0; i < diagrams.length; i++) {
                Canvas.getDataFlowPlugin(diagrams[i].getId())?.process();
              }
            }
          }

          if (item.getParent(ItemType.VoltageLevelFolder)) {
            const topItem = API.getTopItem();
            const sdcModules = topItem?.getSDCModuleFolder().getSDCModules();
            for (const module of sdcModules) {
              const sdcDiagram = module.getSDCDiagram();
              await Canvas.getDataFlowPlugin(sdcDiagram.getId())?.process();
            }
            return true;
          }
        }
        break;
      case ItemType.ConfigurationElement:
        if (context.options.propKey === "value") {
          const cmu = item.getParent(ItemType.ClockCMU);
          if (cmu) {
            const config = API.getSelectedCMUConfiguration(item);
            if (config) {
              const diagrams = cmu.getClockDiagramFolder().getClockDiagrams();
              for (let i = 0; i < diagrams.length; i++) {
                Canvas.getDataFlowPlugin(diagrams[i].getId()).process();
              }
            }
          }

          const refItem = item.getItemRef();
          if (refItem && refItem.getItemType() === ItemType.SDCInstance) {
            const sdcDiagram = refItem.getParent();
            Canvas.getDataFlowPlugin(sdcDiagram.getId()).process();
          }
        }
        break;
      case ItemType.PowerAPMPWRDOWN:
      case ItemType.PowerAPMPWRUP:
        if (context.options.propKey === "name") {
          const node = editor?.getNode(item.getParent()?.getNodeID());
          node.update();
        }
        break;
      default:
        break;
    }
  }
  return true;
};
