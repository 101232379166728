/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:34.620490
 */
export default {
    "startOffset": "0x400",
    "size": "0x80",
    "alignSize": "0x4",
    "sfrs": [
        {
            "name": "INFORM",
            "alignOffset": "0x0",
            "description": "Inform Register for {{name}}",
            "fields": [
                {
                    "name": "SFR_DATA",
                    "index": 0,
                    "size": 16,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "Inform Register for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DATA",
                    "floating": true
                }
            ]
        }
    ]
}