/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import SFREvent from '@/model/src/events/SFREvent'
import SFRChecker from '@/model/src/checkers/SFRChecker'
export default class SFR extends Item {
    FID_SFR_NAME = 0
    FID_SFR_TYPE = 1
    FID_SFR_OFFSET = 2
    FID_SFR_DESCRIPTION = 3
    FID_SFR_ITEMREF = 4
    FID_SFR_LAST = 4

    constructor(obj) {
        super(Object.assign(obj,{itemType:"SFR"}))
        
        this.setEvent(new SFREvent(this))
        this.setChecker(new SFRChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    if (this.properties.description === undefined) {
        this.properties.description = ""
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "SFR"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "type": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "offset": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "description": {"type": "textarea", "label": "Description", "readOnly": true, "default": "", "category": "IP", "multiple": false, "hidden": false}, "itemRef": {"type": "item", "label": "item reference", "desc": "item reference", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "type": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "offset": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "description": {"type": "textarea", "label": "Description", "readOnly": true, "default": "", "category": "IP", "multiple": false, "hidden": false}, "itemRef": {"type": "item", "label": "item reference", "desc": "item reference", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "SFRField", "auto": false, "searchable": false, "title": "Field List", "headers": [{"title": "Name", "align": "start", "filterable": true, "value": "name", "sortable": true}, {"title": "Index", "filterable": true, "value": "index", "align": "start", "sortable": true}, {"title": "Size", "filterable": true, "value": "size", "align": "start", "sortable": true}, {"title": "Access", "filterable": true, "value": "access", "align": "start", "sortable": true}, {"title": "InitValue", "filterable": true, "value": "initValue", "align": "start", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SFR_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_SFR_NAME
    }
    

    setType(type, event=true) {
        if ((type !== null) && (type !== undefined)) {
            if (type.constructor !== String) {
                throw `type(${type}) must be String type`
            }
        }
        const newVal = (type === null || type === undefined) ? undefined : type
        const oldVal = this.properties.type
        this.properties.type = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SFR_TYPE, newVal, oldVal)
        }
    }
    getType(pure = false) {
        return this.properties.type
        
    }
    getFidType() {
        return this.FID_SFR_TYPE
    }
    

    setOffset(offset, event=true) {
        if ((offset !== null) && (offset !== undefined)) {
            if (offset.constructor !== String) {
                throw `offset(${offset}) must be String type`
            }
        }
        const newVal = (offset === null || offset === undefined) ? undefined : offset
        const oldVal = this.properties.offset
        this.properties.offset = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SFR_OFFSET, newVal, oldVal)
        }
    }
    getOffset(pure = false) {
        return this.properties.offset
        
    }
    getFidOffset() {
        return this.FID_SFR_OFFSET
    }
    

    setDescription(description, event=true) {
        if ((description !== null) && (description !== undefined)) {
            if (description.constructor !== String) {
                throw `description(${description}) must be String type`
            }
        }
        const newVal = (description === null || description === undefined) ? undefined : description
        const oldVal = this.properties.description
        this.properties.description = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SFR_DESCRIPTION, newVal, oldVal)
        }
    }
    getDescription(pure = false) {
        return this.properties.description
        
    }
    getFidDescription() {
        return this.FID_SFR_DESCRIPTION
    }
    

    setItemRef(itemRef, event=true) {
        if ((itemRef !== null) && (itemRef !== undefined)) {
            if (!(itemRef instanceof Item) && (itemRef.constructor !== String)) {
                throw `itemRef(${itemRef}) must be Item or String type`
            }
        }
        const newVal = (itemRef === null || itemRef === undefined) ? undefined : (itemRef.constructor === String) ? itemRef : itemRef.getId()
        const oldVal = this.properties.itemRef
        this.properties.itemRef = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SFR_ITEMREF, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getItemRef(pure = false) {
        return pure ? this.properties.itemRef : this.getRefItem(this.properties.itemRef)
        
    }
    getFidItemRef() {
        return this.FID_SFR_ITEMREF
    }
    

    getFidLast() {
        return this.FID_SFR_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_SFR_NAME:
                return "name"

            case this.FID_SFR_TYPE:
                return "type"

            case this.FID_SFR_OFFSET:
                return "offset"

            case this.FID_SFR_DESCRIPTION:
                return "description"

            case this.FID_SFR_ITEMREF:
                return "itemRef"

            default:
                return ""
        }
    }


    newSFRField(properties={}, title = "", domain = "") {
        return this.createChild(this, "SFRField", properties, title, domain)
    }
    remSFRField(obj) {
        return this.removeChild(obj)
    }
    getSFRFields() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "SFRField")
            return res
        })
    }
}