/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import UPFPowerDomainInstanceEvent from '@/model/src/events/UPFPowerDomainInstanceEvent'
import UPFPowerDomainInstanceChecker from '@/model/src/checkers/UPFPowerDomainInstanceChecker'
export default class UPFPowerDomainInstance extends Node {
    FID_UPFPOWERDOMAININSTANCE_NAME = 100
    FID_UPFPOWERDOMAININSTANCE_MODULE = 101
    FID_UPFPOWERDOMAININSTANCE_LAST = 101

    constructor(obj) {
        super(Object.assign(obj,{itemType:"UPFPowerDomainInstance"}))
        
        this.setEvent(new UPFPowerDomainInstanceEvent(this))
        this.setChecker(new UPFPowerDomainInstanceChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "PD_NONAME"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "UPFPowerDomainInstance"
    }

    getSchema() {
        return {"name": {"type": "string", "label": "scope", "default": "PD_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "module": {"type": "item", "label": "module reference", "desc": "module reference", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "label": "scope", "default": "PD_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "module": {"type": "item", "label": "module reference", "desc": "module reference", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "UPFPhantomPowerSource", "auto": false, "searchable": false, "title": "Power Source Mapper", "headers": [{"title": "Power Supply", "value": "powerSource", "align": "start", "sortable": true}, {"title": "Mapped Power Source", "value": "mappedPower", "align": "start", "sortable": true}], "category": "IP"}, {"name": "UPFPhantomGround", "auto": false, "searchable": false, "title": "Ground Mapper", "headers": [{"title": "Power Supply", "value": "powerSource", "align": "start", "sortable": true}, {"title": "Mapped Ground", "value": "mappedPower", "align": "start", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFPOWERDOMAININSTANCE_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_UPFPOWERDOMAININSTANCE_NAME
    }
    

    setModule(module, event=true) {
        if ((module !== null) && (module !== undefined)) {
            if (!(module instanceof Item) && (module.constructor !== String)) {
                throw `module(${module}) must be Item or String type`
            }
        }
        const newVal = (module === null || module === undefined) ? undefined : (module.constructor === String) ? module : module.getId()
        const oldVal = this.properties.module
        this.properties.module = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFPOWERDOMAININSTANCE_MODULE, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getModule(pure = false) {
        return pure ? this.properties.module : this.getRefItem(this.properties.module)
        
    }
    getFidModule() {
        return this.FID_UPFPOWERDOMAININSTANCE_MODULE
    }
    

    getFidLast() {
        return this.FID_UPFPOWERDOMAININSTANCE_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_UPFPOWERDOMAININSTANCE_NAME:
                return "name"

            case this.FID_UPFPOWERDOMAININSTANCE_MODULE:
                return "module"

            default:
                return ""
        }
    }


    newUPFPhantomPowerSource(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFPhantomPowerSource", properties, title, domain)
    }
    remUPFPhantomPowerSource(obj) {
        return this.removeChild(obj)
    }
    getUPFPhantomPowerSources() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFPhantomPowerSource")
            return res
        })
    }
    newUPFPhantomGround(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFPhantomGround", properties, title, domain)
    }
    remUPFPhantomGround(obj) {
        return this.removeChild(obj)
    }
    getUPFPhantomGrounds() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFPhantomGround")
            return res
        })
    }
}