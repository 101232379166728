import {
  Item,
  ItemSchema,
  ChildSpec,
  ItemViewSpec,
  Property,
} from "@/model/types";
import { ItemType } from "@/model/types";

const isPropertyEnum = (obj: any): boolean => {
  return obj.type === "enum" && Array.isArray(obj.targets);
};

const isPropertyItem = (obj: any): boolean => {
  return obj.type === "item";
};

const isPropertyWithoutTargets = (obj: any): boolean => {
  return (
    obj.type !== "enum" && obj.type !== "item" && obj.targets === undefined
  );
};

const isItemSchema = (obj: any): obj is ItemSchema => {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  for (const key in obj) {
    if (
      !isPropertyEnum(obj[key]) &&
      !isPropertyItem(obj[key]) &&
      !isPropertyWithoutTargets(obj[key])
    ) {
      return false;
    }
  }
  return true;
};

const isValidSchema = (
  item: Item,
  prop: Property,
  propKey: string
): boolean => {
  let res = true;
  res = res && !prop.hidden;
  switch (item.getItemType()) {
    case ItemType.Project:
      return true;
    case ItemType.ClockDivider:
      if (propKey === "clkEnValue") {
        return item.getClkEn();
      } else {
        return res;
      }
    case ItemType.ClockFreqmon:
    case ItemType.ClockBuffer:
      if (propKey === "ECC") {
        return false;
      } else {
        return res;
      }
    case ItemType.ClockQChannelManagerEXT:
      return false;
    case ItemType.Label:
      if (propKey === "numChild") {
        return item.getType() === "external";
      } else {
        return res;
      }
    case ItemType.PowerSequenceWrite:
    case ItemType.PowerSequenceReadWait:
    case ItemType.PowerSequenceWait:
    case ItemType.PowerSequenceIf:
      if (
        propKey === "PMD" &&
        item.getParent().getItemRef()?.getItemType() ===
          ItemType.PowerPMDInstance
      ) {
        return false;
      } else {
        return res;
      }
    case ItemType.SDCPhantom: {
      const originItem = item.getOriginItem();
      if (
        originItem.getItemType() === ItemType.Label ||
        originItem.getItemType() === ItemType.LabelRef
      ) {
        if (propKey !== "ClockPoint") {
          return item.getClockPoint();
        }
      }
      return res;
    }
    default:
      return res;
  }
};

const isValidChildSpec = (spec: ChildSpec) => {
  return !spec.auto && !spec.hidden;
};

export default {
  getSchema: (item: Item): ItemSchema => {
    const res: ItemSchema = {};
    if (item && isItemSchema(item._schema)) {
      // return item._schema;
      Object.keys(item._schema).forEach((key: string) => {
        const prop = item._schema[key];
        if (isValidSchema(item, prop, key)) {
          res[key] = prop;
        }
      });
      return res;
    } else {
      //   throw new Error(`Please check the item schema type`);
      console.error(`Please check the item schema type`);
      return res;
    }
  },
  getViewSpec: (item: Item): ItemViewSpec => {
    return item.view ? item.view : { diagram: false, table: false };
  },
  getChildrenSpec: (item: Item) => {
    return item.getChildrenSpec().reduce((acc: ChildSpec[], cur: ChildSpec) => {
      if (isValidChildSpec(cur)) {
        acc.push(cur);
      }
      return acc;
    }, []);
  },
};
