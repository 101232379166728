import { ItemType, Item } from "ITDAModelTypes";
import { NodeType } from "@/canvas/types";
import Canvas from "@/canvas";
import API from "@/api/internal";
import * as ITDAControl from "@/canvas/controls";

export const SDCPostProcess = async (item: Item) => {
  const diagram = item.getParent();
  const phantomGroups = item.getSDCPhantomGroups();
  const nodeMeta = item.getNodeMetaView();
  const { nodeMetaX, nodeMetaY } = {
    nodeMetaX: nodeMeta.getX(),
    nodeMetaY: nodeMeta.getY(),
  };
  for (let i = phantomGroups.length - 1; i >= 0; i--) {
    const phantomGroup = phantomGroups[i];
    const phantomNodes = phantomGroup.getSDCPhantoms();
    let nodeCount = 0;
    for (let j = phantomNodes.length - 1; j >= 0; j--) {
      const phantomNode = phantomNodes[j];
      if (phantomNode.getNodeType() === NodeType.ITDALABEL.getKey()) {
        await removeLabelItems(diagram, phantomNode);
      } else {
        nodeCount += 1;
      }
    }
    if (nodeCount === 0) {
      await API.removeItem(phantomGroup.getId());
    }
  }
  nodeMeta.setX(nodeMetaX);
  nodeMeta.setY(nodeMetaY);
};

const removeLabelItems = async (diagram: Item, labelItem: Item) => {
  const labelRefs = labelItem.getOriginItem()?.getLabelRefs();
  const labelConn = labelItem.getInputs()[0].getConnections()[0];
  if (labelConn) {
    for (const labelRef of labelRefs) {
      const labelRefPhantom = labelRef.getPhantom();
      const labelRefConns = labelRefPhantom.getOutputs()[0].getConnections();
      for (const labelRefConn of labelRefConns) {
        await API.createItem(diagram, ItemType.Connection.getKey(), {
          sourceOutput: labelConn.getSourceOutput().getId(),
          targetInput: labelRefConn.getTargetInput().getId(),
        });
        API.removeItem(labelRefConn.getId());
      }
      API.removeItem(labelRefPhantom.getId());
    }
    if (labelRefs.length) {
      API.removeItem(labelConn.getId());
      API.removeItem(labelItem.getId());
    }
  }
};

export const arrangeSDCPhantomGroups = async (item: Item) => {
  const area = Canvas.getAreaPlugin();
  const nodeView = area.getNodeView(item.getNodeID());
  const nodePosition = {
    x: item.getNodeMetaView().getX(),
    y: item.getNodeMetaView().getY(),
  };
  const SDCPhantomGroups = item.getSDCPhantomGroups();
  let boundary;
  for (const SDCPhantomGroup of SDCPhantomGroups) {
    boundary = await arrangeSDCPhantomGroup(SDCPhantomGroup, boundary);
  }
  await Canvas.getScopesPlugin().update(item.getNodeID());
  if (nodeView && nodePosition) {
    await nodeView.translate(nodePosition.x, nodePosition.y);
  }
};

const arrangeSDCPhantomGroup = async (
  item: Item,
  boundary:
    | { nw: { x: number; y: number }; se: { x: number; y: number } }
    | undefined
) => {
  const editor = Canvas.getEditor();
  const area = Canvas.getAreaPlugin();
  const node = editor.getNode(item.getNodeID());
  const nodeView = area.getNodeView(item.getNodeID());
  const nodePosition = {
    x: item.getNodeMetaView().getX(),
    y: item.getNodeMetaView().getY(),
  };
  await Canvas.getScopesPlugin().update(item.getNodeID());
  if (nodeView && nodePosition) {
    if (!boundary) {
      {
        await nodeView.translate(nodePosition.x, nodePosition.y);
        return {
          nw: { x: nodeView.position.x, y: nodeView.position.y },
          se: {
            x: nodeView.position.x + node.width,
            y: nodeView.position.y + node.height,
          },
        };
      }
    } else {
      if (item.getOriginItem().getItemType() === ItemType.ClockDiagram) {
        await nodeView.translate(boundary.nw.x, boundary.se.y + 50);
        const xMax = Math.max(nodeView.position.x + node.width, boundary.se.x);
        return {
          nw: boundary.nw,
          se: {
            x: xMax,
            y: nodeView.position.y + node.height,
          },
        };
      }
      await nodeView.translate(boundary.se.x + 50, boundary.nw.y); // IP Diagram
    }
  }

  return;
};

export const setSDCValue = (item: Item) => {
  const diagram = API.getParentDiagram(item);
  const editor = Canvas.getEditor(diagram?.getId());
  const node = editor?.getNode(item.getNodeID());
  const SDCControl = node.controls.sdc as ITDAControl.ITDAControlSDC;
  if (item.getClockPoint()) {
    const originItem = item.getOriginItem();
    const sdcInst = item.getParent(ItemType.SDCInstance);
    if (originItem.getItemType() !== ItemType.Label) {
      const SDCName =
        originItem.getItemType() === ItemType.LabelRef &&
        originItem.getType() === "external"
          ? `CLOCK_${sdcInst.getName()}_${originItem.getBoundaryName()}`
          : `CLOCK_${sdcInst.getName()}_${originItem.getName()}`;
      SDCControl.setShowSDC(true);
      SDCControl.setSdcName(SDCName);
    }
  } else {
    SDCControl.setShowSDC(false);
  }
  node.update();
};
