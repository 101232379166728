<template>
  <v-footer class="s-footer">
    <v-btn class="footerBox" @click="Mainfooter"> System Footer </v-btn>
  </v-footer>
</template>

<script setup lang="ts">
import store from "@/store";

const Mainfooter = () => {
  store.commit("SET_FOOTER", true);
};
</script>

<style lang="sass" scoped>
.s-footer
  z-index: 1010
  border-top: 1.5px solid lightgray
  width: "100%"
  height: 2.3125rem !important
.footerBox
  background: none
  box-shadow: none
</style>
