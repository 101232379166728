import {
  Item,
  VariantLevel,
  RefItemElementType,
  ItemType,
} from "ITDAModelTypes";
import * as ItemProperty from "./ItemProperty";
import * as ItemPropertyAutocomplete from "./ItemPropertyAutocomplete";
import * as ItemPropertyCheckbox from "./ItemPropertyCheckbox";
import * as ItemPropertyIntegerField from "./ItemPropertyIntegerField";
import ItemPropertyItemField from "./ItemPropertyItemField";
import ItemPropertyTable from "./ItemPropertyTable";
import * as ItemPropertyTextarea from "./ItemPropertyTextarea";
import * as ItemPropertyTextField from "./ItemPropertyTextField";
import ItemTitle from "./ItemTitle";

import API from "@/api/internal";
import COMP from "@/components/index";

export default {
  ...ItemProperty,
  ...ItemPropertyAutocomplete,
  ...ItemPropertyCheckbox,
  ...ItemPropertyIntegerField,
  ...ItemPropertyItemField,
  ...ItemPropertyTable,
  ...ItemPropertyTextarea,
  ...ItemPropertyTextField,
  ...ItemTitle,
  getVariant: (type: RefItemElementType, level?: VariantLevel) => {
    switch (type) {
      case RefItemElementType.STRING:
      case RefItemElementType.INTEGER:
      case RefItemElementType.ENUM:
      case RefItemElementType.TEXTAREA:
        if (level === VariantLevel.LEVEL0) return "outlined";
        else if (level === VariantLevel.LEVEL1) return "underlined";
        else if (level === VariantLevel.LEVEL2) return "solo";
        else return;
      case RefItemElementType.BOOLEAN:
        return;
      case RefItemElementType.CHIP:
        if (level === VariantLevel.LEVEL0) return "outlined";
        else if (level === VariantLevel.LEVEL1) return "elevated";
        else if (level === VariantLevel.LEVEL2) return "text";
        else return;
      default:
        return;
    }
  },
  update: (item: Item, key: string, type: any, data: string | unknown) => {
    API.setItemValue(item, key, data ? type(data) : data);
    if (item.getItemType() === ItemType.ClockMultiplexer) {
      COMP.updateComponent("NAV_RIGHT_CONFIGURATION");
    }
  },
  change: (item: Item, key: string, type: any, e: Event) => {
    const target = e.target as HTMLInputElement;
    if (type === Boolean) {
      API.setItemValue(item, key, type(target.checked));
    } else {
      API.setItemValue(
        item,
        key,
        target.value ? type(target.value) : target.value
      );
    }
  },
};
