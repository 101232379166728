/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:34.620490
 */
export default {
    "startOffset": "0x0E00",
    "size": "0x200",
    "alignSize": "0x8",
    "sfrs": [
        {
            "name": "CTRL_NMDIVIDER_EXT",
            "alignOffset": "0x4",
            "description": "Clock Component Extension Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_DURATION",
                    "index": 0,
                    "size": 8,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "",
                    "initValue": "0x0",
                    "nc": "SFR_DURATION"
                },
                {
                    "name": "SFR_ENABLE_GRADUAL",
                    "index": 12,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "",
                    "initValue": "0x0",
                    "nc": "SFR_ENABLE_GRADUAL"
                },
                {
                    "name": "SFR_OVERRIDED_CUSTOM",
                    "index": 27,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "",
                    "initValue": "0x0",
                    "nc": "SFR_OVERRIDED_CUSTOM"
                }
            ]
        }
    ]
}