/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:34.620490
 */
export default {
    "startOffset": "0x3000",
    "size": "0x800",
    "alignSize": "0x4",
    "sfrs": [
        {
            "name": "CTRL_CONTROLLER",
            "alignOffset": "0x0",
            "description": "Global Control Register for {{name}}",
            "fields": [
                {
                    "name": "SFR_ENABLE_ACG",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Auto Clock Gating Control for All Clock Components in {{name}}\n0 = Auto Clock Gating is Disabled\n1 = Auto Clock Gating is Enabled",
                    "initValue": "0x0",
                    "nc": "SFR_ENABLE_ACG"
                },
                {
                    "name": "SFR_ENABLE_QCH",
                    "index": 1,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Q-Channel Handshake Enable for All Q-channels in {{name}}\n0 = Q-Channel Handshake is Disabled\n1 = Q-Channel Handshake is Enabled",
                    "initValue": "0x0",
                    "nc": "SFR_ENABLE_QCH"
                },
                {
                    "name": "SFR_ENABLE_PWRDOWN",
                    "index": 2,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "",
                    "initValue": "0x0",
                    "nc": "SFR_ENABLE_PWRDOWN"
                }
            ]
        }
    ]
}