/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import UPFRetentionEvent from '@/model/src/events/UPFRetentionEvent'
import UPFRetentionChecker from '@/model/src/checkers/UPFRetentionChecker'
export default class UPFRetention extends Node {
    FID_UPFRETENTION_NAME = 100
    FID_UPFRETENTION_CELLS = 101
    FID_UPFRETENTION_LAST = 101

    constructor(obj) {
        super(Object.assign(obj,{itemType:"UPFRetention"}))
        
        this.setEvent(new UPFRetentionEvent(this))
        this.setChecker(new UPFRetentionChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "UPFRetention"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "cells": {"type": "string", "label": "lib_cells", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "cells": {"type": "string", "label": "lib_cells", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "UPFElement", "auto": false, "searchable": true, "title": "Element Info", "headers": [{"title": "Element", "value": "name", "align": "start", "sortable": true}], "category": "IP"}, {"name": "UPFMapperPowerSource", "auto": false, "searchable": false, "title": "Power Supply Info", "headers": [{"title": "Power Supply", "value": "powerSource", "align": "start", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFRETENTION_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_UPFRETENTION_NAME
    }
    

    setCells(cells, event=true) {
        if ((cells !== null) && (cells !== undefined)) {
            if (cells.constructor !== String) {
                throw `cells(${cells}) must be String type`
            }
        }
        const newVal = (cells === null || cells === undefined) ? undefined : cells
        const oldVal = this.properties.cells
        this.properties.cells = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFRETENTION_CELLS, newVal, oldVal)
        }
    }
    getCells(pure = false) {
        return this.properties.cells
        
    }
    getFidCells() {
        return this.FID_UPFRETENTION_CELLS
    }
    

    getFidLast() {
        return this.FID_UPFRETENTION_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_UPFRETENTION_NAME:
                return "name"

            case this.FID_UPFRETENTION_CELLS:
                return "cells"

            default:
                return ""
        }
    }


    newUPFElement(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFElement", properties, title, domain)
    }
    remUPFElement(obj) {
        return this.removeChild(obj)
    }
    getUPFElements() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFElement")
            return res
        })
    }
    newUPFMapperPowerSource(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFMapperPowerSource", properties, title, domain)
    }
    remUPFMapperPowerSource(obj) {
        return this.removeChild(obj)
    }
    getUPFMapperPowerSources() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFMapperPowerSource")
            return res
        })
    }
}