import { DomainKeys, Item, ItemType } from "../types";
import ItemHandler from "../src/ItemHandler";
import ItemMigrator from "../src/ItemMigrator";
import Repository from "../store";
import Utils from "../src/events/utils";
import {
  DB,
  DBSchema,
  DBSchemaItem,
  DBSchemaSFR,
  GarbageLevel,
  IDToItemMap,
} from "../store/types";
import ItemFactory from "../wrapper/ItemFactory";
import {
  checkGarbageConnection,
  checkGarbageItemRef,
  checkGarbageNode,
  checkGarbageParent,
} from "./garbage";

export const setupTopDesign = async (project: DBSchemaItem) => {
  const repo = Repository.getInstance();
  const top = repo.getTopItem();
  if (top) {
    top.importProperties(project.properties);
    top.setTitle(top.getName());
  }
};

export const clearDB = async (
  type: "design" | "config",
  domain?: DomainKeys
) => {
  const repo = Repository.getInstance();
  if (type === "design") {
    repo.clear(domain);
    console.debug("cleared", repo.domainToItemMap, repo.garbageToItemMap);
  } else if (type === "config") {
    const itemMap = domain ? repo.getIdToItemMap(domain) : repo.getAllItemMap();
    for (const item of Object.values(itemMap)) {
      if (
        item.getParent(ItemType.ConfigurationFolder) ||
        item.getParent(ItemType.PowerSequenceConfigurationFolder) ||
        item.getParent(ItemType.VoltageLevelFolder)
      ) {
        await ItemHandler.removeItem(item.getId());
      }
    }
  }
};

export const importDB = async (
  domain: DomainKeys,
  data: DBSchema,
  type: "design" | "config"
) => {
  const repo = Repository.getInstance();
  if (!Object.keys(data).length) return;
  repo.startLoading();

  let items = [];
  if (type === "design") {
    items = [...Object.values(data.tops), ...Object.values(data.items)];
  } else {
    checkConfigurationTop(Object.values(data.tops));
    items = [...Object.values(data.items)];
  }

  const objs = Object.values(items);
  createItemInstances(objs, domain);
  updateRelations(objs);

  if (data.sfrs) {
    updateSFRHandler(data.sfrs);
  }
  console.debug(
    "imported",
    repo.getTopItem(),
    repo.domainToItemMap,
    repo.garbageToItemMap
  );

  // To Be Removed
  if (domain === "DUT") {
    const DUTModuleFolder = repo.getTopItem()?.getDUTModuleFolder();
    if (DUTModuleFolder.getDUTModule()) {
      DUTModuleFolder.getDUTModule()
        .getChildren()
        .forEach((cItem: Item) => {
          if (cItem.getItemType() === ItemType.DUTModule) {
            ItemHandler.removeSyncItem(cItem);
          }
        });
    } else {
      ItemHandler.createSyncItem(
        DUTModuleFolder,
        ItemType.DUTModule,
        {
          name: "TOP",
        },
        "TOP",
        domain
      );
    }
  }
  await ItemMigrator.run(domain);
  repo.endLoading();
  if (type === "config") {
    for (const obj of Object.values(data.tops)) {
      const item = repo.getItemByID(obj.id);
      switch (item?.getItemType()) {
        case ItemType.ConfigurationFolder:
        case ItemType.VoltageLevelFolder:
          await item.setSelectedConfiguration();
          await item.setSelectedConfiguration(
            obj.properties.selectedConfiguration
          );
          break;
        default:
          break;
      }
    }
  }
  postProcesses(objs);
};

const checkConfigurationTop = (objs: DBSchemaItem[]) => {
  const repo = Repository.getInstance();
  objs.forEach((obj: DBSchemaItem) => {
    const item = repo.getItemByID(obj.id);
    if (!item) {
      throw new Error(
        `${obj.id}(${obj.itemType}) is not created in design DB.`
      );
    }
  });
};

const createItemInstances = (objs: DBSchemaItem[], domain: DomainKeys) => {
  const repo = Repository.getInstance();
  objs.forEach((obj: DBSchemaItem) => {
    const item = repo.getItemByID(obj.id);
    if (item) {
      throw new Error(`${obj.id} is already created.`);
    } else {
      ItemFactory.createItemInstance(
        obj.itemType,
        Object.assign(obj, { domain: domain })
      );
    }
  });
};

const updateRelations = (objs: DBSchemaItem[]) => {
  const repo = Repository.getInstance();
  objs.forEach((obj: DBSchemaItem) => {
    const pItem = repo.getItemByID(obj.pid!);
    const item = repo.getItemByID(obj.id);
    if (item) {
      if (item.getDomain()) {
        repo.getTopItem()?.addChild(item);
      } else {
        if (checkGarbageParent(item) !== GarbageLevel.FATAL) {
          checkGarbageNode(item);
          if (checkGarbageItemRef(item) !== GarbageLevel.FATAL) {
            ItemHandler.createMapper(pItem, item);
            ItemHandler.postProcess(pItem, item);
            pItem!.addChild(item, false);
          }
          if (item.getItemType() === ItemType.Connection) {
            checkGarbageConnection(item);
          }
        }
      }
    }
  });
};

const postProcesses = (objs: DBSchemaItem[]) => {
  const repo = Repository.getInstance();
  objs.forEach((obj: DBSchemaItem) => {
    const item = repo.getItemByID(obj.id);
    if (item) {
      switch (item.getItemType()) {
        case ItemType.Input:
        case ItemType.Output:
          checkGarbageConnection(item);
          break;
        case ItemType.SFRBlock:
          item.getEvent().lstn_sorted(item, true);
          break;
        default:
          break;
      }
    }
  });

  for (const item of Object.values(repo.getAllItemMap())) {
    switch (item.getItemType()) {
      case ItemType.PowerPMRPMDGRP:
        Utils.updateINTRChildrenTitle(item);
        break;
      case ItemType.PowerAPM:
        Utils.updateAPMChildren(item);
        break;
      default:
        break;
    }
  }
};

const updateSFRHandler = (sfrs: DBSchemaSFR) => {
  const repo = Repository.getInstance();
  for (const [id, obj] of Object.entries(sfrs)) {
    const sfrBlock = repo.getItemByID(id);
    if (!sfrBlock || !obj) {
      continue;
    }
    const sfrHandlerMap = repo.getSFR().getSFRHandlerMap(sfrBlock);
    if (!sfrHandlerMap) {
      continue;
    }
    repo.getSFR().setup(sfrHandlerMap, obj);
  }
};

export const exportDB = (date: number, type: "design" | "config") => {
  const repo = Repository.getInstance();
  const top = Repository.getInstance().getTopItem();
  const res: DB = {
    top: top ? top.serialize(type) : {},
    version: "1.0.0",
    date: date,
    error: Boolean(repo.getTopItem()?.getAllErrors().length),
    domain: Object.assign({}, exportDBSchema(type)),
  };
  return res;
};

export const exportEmptyDB = (date: number, type: "design" | "config"): DB => {
  const top = ItemHandler.createSyncItem(null, ItemType.Project);
  const res: DB = {
    top: top ? top.serialize(type) : {},
    version: "1.0.0",
    date: date,
    error: false,
    domain: Object.assign(
      {},
      type === "design" ? exportDBSchema(type, top) : {}
    ),
  };
  ItemHandler.removeSyncItem(top);
  return res;
};

const exportDBSchema = (
  type: "design" | "config",
  top?: Item
): { [K in DomainKeys]?: DBSchema } => {
  const repo = Repository.getInstance();
  const res: { [K in DomainKeys]?: DBSchema } = {};
  for (const domain of repo.getDomains()) {
    const idToItemMap = repo.getIdToItemMap(domain as DomainKeys);
    if (type === "design") {
      res[domain as DomainKeys] = Object.assign(
        {},
        { sfrs: getDBSchemaSFR(idToItemMap, top) },
        getDBSchemaItem(idToItemMap, type, top)
      );
    } else if (type === "config") {
      res[domain as DomainKeys] = Object.assign(
        {},
        getDBSchemaItem(idToItemMap, type)
      );
    } else {
      throw new Error("TBD");
    }
  }
  return res;
};

const getDBSchemaSFR = (idToItemMap: IDToItemMap, top?: Item): DBSchemaSFR => {
  return top
    ? {}
    : Repository.getInstance()
        .getSFR()
        .serialize(
          Object.values(idToItemMap).filter(
            (o: Item) => o.getItemType() === ItemType.SFRBlock
          )
        );
};

const getDBSchemaItem = (
  idToItemMap: IDToItemMap,
  type: "design" | "config",
  top?: Item
): {
  tops: {
    [key in string]: DBSchemaItem;
  };
  items: {
    [key in string]: DBSchemaItem;
  };
} => {
  const tops: { [key in string]: DBSchemaItem } = {};
  const items: { [key in string]: DBSchemaItem } = {};
  for (const [id, item] of Object.entries(idToItemMap)) {
    if (type === "design") {
      if (item.getDomain()) {
        if (top && top.getId() !== item.pid) continue;
        tops[`${id}`] = item.serialize(type);
      } else {
        if (top) continue;
        if (item.validate(type)) {
          items[`${id}`] = item.serialize(type);
        }
      }
    } else {
      if (item.validate(type)) {
        items[`${id}`] = item.serialize(type);
        if (item.getParent().validate("design")) {
          tops[`${item.getParent().getId()}`] = item
            .getParent()
            .serialize(type);
        }
      }
    }
  }
  return {
    tops,
    items,
  };
};
