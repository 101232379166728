/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:34.620490
 */
export default {
    "startOffset": "0x1400",
    "size": "0x400",
    "alignSize": "0x8",
    "sfrs": [
        {
            "name": "CTRL_DIVIDER_EXT",
            "alignOffset": "0x4",
            "description": "Clock Component Extension Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_DIVRATIO_THROTTLE",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Divide Ratio at THROTTLE for {{name}}\nClock output is divided by (SFR_DIVRATIO +1) from Clock input",
                    "initValue": "0x0",
                    "nc": "SFR_DIVRATIO_THROTTLE"
                },
                {
                    "name": "SFR_ENABLE_PWRDOWN",
                    "index": 16,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Clock output Low Control for {{name}}\n0 = Clock output to Low by CLINK is Disabled\n1 = Clock output to Low by CLINK is Enabled",
                    "initValue": "0x0",
                    "nc": "SFR_ENABLE_PWRDOWN"
                },
                {
                    "name": "SFR_ENABLE_THROTTLE",
                    "index": 17,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Divide Ratio Automatic change Control for {{name}}\n0 = Divide Ratio Automatic change to SFR_DIVRATIO_THROTTLE at THROTTLE is Disabled\n1 = Divide Ratio Automatic change to SFR_DIVRATIO_THROTTLE at THROTTLE is Enabled",
                    "initValue": "0x0",
                    "nc": "SFR_ENABLE_THROTTLE"
                },
                {
                    "name": "SFR_OVERRIDED_PWRDOWN",
                    "index": 24,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Clock output Low Indication for {{name}}\n0 = Clock output is Running\n1 = Clock output is Low by CLINK",
                    "initValue": "0x0",
                    "nc": "SFR_OVERRIDED_PWRDOWN"
                },
                {
                    "name": "SFR_OVERRIDED_THROTTLE",
                    "index": 25,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Divide Ratio Override Indication for {{name}}\n0 = Divide Ratio is Controlled by SFR_DIVRATIO\n1 = Divide Ratio is Overrided by SFR_DIVRATIO_THROTTLE",
                    "initValue": "0x0",
                    "nc": "SFR_OVERRIDED_THROTTLE"
                },
                {
                    "name": "SFR_OVERRIDED_CUSTOM",
                    "index": 27,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Divide Ratio Override Indication for {{name}}\n0 = Divide Ratio is Controlled by SFR_DIVRATIO\n1 = Divide Ratio is Overrided by CUSTOM",
                    "initValue": "0x0",
                    "nc": "SFR_OVERRIDED_CUSTOM"
                }
            ]
        }
    ]
}