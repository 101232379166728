import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const baseColor = color;
    const hoverColor = d3.hsl(baseColor);
    hoverColor.l = Math.min(hoverColor.l + 0.05, 1);

    const component = svg
      .append("rect")
      .attr("width", width)
      .attr("height", height)
      .attr("fill", baseColor)
      .attr("rx", 10)
      .attr("ry", 10);

    component
      .on("mouseover", function () {
        d3.select(this).attr("fill", hoverColor.toString());
      })
      .on("mouseout", function () {
        d3.select(this).attr("fill", baseColor);
      });

    const grid = 12;
    const gridHeight = height / grid;
    const gridWidth = width / grid;

    const cx = gridWidth * 4;
    const cy = gridHeight * 5;

    svg
      .append("rect")
      .attr("x", cx - 2)
      .attr("y", cy)
      .attr("width", 4)
      .attr("height", 20)
      .attr("fill", "black")
      .attr("transform", `rotate(-65, ${cx}, ${cy})`);

    svg
      .append("rect")
      .attr("x", cx - 4)
      .attr("y", cy * 2)
      .attr("width", 8)
      .attr("height", 25)
      .attr("fill", "black")
      .attr("transform", `rotate(-65, ${cx}, ${cy})`);

    svg
      .append("circle")
      .attr("cx", cx)
      .attr("cy", cy)
      .attr("r", height / 4 + 1)
      .attr("stroke", "black")
      .attr("stroke-width", "3")
      .attr("fill", "white");
  },
};
