/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import SDCDiagramEvent from '@/model/src/events/SDCDiagramEvent'
import SDCDiagramChecker from '@/model/src/checkers/SDCDiagramChecker'
export default class SDCDiagram extends Item {
    FID_SDCDIAGRAM_TYPE = 0
    FID_SDCDIAGRAM_LAST = 0

    constructor(obj) {
        super(Object.assign(obj,{itemType:"SDCDiagram"}))
        
        this.setEvent(new SDCDiagramEvent(this))
        this.setChecker(new SDCDiagramChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "SDC"
        }
    (this.view.diagram = true), (this.view.table = false)
    if (this.properties.type === undefined) {
        this.properties.type = "SDC"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "SDCDiagram"
    }

    getSchema() {
        return {"type": {"type": "enum", "targets": ["SDC"], "multiple": false, "default": "SDC", "category": "IP", "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"type": {"type": "enum", "targets": ["SDC"], "multiple": false, "default": "SDC", "category": "IP", "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "Connection", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "SDCInstance", "auto": false, "searchable": false, "title": "", "headers": [], "interface": "Instance", "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setType(type, event=true) {
        if ((type !== null) && (type !== undefined)) {
            if (type.constructor !== String) {
                throw `type(${type}) must be String type`
            }
        }
        const newVal = (type === null || type === undefined) ? undefined : type
        const oldVal = this.properties.type
        this.properties.type = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SDCDIAGRAM_TYPE, newVal, oldVal)
        }
    }
    getType(pure = false) {
        return this.properties.type
        
    }
    getFidType() {
        return this.FID_SDCDIAGRAM_TYPE
    }
    

    getFidLast() {
        return this.FID_SDCDIAGRAM_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_SDCDIAGRAM_TYPE:
                return "type"

            default:
                return ""
        }
    }


    newConnection(properties={}, title = "", domain = "") {
        return this.createChild(this, "Connection", properties, title, domain)
    }
    remConnection(obj) {
        return this.removeChild(obj)
    }
    getConnections() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "Connection")
            return res
        })
    }
    newInstance(properties={}, title = "", domain = "") {
        return this.createChild(this, "SDCInstance", properties, title, domain)
    }
    remInstance(obj) {
        return this.removeChild(obj)
    }
    getInstances() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "SDCInstance")
            return res
        })
    }
}