/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:34.620490
 */
export default {
    "startOffset": "0x180",
    "size": "0x40",
    "alignSize": "0x4",
    "sfrs": [
        {
            "name": "CTRL_USERDEFOUT",
            "alignOffset": "0x0",
            "description": "Power Component Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_USERDEFOUT",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "P_WIDTH_DATA",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "User Defined Control Signal for {{name}}\nSet USERDEFOUT Value",
                    "initValue": "0x0",
                    "nc": "SFR_USERDEFOUT"
                }
            ]
        }
    ]
}