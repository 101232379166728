import ITDACanvas from "../ITDACanvas";
import ITDAEditor from "../ITDAEditor";
import {
  ITDAAreaPlugin,
  ITDACommentPlugin,
  ITDAConnectionPlugin,
  ITDAConnectionReRoutePlugin,
  ITDADataFlowPlugin,
  ITDADockPlugin,
  ITDAHistoryPlugin,
  ITDARenderPlugin,
  ITDAScopesPlugin,
} from "../plugins";
import { ITDACanvasType, ITDAPlugin } from "../plugins/types";

export class Repository {
  private static instance: Repository;
  private map: {
    [id: string]: {
      [key: string]: ITDACanvas;
    };
  };
  private id!: string;
  private dock = false;
  private imported = true;

  private constructor() {
    this.map = {};
  }

  public static getInstance(): Repository {
    if (!Repository.instance) {
      Repository.instance = new Repository();
    }
    return Repository.instance;
  }

  public clear(): void {
    this.map = {};
  }

  public addPlugin(id: string, type: ITDACanvasType, plugin: ITDACanvas) {
    if (!this.map[id]) {
      this.map[id] = {};
    }
    if (this.map[id]) {
      this.map[id][type.getKey()] = plugin;
    }
    this.id = id;
  }

  public getPlugin(id: string, type: ITDACanvasType): ITDACanvas | undefined {
    if (this.map[id]) {
      return this.map[id][type.getKey()];
    }
  }

  public getPlugins(id: string): ITDACanvas[] {
    return this.map[id] ? Object.values(this.map[id]) : [];
  }

  public getEditor(id: string): ITDAEditor {
    return this.getPlugin(id, ITDACanvasType.Editor) as ITDAEditor;
  }

  public getAreaPlugin(id: string): ITDAAreaPlugin {
    return this.getPlugin(id, ITDACanvasType.Area) as ITDAAreaPlugin;
  }

  public getDataFlowPlugin(id: string): ITDADataFlowPlugin {
    return this.getPlugin(id, ITDACanvasType.DataFlow) as ITDADataFlowPlugin;
  }

  public getCommentPlugin(id: string): ITDACommentPlugin {
    return this.getPlugin(id, ITDACanvasType.Comment) as ITDACommentPlugin;
  }

  public getConnectionPlugin(id: string): ITDAConnectionPlugin {
    return this.getPlugin(
      id,
      ITDACanvasType.Connection
    ) as ITDAConnectionPlugin;
  }

  public getConnectionReRoutePlugin(id: string): ITDAConnectionReRoutePlugin {
    return this.getPlugin(
      id,
      ITDACanvasType.ConnectionReRoute
    ) as ITDAConnectionReRoutePlugin;
  }

  public getRenderPlugin(id: string): ITDARenderPlugin {
    return this.getPlugin(id, ITDACanvasType.Render) as ITDARenderPlugin;
  }

  public getDockPlugin(id: string): ITDADockPlugin {
    return this.getPlugin(id, ITDACanvasType.Dock) as ITDADockPlugin;
  }

  public getHistoryPlugin(id: string): ITDAHistoryPlugin {
    return this.getPlugin(id, ITDACanvasType.Dock) as ITDAHistoryPlugin;
  }

  public getScopesPlugin(id: string): ITDAScopesPlugin {
    return this.getPlugin(id, ITDACanvasType.Scopes) as ITDAScopesPlugin;
  }

  public setup(id: string) {
    if (this.map[id]) {
      Object.values(this.map[id]).forEach((o: ITDAPlugin) => o.use?.());
      Object.values(this.map[id]).forEach((o: ITDAPlugin) => o.event?.());
    }
  }

  public getCurrentID(): string {
    return this.id;
  }

  public updateDockState(dock: boolean): void {
    this.dock = dock;
  }

  public isDock(): boolean {
    return this.dock;
  }

  public setImported(imported: boolean): void {
    this.imported = imported;
  }

  public getImported(): boolean {
    return this.imported;
  }
}
