/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import InputEvent from '@/model/src/events/InputEvent'
import InputChecker from '@/model/src/checkers/InputChecker'
export default class Input extends Item {
    FID_INPUT_NAME = 0
    FID_INPUT_KEY = 1
    FID_INPUT_POSITION = 2
    FID_INPUT_HIDDEN = 3
    FID_INPUT_SOCKET = 4
    FID_INPUT_MULTIPLE = 5
    FID_INPUT_CONNECTIONS = 6
    FID_INPUT_WIDTH = 7
    FID_INPUT_LAST = 7

    constructor(obj) {
        super(Object.assign(obj,{itemType:"Input"}))
        
        this.setEvent(new InputEvent(this))
        this.setChecker(new InputChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = ""
    }
    if (this.properties.key === undefined) {
        this.properties.key = "in"
    }
    if (this.properties.position === undefined) {
        this.properties.position = "LEFT"
    }
    if (this.properties.hidden === undefined) {
        this.properties.hidden = false
    }
    if (this.properties.socket === undefined) {
        this.properties.socket = "CLK"
    }
    if (this.properties.multiple === undefined) {
        this.properties.multiple = false
    }
    if (this.properties.connections === undefined) {
        this.properties.connections = []
    }
    if (this.properties.width === undefined) {
        this.properties.width = 1
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "Input"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "key": {"type": "string", "desc": "node port label", "default": "in", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "position": {"type": "enum", "targets": ["TOP", "RIGHT", "BOTTOM", "LEFT"], "multiple": false, "default": "LEFT", "category": "IP", "hidden": false, "readOnly": false}, "hidden": {"type": "boolean", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "socket": {"type": "enum", "targets": ["CLK", "QCH", "PWRCTRL", "SEQ", "SEQREF", "SFRIF", "CLINK", "PLINK"], "multiple": false, "default": "CLK", "category": "IP", "hidden": false, "readOnly": false}, "multiple": {"type": "boolean", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "connections": {"type": "item", "multiple": true, "category": "IP", "hidden": false, "readOnly": false}, "width": {"type": "integer", "default": 1, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "key": {"type": "string", "desc": "node port label", "default": "in", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "position": {"type": "enum", "targets": ["TOP", "RIGHT", "BOTTOM", "LEFT"], "multiple": false, "default": "LEFT", "category": "IP", "hidden": false, "readOnly": false}, "hidden": {"type": "boolean", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "socket": {"type": "enum", "targets": ["CLK", "QCH", "PWRCTRL", "SEQ", "SEQREF", "SFRIF", "CLINK", "PLINK"], "multiple": false, "default": "CLK", "category": "IP", "hidden": false, "readOnly": false}, "multiple": {"type": "boolean", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "connections": {"type": "item", "multiple": true, "category": "IP", "hidden": false, "readOnly": false}, "width": {"type": "integer", "default": 1, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_INPUT_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_INPUT_NAME
    }
    

    setKey(key, event=true) {
        if ((key !== null) && (key !== undefined)) {
            if (key.constructor !== String) {
                throw `key(${key}) must be String type`
            }
        }
        const newVal = (key === null || key === undefined) ? undefined : key
        const oldVal = this.properties.key
        this.properties.key = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_INPUT_KEY, newVal, oldVal)
        }
    }
    getKey(pure = false) {
        return this.properties.key
        
    }
    getFidKey() {
        return this.FID_INPUT_KEY
    }
    

    setPosition(position, event=true) {
        if ((position !== null) && (position !== undefined)) {
            if (position.constructor !== String) {
                throw `position(${position}) must be String type`
            }
        }
        const newVal = (position === null || position === undefined) ? undefined : position
        const oldVal = this.properties.position
        this.properties.position = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_INPUT_POSITION, newVal, oldVal)
        }
    }
    getPosition(pure = false) {
        return this.properties.position
        
    }
    getFidPosition() {
        return this.FID_INPUT_POSITION
    }
    

    setHidden(hidden, event=true) {
        if ((hidden !== null) && (hidden !== undefined)) {
            if (hidden.constructor !== Boolean) {
                throw `hidden(${hidden}) must be Boolean type`
            }
        }
        const newVal = (hidden === null || hidden === undefined) ? undefined : hidden
        const oldVal = this.properties.hidden
        this.properties.hidden = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_INPUT_HIDDEN, newVal, oldVal)
        }
    }
    getHidden(pure = false) {
        return this.properties.hidden
        
    }
    getFidHidden() {
        return this.FID_INPUT_HIDDEN
    }
    

    setSocket(socket, event=true) {
        if ((socket !== null) && (socket !== undefined)) {
            if (socket.constructor !== String) {
                throw `socket(${socket}) must be String type`
            }
        }
        const newVal = (socket === null || socket === undefined) ? undefined : socket
        const oldVal = this.properties.socket
        this.properties.socket = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_INPUT_SOCKET, newVal, oldVal)
        }
    }
    getSocket(pure = false) {
        return this.properties.socket
        
    }
    getFidSocket() {
        return this.FID_INPUT_SOCKET
    }
    

    setMultiple(multiple, event=true) {
        if ((multiple !== null) && (multiple !== undefined)) {
            if (multiple.constructor !== Boolean) {
                throw `multiple(${multiple}) must be Boolean type`
            }
        }
        const newVal = (multiple === null || multiple === undefined) ? undefined : multiple
        const oldVal = this.properties.multiple
        this.properties.multiple = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_INPUT_MULTIPLE, newVal, oldVal)
        }
    }
    getMultiple(pure = false) {
        return this.properties.multiple
        
    }
    getFidMultiple() {
        return this.FID_INPUT_MULTIPLE
    }
    

    setConnections(connections, event=true) {
        if ((connections !== null) && (connections !== undefined)) {
            if (connections.constructor !== Array) {
                throw `connections(${connections}) must be Array type`
            }
        }
        const newVal = (connections === null || connections === undefined) ? [] : connections.map((o) => {
            if (!o || o.constructor === String) {
                return o
            } else if (this.toRaw(o) instanceof Item) {
                return o.getId()
            }
        })
        const oldVal = this.properties.connections
        this.properties.connections = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_INPUT_CONNECTIONS, newVal.map((id) => this.getRefItem(id)), oldVal.map((id) => this.getRefItem(id)))
        }
    }
    getConnections(pure = false) {
        return pure ? this.properties.connections : this.properties.connections.map((id) => this.getRefItem(id))
        
    }
    getFidConnections() {
        return this.FID_INPUT_CONNECTIONS
    }
    

    setWidth(width, event=true) {
        if ((width !== null) && (width !== undefined)) {
            if (width.constructor !== Number) {
                throw `width(${width}) must be Number type`
            }
        }
        const newVal = (width === null || width === undefined) ? undefined : width
        const oldVal = this.properties.width
        this.properties.width = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_INPUT_WIDTH, newVal, oldVal)
        }
    }
    getWidth(pure = false) {
        return this.properties.width
        
    }
    getFidWidth() {
        return this.FID_INPUT_WIDTH
    }
    

    getFidLast() {
        return this.FID_INPUT_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_INPUT_NAME:
                return "name"

            case this.FID_INPUT_KEY:
                return "key"

            case this.FID_INPUT_POSITION:
                return "position"

            case this.FID_INPUT_HIDDEN:
                return "hidden"

            case this.FID_INPUT_SOCKET:
                return "socket"

            case this.FID_INPUT_MULTIPLE:
                return "multiple"

            case this.FID_INPUT_CONNECTIONS:
                return "connections"

            case this.FID_INPUT_WIDTH:
                return "width"

            default:
                return ""
        }
    }


}