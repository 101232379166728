import axios from "axios";
import { domain, host, prdMode, protocol } from "./config";

export const loadTargets = async (type: "design" | "config"): Promise<[]> => {
  try {
    // if (prdMode) {
    //   const obj = await isAuthenticated()
    //   if (obj.res) {
    //       if (!obj.data.data.res) {
    //           window.location.href = `https://${domain}`
    //       }
    //   } else {
    //       window.location.href = `https://${domain}`
    //   }
    // }
    const res = await axios.post(
      `${protocol}://${host}/io/load_targets`,
      null,
      {
        params: { type: type },
      }
    );
    return res && res.status === 200 ? res.data : [];
  } catch (err) {
    console.log(err);
    return [];
  }
};
