import EventInterface from "@/model/src/EventInterface";
import ItemFactory from "./ItemFactory";
import ItemHandler from "./ItemHandler";
import ItemType from "./ItemType";
import NodeType from "./NodeType";
import SFRHandler from "./SFRHandler";
import SequenceHandler from "./SequenceHandler";
import utils from "./events/utils";

export default class ItemEvent extends EventInterface {
  constructor(item) {
    super(item);
    // this.addPropertyListener(item.getFidTitle(), this.lstn_item_label)

    switch (item.getItemType()) {
      case ItemType.PowerSequenceWrite:
      case ItemType.PowerSequenceReadWait:
      case ItemType.PowerSequenceWait:
      case ItemType.PowerSequenceIf:
        this.addPropertyListener(item.getFidLabel(), this.lstn_label);
        this.addPropertyListener(item.getFidBack(), this.lstn_back);
        break;
      case ItemType.PowerSequenceGoto:
      case ItemType.PowerSequenceCall:
        this.addPropertyListener(item.getFidLabel(), this.lstn_label);
        break;
      default:
        break;
    }
    switch (item.getItemType()) {
      case ItemType.SFRBlock:
      case ItemType.SFR:
        break;
      default:
        this.addArrayListener(item.getFidChildren(), {
          add: this.lstn_child_add,
          remove: this.lstn_child_remove,
          clear: this.lstn_child_clear,
        });
        break;
    }
    // this.addArrayListener(item.getFidChildren(), {
    //   add: this.lstn_child_add,
    //   remove: this.lstn_child_remove,
    //   clear: this.lstn_child_clear,
    // });
  }

  lstn_back(item, newVal) {
    if (newVal) {
      return SequenceHandler.createSequenceBack(item);
    } else {
      return SequenceHandler.removeSequenceBack(item);
    }
  }

  lstn_label(item, newVal) {
    if (newVal) {
      return SequenceHandler.createSequenceLabel(item);
    } else {
      return SequenceHandler.removeSequenceLabel(item);
    }
  }

  postProcess(item) {
    // console.debug(item);
    if (
      !ItemHandler.getTopItem().getAcgMode() &&
      item.getParent(ItemType.ClockCMU)
    ) {
      return item.getEvent().refreshCLKCOMPSFR(item);
    }
  }

  // lstn_item_label(item, newVal, oldVal) {
  //     console.debug("lstn item label", item, newVal, oldVal)
  // }

  async refreshCLKCOMPSFR(item) {
    if (item.getParent(ItemType.ClockCMU)) {
      const mapper = item
        .getMappers()
        .find(
          (mapper) =>
            mapper.getTarget() === item &&
            mapper.getSource().getItemType() === ItemType.SFR
        );
      if (mapper) {
        const specType = "FULL";
        const sfr = mapper.getSource();
        for (const field of sfr.getSFRFields()) {
          await ItemHandler.removeItem(field);
        }

        const sfrSpec = item.getSFRSpec(specType);
        const fields = sfrSpec.sfrs[0].fields;
        if (ItemHandler.getTopItem().getAcgMode()) {
          for (const field of fields) {
            SFRHandler.createSFRField(sfr, field);
          }
        } else {
          const targets = fields.filter(
            (field) =>
              field.index !== 12 && field.index !== 13 && field.index !== 14
          );
          for (const field of targets) {
            SFRHandler.createSFRField(sfr, field);
          }
        }
        ItemHandler.refreshSFRInfo(item);
      }
    }
  }

  setupSFRHandler(item) {
    var subTypes = [];
    switch (item.getItemType()) {
      case ItemType.ClockPLLCtrl:
        subTypes.push(ItemType.ClockPLL);
        subTypes.push(ItemType.ClockPLLCtrlEXT);
        break;
      case ItemType.ClockRefCLKMultiplexer:
        subTypes.push(ItemType.ClockRefCLKMultiplexerEXT);
        break;
      case ItemType.ClockMultiplexer:
        subTypes.push(ItemType.ClockMultiplexerEXT);
        break;
      case ItemType.ClockDivider:
        subTypes.push(ItemType.ClockDividerEXT);
        break;
      case ItemType.ClockNMDivider:
        subTypes.push(ItemType.ClockNMDividerEXT);
        break;
      case ItemType.ClockGate:
        subTypes.push(ItemType.ClockGateEXT);
        break;
      case ItemType.ClockFreqmon:
        subTypes.push(ItemType.ClockFreqmonEXT);
        break;
      case ItemType.ClockQChannelManager:
        subTypes.push(ItemType.ClockQChannelManagerEXT);
        break;

      case ItemType.PowerPMRINTRSFR:
        subTypes.push(ItemType.PowerPMRINTRSFRATOM);
        break;
      case ItemType.PowerPMRINTREXT:
        subTypes.push(ItemType.PowerPMRINTREXTATOM);
        break;
      case ItemType.PowerPMRTIMERGRP:
        subTypes.push(ItemType.PowerPMRTIMER);
        break;
      case ItemType.PowerPMRPMDGRP:
        subTypes.push(ItemType.PowerPMRPMDCTRL);
        break;
      default:
        break;
    }

    var module = null;
    if (item.getItemType().getCategory() === NodeType.Clock) {
      module = item.getParent(ItemType.ClockCMU);
    } else if (item.getItemType().getCategory() === NodeType.Power) {
      module = item.getParent(ItemType.PowerPMC);
    }

    if (subTypes.length && module) {
      const handlerMap = SFRHandler.getSfrHandlerMap(module.getSFRBlock());
      if (module.getItemType() === ItemType.PowerPMC) {
        handlerMap.set(
          `${item.getId()}/${ItemType.PowerPMRINTRGenBit.getKey()}`,
          new SFRHandler(
            ItemFactory.getSFRItemTarget(
              ItemType.PowerPMRINTRGenBit
            ).getSFRMeta()
          )
        );
        handlerMap.set(
          `${item.getId()}/${ItemType.PowerPMRINTREvent.getKey()}`,
          new SFRHandler(
            ItemFactory.getSFRItemTarget(
              ItemType.PowerPMRINTREvent
            ).getSFRMeta()
          )
        );
      }
      subTypes.forEach((subType) => {
        handlerMap.set(
          `${item.getId()}/${subType.getKey()}`,
          new SFRHandler(ItemFactory.getSFRItemTarget(subType).getSFRMeta())
        );
      });
    }
  }

  setupDefaultEvents(item) {
    switch (item.getItemType()) {
      case ItemType.ClockFreqmon:
      case ItemType.ClockPLLCtrl:
      case ItemType.ClockRefCLKMultiplexer:
      case ItemType.ClockMultiplexer:
      case ItemType.ClockDivider:
      case ItemType.ClockNMDivider:
      case ItemType.ClockGate:
      case ItemType.ClockQChannelManager:
        var extItem = item[`get${item.getItemType().getKey()}EXT`]();
        SFRHandler.createSFR(
          item.getParent(ItemType.ClockCMU).getSFRBlock(),
          extItem,
          "FULL"
        );
        return extItem.getEvent().refreshSFR(extItem);
      case ItemType.PowerAPM:
        return utils.updateAPMChildren(item);
      case ItemType.PowerPMRINTRSFR:
      case ItemType.PowerPMRINTREXT:
        return item.getEvent().setup(item);
      case ItemType.PowerPMRTIMERGRP:
        return item.getEvent().lstn_numOfTimers(item, item.getNumOfTimers());
      case ItemType.PowerPMRPMDGRP:
        return item
          .getEvent()
          .lstn_numOfPMDs(
            item,
            item.getNumOfPMDs(),
            ItemHandler.isLoading() ? item.getNumOfPMDs() : 0
          );
      default:
        break;
    }
  }

  lstn_child_add(item, value) {
    const promises = [];
    switch (value.getItemType()) {
      case ItemType.Connection:
        utils.lstn_connection_added(item, value);
        break;
      case ItemType.PowerAPMPWRDOWN:
      case ItemType.PowerAPMPWRUP:
        utils.updateAPMChildren(item, value);
        break;

      // case ItemType.UPFPowerSource:
      // case ItemType.UPFGround:
      //   item
      //     .getParent(ItemType.UPFModule)
      //     .getConfigurationFolder()
      //     .getConfigurations()
      //     .forEach((config) => {
      //       let elem = config
      //         .getConfigurationElements()
      //         .find((elem) => elem.getItemRef() === value.getId());
      //       if (elem) {
      //         ItemHandler.removeItem(elem);
      //       }
      //       ItemHandler.createItem(config, ItemType.ConfigurationElement, {
      //         itemRef: value.getId(),
      //         prop: "VL",
      //       });
      //     });
      //   break;;
      default:
        break;
    }
    return Promise.all(promises);
  }

  lstn_child_remove(item, value) {
    const promises = [];
    switch (value.getItemType()) {
      case ItemType.Input:
        if (item.getItemType() === ItemType.PowerPMDInstance) {
          const output = value.getConnections()[0]?.getSourceOutput();
          if (output) {
            const outputIdx = output.getName().slice(-1);
            output
              .getParent()
              .getPowerPMRPMDCTRLs()
              [outputIdx].setName(
                `${output.getParent().getName()}__PMD${outputIdx}`
              );
          }
        }
        value.getConnections().forEach((conn) => {
          promises.push(ItemHandler.removeItem(conn));
        });
        break;
      case ItemType.ClockIPInput:
      case ItemType.Output:
        value.getConnections().forEach((conn) => {
          promises.push(ItemHandler.removeItem(conn));
        });
        break;
      case ItemType.Connection:
        promises.push(...utils.lstn_connection_removed(item, value));
        break;
      case ItemType.DUTInstance:
        if (value.getType() === "BLOCK") {
          promises.push(ItemHandler.removeItem(value.getModule()));
        }
        break;
      case ItemType.PowerAPMPWRDOWN:
      case ItemType.PowerAPMPWRUP:
        utils.updateAPMChildren(item, value);
        break;
      // case ItemType.PowerCLINK:
      // case ItemType.PowerPMRPMDGRP:
      //   value.getEvent().postProcess(value);
      //   break;
      // case ItemType.UPFPowerSource:
      // case ItemType.UPFGround:
      //   item
      //     .getParent(ItemType.UPFModule)
      //     .getConfigurationFolder()
      //     .getConfigurations()
      //     .forEach((config) => {
      //       let elem = config
      //         .getConfigurationElements()
      //         .find((elem) => elem.getItemRef() === value.getId());
      //       promises.push(ItemHandler.removeItem(elem));
      //     });
      //   break;
      default:
        break;
    }
    // if (ConfigurationHandler.isConfigurationTarget(value)) {
    //   const module = ConfigurationHandler.getTargetModule(value);
    //   if (module) {
    //     ConfigurationHandler.refreshConfigurationFolder(
    //       module.getConfigurationFolder()
    //     );
    //   }
    // }
    return Promise.all(promises);
  }

  lstn_child_clear(item, value) {
    // console.debug("lstn item clear", item, value);
  }
}
