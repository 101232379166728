import { ConfigurationMapType, DB } from "ITDAModelTypes";
import axios from "axios";
import io from "socket.io-client";
import { host, s_host, protocol } from "./config";

export const generateRTL = async (
  name: string,
  design: string,
  config: string,
  configMap: ConfigurationMapType,
  type: "dev" | "user" | "obfuscation" | "doc"
): Promise<boolean> => {
  try {
    const info = await axios.get(`${protocol}://${host}/gen/info`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return connectGenerator(info.data, name, design, config, configMap, type);
  } catch (err) {
    console.log(err);
    return false;
  }
};

const connectGenerator = (
  user: string,
  name: string,
  design: string,
  config: string,
  configMap: ConfigurationMapType,
  type: "dev" | "user" | "obfuscation" | "doc"
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    try {
      const socket = io(`${protocol}://${s_host}`, {
        // upgrade: false,
        // pingTimeout: 1000,
      });
      socket.on("connect", () => console.log("Connected to the server"));
      socket.on("reconnecting", (data: any) => console.error(data));
      socket.on("reconnect", (data: any) => console.error(data));
      socket.on("connect_error", (err: any) => {
        console.error(err);
        clearInterval(pingId);
        resolve(false);
      });
      socket.on("reconnect_failed", () => {
        console.error("reconnect failed");
        clearInterval(pingId);
        resolve(false);
      });
      socket.on("disconnect", () => {
        console.log("disconnected");
        clearInterval(pingId);
        resolve(false);
      });
      socket.on("itda_generate_rtl_log", (data: any) => console.log(data));

      const pingId = setInterval(() => {
        console.log("ping");
        socket.emit("ping", (data: any) => {
          if (data) {
            console.log("pong");
          }
        });
      }, 10000);

      const room = JSON.stringify(new Date().getTime());
      console.log(`start generator: ${room}`);
      socket.emit(
        "itda_generate_rtl",
        {
          user: user,
          prjName: name,
          design: design,
          config: config,
          type: type,
          gen_type: type === "doc" ? "doc" : "rtl",
          config_map: configMap,
        },
        room,
        (data: any) => {
          console.log(data);
          if (data.res) {
            const elem = document.createElement("a");
            elem.setAttribute(
              "href",
              `${protocol}://${host}/gen/download?type=${data.type}&name=${data.prjName}&log_id=${data.log_id}`
            );
            elem.setAttribute("download", "download");
            elem.click();
          } else {
            alert(`generateRTL failed.`);
          }
          socket.disconnect();
        }
      );
    } catch (err) {
      console.log(err);
      reject(false);
    }
  });
};
