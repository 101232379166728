<template>
  <v-footer class="s-footer">
    <v-row no-gutters class="main-footer">
      <v-tabs v-model="tabModel">
        <v-tab v-for="tab in tabs" :key="tab" :value="tab">
          {{ tab }}
        </v-tab>
      </v-tabs>
      <v-spacer></v-spacer>
      <v-btn icon class="x-button" size="small" @click="hidePane">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>

    <v-row class="context-footer">
      <v-card-text>
        <v-tabs-window v-model="tabModel">
          <v-tabs-window-item value="CLI">
            <CLITab />
          </v-tabs-window-item>
          <v-tabs-window-item value="OUTPUT">
            <OutputTab />
          </v-tabs-window-item>
          <v-tabs-window-item value="PROBLEMS">
            <ProblemsTab />
          </v-tabs-window-item>
          <v-tabs-window-item value="MERONG">
            <MERONGTab />
          </v-tabs-window-item>
        </v-tabs-window>
      </v-card-text>
    </v-row>
  </v-footer>
</template>

<script setup lang="ts">
import CLITab from "./tabs/CLITab.vue";
import OutputTab from "./tabs/OutputTab.vue";
import ProblemsTab from "./tabs/ProblemsTab.vue";
import MERONGTab from "./tabs/MERONGTab.vue";
import store from "@/store";
import { ref, reactive } from "vue";

const tabModel = ref("");
const tabs = reactive(["CLI", "PROBLEMS", "OUTPUT", "MERONG"]);

const hidePane = () => {
  store.commit("SET_FOOTER", false);
};
</script>

<style lang="sass" scoped>
.s-footer
  color : lightgray
  z-index: 1010
  padding: 0

.main-footer
  background-color: black
  position: absolute
  top: 0
  width: 100%
  height: 40px
  z-index: 1
  border-bottom: 2.5px solid lightgray

.context-footer
  word-wrap: break-word
  white-space: pre-wrap
  padding: 15px
  margin-top: 40px
  width: 100%
  height: 100%

.context
  overflow-y: scroll
  width: 100%
  height: 100%

.x-button
  margin-right: 2px
  color:white
  background: none
  box-shadow: none
</style>
