<template>
  <v-app>
    <HeaderSystem />
    <splitpanes horizontal>
      <pane>
        <splitpanes>
          <pane min-size="3%" size="20%">
            <navigation-left />
          </pane>
          <pane>
            <splitpanes horizontal>
              <pane>
                <v-container class="main-page">
                  <HeaderMain />
                  <router-view />
                </v-container>
              </pane>
            </splitpanes>
          </pane>
          <pane min-size="3%" size="20%">
            <navigation-right />
          </pane>
        </splitpanes>
      </pane>
      <pane v-if="store.getters['GET_FOOTER']" size="40%">
        <FooterMain />
      </pane>
    </splitpanes>
    <FooterSystem />
    <in-progress />
  </v-app>
</template>

<script setup lang="ts">
import HeaderSystem from "@/components/header/HeaderSystem.vue";
import HeaderMain from "@/components/header/HeaderMain.vue";
import NavigationLeft from "@/components/navigation/NavigationLeft.vue";
import NavigationRight from "@/components/navigation/NavigationRight.vue";
import FooterMain from "@/components/footer/FooterMain.vue";
import FooterSystem from "@/components/footer/FooterSystem.vue";
import InProgress from "@/components/InProgress.vue";
import { Splitpanes, Pane } from "splitpanes";
import { onMounted } from "vue";
import { useStore } from "vuex";
import events from "./events";

const store = useStore();

onMounted(async () => {
  store.commit("SET_IN_PROGRESS", "CLI TEST");
  events.setupEvents();
  store.commit("SET_DONE", "CLI TEST");
});
</script>

<style lang="sass">
html
  //overflow:   scroll

::-webkit-scrollbar
    width: 0px
    background: transparent

@import url(splitpanes/dist/splitpanes.css)
.splitpanes__pane
  display: flex
  font-family: Helvetica, Arial, sans-serif
  color: rgba(255, 255, 255, 0.6)
  // font-size: 5em

.splitpanes__splitter
  border: 1px solid lightgray
  z-index: 2000

.main-page
  height: 100%
  padding: 0
  display: flex
  flex-direction: column
</style>
