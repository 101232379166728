/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import ClockGateEXTEvent from '@/model/src/events/ClockGateEXTEvent'
import ClockGateEXTChecker from '@/model/src/checkers/ClockGateEXTChecker'
import ITDA_CMU_GATE_EXT_PT from '@/model/gen/sfrSpec/ITDA_CMU_GATE_EXT_PT'
export default class ClockGateEXT extends Item {
    FID_CLOCKGATEEXT_SHORTSTOPMODE = 0
    FID_CLOCKGATEEXT_CUSTOMMODE = 1
    FID_CLOCKGATEEXT_EWAKEUPMODE = 2
    FID_CLOCKGATEEXT_NAME = 3
    FID_CLOCKGATEEXT_LAST = 3

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ClockGateEXT"}))
        
        this.setEvent(new ClockGateEXTEvent(this))
        this.setChecker(new ClockGateEXTChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.shortStopMode === undefined) {
        this.properties.shortStopMode = false
    }
    if (this.properties.customMode === undefined) {
        this.properties.customMode = false
    }
    if (this.properties.ewakeupMode === undefined) {
        this.properties.ewakeupMode = false
    }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ClockGateEXT"
    }

    getSchema() {
        return {"shortStopMode": {"type": "boolean", "label": "Use SHORTSTOP", "desc": "Use ShotStop", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "customMode": {"type": "boolean", "label": "Use CUSTOM", "desc": "Use Custom Ctrl", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "ewakeupMode": {"type": "boolean", "label": "Use EWAKEUP", "desc": "Use EWAKEUP", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "name": {"type": "string", "hidden": true, "default": "NONAME", "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"shortStopMode": {"type": "boolean", "label": "Use SHORTSTOP", "desc": "Use ShotStop", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "customMode": {"type": "boolean", "label": "Use CUSTOM", "desc": "Use Custom Ctrl", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "ewakeupMode": {"type": "boolean", "label": "Use EWAKEUP", "desc": "Use EWAKEUP", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "name": {"type": "string", "hidden": true, "default": "NONAME", "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "ClockSIGNAL", "interface": "ShortStopSignal", "condition": {"type": "ShortStopSignal"}, "properties": {"type": "ShortStopSignal"}, "auto": false, "searchable": false, "title": "SHORTSTOP Signal List", "headers": [{"title": "Name", "value": "SignalName", "align": "start", "sortable": true}, {"title": "Reset Value", "value": "SignalReset", "align": "start", "sortable": true}], "category": "IP"}, {"name": "ClockSIGNAL", "interface": "EwakeupSignal", "condition": {"type": "EwakeupSignal"}, "properties": {"type": "EwakeupSignal"}, "auto": false, "searchable": false, "title": "EWAKEUP Signal List", "headers": [{"title": "Name", "value": "SignalName", "align": "start", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_CMU_GATE_EXT_PT
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_CMU_GATE_EXT_PT
        }
    }

    setShortStopMode(shortStopMode, event=true) {
        if ((shortStopMode !== null) && (shortStopMode !== undefined)) {
            if (shortStopMode.constructor !== Boolean) {
                throw `shortStopMode(${shortStopMode}) must be Boolean type`
            }
        }
        const newVal = (shortStopMode === null || shortStopMode === undefined) ? undefined : shortStopMode
        const oldVal = this.properties.shortStopMode
        this.properties.shortStopMode = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKGATEEXT_SHORTSTOPMODE, newVal, oldVal)
        }
    }
    getShortStopMode(pure = false) {
        return this.properties.shortStopMode
        
    }
    getFidShortStopMode() {
        return this.FID_CLOCKGATEEXT_SHORTSTOPMODE
    }
    

    setCustomMode(customMode, event=true) {
        if ((customMode !== null) && (customMode !== undefined)) {
            if (customMode.constructor !== Boolean) {
                throw `customMode(${customMode}) must be Boolean type`
            }
        }
        const newVal = (customMode === null || customMode === undefined) ? undefined : customMode
        const oldVal = this.properties.customMode
        this.properties.customMode = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKGATEEXT_CUSTOMMODE, newVal, oldVal)
        }
    }
    getCustomMode(pure = false) {
        return this.properties.customMode
        
    }
    getFidCustomMode() {
        return this.FID_CLOCKGATEEXT_CUSTOMMODE
    }
    

    setEwakeupMode(ewakeupMode, event=true) {
        if ((ewakeupMode !== null) && (ewakeupMode !== undefined)) {
            if (ewakeupMode.constructor !== Boolean) {
                throw `ewakeupMode(${ewakeupMode}) must be Boolean type`
            }
        }
        const newVal = (ewakeupMode === null || ewakeupMode === undefined) ? undefined : ewakeupMode
        const oldVal = this.properties.ewakeupMode
        this.properties.ewakeupMode = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKGATEEXT_EWAKEUPMODE, newVal, oldVal)
        }
    }
    getEwakeupMode(pure = false) {
        return this.properties.ewakeupMode
        
    }
    getFidEwakeupMode() {
        return this.FID_CLOCKGATEEXT_EWAKEUPMODE
    }
    

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKGATEEXT_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_CLOCKGATEEXT_NAME
    }
    

    getFidLast() {
        return this.FID_CLOCKGATEEXT_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CLOCKGATEEXT_SHORTSTOPMODE:
                return "shortStopMode"

            case this.FID_CLOCKGATEEXT_CUSTOMMODE:
                return "customMode"

            case this.FID_CLOCKGATEEXT_EWAKEUPMODE:
                return "ewakeupMode"

            case this.FID_CLOCKGATEEXT_NAME:
                return "name"

            default:
                return ""
        }
    }


    newShortStopSignal(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockSIGNAL", properties, title, domain)
    }
    remShortStopSignal(obj) {
        return this.removeChild(obj)
    }
    getShortStopSignals() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockSIGNAL")
            res &= (item.properties.type === "ShortStopSignal")
            return res
        })
    }
    newEwakeupSignal(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockSIGNAL", properties, title, domain)
    }
    remEwakeupSignal(obj) {
        return this.removeChild(obj)
    }
    getEwakeupSignals() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockSIGNAL")
            res &= (item.properties.type === "EwakeupSignal")
            return res
        })
    }
}