<template>
  <v-navigation-drawer
    class="drawer"
    app
    location="right"
    permanent
    :style="style">
    <n-collapse :default-expanded-names="[0, 1, 2, 3]">
      <!-- <template #header-extra>
        <v-icon>mdi-swap-vertical-bold</v-icon>
      </template> -->
      <!-- <template #arrow><v-icon></v-icon></template> -->
      <ComponentPanel
        v-if="diagram"
        :seed="`${nodeItem?.getId()}-${
          store.getters['component/GET_NAV_RIGHT_COMPONENT']
        }`"
        :name="0" />
      <PropertyPanel
        v-if="diagram && nodeItem && showProperty"
        :item="nodeItem"
        :seed="`${nodeItem?.getId()}-${
          store.getters['component/GET_NAV_RIGHT_PROPERTY']
        }`"
        :name="1" />
      <ConfigurationPanel
        v-if="diagram && nodeItem && showConfiguration()"
        :item="nodeItem"
        :seed="`${nodeItem?.getId()}-${
          store.getters['component/GET_NAV_RIGHT_CONFIGURATION']
        }`"
        :name="2" />
      <SFRPanel
        v-if="diagram && nodeItem && showSFRInfo"
        :item="nodeItem"
        :seed="`${nodeItem?.getId()}-${
          store.getters['component/GET_NAV_RIGHT_SFR']
        }`"
        :name="3" />
    </n-collapse>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import ComponentPanel from "./panels/ComponentPanel.vue";
import PropertyPanel from "./panels/PropertyPanel.vue";
import ConfigurationPanel from "./panels/ConfigurationPanel.vue";
import SFRPanel from "./panels/SFRPanel.vue";
import API from "@/api/internal";
import { ItemType, Item } from "ITDAModelTypes";
import { NodeType } from "@/canvas/types";

const store = useStore();
const expanded = ref([0, 1, 2, 3]);

const diagram = ref(false);
const nodeItem = ref<Item | null>(null);
const showProperty = ref(true);
const showSFRInfo = ref(false);

const showConfiguration = () => {
  const currentItem = API.getCurrentItem();
  const currentNode = API.getCurrentNodeItem();
  if (!currentItem || !currentNode) return false;
  if (currentItem.getParent(ItemType.ClockCMU)) {
    return API.getSelectedCMUConfiguration(currentItem) ? true : false;
  } else if (currentItem.getParent(ItemType.PowerPMD)) {
    return API.getSelectedPMDConfiguration(currentItem) ? true : false;
  } else if (currentItem.getParent(ItemType.PowerPMC)) {
    return API.getSelectedPMCConfiguration(currentItem) ? true : false;
  } else if (currentNode.getItemType() === ItemType.SDCInstance) {
    return API.getSelectedVoltageConfiguration(currentNode) ? true : false;
  }
  return false;
};

watch(
  () => store.getters["item/GET_CURRENT_ITEM"],
  (newVal: Item) => {
    if (newVal) {
      const viewSpec = API.getItemViewSpec(newVal);
      diagram.value = viewSpec.diagram;
      nodeItem.value = null;
      switch (newVal.getItemType()) {
        case ItemType.ClockDiagram:
        case ItemType.ClockIPDiagram:
        case ItemType.PowerDiagram:
          showSFRInfo.value = true;
          break;
        default:
          showSFRInfo.value = false;
          break;
      }
    } else {
      diagram.value = false;
      nodeItem.value = null;
      showSFRInfo.value = false;
    }
  }
);
watch(
  () => store.getters["item/GET_CURRENT_NODE_ITEM"],
  (newVal: Item) => {
    if (newVal) {
      nodeItem.value = newVal;
      const itemType = newVal.getItemType();
      if (itemType === ItemType.SDCPhantomGroup) {
        showProperty.value = false;
      } else if (itemType === ItemType.SDCPhantom) {
        const originItem = newVal.getOriginItem();
        const originItemType = originItem.getItemType();
        showProperty.value =
          (originItemType === ItemType.Label &&
            originItem.getType() === "external") ||
          (originItemType === ItemType.LabelRef &&
            originItem.getType() === "external") ||
          (originItemType !== ItemType.Label &&
            originItemType !== ItemType.LabelRef);
      } else {
        showProperty.value = true;
      }
    } else {
      nodeItem.value = null;
    }
  }
);

const style = computed(() => {
  return {
    width: "inherit",
    height: "inherit",
    minWidth: `3%`,
  };
});
</script>

<style lang="sass" scoped>
.drawer
    padding: 2.3125rem 0 0 0
    top: 0px !important
    overflow: hidden
    user-select: none
</style>
