import { NodeType } from "../types";
import ITDANode from "./ITDANode";
import { ITDAControlClock } from "./types";
import * as ITDAControl from "../controls";
import API from "@/api/internal";
import { Item, ItemType } from "ITDAModelTypes";

export default abstract class ITDANodeClock
  extends ITDANode
  implements ITDAControlClock
{
  clkEn = false;
  constructor(name: string, type: NodeType) {
    super(name, type);
  }

  getCtrlEXT(): ITDAControl.ITDAControlEXT {
    return this.controls.ext as ITDAControl.ITDAControlEXT;
  }

  getCtrlFrequency(): ITDAControl.ITDAControlFrequency {
    return this.controls.freq as ITDAControl.ITDAControlFrequency;
  }

  getCtrlSDC(): ITDAControl.ITDAControlSDC {
    return this.controls.sdc as ITDAControl.ITDAControlSDC;
  }

  set width(data: number) {
    super.width = this.adjustNodeToGrid(data);
  }

  get width() {
    return (
      this.adjustNodeToGrid(super.width) -
      this.repo.getEditor(this.repo.getCurrentID()).getGridStroke() / 2
    );
  }

  set height(data: number) {
    super.height = this.adjustNodeToGrid(data);
  }

  get height() {
    return (
      this.adjustNodeToGrid(super.height) -
      this.repo.getEditor(this.repo.getCurrentID()).getGridStroke() / 2
    );
  }

  protected getConfigurationElements(): Item[] {
    const item = API.getItem(this.getItemID());
    const diagramId = API.getCurrentItem()?.getId();
    let res: Item[] = [];
    if (item && diagramId && item.getItemType() === ItemType.SDCPhantom) {
      const sdcInstanceItem = item.getParent(ItemType.SDCInstance);
      res = sdcInstanceItem
        ? API.getCurrentConfigurationElements(
            item.getOriginItem(),
            sdcInstanceItem
          )
        : [];
    } else {
      res = item ? API.getCMUConfigurationElements(item) : [];
    }
    return res;
  }

  protected getFrequencyInitValue(): string | undefined {
    const item = API.getItem(this.getItemID());
    if (item && item.getItemType() === ItemType.SDCPhantom) {
      const sdcInstanceItem = item.getParent(ItemType.SDCInstance);
      const selectedVoltageConfiuration =
        API.getSelectedVoltageConfiguration(sdcInstanceItem);
      const freqConfig = selectedVoltageConfiuration
        ?.getConfigurationElements()
        .find((e: Item) => e.getItemRef() === sdcInstanceItem);
      return freqConfig ? "0.00" : undefined;
    }
    return item && API.getSelectedCMUConfiguration(item) ? "0.00" : undefined;
  }
}
