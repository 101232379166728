<template>
  <v-container>
    <v-card hover class="mx-auto mb-8" min-width="328px"
      ><!--disabled-->
      <v-card-title class="text-primary text-center">
        <h3>CREATE EMPTY DB</h3>
      </v-card-title>
      <v-row class="ma-2">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="createdDesignName"
            dense
            label="Design Name"
            variant="outlined"
            color="primary"
            @update:modelValue="setCreatedDesignName">
            <template v-slot:append-inner>
              <v-btn color="white" class="pa-0" type="submit">
                <div
                  class="f-12 text-primary"
                  @click="createDB(`design`, createdDesignName)">
                  submit
                </div>
              </v-btn>
              <!-- <v-btn icon="$vuetify" variant="text" /> -->
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <!-- <v-form @submit.prevent> -->
          <v-text-field
            v-model="createdConfigName"
            dense
            label="Config Name"
            variant="outlined"
            color="primary"
            @update:modelValue="setCreatedConfigName">
            <template v-slot:append-inner>
              <v-btn color="white" class="pa-0" type="submit">
                <div
                  class="f-12 text-primary"
                  @click="createDB(`config`, createdConfigName)">
                  submit
                </div>
              </v-btn>
              <!-- <v-btn icon="$vuetify" variant="text" /> -->
            </template>
          </v-text-field>
          <!-- </v-form> -->
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script setup lang="ts">
import { ref, defineEmits } from "vue";
import API from "@/api";

const emit = defineEmits(["targetUpdated"]);

const createdDesignName = ref("");
const createdConfigName = ref("");
const setCreatedDesignName = (value: string) => {
  createdDesignName.value = value;
};
const setCreatedConfigName = (value: string) => {
  createdConfigName.value = value;
};
const createDB = async (type: "design" | "config", name: string) => {
  if (!name) {
    return;
  }
  if (
    window.confirm(
      `${type} DB "${name}"" will be crated. Do you want to proceed?`
    )
  ) {
    const db = API.exportEmptyDB(Date.now(), type);
    await API.save(name, type, db);
    emit("targetUpdated");
  }
};
</script>
<style lang="css" scoped>
.v-card-title {
  background-color: #e3ecf7;
}
.f-12 {
  font-size: 12px;
}
</style>
