<template>
  <v-row class="item-title text-center">
    <v-col cols="12">
      <h3 class="header">{{ title }}</h3>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { useRoute, onBeforeRouteUpdate } from "vue-router";
import API from "@/api/internal";

const defaultTitle = "Innovative Technology and Design Automation";
const title = ref(defaultTitle);
const route = useRoute();

const updateTitle = async () => {
  if (!route.query.id) {
    title.value = defaultTitle;
    return;
  }

  try {
    const currentItem = await API.getCurrentItem();
    title.value = currentItem
      ? API.getItemValue(currentItem, "title") || defaultTitle
      : defaultTitle;
  } catch (error) {
    console.error("Error fetching title:", error);
    title.value = defaultTitle;
  }
};

watch(
  () => route.query.id,
  async () => {
    await updateTitle();
  },
  { immediate: true }
);

onBeforeRouteUpdate((to, from, next) => {
  updateTitle();
  next();
});

onMounted(async () => {
  await updateTitle();
});
</script>

<style lang="sass" scoped>
.item-title
  max-height: 64px
.header
  flex-grow: 1
  width: 100%
  height: 64px !important
  display: flex
  justify-content: center
  align-items: center
  color: white
  background-color: black
  padding: 1rem 1rem 1rem 1rem
  user-select: none
  font-family: "Roboto", sans-serif
  font-weight: 400
  font-size: 1.5rem

.v-row
  margin: 0 !important
.v-col
  padding: 0 !important
</style>
