export const prdMode = process.env.NODE_ENV === "production";
export const subDomain = process.env.VUE_APP_SUB_DOMAIN;
export const domain = "soc-canvas.com";
export const protocol = prdMode ? "https" : "http";
export const port = 4400;
export const localhost = "192.168.50.110";
export const host = prdMode
  ? `${subDomain}.${domain}/api`
  : `${localhost}:${port}`;
export const s_host = prdMode
  ? `${subDomain}.${domain}`
  : `${localhost}:${port + 10}`;
