import Utils from "./utils";
import {
  Item,
  ItemType,
  RefItemElement,
  RefItemElementTarget,
  RefItemElementType,
  VariantLevel,
} from "ITDAModelTypes";

export const getSequenceItemLabelElements = (
  item: Item,
  propKey: string
): RefItemElement[] => {
  if (!item.getItemLabel()) {
    return [];
  }
  return [
    {
      icon: "mdi-chevron-double-right",
      variant: VariantLevel.LEVEL0,
      disabled: false,
      type: RefItemElementType.CHIP,
      propKey: propKey,
      label: "",
      multiple: false,
      targets: [
        {
          key: `${item.getItemLabel().getParent().getTitle()}:${item
            .getItemLabel()
            .getName()}`,
          item: item.getItemLabel(),
          closable: false,
        },
      ],
      event: eventItemLabel,
    },
  ];
};

const eventItemLabel = async (item: Item) => {
  Utils.moveToTarget(item, item.getItemLabel());
};

export const getSequenceLabelsElements = (
  item: Item,
  propKey: string
): RefItemElement[] => {
  const childrenData: RefItemElementTarget[] = [];
  childrenData.push(...addLabels(item.getParent()));

  const seqConfiguration = item.getParent(ItemType.PowerSequenceConfiguration);
  if (
    seqConfiguration?.getTitle() === "PMR" &&
    item.getParent().getName() === "RAM"
  ) {
    const ROM = seqConfiguration
      .getPowerSequenceDiagrams()
      .find((diagram: Item) => {
        return diagram.getType() === "ROM";
      });
    childrenData.push(...addLabels(ROM));
  }

  childrenData.sort((a, b) => {
    return a.key.localeCompare(b.key, "ko");
  });

  const model = item.getItemLabel()?.getName();
  return [
    {
      variant: VariantLevel.LEVEL0,
      disabled: false,
      type: RefItemElementType.ENUM,
      propKey,
      label: "Source Label",
      model,
      targets: childrenData,
      event: eventLabels,
    },
  ];
};

const eventLabels = (
  item: Item,
  elem: RefItemElement,
  value: string | string[]
) => {
  const target = elem.targets?.find((target) => target.key === value);
  if (target) {
    item.setItemLabel(target.item);
  } else {
    item.setItemLabel();
  }
};

const addLabels = (diagram: Item): RefItemElementTarget[] => {
  const res: RefItemElementTarget[] = [];
  const labels = diagram.getPowerSequenceLabels();
  labels.forEach((label: Item) => {
    res.push({
      key: label.getName(),
      item: label,
      closable: true,
    });
  });
  return res;
};
