/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import PowerPMRINTRSFREvent from '@/model/src/events/PowerPMRINTRSFREvent'
import PowerPMRINTRSFRChecker from '@/model/src/checkers/PowerPMRINTRSFRChecker'
import ITDA_PMS_PMC_INTR_SFR from '@/model/gen/sfrSpec/ITDA_PMS_PMC_INTR_SFR'
export default class PowerPMRINTRSFR extends Node {
    FID_POWERPMRINTRSFR_NAME = 100
    FID_POWERPMRINTRSFR_MAXNUM = 101
    FID_POWERPMRINTRSFR_LAST = 101

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerPMRINTRSFR"}))
        
        this.setEvent(new PowerPMRINTRSFREvent(this))
        this.setChecker(new PowerPMRINTRSFRChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "PowerPMRINTRSFR"
        }
    if (this.properties.name === undefined) {
        this.properties.name = "INTRSFR_NONAME"
    }
    if (this.properties.maxNum === undefined) {
        this.properties.maxNum = 8
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerPMRINTRSFR"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "INTRSFR_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 8, "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "INTRSFR_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 8, "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "PowerPMRINTRGRP", "auto": false, "searchable": false, "title": "", "headers": [], "hidden": true, "category": "IP"}, {"name": "PowerPMRINTRSFRATOM", "auto": false, "searchable": false, "title": "", "headers": [], "hidden": true, "category": "IP"}]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_PMS_PMC_INTR_SFR
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_PMS_PMC_INTR_SFR
        }
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMRINTRSFR_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_POWERPMRINTRSFR_NAME
    }
    

    setMaxNum(maxNum, event=true) {
        if ((maxNum !== null) && (maxNum !== undefined)) {
            if (maxNum.constructor !== Number) {
                throw `maxNum(${maxNum}) must be Number type`
            }
        }
        const newVal = (maxNum === null || maxNum === undefined) ? undefined : maxNum
        const oldVal = this.properties.maxNum
        this.properties.maxNum = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMRINTRSFR_MAXNUM, newVal, oldVal)
        }
    }
    getMaxNum(pure = false) {
        return this.properties.maxNum
        
    }
    getFidMaxNum() {
        return this.FID_POWERPMRINTRSFR_MAXNUM
    }
    

    getFidLast() {
        return this.FID_POWERPMRINTRSFR_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERPMRINTRSFR_NAME:
                return "name"

            case this.FID_POWERPMRINTRSFR_MAXNUM:
                return "maxNum"

            default:
                return ""
        }
    }


    newPowerPMRINTRGRP(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerPMRINTRGRP", properties, title, domain)
    }
    remPowerPMRINTRGRP(obj) {
        return this.removeChild(obj)
    }
    getPowerPMRINTRGRPs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerPMRINTRGRP")
            return res
        })
    }
    newPowerPMRINTRSFRATOM(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerPMRINTRSFRATOM", properties, title, domain)
    }
    remPowerPMRINTRSFRATOM(obj) {
        return this.removeChild(obj)
    }
    getPowerPMRINTRSFRATOMs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerPMRINTRSFRATOM")
            return res
        })
    }
}