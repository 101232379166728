/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import SFRBlockEvent from '@/model/src/events/SFRBlockEvent'
import SFRBlockChecker from '@/model/src/checkers/SFRBlockChecker'
export default class SFRBlock extends Item {
    FID_SFRBLOCK_NAME = 0
    FID_SFRBLOCK_LAST = 0

    constructor(obj) {
        super(Object.assign(obj,{itemType:"SFRBlock"}))
        
        this.setEvent(new SFRBlockEvent(this))
        this.setChecker(new SFRBlockChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "SFR"
        }
    if (this.properties.name === undefined) {
        this.properties.name = "SFR_NONAME"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "SFRBlock"
    }

    getSchema() {
        return {"name": {"type": "string", "readOnly": true, "default": "SFR_NONAME", "category": "IP", "multiple": false, "hidden": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "readOnly": true, "default": "SFR_NONAME", "category": "IP", "multiple": false, "hidden": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "SFR", "auto": false, "searchable": true, "title": "SFR List", "headers": [{"title": "Name", "align": "start", "filterable": true, "value": "name", "sortable": true}, {"title": "Offset", "filterable": true, "value": "offset", "align": "start", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SFRBLOCK_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_SFRBLOCK_NAME
    }
    

    getFidLast() {
        return this.FID_SFRBLOCK_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_SFRBLOCK_NAME:
                return "name"

            default:
                return ""
        }
    }


    newSFR(properties={}, title = "", domain = "") {
        return this.createChild(this, "SFR", properties, title, domain)
    }
    remSFR(obj) {
        return this.removeChild(obj)
    }
    getSFRs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "SFR")
            return res
        })
    }
}