<template>
  <div style="display: flex; justify-content: flex-start">
    <n-color-picker
      v-if="hideCanvas"
      v-model:value="model"
      :modes="['hex']"
      show-alphe="false"
      @update:value="changeColor"></n-color-picker>
    <div v-else>
      <v-card style="width: 100%">
        <v-card-subtitle>{{
          Utils.getLabel(props.propKey, props.prop)
        }}</v-card-subtitle>
        <v-color-picker
          v-model="model"
          v-model:mode="mode"
          @update:modelValue="changeColor"></v-color-picker>
        <div v-if="props.errorMessages" style="color: red">
          {{ props.errorMessages }}
        </div>
      </v-card>
    </div>
  </div>
</template>

<script setup lang="ts">
import Utils from "@/components/item/utils";
import { defineProps, ref, onMounted } from "vue";
import { ItemType, ItemPropsType } from "ITDAModelTypes";

const hideCanvas = ref(false);

const props = defineProps<ItemPropsType>();
const mode = "hexa";
const model = ref(props.item.getColor());

const changeColor = (color: string) => {
  model.value = color;
  Utils.update(props.item, props.propKey, String, color);
};

onMounted(() => {
  hideCanvas.value =
    props.item.getItemType() === ItemType.SDCClockGroup ? true : false;
});
</script>
