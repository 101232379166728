import {
  Item,
  VariantLevel,
  RefItemElement,
  RefItemElementType,
  RefItemElementTarget,
  Property,
} from "ITDAModelTypes";
import API from "@/api/internal";
import Canvas from "@/canvas";
import { NodeType } from "@/canvas/types";

type Event = (
  item: Item,
  elem: RefItemElement,
  value: string | string[]
) => void;

export const getBlock = (
  item: Item,
  propKey: string,
  prop: Property
): RefItemElement[] => {
  const upper = item.getUpper();
  const model = upper ? upper.getName() : undefined;
  const targets: RefItemElementTarget[] = [];
  const diagram = API.getParentDiagram(item);
  if (diagram) {
    diagram.getDUTInstances().filter((blk: Item) => {
      if (blk.getType() === "BLOCK" && blk !== item) {
        targets.push({
          key: blk.getName(),
          item: blk,
          closable: true,
        });
      }
    });
  }
  return [getBlockElement(model, targets, propKey, eventBlock)];
};

const getBlockElement = (
  model: string | undefined,
  targets: RefItemElementTarget[],
  propKey: string,
  event: Event
): RefItemElement => {
  return {
    variant: VariantLevel.LEVEL0,
    disabled: false,
    type: RefItemElementType.ENUM,
    propKey: propKey,
    label: "Select Block",
    multiple: false,
    model: model,
    targets: targets,
    event: event,
  };
};

const eventBlock = async (
  item: Item,
  elem: RefItemElement,
  value: string | string[]
) => {
  const target = elem.targets?.find((target) => target.key === value);
  if (target) {
    item.setUpper(target.item);
  } else {
    item.setUpper(null);
  }
};

export const getModule = (
  item: Item,
  propKey: string,
  prop: Property
): RefItemElement[] => {
  const diagram = API.getParentDiagram(item);
  const nodeType = diagram
    ? Canvas.getNodeType(diagram.getId(), item.getNodeID())
    : undefined;
  switch (nodeType) {
    case NodeType.ITDAINSTANCEPMD:
      return getPMDModule(item, propKey, prop);
    case NodeType.ITDAINSTANCESDC:
    case NodeType.ITDAINSTANCECMU:
      return getClockModule(item, propKey, prop);
    // case NodeType.ITDAINSTANCEBLOCK:
    //   return getDUTBlockModule(item, propKey, prop);
    case NodeType.ITDAINSTANCEPMC:
      return getDUTPMCModule(item, propKey, prop);
    case NodeType.ITDAINSTANCEPOWERDOMAIN:
      return getUPFBlockModule(item, propKey, prop);
    default:
      return [];
  }
};

const getPMDModule = (
  item: Item,
  propKey: string,
  prop: Property
): RefItemElement[] => {
  const moduleItem = item.getModule();
  const model = moduleItem ? moduleItem.getName() : undefined;
  const targets: RefItemElementTarget[] = [];
  API.getTopItem()
    ?.getPowerPMDFolder()
    .getPowerPMDs()
    .forEach((pmd: Item) => {
      targets.push({
        key: pmd.getName(),
        item: pmd,
        closable: true,
      });
    });
  return [getElement(model, targets, propKey, event)];
};

const getClockModule = (
  item: Item,
  propKey: string,
  prop: Property
): RefItemElement[] => {
  const topItem = API.getTopItem();
  const modules = topItem?.getClockCMUFolder().getClockCMUs();
  const moduleItem = item.getModule();
  const model = moduleItem ? moduleItem.getName() : undefined;
  const targets = [];
  for (const module of modules) {
    targets.push({
      key: module.getName(),
      item: module,
      closable: false,
    });
  }
  return [getElement(model, targets, propKey, event)];
};

// const getDUTBlockModule = (
//   item: Item,
//   propKey: string,
//   prop: Property
// ): RefItemElement[] => {
//   const topItem = API.getTopItem();
//   const modules = topItem?.getDUTModuleFolder().getDUTModules();
//   const diagram = API.getParentDiagram(item);
//   const moduleItem = item.getModule();
//   const model = moduleItem ? moduleItem.getName() : undefined;
//   const targets = [];
//   for (const module of modules) {
//     if (module === diagram?.getParent()) {
//       continue;
//     }
//     if (module.getDUTModuleDiagram().getDUTBlock().getMIM() === true) {
//       continue;
//     }
//     targets.push({
//       key: module.getName(),
//       item: module,
//       closable: false,
//     });
//   }
//   return [getElement(model, targets, propKey, event)];
// };

const getDUTPMCModule = (
  item: Item,
  propKey: string,
  prop: Property
): RefItemElement[] => {
  const topItem = API.getTopItem();
  const modules = topItem?.getPowerPMCFolder().getPowerPMCs();
  const moduleItem = item.getModule();
  const model = moduleItem ? moduleItem.getName() : undefined;
  const targets = [];
  for (const module of modules) {
    targets.push({
      key: module.getName(),
      item: module,
      closable: false,
    });
  }
  return [getElement(model, targets, propKey, event)];
};

const getUPFBlockModule = (
  item: Item,
  propKey: string,
  prop: Property
): RefItemElement[] => {
  const topItem = API.getTopItem();
  const modules = topItem?.getUPFModuleFolder().getUPFModules();
  const diagram = API.getParentDiagram(item);
  const module = item.getModule();
  const model = module ? module.getName() : undefined;
  const targets = [];
  for (const module of modules) {
    if (module === diagram?.getParent()) {
      continue;
    }
    targets.push({
      key: module.getName(),
      item: module,
      closable: false,
    });
  }
  return [getElement(model, targets, propKey, event)];
};

export const getPowerSource = (item: Item): RefItemElement[] => {
  const topItem = API.getTopItem();
  const voltageDomains = topItem?.getVoltage().getVoltageDomains();
  const powerSource = item.getPrimaryPowerSource();
  const model = powerSource ? powerSource.getName() : undefined;
  const targets = [];
  for (const voltageDomain of voltageDomains) {
    targets.push({
      key: voltageDomain.getName(),
      item: voltageDomain,
      closable: false,
    });
  }
  return [
    {
      variant: VariantLevel.LEVEL0,
      disabled: false,
      type: RefItemElementType.ENUM,
      propKey: "",
      label: "",
      multiple: false,
      model: model,
      targets: targets,
      event: powerEvent,
    },
  ];
};

const powerEvent = (
  item: Item,
  elem: RefItemElement,
  value: string | string[]
) => {
  const target = elem.targets?.find((target) => target.key === value);
  if (target) {
    item.setPrimaryPowerSource(target.item.getId());
  } else {
    item.setPrimaryPowerSource(null);
  }
};

const getElement = (
  model: string | undefined,
  targets: RefItemElementTarget[],
  propKey: string,
  event: Event
): RefItemElement => {
  return {
    variant: VariantLevel.LEVEL0,
    disabled: false,
    type: RefItemElementType.ENUM,
    propKey: propKey,
    label: "Select Module",
    multiple: false,
    model: model,
    targets: targets,
    event: event,
  };
};

const event = async (
  item: Item,
  elem: RefItemElement,
  value: string | string[]
) => {
  const target = elem.targets?.find((target) => target.key === value);
  if (target) {
    item.setModule(target.item);
  } else {
    item.setModule(null);
  }
};
