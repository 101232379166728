/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import ClockGateEvent from '@/model/src/events/ClockGateEvent'
import ClockGateChecker from '@/model/src/checkers/ClockGateChecker'
import ITDA_CMU_GATE_PT from '@/model/gen/sfrSpec/ITDA_CMU_GATE_PT'
export default class ClockGate extends Node {
    FID_CLOCKGATE_NAME = 100
    FID_CLOCKGATE_MAXNUM = 101
    FID_CLOCKGATE_ECC = 102
    FID_CLOCKGATE_ENABLEINITVALUE = 103
    FID_CLOCKGATE_SDCPATH = 104
    FID_CLOCKGATE_LAST = 104

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ClockGate"}))
        
        this.setEvent(new ClockGateEvent(this))
        this.setChecker(new ClockGateChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    if (this.properties.maxNum === undefined) {
        this.properties.maxNum = 256
    }
    if (this.properties.ECC === undefined) {
        this.properties.ECC = false
    }
    if (this.properties.enableInitValue === undefined) {
        this.properties.enableInitValue = 1
    }
    if (this.properties.SDCPath === undefined) {
        this.properties.SDCPath = "/{{CMU}}/{{NODE}}/{{NODE}}__CLKCOMP_GATE/{{NODE}}__CLKCOMP_GATE__CLKGATE/$POINT$ITDA_GENERIC_CLKGATE$GCK"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ClockGate"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 256, "category": "IP", "multiple": false, "readOnly": false}, "ECC": {"type": "boolean", "label": "External Clock Path", "desc": "Control External Clock", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "enableInitValue": {"type": "integer", "label": "ENABLE Reset Value (Integer)", "desc": "ENABLE Reset value (Integer)", "default": 1, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "SDCPath": {"type": "string", "hidden": true, "default": "/{{CMU}}/{{NODE}}/{{NODE}}__CLKCOMP_GATE/{{NODE}}__CLKCOMP_GATE__CLKGATE/$POINT$ITDA_GENERIC_CLKGATE$GCK", "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 256, "category": "IP", "multiple": false, "readOnly": false}, "ECC": {"type": "boolean", "label": "External Clock Path", "desc": "Control External Clock", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "enableInitValue": {"type": "integer", "label": "ENABLE Reset Value (Integer)", "desc": "ENABLE Reset value (Integer)", "default": 1, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "SDCPath": {"type": "string", "hidden": true, "default": "/{{CMU}}/{{NODE}}/{{NODE}}__CLKCOMP_GATE/{{NODE}}__CLKCOMP_GATE__CLKGATE/$POINT$ITDA_GENERIC_CLKGATE$GCK", "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "ClockGateEXT", "auto": true, "category": "IP"}]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_CMU_GATE_PT
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_CMU_GATE_PT
        }
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKGATE_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_CLOCKGATE_NAME
    }
    

    setMaxNum(maxNum, event=true) {
        if ((maxNum !== null) && (maxNum !== undefined)) {
            if (maxNum.constructor !== Number) {
                throw `maxNum(${maxNum}) must be Number type`
            }
        }
        const newVal = (maxNum === null || maxNum === undefined) ? undefined : maxNum
        const oldVal = this.properties.maxNum
        this.properties.maxNum = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKGATE_MAXNUM, newVal, oldVal)
        }
    }
    getMaxNum(pure = false) {
        return this.properties.maxNum
        
    }
    getFidMaxNum() {
        return this.FID_CLOCKGATE_MAXNUM
    }
    

    setECC(ECC, event=true) {
        if ((ECC !== null) && (ECC !== undefined)) {
            if (ECC.constructor !== Boolean) {
                throw `ECC(${ECC}) must be Boolean type`
            }
        }
        const newVal = (ECC === null || ECC === undefined) ? undefined : ECC
        const oldVal = this.properties.ECC
        this.properties.ECC = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKGATE_ECC, newVal, oldVal)
        }
    }
    getECC(pure = false) {
        return this.properties.ECC
        
    }
    getFidECC() {
        return this.FID_CLOCKGATE_ECC
    }
    

    setEnableInitValue(enableInitValue, event=true) {
        if ((enableInitValue !== null) && (enableInitValue !== undefined)) {
            if (enableInitValue.constructor !== Number) {
                throw `enableInitValue(${enableInitValue}) must be Number type`
            }
        }
        const newVal = (enableInitValue === null || enableInitValue === undefined) ? undefined : enableInitValue
        const oldVal = this.properties.enableInitValue
        this.properties.enableInitValue = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKGATE_ENABLEINITVALUE, newVal, oldVal)
        }
    }
    getEnableInitValue(pure = false) {
        return this.properties.enableInitValue
        
    }
    getFidEnableInitValue() {
        return this.FID_CLOCKGATE_ENABLEINITVALUE
    }
    

    setSDCPath(SDCPath, event=true) {
        if ((SDCPath !== null) && (SDCPath !== undefined)) {
            if (SDCPath.constructor !== String) {
                throw `SDCPath(${SDCPath}) must be String type`
            }
        }
        const newVal = (SDCPath === null || SDCPath === undefined) ? undefined : SDCPath
        const oldVal = this.properties.SDCPath
        this.properties.SDCPath = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKGATE_SDCPATH, newVal, oldVal)
        }
    }
    getSDCPath(pure = false) {
        return this.properties.SDCPath
        
    }
    getFidSDCPath() {
        return this.FID_CLOCKGATE_SDCPATH
    }
    

    getFidLast() {
        return this.FID_CLOCKGATE_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CLOCKGATE_NAME:
                return "name"

            case this.FID_CLOCKGATE_MAXNUM:
                return "maxNum"

            case this.FID_CLOCKGATE_ECC:
                return "ECC"

            case this.FID_CLOCKGATE_ENABLEINITVALUE:
                return "enableInitValue"

            case this.FID_CLOCKGATE_SDCPATH:
                return "SDCPath"

            default:
                return ""
        }
    }


    newClockGateEXT(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockGateEXT", properties, title, domain)
    }
    remClockGateEXT(obj) {
        return this.removeChild(obj)
    }
    getClockGateEXT() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "ClockGateEXT")
            return res
        })
    }
}