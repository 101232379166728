/**
 *  Generated by Modeler
 *  timestamp: 2024-08-27 10:28:21.895823
 */
//import ErrorType from '@/model/src/ErrorType'
import ItemChecker from "@/model/src/ItemChecker";

export default class SDCPhantomChecker extends ItemChecker {
  constructor(item) {
    super(item);
    //this.addChecker(item.getFidPhantom(), this.checkPhantom, ErrorType.NAME)
    //this.addChecker(item.getFidClockPoint(), this.checkClockPoint, ErrorType.NAME)
    //this.addChecker(item.getFidPorts(), this.checkPorts, ErrorType.NAME)
    //this.addChecker(item.getFidPath(), this.checkPath, ErrorType.NAME)
    //this.addChecker(item.getFidOriginItem(), this.checkOriginItem, ErrorType.NAME)
  }
  checkPhantom() {
    // TBD
  }
  checkClockPoint() {
    // TBD
  }
  checkPorts() {
    // TBD
  }
  checkPath() {
    // TBD
  }
  checkOriginItem() {
    // TBD
  }
}
