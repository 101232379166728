<template>
  <v-text-field
    ref="input"
    v-model="model"
    color="primary"
    :style="props.table ? `margin-bottom: 15px` : `margin-bottom: 0`"
    :label="props.table ? undefined : Utils.getLabel(props.propKey, props.prop)"
    :disabled="
      Utils.isDisabledIntegerField(props.item, props.propKey, props.prop)
    "
    :variant="
      props.itemRef
        ? props.itemRef.variant
          ? Utils.getVariant(
              RefItemElementType.INTEGER,
              VariantLevel[props.itemRef.variant]
            )
          : variantDefault
        : variantDefault
    "
    :rules="combinedRules"
    :error-messages="props.errorMessages"
    :hide-details="!inputError && !checkError"
    @change="
      inputError
        ? undefined
        : props.itemRef
        ? props.itemRef.event(props.item, props.itemRef, $event.target.value)
        : Utils.change(props.item, props.propKey, Number, $event)
    " />
</template>

<script setup lang="ts">
import API from "@/api/internal";
import { computed, defineProps, nextTick, onUpdated, ref, watch } from "vue";
import Utils from "@/components/item/utils";
import {
  ItemPropsType,
  RefItemElementType,
  VariantLevel,
} from "ITDAModelTypes";
const props = defineProps<ItemPropsType>();
const value = props.itemRef
  ? props.itemRef.model
  : Utils.getValue(props.item, props.propKey);

const model = ref(value);
const input = ref<any>(null);
const inputError = ref(false);
const checkError = ref(false);

const variantDefault = "underlined";

const rules = API.getConfigurationRules(props.item, props.propKey);
const itemRules = API.getItemRules(props.item, props.propKey);

const combinedRules = computed(() => {
  return [...rules, ...itemRules];
});

watch(model, async () => {
  await nextTick();
  const err = await input.value.validate();
  inputError.value = err.length > 0;
});

onUpdated(() => {
  checkError.value = !!props.errorMessages;
});
</script>
