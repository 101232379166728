<template>
  <div class="value-space" :style="extStyle" @pointerdown.stop="">
    <div
      v-for="(value, idx) in Utils.getEXTData(
        props.data.repo.getCurrentID(),
        props.data.node.id
      )"
      :key="idx">
      {{ value }}
    </div>
  </div>
</template>

<script setup lang="ts">
import Utils from "../../utils";
import { defineProps, computed, onUpdated, ref } from "vue";
import Canvas from "@/canvas";

const props = defineProps<{
  data: any;
}>();

const extsLength = ref(0);
const gridSpace = Canvas.getEditor(Canvas.getCurrentID()).getGridSpace();

const extStyle = computed(() => {
  const baseGridSpace = 10; // base grid 20
  const baseFontSize = 0.7815;
  const computedFontSize = (gridSpace / baseGridSpace) * baseFontSize;
  return {
    top: `-${extsLength.value * 20}px`,
    width: `${props.data.node.width}px`,
    fontSize: `${computedFontSize}rem`,
  };
});

onUpdated(() => {
  extsLength.value = Utils.getEXTData(
    props.data.repo.getCurrentID(),
    props.data.node.id
  ).length;
});
</script>

<style lang="sass" scoped>
.value-space
  position: absolute
  display: flex
  flex-direction: column
  align-items: center
  color: blue
</style>
