<template>
  <v-textarea
    v-model="model"
    :label="props.table ? undefined : Utils.getLabel(props.propKey, props.prop)"
    :disabled="Utils.isDisabledTextarea(props.item, props.propKey, props.prop)"
    :variant="
      props.itemRef
        ? props.itemRef.variant
          ? Utils.getVariant(
              RefItemElementType.TEXTAREA,
              VariantLevel[props.itemRef.variant]
            )
          : variantDefault
        : variantDefault
    "
    auto-grow
    background-color="grey lighten-4"
    color="blue-grey lighten-2"
    :error-messages="props.errorMessages"
    :hide-details="!props.errorMessages"
    @change="Utils.change(props.item, props.propKey, String, $event)">
  </v-textarea>
</template>

<script setup lang="ts">
import { defineProps, ref } from "vue";
import Utils from "@/components/item/utils";
import {
  ItemPropsType,
  RefItemElementType,
  VariantLevel,
} from "ITDAModelTypes";
const props = defineProps<ItemPropsType>();

const model = ref(Utils.getDescription(props.item, props.propKey));
const variantDefault = "filled";
</script>
