/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import PowerSequenceBackEvent from '@/model/src/events/PowerSequenceBackEvent'
import PowerSequenceBackChecker from '@/model/src/checkers/PowerSequenceBackChecker'
export default class PowerSequenceBack extends Node {
    FID_POWERSEQUENCEBACK_LAST = 100

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerSequenceBack"}))
        
        this.setEvent(new PowerSequenceBackEvent(this))
        this.setChecker(new PowerSequenceBackChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerSequenceBack"
    }

    getSchema() {
        return {}
    }

    static getSchema() {
        return {}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    getFidLast() {
        return this.FID_POWERSEQUENCEBACK_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            default:
                return ""
        }
    }


}