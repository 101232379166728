/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import PowerAPMPWRUPEvent from '@/model/src/events/PowerAPMPWRUPEvent'
import PowerAPMPWRUPChecker from '@/model/src/checkers/PowerAPMPWRUPChecker'
export default class PowerAPMPWRUP extends Item {
    FID_POWERAPMPWRUP_NAME = 0
    FID_POWERAPMPWRUP_LAST = 0

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerAPMPWRUP"}))
        
        this.setEvent(new PowerAPMPWRUPEvent(this))
        this.setChecker(new PowerAPMPWRUPChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerAPMPWRUP"
    }

    getSchema() {
        return {"name": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERAPMPWRUP_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_POWERAPMPWRUP_NAME
    }
    

    getFidLast() {
        return this.FID_POWERAPMPWRUP_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERAPMPWRUP_NAME:
                return "name"

            default:
                return ""
        }
    }


}