/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import ProjectEvent from '@/model/src/events/ProjectEvent'
import ProjectChecker from '@/model/src/checkers/ProjectChecker'
export default class Project extends Item {
    FID_PROJECT_NAME = 0
    FID_PROJECT_ACGMODE = 1
    FID_PROJECT_SAFETYMODE = 2
    FID_PROJECT_CONNECTIONMODE = 3
    FID_PROJECT_MIGRATION = 4
    FID_PROJECT_JTAG = 5
    FID_PROJECT_LAST = 5

    constructor(obj) {
        super(Object.assign(obj,{itemType:"Project"}))
        
        this.setEvent(new ProjectEvent(this))
        this.setChecker(new ProjectChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "Project"
        }
    if (this.properties.name === undefined) {
        this.properties.name = "PRJ_NONAME"
    }
    if (this.properties.acgMode === undefined) {
        this.properties.acgMode = false
    }
    if (this.properties.safetyMode === undefined) {
        this.properties.safetyMode = false
    }
    if (this.properties.connectionMode === undefined) {
        this.properties.connectionMode = false
    }
    if (this.properties.jtag === undefined) {
        this.properties.jtag = true
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "Project"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "PRJ_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "acgMode": {"type": "boolean", "label": "Use Auto Clock Gating", "hidden": true, "default": false, "category": "IP", "multiple": false, "readOnly": false}, "safetyMode": {"type": "boolean", "label": "Safety Mode", "hidden": true, "default": false, "category": "IP", "multiple": false, "readOnly": false}, "connectionMode": {"type": "boolean", "label": "Generate Connection TestCase", "hidden": true, "default": false, "category": "IP", "multiple": false, "readOnly": false}, "migration": {"type": "item", "label": "SFR Spec Refresh (Offset can be changed)", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "jtag": {"type": "boolean", "label": "Insert JTAG Module", "hidden": true, "default": true, "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "PRJ_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "acgMode": {"type": "boolean", "label": "Use Auto Clock Gating", "hidden": true, "default": false, "category": "IP", "multiple": false, "readOnly": false}, "safetyMode": {"type": "boolean", "label": "Safety Mode", "hidden": true, "default": false, "category": "IP", "multiple": false, "readOnly": false}, "connectionMode": {"type": "boolean", "label": "Generate Connection TestCase", "hidden": true, "default": false, "category": "IP", "multiple": false, "readOnly": false}, "migration": {"type": "item", "label": "SFR Spec Refresh (Offset can be changed)", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "jtag": {"type": "boolean", "label": "Insert JTAG Module", "hidden": true, "default": true, "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "ClockCMUFolder", "auto": true, "category": "IP"}, {"name": "PowerPMDFolder", "auto": true, "category": "IP"}, {"name": "PowerPMCFolder", "auto": true, "category": "IP"}, {"name": "WrapperDFTFolder", "auto": true, "category": "IP"}, {"name": "DUTModuleFolder", "auto": true, "category": "IP"}, {"name": "Voltage", "auto": true, "category": "IP"}, {"name": "SDCModuleFolder", "auto": true, "category": "IP"}, {"name": "UPFModuleFolder", "auto": true, "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_PROJECT_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_PROJECT_NAME
    }
    

    setAcgMode(acgMode, event=true) {
        if ((acgMode !== null) && (acgMode !== undefined)) {
            if (acgMode.constructor !== Boolean) {
                throw `acgMode(${acgMode}) must be Boolean type`
            }
        }
        const newVal = (acgMode === null || acgMode === undefined) ? undefined : acgMode
        const oldVal = this.properties.acgMode
        this.properties.acgMode = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_PROJECT_ACGMODE, newVal, oldVal)
        }
    }
    getAcgMode(pure = false) {
        return this.properties.acgMode
        
    }
    getFidAcgMode() {
        return this.FID_PROJECT_ACGMODE
    }
    

    setSafetyMode(safetyMode, event=true) {
        if ((safetyMode !== null) && (safetyMode !== undefined)) {
            if (safetyMode.constructor !== Boolean) {
                throw `safetyMode(${safetyMode}) must be Boolean type`
            }
        }
        const newVal = (safetyMode === null || safetyMode === undefined) ? undefined : safetyMode
        const oldVal = this.properties.safetyMode
        this.properties.safetyMode = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_PROJECT_SAFETYMODE, newVal, oldVal)
        }
    }
    getSafetyMode(pure = false) {
        return this.properties.safetyMode
        
    }
    getFidSafetyMode() {
        return this.FID_PROJECT_SAFETYMODE
    }
    

    setConnectionMode(connectionMode, event=true) {
        if ((connectionMode !== null) && (connectionMode !== undefined)) {
            if (connectionMode.constructor !== Boolean) {
                throw `connectionMode(${connectionMode}) must be Boolean type`
            }
        }
        const newVal = (connectionMode === null || connectionMode === undefined) ? undefined : connectionMode
        const oldVal = this.properties.connectionMode
        this.properties.connectionMode = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_PROJECT_CONNECTIONMODE, newVal, oldVal)
        }
    }
    getConnectionMode(pure = false) {
        return this.properties.connectionMode
        
    }
    getFidConnectionMode() {
        return this.FID_PROJECT_CONNECTIONMODE
    }
    

    setMigration(migration, event=true) {
        if ((migration !== null) && (migration !== undefined)) {
            if (!(migration instanceof Item) && (migration.constructor !== String)) {
                throw `migration(${migration}) must be Item or String type`
            }
        }
        const newVal = (migration === null || migration === undefined) ? undefined : (migration.constructor === String) ? migration : migration.getId()
        const oldVal = this.properties.migration
        this.properties.migration = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_PROJECT_MIGRATION, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getMigration(pure = false) {
        return pure ? this.properties.migration : this.getRefItem(this.properties.migration)
        
    }
    getFidMigration() {
        return this.FID_PROJECT_MIGRATION
    }
    

    setJtag(jtag, event=true) {
        if ((jtag !== null) && (jtag !== undefined)) {
            if (jtag.constructor !== Boolean) {
                throw `jtag(${jtag}) must be Boolean type`
            }
        }
        const newVal = (jtag === null || jtag === undefined) ? undefined : jtag
        const oldVal = this.properties.jtag
        this.properties.jtag = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_PROJECT_JTAG, newVal, oldVal)
        }
    }
    getJtag(pure = false) {
        return this.properties.jtag
        
    }
    getFidJtag() {
        return this.FID_PROJECT_JTAG
    }
    

    getFidLast() {
        return this.FID_PROJECT_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_PROJECT_NAME:
                return "name"

            case this.FID_PROJECT_ACGMODE:
                return "acgMode"

            case this.FID_PROJECT_SAFETYMODE:
                return "safetyMode"

            case this.FID_PROJECT_CONNECTIONMODE:
                return "connectionMode"

            case this.FID_PROJECT_MIGRATION:
                return "migration"

            case this.FID_PROJECT_JTAG:
                return "jtag"

            default:
                return ""
        }
    }


    newClockCMUFolder(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockCMUFolder", properties, title, domain)
    }
    remClockCMUFolder(obj) {
        return this.removeChild(obj)
    }
    getClockCMUFolder() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "ClockCMUFolder")
            return res
        })
    }
    newPowerPMDFolder(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerPMDFolder", properties, title, domain)
    }
    remPowerPMDFolder(obj) {
        return this.removeChild(obj)
    }
    getPowerPMDFolder() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "PowerPMDFolder")
            return res
        })
    }
    newPowerPMCFolder(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerPMCFolder", properties, title, domain)
    }
    remPowerPMCFolder(obj) {
        return this.removeChild(obj)
    }
    getPowerPMCFolder() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "PowerPMCFolder")
            return res
        })
    }
    newWrapperDFTFolder(properties={}, title = "", domain = "") {
        return this.createChild(this, "WrapperDFTFolder", properties, title, domain)
    }
    remWrapperDFTFolder(obj) {
        return this.removeChild(obj)
    }
    getWrapperDFTFolder() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "WrapperDFTFolder")
            return res
        })
    }
    newDUTModuleFolder(properties={}, title = "", domain = "") {
        return this.createChild(this, "DUTModuleFolder", properties, title, domain)
    }
    remDUTModuleFolder(obj) {
        return this.removeChild(obj)
    }
    getDUTModuleFolder() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "DUTModuleFolder")
            return res
        })
    }
    newVoltage(properties={}, title = "", domain = "") {
        return this.createChild(this, "Voltage", properties, title, domain)
    }
    remVoltage(obj) {
        return this.removeChild(obj)
    }
    getVoltage() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "Voltage")
            return res
        })
    }
    newSDCModuleFolder(properties={}, title = "", domain = "") {
        return this.createChild(this, "SDCModuleFolder", properties, title, domain)
    }
    remSDCModuleFolder(obj) {
        return this.removeChild(obj)
    }
    getSDCModuleFolder() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "SDCModuleFolder")
            return res
        })
    }
    newUPFModuleFolder(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFModuleFolder", properties, title, domain)
    }
    remUPFModuleFolder(obj) {
        return this.removeChild(obj)
    }
    getUPFModuleFolder() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "UPFModuleFolder")
            return res
        })
    }
}