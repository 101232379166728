import * as ITDAControl from "../controls";
import * as ITDANodeRenderer from "./renderers";
import { NodeType } from "../types";
import ITDANodeClock from "./ITDANodeClock";
import API from "@/api/internal";
import { Item, ItemType } from "ITDAModelTypes";

export default class ITDAPLLCTRL extends ITDANodeClock {
  constructor(name = "") {
    super(name, NodeType.ITDAPLLCTRL);
    this.render = ITDANodeRenderer.ITDAPLLCTRL.render;
  }

  async data(
    inputs: Record<`CLK_INPUT`, any>
  ): Promise<Record<`CLK_OUTPUT`, any>> {
    const freq = this.controls.freq as ITDAControl.ITDAControlFrequency;
    let frequency = this.getFrequencyInitValue();
    if (Object.keys(inputs).length !== 0) {
      const inputFreq = inputs.CLK_INPUT[0];
      let item = API.getItem(this.getItemID()) as Item;
      if (item.getItemType() === ItemType.SDCPhantom) {
        item = item.getOriginItem();
      }
      const pllSpec = API.getExternalPLLSpec().find(
        (spec) => spec.name === item.getPlltype()
      );
      const configElements = this.getConfigurationElements();
      const configSelection = configElements.find(
        (elem: Item) => elem.getProp() === "selection"
      );
      if (configSelection && Number(configSelection.getValue())) {
        if (pllSpec) {
          let formula = pllSpec.formula;
          formula = formula.replace("$FIN", inputFreq);
          for (const sfr of pllSpec.sfrs) {
            for (const field of sfr.fields) {
              if (field.config) {
                const prop = field.name.split("SFR_")[1];
                const configElem = configElements.find(
                  (elem: Item) => elem.getProp() === prop
                );
                let value = "0";
                if (configElem && configElem.getValue()) {
                  value = configElem.getValue();
                }
                formula = formula.replace(`$${prop}`, value);
              }
            }
          }
          frequency = eval(formula);
        }
      } else {
        frequency = inputFreq;
      }
    }

    if (frequency) {
      freq.setFrequency(Number(frequency).toFixed(2));
    }
    this.update();
    return { CLK_OUTPUT: freq.getFrequency() };
  }
}
