import ItemFactory from "./ItemFactory";
import MapperFactory from "./MapperFactory";

// import store from '@/store/index'
import ItemType from "./ItemType";
import SFRHandler from "./SFRHandler";
import Domain from "./Domain";
import Category from "./Category";
import DiagramType from "./DiagramType";
import ConfigurationHandler from "./ConfigurationHandler";
import UPFHandler from "./UPFHandler";
// import SequenceHandler from "./SequenceHandler"
import Repository from "../store";

// const SFR = Repository.getInstance().getSFR();

export default class ItemHandler {
  static getCurrentDesignCategory() {
    // const res = Category.getObjByKey(store.getters['GET_DESIGN_CATEGORY'])
    // const res = store.getters['GET_DESIGN_CATEGORY']
    // return res ? res : Category.IP
    return Category.IP;
  }

  static getCurrentDesignDomain() {
    // const res = Domain.getObjByKey(store.getters['GET_DESIGN_DOMAIN'])
    // const res = store.getters['GET_DESIGN_DOMAIN']
    const res = Domain.getObjByKey(Repository.getInstance().getCurrentDomain());
    return res ? res : Domain.Clock;
  }

  static getRefItem(id) {
    return Repository.getInstance().getItemByID(id);
  }

  static getTopItem() {
    return Repository.getInstance().getTopItem();
  }

  static getCurrentItem() {
    // return store.getters['item/GET_CURRENT_ITEM']
    return Repository.getInstance().getCurrentItem();
  }

  static getModuleItems() {
    // return Object.values(store.getters['item/GET_MODULE_ITEMS'])
    return [];
  }

  static getPowerDomainItems() {
    // return Object.values(store.getters['item/GET_POWER_DOMAIN_ITEMS'])
    return [];
  }

  static isLoadedConfigDB() {
    // return store.getters['io/GET_CURRENT_CONFIG_DB'] ? true : false
    return true;
  }

  static getPLLSpec(type) {
    // return type ? store.getters['sfr/GET_PLL_SPEC'][type] : null
    return null;
  }

  static isLoading() {
    // return store.getters['IS_LOADING']
    return Repository.getInstance().isLoading();
  }

  static isMigrating() {
    // return store.getters['IS_MIGRATING']
    return false;
  }

  static refreshCnt() {
    // store.commit('REFRESH_CNT')
  }

  static getItemSchema(type) {
    return ItemFactory.getConstructor(type).getSchema();
  }

  static createSyncItem(
    parent,
    type,
    properties = {},
    title = "",
    domain = ""
  ) {
    try {
      const data = this._getDefaultItemData(parent, type, domain);
      if (title) {
        data.title = title;
      }
      Object.assign(data.properties, properties);
      const item = ItemFactory.createItemInstance(
        type,
        data,
        Repository.getInstance().getExternalPLLSpec(),
        Repository.getInstance().getExternalHMSpec()
      );
      if (parent) {
        parent.addChild(item, false);
      }

      this.createMapper(parent, item);
      this._updateDefaultData(parent, item);
      this.createChildItems(parent, item);
      item.getEvent().setupSFRHandler(item);
      this.createItemSFR(parent, item);
      this.postProcess(parent, item);
      return item;
    } catch (err) {
      console.error(err);
    }
  }

  static createItem(parent, type, properties = {}, title = "", domain = "") {
    try {
      let res = [];
      const data = this._getDefaultItemData(
        parent,
        type,
        domain ? domain : Repository.getInstance().getCurrentDomain()
      );
      if (title) {
        data.title = title;
      }
      Object.assign(data.properties, properties);
      const item = ItemFactory.createItemInstance(
        type,
        data,
        Repository.getInstance().getExternalPLLSpec(),
        Repository.getInstance().getExternalHMSpec()
      );
      // if (parent) {
      //   parent.addChild(item);
      // }
      this.createMapper(parent, item);
      this._updateDefaultData(parent, item);
      this.createChildItems(parent, item);
      item.getEvent().setupSFRHandler(item);
      this.createItemSFR(parent, item);

      // item.getEvent().setupSFRHandler(item);
      // res = res.concat(this.createChildItems(parent, item));
      // res.push(this.createChildItems(parent, item));
      // this.createItemSFR(parent, item);
      // this.postProcess(parent, item);

      // if (parent) {
      //   res.push(parent.addChild(item));
      // }
      return {
        item: item,
        promise: Promise.all(this.postProcess(parent, item)).then((results) => {
          return parent
            ? parent.addChild(item)
            : new Promise((resolve) => resolve(true));
        }),
        // promise: this.createChildItems(parent, item)
        //   .then((result) => {
        //     item.getEvent().setupSFRHandler(item);
        //     return this.createItemSFR(parent, item);
        //   })
        //   .then((result) => {
        //     return Promise.all(this.postProcess(parent, item));
        //   })
        //   .then((results) => {
        //     return parent
        //       ? parent.addChild(item)
        //       : new Promise((resolve) => resolve(true));
        //   }),
      };
    } catch (err) {
      console.error(err);
    }
  }

  static _getDefaultItemData(parent, type, domain) {
    const res = {
      pid: parent ? parent.getId() : null,
      domain: domain,
      disabled: false,
      sorted: false,
      hidden: false,
      hiddenChildren: false,
      properties: {},
    };
    switch (type) {
      case ItemType.ClockCMUCtrl:
        res.hidden = true;
        break;
      case ItemType.ClockGate:
      case ItemType.ClockQChannelManager:
        if (parent.getItemType() === ItemType.ClockCMU) {
          res.hidden = true;
        }
        break;

      case ItemType.ClockDiagram:
      case ItemType.PowerSequenceDiagram:
      case ItemType.PowerDiagram:
      case ItemType.ClockIPDiagram:
        res.hiddenChildren = true;
        break;
      case ItemType.PowerPMR:
      case ItemType.PowerPMDCTRLSPT:
        res.hidden = true;
        break;

      default:
        break;
    }
    return res;
  }

  static _updateDefaultData(parent, item) {
    switch (item.getItemType()) {
      case ItemType.Project:
      case ItemType.Configuration:
      case ItemType.VoltageDomain:
      case ItemType.DUTModule:
      case ItemType.ClockCMU:
      case ItemType.PowerPMC:
      case ItemType.PowerPMD:
        item.setTitle(item.getName());
        break;
      case ItemType.ClockQChannelManager:
        if (parent.getItemType() === ItemType.ClockCMU) {
          item.setName(`SFRIF_QCH_${parent.getName()}`);
        }
        break;
      case ItemType.ClockCMUCtrl:
        item.setName(`${parent.getName()}`);
        break;
      case ItemType.ClockGate:
        if (parent.getItemType() === ItemType.ClockCMU) {
          item.setName(`SFRIF_GATE_${parent.getName()}`);
        }
        break;
      default:
        break;
    }
  }

  static postProcess(parent, item) {
    let res = [];
    switch (item.getItemType()) {
      case ItemType.SFRBlock:
        const sfrHandlerMap = new Map();
        for (const comp of ItemFactory.getSFRItemTargets()) {
          if (comp.hasSFRSpec()) {
            sfrHandlerMap.set(
              comp.getClassName(),
              new SFRHandler(comp.getSFRMeta())
            );
          }
        }
        Repository.getInstance().getSFR().addSFRHandlerMap(item, sfrHandlerMap);
        break;
      case ItemType.ConfigurationFolder:
        item.setSelectedConfiguration(null, false);
        item.setTitle(
          ConfigurationHandler.getConfigurationTitle(parent.getItemType())
        );
        item.hiddenChildren = true;
        break;
      case ItemType.Configuration:
        item.setSelected(false);
        break;
      case ItemType.ConfigurationElement:
        item.hidden = true;
        break;
      case ItemType.DUTInstance:
        item.hidden = true;
        item.hiddenChildren = false;
        break;
      case ItemType.SDCModule:
        item.setTitle(item.getName());
        // item.hiddenChildren = true;
        break;
      case ItemType.UPFModule:
        item.setTitle(item.getName());
        break;
      case ItemType.SDCInstance:
        item.hiddenChildren = true;
        break;
      case ItemType.DUTModule:
        item.hidden = true;
        item.setTitle(item.getName());
        break;
      case ItemType.DUTModuleDiagram:
      case ItemType.SDCDiagram:
      case ItemType.UPFDiagram:
        item.hidden = true;
        item.hiddenChildren = true;
        break;
      case ItemType.UPFElement:
        item.hidden = parent.getItemType() === ItemType.PowerDomain;
        break;
      case ItemType.ClockDiagramFolder:
        item.setTitle("Clock Diagram", false);
        break;
      case ItemType.ClockDiagram:
        item.setType(DiagramType.Clock.getKey());
        item.setTitle(item.getName(), false);
        break;

      case ItemType.PowerPMRCore:
      case ItemType.PowerPMRCoreMode:
      case ItemType.PowerPMRCoreNMI:
      case ItemType.PowerPMRCorePCU:
      case ItemType.PowerPMRCoreReset:
        item.getEvent().lstn_update_name(item, parent.getName());
        break;
      case ItemType.PowerPMDCTRLSPT:
        item.setName(parent.getName());
        break;

      case ItemType.PowerSource:
      case ItemType.UPFGround:
        // item.hidden = true
        if (!item.getUPFMapperPowerSources().length) {
          ItemHandler.createItem(item, ItemType.UPFMapperPowerSource);
        }
        break;
      case ItemType.UPFIsolationCell:
      case ItemType.UPFRetention:
        if (!item.getUPFMapperPowerSources().length) {
          ItemHandler.createItem(item, ItemType.UPFMapperPowerSource);
        }
        break;
      case ItemType.UPFPowerSwitch:
        if (!item.getUPFElements().length) {
          ItemHandler.createItem(item, ItemType.UPFElement, {
            name: "PO_NONAME",
          });
        }
        if (!item.getUPFMapperPowerSources().length) {
          ItemHandler.createItem(item, ItemType.UPFMapperPowerSource);
        }
        break;
      case ItemType.UPFLevelShifter:
      case ItemType.UPFEnableLevelShifter:
      case ItemType.UPFSRAM:
        if (!item.getUPFMapperPowerSources().length) {
          ItemHandler.createItem(item, ItemType.UPFMapperPowerSource);
          ItemHandler.createItem(item, ItemType.UPFMapperPowerSource);
        }
        break;
      case ItemType.Input:
      case ItemType.Output:
        item.hidden = true;
        break;
      default:
        break;
    }
    item.getEvent().setupSFRHandler(item);
    if (!this.isLoading()) {
      const event = item.getEvent().setupDefaultEvents(item);
      if (event instanceof Promise) {
        res.push(event);
      }
      const obj = item.getEvent().postProcess(item);
      if (obj instanceof Array) {
        obj.forEach((o) => {
          if (o instanceof Promise) {
            res.push(o);
          }
        });
      } else if (obj instanceof Promise) {
        res.push(obj);
      }
    }
    return res;
  }

  static createChildItems(parent, item, migration = false) {
    for (const childSpec of item.getChildrenSpec()) {
      if (!childSpec.auto) {
        continue;
      }

      if (item.getItemType() === ItemType.ClockCMU) {
        if (
          childSpec.name === ItemType.ClockGate.getKey() ||
          childSpec.name === ItemType.ClockQChannelManager.getKey()
        ) {
          if (!ItemHandler.getTopItem().getAcgMode()) {
            return;
          }
        }
      }

      var childItem = null;
      if (migration) {
        if (childSpec.interface) {
          childItem = item[`get${childSpec.interface}`]();
        } else {
          childItem = item
            .getChildren()
            .find(
              (item) =>
                item.getItemType() === ItemType.getObjByKey(childSpec.name)
            );
        }
      }
      if (!childItem) {
        this.createSyncItem(
          item,
          ItemType.getObjByKey(childSpec.name),
          {},
          childSpec.title,
          item.domain
        );
      }
    }
    return true;
  }

  static createItemSFR(parent, item, specType = "FULL") {
    if (!item.hasSFRSpec()) {
      return;
    }
    switch (item.getItemType()) {
      case ItemType.ClockFreqmon:
      case ItemType.ClockCMUCtrl:
      case ItemType.ClockPLLCtrl:
      case ItemType.ClockRefCLKMultiplexer:
      case ItemType.ClockMultiplexer:
      case ItemType.ClockDivider:
      case ItemType.ClockNMDivider:
      case ItemType.ClockGate:
      case ItemType.ClockBuffer:
      case ItemType.ClockQChannelManager:
        var cmu =
          parent.getItemType() === ItemType.ClockCMU
            ? parent
            : parent.getParent(ItemType.ClockCMU);
        return SFRHandler.createSFR(cmu.getSFRBlock(), item, specType);
      case ItemType.ClockPLL:
        var pllCtrl = parent;
        var sfrSpec = Object.assign({}, item.getSFRSpec("FULL"));
        var pllSpec = Repository.getInstance()
          .getExternalPLLSpec()
          .find((spec) => spec.name === pllCtrl.getPlltype());
        sfrSpec.sfrs = pllSpec
          ? sfrSpec.sfrs.concat(...pllSpec.sfrs)
          : sfrSpec.sfrs;
        return SFRHandler.createSFR(
          pllCtrl.getParent(ItemType.ClockCMU).getSFRBlock(),
          item,
          "FULL",
          sfrSpec
        );
      case ItemType.PowerAPM:
      case ItemType.PowerPSW:
      case ItemType.PowerPWREN:
      case ItemType.PowerISOEN:
      case ItemType.PowerMEM:
      case ItemType.PowerRETENTION:
      case ItemType.PowerHANDSHAKE:
      case ItemType.PowerOTP:
      case ItemType.PowerREFCLKEN:
      case ItemType.PowerCLINK:
      case ItemType.PowerPCH:
      case ItemType.PowerRESET:
      case ItemType.PowerUSERDEFOUT:
      case ItemType.PowerUSERDEFIN:
      case ItemType.PowerPMRINFORM:
      case ItemType.PowerPMRTIMEOUT:
      case ItemType.PowerPMRCTRL:
      case ItemType.PowerPMRLATCH:
      case ItemType.PowerPMRINTRSFR:
      case ItemType.PowerPMRINTRSFRATOM:
      case ItemType.PowerPMRINTREXT:
      case ItemType.PowerPMRINTREXTATOM:
      case ItemType.PowerPMRTIMERGRP:
      case ItemType.PowerPMRTIMER:
      case ItemType.PowerPMRPMDGRP:
      case ItemType.PowerPMRPMDCTRL:
        var pmd = parent.getParent(ItemType.PowerPMD);
        var sfrBlock = pmd
          ? pmd.getSFRBlock()
          : parent.getParent(ItemType.PowerPMC).getSFRBlock();
        return SFRHandler.createSFR(sfrBlock, item, specType);
      case ItemType.PowerPMRCoreMode:
      case ItemType.PowerPMRCoreNMI:
      case ItemType.PowerPMRCorePCU:
      case ItemType.PowerPMRCoreReset:
        return SFRHandler.createSFR(
          parent.getParent(ItemType.PowerPMC).getSFRBlock(),
          item,
          specType
        );
      case ItemType.PowerPMDCTRLSPT:
        return SFRHandler.createSFR(parent.getSFRBlock(), item, specType);
      default:
        return [];
    }
  }

  static createMapper(parent, item) {
    var target = null;
    switch (item.getItemType()) {
      case ItemType.ClockPLL:
        var pllCtrl = item.getParent();
        MapperFactory.createMapper("name", item, pllCtrl);
        break;
      case ItemType.ClockFreqmonEXT:
      case ItemType.ClockPLLCtrlEXT:
      case ItemType.ClockRefCLKMultiplexerEXT:
      case ItemType.ClockMultiplexerEXT:
      case ItemType.ClockDividerEXT:
      case ItemType.ClockNMDividerEXT:
      case ItemType.ClockGateEXT:
        MapperFactory.createMapper("name", item, parent);
        break;
      case ItemType.ClockQChannelManagerEXT:
        MapperFactory.createMapper("name", item, parent);
        MapperFactory.createMapper("title", item, parent);
        break;
      case ItemType.SFR:
        target = item.getItemRef();
        if (target) {
          MapperFactory.createMapper("SFRToNode", item, target);
        }
        break;
      case ItemType.SFRField:
        target = item.getItemRef();
        if (target) {
          MapperFactory.createMapper("SFRFieldToNode", item, target);
        }
        //   if (target) {
        //     if (target.getItemType() === ItemType.PowerPMC) {
        //       MapperFactory.createMapper("SFRFieldToPMUSub", item, target);
        //     }
        //   }
        break;
      case ItemType.ClockCMUCtrl:
      case ItemType.SFRBlock:
        MapperFactory.createMapper("name", item, parent);
        break;
      case ItemType.ClockGate:
        if (parent.getItemType() === ItemType.ClockCMU) {
          MapperFactory.createMapper("SFRIFToCMU", item, parent);
        }
        break;
      case ItemType.ClockQChannelManager:
        if (parent.getItemType() === ItemType.ClockCMU) {
          MapperFactory.createMapper("SFRIFToCMU", item, parent);
        }
        break;
      case ItemType.PowerPMR:
      case ItemType.PowerPMRCore:
      case ItemType.PowerPMRCoreMode:
      case ItemType.PowerPMRCoreNMI:
      case ItemType.PowerPMRCorePCU:
      case ItemType.PowerPMRCoreReset:
      case ItemType.PowerPMRINTRSFRATOM:
      case ItemType.PowerPMRINTREXTATOM:
      case ItemType.PowerPMRTIMER:
      case ItemType.PowerPMRPMDCTRL:
      case ItemType.PowerPMRINTRGRP:
      case ItemType.PowerPMRINTRGenBit:
      case ItemType.PowerPMRINTREvent:
      case ItemType.PowerPMDCTRLSPT:
        MapperFactory.createMapper("name", item, parent);
        break;
      case ItemType.PowerSequenceDiagram:
        var refItem = item.getItemRef();
        if (refItem) {
          MapperFactory.createMapper("name", item, refItem);
        }
        break;
      // case ItemType.DUTBlock:
      //   var dutModule = parent.getParent(ItemType.DUTModule);
      //   if (dutModule) {
      //     MapperFactory.createMapper("name", item, dutModule);
      //   }
      //   break;
      case ItemType.DUTInstance:
        if (item.getType() === "BLOCK") {
          const dutModule = item.getModule();
          if (dutModule) {
            MapperFactory.createMapper("name", dutModule, item);
          }
        }
        break;
      case ItemType.UPFPowerDomain:
        var upfModule = parent.getParent(ItemType.UPFModule);
        if (upfModule) {
          MapperFactory.createMapper("name", item, upfModule);
        }
        break;
      default:
        break;
    }
  }

  static removeSyncItem(data) {
    try {
      var item =
        data.constructor === String
          ? Repository.getInstance().getItemByID(data)
          : data;
      if (item) {
        this._removeSyncItem(item);
        if (item.getParent()) {
          item.getParent().remChild(item, false);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  static _removeSyncItem(item) {
    for (const cItem of item.getChildren()) {
      this._removeSyncItem(cItem);
      item.remChild(cItem, false);
    }
    this.removeSFR(item);
    this.removeMapper(item);
    Repository.getInstance().remItem(item);
  }

  static async removeItem(data) {
    var item =
      data && data.constructor === String
        ? Repository.getInstance().getItemByID(data)
        : data;
    if (item) {
      await this._removeItem(item);
      if (item.getParent()) {
        await item.getParent().remChild(item);
      }
    }
  }

  static async _removeItem(item) {
    const res = [];
    for (const cItem of item.getChildren()) {
      res.push(this._removeChildItem(item, cItem));
    }
    await Promise.all(res);
    this.removeSFR(item);
    this.removeMapper(item);
    Repository.getInstance().remItem(item);
  }

  static async _removeChildItem(item, cItem) {
    await this._removeItem(cItem);
    await item.remChild(cItem);
  }

  static removeSFR(item) {
    if (!item.hasSFRSpec()) {
      return;
    }
    SFRHandler.removeSFR(item.getSFRBlock(item), item);
  }

  static removeMapper(item) {
    for (const mapper of item.getMappers()) {
      MapperFactory.removeMapper(mapper);
    }
  }

  static refreshSFRInfo(item) {
    let extItem = null;
    switch (item.getItemType()) {
      case ItemType.ClockMultiplexer:
        var value = Number(item.getNumberOfParents()).toString(2).length - 1;
        for (const mapper of item.getMappers()) {
          if (mapper.getSource().getItemType() === ItemType.SFR) {
            this._updateSFRFieldSize(mapper.getSource(), "SFR_SELECT", value);
          }
        }
        extItem = item.getClockMultiplexerEXT();
        if (extItem.getThrottleMode()) {
          const mapper = extItem
            .getMappers()
            .find((mapper) => mapper.getTarget() === extItem);
          this._updateSFRFieldSize(
            mapper.getSource(),
            "SFR_SELECT_THROTTLE",
            value
          );
        }
        break;
      case ItemType.ClockDivider:
        for (const mapper of item.getMappers()) {
          if (mapper.getSource().getItemType() === ItemType.SFR) {
            this._updateSFRFieldSize(
              mapper.getSource(),
              "SFR_DIVRATIO",
              item.getMaxDivRatioWidth()
            );
            this._updateSFRFieldInitValue(
              mapper.getSource(),
              "SFR_DIVRATIO",
              item.getDivRatioInitValue()
            );
          }
        }
        extItem = item.getClockDividerEXT();
        if (extItem.getThrottleMode()) {
          const mapper = extItem
            .getMappers()
            .find((mapper) => mapper.getTarget() === extItem);
          this._updateSFRFieldSize(
            mapper.getSource(),
            "SFR_DIVRATIO_THROTTLE",
            item.getMaxDivRatioWidth()
          );
          this._updateSFRFieldInitValue(
            mapper.getSource(),
            "SFR_DIVRATIO_THROTTLE",
            item.getDivRatioInitValue()
          );
        }
        break;
      case ItemType.ClockGate:
        for (const mapper of item.getMappers()) {
          if (mapper.getSource().getItemType() === ItemType.SFR) {
            this._updateSFRFieldInitValue(
              mapper.getSource(),
              "SFR_ENABLE",
              item.getEnableInitValue()
            );
          }
        }
        break;
      case ItemType.PowerREFCLKEN:
      case ItemType.PowerPSW:
      case ItemType.PowerISOEN:
      case ItemType.PowerOTP:
        for (const mapper of item.getMappers()) {
          if (mapper.getSource().getItemType() === ItemType.SFR) {
            this._updateSFRFieldNameReset(
              mapper.getSource(),
              null,
              item.getIsResetnCLDBoot() ? "CLDBOOT_RESETn" : ""
            );
          }
        }
        break;
      case ItemType.PowerPWREN:
        for (const mapper of item.getMappers()) {
          if (mapper.getSource().getItemType() === ItemType.SFR) {
            this._updateSFRFieldInitValue(
              mapper.getSource(),
              "SFR_PWREN",
              item.getPwrenInitValue()
            );
            this._updateSFRFieldNameReset(
              mapper.getSource(),
              null,
              item.getIsResetnCLDBoot() ? "CLDBOOT_RESETn" : ""
            );
          }
        }
        break;
      case ItemType.PowerHANDSHAKE:
        for (const mapper of item.getMappers()) {
          if (mapper.getSource().getItemType() === ItemType.SFR) {
            this._updateSFRFieldInitValue(
              mapper.getSource(),
              "SFR_REQ",
              item.getReqInitValue()
            );
            this._updateSFRFieldNameReset(
              mapper.getSource(),
              null,
              item.getIsResetnCLDBoot() ? "CLDBOOT_RESETn" : ""
            );
          }
        }
        break;
      case ItemType.PowerMEM:
        for (const mapper of item.getMappers()) {
          if (mapper.getSource().getItemType() === ItemType.SFR) {
            this._updateSFRFieldInitValue(
              mapper.getSource(),
              "SFR_MEM_RET",
              item.getMemRetInitValue()
            );
            this._updateSFRFieldNameReset(
              mapper.getSource(),
              null,
              item.getIsResetnCLDBoot() ? "CLDBOOT_RESETn" : ""
            );
          }
        }
        break;
      case ItemType.PowerPCH:
        for (const mapper of item.getMappers()) {
          if (mapper.getSource().getItemType() === ItemType.SFR) {
            this._updateSFRFieldSize(
              mapper.getSource(),
              "SFR_PSTATE",
              item.getMaxPstateWidth()
            );
            this._updateSFRFieldSize(
              mapper.getSource(),
              "SFR_PACTIVE",
              item.getMaxPstateWidth()
            );
            this._updateSFRFieldNameReset(
              mapper.getSource(),
              null,
              item.getIsResetnCLDBoot() ? "CLDBOOT_RESETn" : ""
            );
          }
        }
        break;
      case ItemType.PowerPMRCTRL:
        for (const mapper of item.getMappers()) {
          if (mapper.getSource().getItemType() === ItemType.SFR) {
            this._updateSFRFieldNameReset(
              mapper.getSource(),
              null,
              item.getIsCLDBoot() ? "CLDBOOT_RESETn" : ""
            );
          }
        }
        break;
      case ItemType.PowerPMRTIMEOUT:
        for (const mapper of item.getMappers()) {
          if (mapper.getSource().getItemType() === ItemType.SFR) {
            this._updateSFRFieldInitValue(
              mapper.getSource(),
              "SFR_DATA",
              item.getDataInitValue()
            );
          }
        }
        break;
      case ItemType.PowerRESET:
        for (const mapper of item.getMappers()) {
          if (mapper.getSource().getItemType() === ItemType.SFR) {
            this._updateSFRFieldNameReset(
              mapper.getSource(),
              null,
              item.getIsResetnCLDBoot() ? "CLDBOOT_RESETn" : ""
            );
          }
        }
        break;
      case ItemType.PowerRETENTION:
        for (const mapper of item.getMappers()) {
          if (mapper.getSource().getItemType() === ItemType.SFR) {
            this._updateSFRFieldInitValue(
              mapper.getSource(),
              "SFR_RETENTION",
              item.getRetentionInitValue()
            );
            this._updateSFRFieldNameReset(
              mapper.getSource(),
              null,
              item.getIsResetnCLDBoot() ? "CLDBOOT_RESETn" : ""
            );
          }
        }
        break;
      case ItemType.PowerUSERDEFIN:
        for (const mapper of item.getMappers()) {
          if (mapper.getSource().getItemType() === ItemType.SFR) {
            this._updateSFRFieldSize(
              mapper.getSource(),
              "SFR_USERDEFIN",
              item.getMaxUserdefWidth()
            );
          }
        }
        break;
      case ItemType.PowerUSERDEFOUT:
        for (const mapper of item.getMappers()) {
          if (mapper.getSource().getItemType() === ItemType.SFR) {
            this._updateSFRFieldSize(
              mapper.getSource(),
              "SFR_USERDEFOUT",
              item.getMaxUserdefWidth()
            );
            this._updateSFRFieldInitValue(
              mapper.getSource(),
              "SFR_USERDEFOUT",
              item.getUserdefInitValue()
            );
            this._updateSFRFieldNameReset(
              mapper.getSource(),
              null,
              item.getIsResetnCLDBoot() ? "CLDBOOT_RESETn" : ""
            );
          }
        }
        break;
      default:
        break;
    }
  }

  static _updateSFRFieldSize(sfr, name, value) {
    sfr.getSFRFields().forEach((sfrField) => {
      if (sfrField.getName() === name) {
        sfrField.setSize(value, false);
        var range = `[${
          sfrField.getSize() + sfrField.getIndex() - 1
        }:${sfrField.getIndex()}]`;
        sfrField.setTitle(`${sfrField.getName()}${range}`, false);
      }
    });
  }

  static _updateSFRFieldInitValue(sfr, name, value) {
    sfr.getSFRFields().forEach((sfrField) => {
      if (sfrField.getName() === name) {
        value = "0x" + Number(value).toString(16).toUpperCase();
        sfrField.setInitValue(value, false);
      }
    });
  }

  static _updateSFRFieldNameReset(sfr, name, value) {
    sfr.getSFRFields().forEach((sfrField) => {
      if (!name || sfrField.getName() === name) {
        if (sfrField.getAccess() === "RW") {
          sfrField.setNameReset(value, false);
        }
      }
    });
  }

  static verilog(value) {
    if (!/^[A-Za-z]/.test(value)) {
      return "The first character must be an English alphabet letter";
    }
    if (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value)) {
      return "Only English Alphabet is supported";
    }
    if (/\d+\.\d+/.test(value)) {
      return `${value} must be an integer type`;
    }
    if (/ /.test(value) === true) {
      return "Cannot use space key";
    }
    if (/[^A-Za-z0-9_]/.test(value)) {
      return "Only _ is allowed as a special character";
    }
    return true;
  }

  static getRules(item, prop) {
    const res = [];

    const isDecimalInteger = (value) => {
      return /^-?\d+$/.test(String(value).trim());
    };

    const isHexadecimal = (value) => {
      const trimmedValue = String(value).trim();
      // return (
      //   trimmedValue === "0x" || /^(0x|0X)[0-9a-fA-F]+$/.test(trimmedValue)
      // );
      return (
        trimmedValue === "0x0" ||
        /^(0x|0X)[1-9a-fA-F][0-9a-fA-F]*$/.test(trimmedValue)
      );
    };

    const isValidNonLeadingZeroInteger = (value) => {
      const strValue = String(value).trim();
      if (isHexadecimal(strValue)) {
        return true;
      }
      return /^-?[1-9]\d*$/.test(strValue) || /^0$/.test(strValue);
    };

    let maxDivRatioWidth = 12;
    if (item.getItemType().key === "ClockDivider") {
      if (prop === "maxDivRatioWidth") {
        res.push((value) => {
          if (!isDecimalInteger(value) && !isHexadecimal(value)) {
            return `${value} must be an integer or hexadecimal type`;
          }
          if (!isValidNonLeadingZeroInteger(value)) {
            return `${value} cannot be preceded by an integer, except for 0`;
          }

          if (value < 1 || value > maxDivRatioWidth) {
            return `${value} must be in the range 1 ~ ${maxDivRatioWidth}`;
          }
          return true;
        });
      } else if (prop === "divRatioInitValue") {
        res.push((value) => {
          if (!isDecimalInteger(value) && !isHexadecimal(value)) {
            return `${value} must be an integer or hexadecimal type`;
          }
          if (!isValidNonLeadingZeroInteger(value)) {
            return `${value} cannot be preceded by an integer, except for 0`;
          }
          const maxValue = Math.pow(2, item.getMaxDivRatioWidth());
          if (
            value === "" ||
            Number(value) >= Number(maxValue) ||
            Number(value) < 0
          ) {
            return `${value} must be in the range 0 ~ ${maxValue - 1}`;
          }
          return true;
        });
      } else if (prop === "clkEnValue") {
        res.push((value) => {
          if (!isDecimalInteger(value) && !isHexadecimal(value)) {
            return `${value} must be an integer or hexadecimal type`;
          }
          if (!isValidNonLeadingZeroInteger(value)) {
            return `${value} cannot be preceded by an integer, except for 0`;
          }
          const maxValue = 4;
          if (
            value === "" ||
            Number(value) >= Number(maxValue) ||
            Number(value) < 0
          ) {
            return `${value} must be in the range 0 ~ ${maxValue - 1}`;
          }
          return true;
        });
      }
    } else if (item.getItemType().key === "ClockGate") {
      if (prop === "enableInitValue") {
        res.push((value) => {
          if (!isDecimalInteger(value) && !isHexadecimal(value)) {
            return `${value} must be an integer or hexadecimal type`;
          }
          if (!isValidNonLeadingZeroInteger(value)) {
            return `${value} cannot be preceded by an integer, except for 0`;
          }
          const maxValue = 2;
          if (
            value === "" ||
            Number(value) >= Number(maxValue) ||
            Number(value) < 0
          ) {
            return `${value} must be in the range 0 ~ ${maxValue - 1}`;
          }
          return true;
        });
      }
    } else if (item.getItemType().key === "LabelRef") {
      if (prop === "boundaryName") {
        res.push((value) => {
          const result = this.verilog(value);
          if (result !== true) {
            return result;
          }
          return true;
        });
      }
    } else {
      switch (item.getItemType()) {
        case ItemType.PowerPMRTIMEOUT:
          if (prop === "dataInitValue") {
            let maxDataInitValue = Math.pow(2, 32) - 1;
            res.push((value) => {
              if (!isDecimalInteger(value) && !isHexadecimal(value)) {
                return `${value} must be an integer or hexadecimal type`;
              } else if (!isValidNonLeadingZeroInteger(value)) {
                return `${value} cannot be preceded by an integer, except for 0`;
              } else {
                if (
                  isNaN(value) ||
                  Number(value) < 0 ||
                  Number(value) > maxDataInitValue
                ) {
                  return `${value} must be in the range 0 ~ ${maxDataInitValue}`;
                }
              }
              return true;
            });
          }
          break;
        case ItemType.PowerPWREN:
          if (prop === "pwrenInitValue") {
            res.push((value) => {
              if (!isDecimalInteger(value) && !isHexadecimal(value)) {
                return `${value} must be an integer or hexadecimal type`;
              } else if (!isValidNonLeadingZeroInteger(value)) {
                return `${value} cannot be preceded by an integer, except for 0`;
              } else {
                if (isNaN(value) || Number(value) < 0 || Number(value) > 1) {
                  return `${value} must be in the range 0 ~ 1`;
                }
              }
              return true;
            });
          }
          break;
        case ItemType.PowerMEM:
          if (prop === "memRetInitValue") {
            res.push((value) => {
              if (!isDecimalInteger(value) && !isHexadecimal(value)) {
                return `${value} must be an integer or hexadecimal type`;
              } else if (!isValidNonLeadingZeroInteger(value)) {
                return `${value} cannot be preceded by an integer, except for 0`;
              } else {
                if (isNaN(value) || Number(value) < 0 || Number(value) > 1) {
                  return `${value} must be in the range 0 ~ 1`;
                }
              }
              return true;
            });
          }
          break;
        case ItemType.PowerRETENTION:
          if (prop === "retentionInitValue") {
            res.push((value) => {
              if (!isDecimalInteger(value) && !isHexadecimal(value)) {
                return `${value} must be an integer or hexadecimal type`;
              } else if (!isValidNonLeadingZeroInteger(value)) {
                return `${value} cannot be preceded by an integer, except for 0`;
              } else {
                if (isNaN(value) || Number(value) < 0 || Number(value) > 1) {
                  return `${value} must be in the range 0 ~ 1`;
                }
              }
              return true;
            });
          }
          break;
        case ItemType.PowerHANDSHAKE:
          if (prop === "reqInitValue") {
            res.push((value) => {
              if (!isDecimalInteger(value) && !isHexadecimal(value)) {
                return `${value} must be an integer or hexadecimal type`;
              } else if (!isValidNonLeadingZeroInteger(value)) {
                return `${value} cannot be preceded by an integer, except for 0`;
              } else {
                if (isNaN(value) || Number(value) < 0 || Number(value) > 1) {
                  return `${value} must be in the range 0 ~ 1`;
                }
              }
              return true;
            });
          }
          break;
        case ItemType.PowerPCH:
          if (prop === "maxPstateWidth") {
            res.push((value) => {
              if (!isDecimalInteger(value) && !isHexadecimal(value)) {
                return `${value} must be an integer or hexadecimal type`;
              }
              if (!isValidNonLeadingZeroInteger(value)) {
                return `${value} cannot be preceded by an integer, except for 0`;
              }
              const numericValue = isHexadecimal(value)
                ? parseInt(value, 16) || 0
                : Number(value);

              if (value === "0x0" || value === "0") {
                return "Value must be in the range 1 ~ 8";
              }

              if (
                numericValue !== 0 &&
                (numericValue < 1 || numericValue > 8)
              ) {
                return `${value} must be in the range 1 ~ 8`;
              }
              return true;
            });
          }
          break;
        case ItemType.PowerUSERDEFOUT:
          if (prop === "maxUserdefWidth") {
            res.push((value) => {
              if (!isDecimalInteger(value) && !isHexadecimal(value)) {
                return `${value} must be an integer or hexadecimal type`;
              }
              if (!isValidNonLeadingZeroInteger(value)) {
                return `${value} cannot be preceded by an integer, except for 0`;
              }
              const numericValue = isHexadecimal(value)
                ? parseInt(value, 16) || 0
                : Number(value);

              if (value === "0x0" || value === "0") {
                return "0 must be in the range 1 ~ 32";
              }
              if (
                isNaN(numericValue) ||
                Number(numericValue) < 1 ||
                Number(numericValue) > 32
              ) {
                return `${value} must be in the range 1 ~ 32`;
              }

              return true;
            });
          } else if (prop === "userdefInitValue") {
            res.push((value) => {
              if (!isDecimalInteger(value) && !isHexadecimal(value)) {
                return `${value} must be an integer or hexadecimal type`;
              }
              if (!isValidNonLeadingZeroInteger(value)) {
                return `${value} cannot be preceded by an integer, except for 0`;
              }

              if (
                isNaN(value) ||
                Number(value) < 0 ||
                Number(value) > Math.pow(2, item.getMaxUserdefWidth()) - 1
              ) {
                return `${value} must be in the range 0 ~ ${
                  Math.pow(2, item.getMaxUserdefWidth()) - 1
                }`;
              }
              return true;
            });
          }
          break;
        case ItemType.PowerUSERDEFIN:
          if (prop === "maxUserdefWidth") {
            res.push((value) => {
              if (!isDecimalInteger(value) && !isHexadecimal(value)) {
                return `${value} must be an integer or hexadecimal type`;
              }
              if (!isValidNonLeadingZeroInteger(value)) {
                return `${value} cannot be preceded by an integer, except for 0`;
              }
              const numericValue = isHexadecimal(value)
                ? parseInt(value, 16) || 0
                : Number(value);

              if (value === "0") {
                return "0 must be in the range 1 ~ 32";
              }
              if (
                isNaN(numericValue) ||
                Number(numericValue) < 1 ||
                Number(numericValue) > 32
              ) {
                return `${value} must be in the range 1 ~ 32`;
              }

              return true;
            });
          }
          break;
        case ItemType.PowerSequenceWrite:
        case ItemType.PowerSequenceReadWait:
        case ItemType.PowerSequenceIf:
          if (prop === "value") {
            res.push((value) => {
              if (value) {
                let field = item.getSFRField();

                if (!field) {
                  return `Value should not be provided when SFR Field is unselected`;
                }
                if (!isDecimalInteger(value) && !isHexadecimal(value)) {
                  return `${value} must be an integer or hexadecimal type`;
                }
                if (!isValidNonLeadingZeroInteger(value)) {
                  return `${value} cannot be preceded by an integer, except for 0`;
                }
                const maxValue = Math.pow(2, field.getSize()) - 1;
                const numericValue = Number(value);

                if (numericValue < 0 || numericValue > maxValue) {
                  return `${value} must be in the range 0 ~ ${maxValue}`;
                }
              }
              return true;
            });
          }
          break;
        case ItemType.PowerSequenceWait:
          if (prop === "value") {
            res.push((value) => {
              let field = item.getSFRField();
              if (field) {
                if (value) {
                  return `Value should not be provided when SFR Field is selected`;
                }
              } else {
                if (value) {
                  if (!isDecimalInteger(value) && !isHexadecimal(value)) {
                    return `${value} must be an integer or hexadecimal type`;
                  }
                  if (!isValidNonLeadingZeroInteger(value)) {
                    return `${value} cannot be preceded by an integer, except for 0`;
                  }
                  const numericValue = Number(value);

                  if (numericValue > 65535 || numericValue < 0) {
                    return `${value} must be in the range 0 ~ 65535`;
                  }
                }
              }
              return true;
            });
          }
          break;
        case ItemType.SDCPhantom:
          if (prop === "path") {
            res.push((value) => {
              if (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value)) {
                return "Only English Alphabet is supported";
              }
              if (/\d+\.\d+/.test(value)) {
                return `${value} must be a integer type`;
              }
              if (/ /.test(value) === true) {
                return "Cannot Use Space Key";
              }

              if (/[^A-Za-z0-9_\/\$\.\[\] ]/.test(value)) {
                return "Only _ / $ . are allowed as selection sign";
              }
              return true;
            });
          }
          break;
        default:
          break;
      }
    }
    return res;
  }
}
