<template>
  <div
    v-if="props.data.signals.length >= 0 && nodeType === 'ITDANodePower'"
    class="signals"
    :style="getSignalsStyle()">
    <div
      v-for="(signal, idx) in getSignals()"
      :key="`signal-` + idx + props.seed"
      class="signal">
      <div v-if="isPowerSettingTarget(signal)" class="power-setting">
        {{ getPowerSettingValue(signal) }}
      </div>
      <div
        :ref="(el) => setRef(el as HTMLElement | null, idx)"
        class="arrow"
        :style="getArrowStyle(signal)"
        :data-testid="'signal-' + idx"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  CSSProperties,
  defineProps,
  onBeforeUpdate,
  onMounted,
  onUpdated,
  ref,
  watch,
} from "vue";
import API from "@/api/internal";
import { ItemType, Item } from "ITDAModelTypes";
import ITDAControlSignal from "../ITDAControlSignal";
import { NodeType } from "@/canvas/types";

const props = defineProps<{
  data: any;
  seed: number;
}>();

const nodeWidth = ref<number>(0);
const signals = ref<Item[]>([]);
const signalHTMLElems = ref<{ elem: HTMLElement; idx: number }[]>([]);
const nodeType = computed(() => props.data.node.inheritedClassName);
const nodeInstance = computed(() => props.data.node.getType().getKey());
const signalsLength = ref<number>(0);

const setRef = (el: HTMLElement | null, idx: number) => {
  if (el) collectRefs(el, idx);
};

const getSignalsStyle = (): CSSProperties => {
  const res = {
    width: "160px",
    left: `${nodeWidth.value}px`,
    height: `${height.value}px`,
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    position: "relative" as const,
  };

  if (nodeInstance.value === "ITDACLINK" || nodeInstance.value === "ITDAMEM") {
    Object.assign(res, { bottom: "5px" });
  }
  if (nodeInstance.value === "ITDAPCH") {
    Object.assign(res, { top: "5px" });
  }
  if (nodeInstance.value === "ITDAAPM") {
    if (signalsLength.value < 3) {
      Object.assign(res);
    } else if (signalsLength.value >= 3) {
      Object.assign(res, { top: "5px" });
    }
  }
  return res;
};

const height = computed(() => {
  return signals.value.length < 3 ? 80 : signals.value.length * 30;
});

const currentDiagram = API.getCurrentItem();

const isPowerSettingTarget = (signal: ITDAControlSignal): boolean => {
  const item = API.getItem(props.data.node.getItemID());
  if (!item) return false;

  let selectedConfig;
  if (
    currentDiagram?.getParent().getParent().getItemType() === ItemType.PowerPMD
  ) {
    selectedConfig = API.getSelectedPMDConfiguration(item);
  } else if (
    currentDiagram?.getParent().getParent().getItemType() === ItemType.PowerPMC
  ) {
    selectedConfig = API.getSelectedPMCConfiguration(item);
  }
  if (!selectedConfig) return false;

  const configSchemas = API.getConfigSchema(item);
  for (const configSchema of configSchemas) {
    if (signal.getName() === configSchema.prop) {
      return true;
    }
  }

  return false;
};

const getPowerSettingValue = (signal: ITDAControlSignal): number => {
  const item = API.getItem(props.data.node.getItemID());
  let configElems: Item[] = [];
  if (item) {
    if (
      currentDiagram?.getParent().getParent().getItemType() ===
      ItemType.PowerPMD
    ) {
      configElems = API.getPMDConfigurationElements(item);
    } else if (
      currentDiagram?.getParent().getParent().getItemType() ===
      ItemType.PowerPMC
    ) {
      configElems = API.getPMCConfigurationElements(item);
    }
  }
  const configElem = configElems.find(
    (elem) => elem.getProp() === signal.getName()
  );
  if (configElem) {
    const value = configElem.getValue();
    if (value === "0x0") return 0;
    if (value === "0x1") return 1;
    return value;
  }
  return 0;
};

const collectRefs = (el: HTMLElement, idx: number) => {
  if (el) {
    signalHTMLElems?.value.push({
      elem: el,
      idx: idx,
    });
  }
};

const getSignals = () => {
  if (props.data.node.getType() === NodeType.ITDAAPM) {
    const item = API.getItem(props.data.node.getItemID());
    if (item) {
      signals.value = [
        ...item.getPowerAPMPWRDOWNs(),
        ...item.getPowerAPMPWRUPs(),
      ];
      return [...item.getPowerAPMPWRDOWNs(), ...item.getPowerAPMPWRUPs()];
    }
  } else {
    signals.value = [...props.data.signals];
    return props.data.signals;
  }
};

const renderSignals = () => {
  signalHTMLElems.value.forEach((obj: { elem: HTMLElement; idx: number }) => {
    const signalData =
      props.data.node.getType() === NodeType.ITDAAPM
        ? {
            signal: { direction: "in", name: signals.value[obj.idx].getName() },
            index: obj.idx,
          }
        : { signal: props.data.signals[obj.idx], index: obj.idx };

    props.data.render(obj.elem, signalData);
  });
};

const getArrowStyle = (signal: ITDAControlSignal): CSSProperties => {
  return isPowerSettingTarget(signal)
    ? {
        paddingRight: "6px",
        height: "inherit",
        flexWrap: "nowrap",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }
    : {
        height: "inherit",
        flexWrap: "nowrap",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      };
};

onUpdated(() => {
  nodeWidth.value = props.data.node.width;
  getSignals();
  renderSignals();
});

onMounted(() => {
  nodeWidth.value = props.data.node.width;
  getSignals();
  renderSignals();
});

onBeforeUpdate(() => {
  signalHTMLElems.value.forEach((obj: { elem: HTMLElement; idx: number }) => {
    if (obj.elem.firstChild) {
      obj.elem.removeChild(obj.elem.firstChild);
    }
  });
  signalHTMLElems.value = [];
  getSignals();
});

watch(
  () => getSignals(),
  (newSignals) => {
    signalsLength.value = newSignals.length;
  }
);
</script>

<style lang="sass" scoped>
.signal
  height: 30px
  text-align: left
  display: flex
  flex-wrap: nowrap
  flex-direction: row
  justify-content: center
  align-items: center
  flex: 1 1
.power-setting
  position: relative
  font-size: 0.7rem
  color: red
  right: 3.5px
  z-index: 10
</style>
