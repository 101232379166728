/**
 *  Generated by Modeler
 *  timestamp: 2022-08-22 12:24:20.652766
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";
import ConnectionType from "../ConnectionType";
import ItemType from "../ItemType";

export default class OutputChecker extends ItemChecker {
  constructor(item) {
    super(item);
    // this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME);
    // this.addChecker(item.getFidWidth(), this.checkWidth, ErrorType.CONFIG);
    this.addChecker(
      item.getFidConnections(),
      this.checkConnections,
      ErrorType.CLOCK_DIAGRAM
    );
  }
  checkName() {}
  // checkWidth(item) {
  //   if (item.getWidth() < 1) {
  //     this.newError(`Width must be positive`);
  //   }
  // }
  checkConnections(item) {
    if (item.getParent().getItemType() === ItemType.DUTInstance) {
      return;
    }

    const idx = item
      .getParent()
      .getOutputs()
      .findIndex((obj) => obj.getId() === item.getId());
    if (
      !item.getConnections().find((conn) => conn) &&
      item.properties.socket !== "QCH"
    ) {
      this.newError(`Output(${idx}) must be connected`);
    }
  }
}
