import API from "@/api/internal";
import canvas from "@/canvas";
import { ITDASocketType } from "@/canvas/common";
import { Item, ItemType } from "ITDAModelTypes";

export const getDescription = (diagramID: string, nodeID: string) => {
  const editor = canvas.getEditor(diagramID);
  const node = editor.getNode(nodeID);
  const item = API.getItem(node?.getItemID());
  switch (item?.getItemType()) {
    case ItemType.PowerSequenceWrite:
    case ItemType.PowerSequenceReadWait:
    case ItemType.PowerSequenceWait:
      return getLabel(
        item.getPMD(),
        item.getPWRCOMP(),
        item.getSFRField(),
        item.getValue()
      );
    case ItemType.PowerSequenceLabelRef: {
      const input = item
        .getInputs()
        .find(
          (input: Item) => input.getSocket() === ITDASocketType.SEQREF.getKey()
        );
      if (input) {
        for (const conn of input.getConnections()) {
          const seq = conn.getSourceOutput().getParent();
          if (seq.getItemType() === ItemType.PowerSequenceIf) {
            return getLabel(
              seq.getPMD(),
              seq.getPWRCOMP(),
              seq.getSFRField(),
              seq.getValue()
            );
          }
        }
      }
    }
  }
};

const getLabel = (
  pmd: Item,
  powerComponent: Item,
  sfrField: Item,
  value: string
) => {
  const pmdName = pmd ? pmd.getName() : "";
  const powerComponentName = powerComponent ? powerComponent.getName() : "";
  const sfrParentFieldName = sfrField ? sfrField.getParent().getTitle() : "";
  const sfrFieldName = sfrField ? sfrField.getTitle() : "";

  if (pmd && powerComponent && sfrField) {
    return (
      powerComponentName +
        " / " +
        pmdName +
        "<br>" +
        sfrParentFieldName +
        " / " +
        sfrFieldName +
        ", " +
        value || "undefined"
    );
  }

  if (pmd && powerComponent) {
    return powerComponentName + " / " + pmdName;
  }

  if (powerComponent && sfrField) {
    return (
      powerComponentName +
        "<br>" +
        sfrParentFieldName +
        " / " +
        sfrFieldName +
        ", " +
        value || "undefined"
    );
  }

  if (pmd && sfrField) {
    return (
      pmdName +
        "<br>" +
        sfrParentFieldName +
        " / " +
        sfrFieldName +
        ", " +
        value || "undefined"
    );
  }

  if (pmd) {
    return pmdName;
  }

  if (powerComponent) {
    return powerComponentName + ", " + value || "undefined";
  }

  if (sfrField) {
    return (
      sfrParentFieldName + " / " + sfrFieldName + ", " + value || "undefined"
    );
  }
};
