/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import PowerSequenceLabelEvent from '@/model/src/events/PowerSequenceLabelEvent'
import PowerSequenceLabelChecker from '@/model/src/checkers/PowerSequenceLabelChecker'
export default class PowerSequenceLabel extends Node {
    FID_POWERSEQUENCELABEL_NAME = 100
    FID_POWERSEQUENCELABEL_COLOR = 101
    FID_POWERSEQUENCELABEL_LABELREFS = 102
    FID_POWERSEQUENCELABEL_LAST = 102

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerSequenceLabel"}))
        
        this.setEvent(new PowerSequenceLabelEvent(this))
        this.setChecker(new PowerSequenceLabelChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    if (this.properties.labelRefs === undefined) {
        this.properties.labelRefs = []
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerSequenceLabel"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "color": {"type": "color", "label": "Select LABEL Color", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "labelRefs": {"type": "item", "label": "connected reference", "desc": "connected reference", "multiple": true, "default": [], "category": "IP", "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "color": {"type": "color", "label": "Select LABEL Color", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "labelRefs": {"type": "item", "label": "connected reference", "desc": "connected reference", "multiple": true, "default": [], "category": "IP", "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERSEQUENCELABEL_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_POWERSEQUENCELABEL_NAME
    }
    

    setColor(color, event=true) {
        if ((color !== null) && (color !== undefined)) {
            if (color.constructor !== String) {
                throw `color(${color}) must be String type`
            }
        }
        const newVal = (color === null || color === undefined) ? undefined : color
        const oldVal = this.properties.color
        this.properties.color = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERSEQUENCELABEL_COLOR, newVal, oldVal)
        }
    }
    getColor(pure = false) {
        return this.properties.color
        
    }
    getFidColor() {
        return this.FID_POWERSEQUENCELABEL_COLOR
    }
    

    setLabelRefs(labelRefs, event=true) {
        if ((labelRefs !== null) && (labelRefs !== undefined)) {
            if (labelRefs.constructor !== Array) {
                throw `labelRefs(${labelRefs}) must be Array type`
            }
        }
        const newVal = (labelRefs === null || labelRefs === undefined) ? [] : labelRefs.map((o) => {
            if (!o || o.constructor === String) {
                return o
            } else if (this.toRaw(o) instanceof Item) {
                return o.getId()
            }
        })
        const oldVal = this.properties.labelRefs
        this.properties.labelRefs = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERSEQUENCELABEL_LABELREFS, newVal.map((id) => this.getRefItem(id)), oldVal.map((id) => this.getRefItem(id)))
        }
    }
    getLabelRefs(pure = false) {
        return pure ? this.properties.labelRefs : this.properties.labelRefs.map((id) => this.getRefItem(id))
        
    }
    getFidLabelRefs() {
        return this.FID_POWERSEQUENCELABEL_LABELREFS
    }
    

    getFidLast() {
        return this.FID_POWERSEQUENCELABEL_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERSEQUENCELABEL_NAME:
                return "name"

            case this.FID_POWERSEQUENCELABEL_COLOR:
                return "color"

            case this.FID_POWERSEQUENCELABEL_LABELREFS:
                return "labelRefs"

            default:
                return ""
        }
    }


}