import ItemHandler from "@/model/src/ItemHandler";
import {
  Item,
  ItemType,
  RefItemElement,
  RefItemElementTarget,
  RefItemElementType,
  VariantLevel,
} from "ITDAModelTypes";

export const getPowerStatusSettingsConfiguration = (
  item: Item,
  propKey: string
): RefItemElement[] => {
  return item.getModule()
    ? [
        {
          variant: VariantLevel.LEVEL0,
          disabled: false,
          type: RefItemElementType.ENUM,
          propKey,
          label: "Select Power Status",
          model: getModel(item),
          targets: getTargets(item),
          event: eventPowerStatusSettings,
        },
      ]
    : [];
};

const getModel = (item: Item): string | undefined => {
  const pmc = item.getParent(ItemType.PowerPMC);
  const selectedPMCPowerSetting = pmc
    .getConfigurationFolder()
    .getSelectedConfiguration();
  const configElem = selectedPMCPowerSetting
    .getConfigurationElements()
    .find((elem: Item) => elem.getItemRef() === item);

  return configElem?.getValue()
    ? ItemHandler.getRefItem(configElem?.getValue())?.getName()
    : undefined;
};

const getTargets = (item: Item): RefItemElementTarget[] => {
  const childrenData: RefItemElementTarget[] = [];
  const pmdPowerSettings = item
    .getModule()
    .getConfigurationFolder()
    .getConfigurations();
  pmdPowerSettings.map((pmdPowerSetting: Item) => {
    childrenData.push({
      key: pmdPowerSetting.getTitle(),
      item: pmdPowerSetting,
      closable: true,
    });
  });

  childrenData.sort((a, b) => {
    return a.key.localeCompare(b.key, "ko");
  });

  return childrenData;
};

const eventPowerStatusSettings = (
  item: Item,
  elem: RefItemElement,
  value: string | string[]
) => {
  const pmc = item.getParent(ItemType.PowerPMC);
  const selectedPMCPowerSetting = pmc
    .getConfigurationFolder()
    .getSelectedConfiguration();
  const pmcPowerSettingElems =
    selectedPMCPowerSetting.getConfigurationElements();

  if (value) {
    const pmcHasItemRef = pmcPowerSettingElems.some(
      (elem: Item) => elem.getItemRef() === item
    );

    const pmdPowerSettings = item
      .getModule()
      .getConfigurationFolder()
      .getConfigurations();
    const selectedPMDPowerSettingId = pmdPowerSettings
      .find((powerSetting: Item) => powerSetting.getName() === value)
      .getId();

    if (pmcHasItemRef) {
      const itemRef = pmcPowerSettingElems.find(
        (elem: Item) => elem.getItemRef() === item
      );
      itemRef.setValue(selectedPMDPowerSettingId);
    } else {
      const newPmcPss = selectedPMCPowerSetting.newConfigurationElement().item;
      newPmcPss.setItemRef(item);
      newPmcPss.setValue(selectedPMDPowerSettingId);
    }
  } else {
    pmcPowerSettingElems.forEach((elem: Item) => {
      if (elem.getItemRef().getId() === item.getId()) {
        elem.setValue("null");
      }
    });
  }
};
