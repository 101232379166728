<template>
  <div class="navigation-title">
    <v-card-title class="title">
      <v-list-item class="px-1">
        <v-sheet class="d-flex align-content-center flex-wrap p-0">
          <!-- v-col아니라, flex 레이아웃 써야함 -->
          <!-- <v-col cols="2"> -->
          <v-list-item class="image">
            <v-img
              min-width="80px"
              max-width="80px"
              min-height="20px"
              max-height="20px"
              src="@/assets/itda.svg"></v-img>
          </v-list-item>
          <!-- </v-col> -->
          <!-- <v-col cols="10" class="Title"> -->
          <v-list-item-title class="text-h5 pa-3">
            <router-link
              to="/"
              style="
                text-decoration: none;
                color: black;
                display: block;
                margin-left: -0.8rem;
              ">
              <v-sheet>{{
                store.getters["domain/GET_CURRENT_DOMAIN"] + " Canvas"
              }}</v-sheet>
            </router-link>
          </v-list-item-title>
          <!-- </v-col> -->
        </v-sheet>
      </v-list-item>
    </v-card-title>
  </div>
</template>
<script setup lang="ts">
import { useStore } from "vuex";
const store = useStore();
</script>
<style lang="sass" scoped>
.navigation-title
  height: 64px
  padding: 0rem 1rem 0rem 1rem
  display: flex
  align-items: center
.image
  padding-left: 0px
.title
  padding: 0
</style>
