/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:34.620490
 */
export default {
    "startOffset": "0x140",
    "size": "0x40",
    "alignSize": "0x4",
    "sfrs": [
        {
            "name": "CTRL_RESET",
            "alignOffset": "0x0",
            "description": "Power Component Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_RESETn",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Reset Control Signal for {{name}}\n0 = Set RESETn to 0 (Reset is Asserted)\n1 = Set RESETn to 1 (Reset is Released)",
                    "initValue": "0x0",
                    "nc": "SFR_RESETn"
                }
            ]
        }
    ]
}