/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:34.620490
 */
export default {
    "startOffset": "0x480",
    "size": "0x40",
    "alignSize": "0x4",
    "sfrs": [
        {
            "name": "TIMEOUT",
            "alignOffset": "0x0",
            "description": "Timeout Counter Expire Value for {{name}}",
            "fields": [
                {
                    "name": "SFR_DATA",
                    "index": 0,
                    "size": 32,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "Timeout Counter Expire Value for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DATA",
                    "floating": true
                }
            ]
        }
    ]
}