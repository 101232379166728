/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import UPFPhantomGroundEvent from '@/model/src/events/UPFPhantomGroundEvent'
import UPFPhantomGroundChecker from '@/model/src/checkers/UPFPhantomGroundChecker'
export default class UPFPhantomGround extends Node {
    FID_UPFPHANTOMGROUND_ORIGINITEM = 100
    FID_UPFPHANTOMGROUND_POWERSOURCE = 101
    FID_UPFPHANTOMGROUND_MAPPEDMODULE = 102
    FID_UPFPHANTOMGROUND_MAPPEDPOWER = 103
    FID_UPFPHANTOMGROUND_LAST = 103

    constructor(obj) {
        super(Object.assign(obj,{itemType:"UPFPhantomGround"}))
        
        this.setEvent(new UPFPhantomGroundEvent(this))
        this.setChecker(new UPFPhantomGroundChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "UPFPhantomGround"
    }

    getSchema() {
        return {"originItem": {"type": "item", "multiple": false, "hidden": true, "category": "IP", "readOnly": false}, "powerSource": {"type": "item", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}, "mappedModule": {"type": "item", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}, "mappedPower": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"originItem": {"type": "item", "multiple": false, "hidden": true, "category": "IP", "readOnly": false}, "powerSource": {"type": "item", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}, "mappedModule": {"type": "item", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}, "mappedPower": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setOriginItem(originItem, event=true) {
        if ((originItem !== null) && (originItem !== undefined)) {
            if (!(originItem instanceof Item) && (originItem.constructor !== String)) {
                throw `originItem(${originItem}) must be Item or String type`
            }
        }
        const newVal = (originItem === null || originItem === undefined) ? undefined : (originItem.constructor === String) ? originItem : originItem.getId()
        const oldVal = this.properties.originItem
        this.properties.originItem = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFPHANTOMGROUND_ORIGINITEM, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getOriginItem(pure = false) {
        return pure ? this.properties.originItem : this.getRefItem(this.properties.originItem)
        
    }
    getFidOriginItem() {
        return this.FID_UPFPHANTOMGROUND_ORIGINITEM
    }
    

    setPowerSource(powerSource, event=true) {
        if ((powerSource !== null) && (powerSource !== undefined)) {
            if (!(powerSource instanceof Item) && (powerSource.constructor !== String)) {
                throw `powerSource(${powerSource}) must be Item or String type`
            }
        }
        const newVal = (powerSource === null || powerSource === undefined) ? undefined : (powerSource.constructor === String) ? powerSource : powerSource.getId()
        const oldVal = this.properties.powerSource
        this.properties.powerSource = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFPHANTOMGROUND_POWERSOURCE, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getPowerSource(pure = false) {
        return pure ? this.properties.powerSource : this.getRefItem(this.properties.powerSource)
        
    }
    getFidPowerSource() {
        return this.FID_UPFPHANTOMGROUND_POWERSOURCE
    }
    

    setMappedModule(mappedModule, event=true) {
        if ((mappedModule !== null) && (mappedModule !== undefined)) {
            if (!(mappedModule instanceof Item) && (mappedModule.constructor !== String)) {
                throw `mappedModule(${mappedModule}) must be Item or String type`
            }
        }
        const newVal = (mappedModule === null || mappedModule === undefined) ? undefined : (mappedModule.constructor === String) ? mappedModule : mappedModule.getId()
        const oldVal = this.properties.mappedModule
        this.properties.mappedModule = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFPHANTOMGROUND_MAPPEDMODULE, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getMappedModule(pure = false) {
        return pure ? this.properties.mappedModule : this.getRefItem(this.properties.mappedModule)
        
    }
    getFidMappedModule() {
        return this.FID_UPFPHANTOMGROUND_MAPPEDMODULE
    }
    

    setMappedPower(mappedPower, event=true) {
        if ((mappedPower !== null) && (mappedPower !== undefined)) {
            if (mappedPower.constructor !== String) {
                throw `mappedPower(${mappedPower}) must be String type`
            }
        }
        const newVal = (mappedPower === null || mappedPower === undefined) ? undefined : mappedPower
        const oldVal = this.properties.mappedPower
        this.properties.mappedPower = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFPHANTOMGROUND_MAPPEDPOWER, newVal, oldVal)
        }
    }
    getMappedPower(pure = false) {
        return this.properties.mappedPower
        
    }
    getFidMappedPower() {
        return this.FID_UPFPHANTOMGROUND_MAPPEDPOWER
    }
    

    getFidLast() {
        return this.FID_UPFPHANTOMGROUND_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_UPFPHANTOMGROUND_ORIGINITEM:
                return "originItem"

            case this.FID_UPFPHANTOMGROUND_POWERSOURCE:
                return "powerSource"

            case this.FID_UPFPHANTOMGROUND_MAPPEDMODULE:
                return "mappedModule"

            case this.FID_UPFPHANTOMGROUND_MAPPEDPOWER:
                return "mappedPower"

            default:
                return ""
        }
    }


}