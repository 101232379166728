/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import ClockSIGNALEvent from '@/model/src/events/ClockSIGNALEvent'
import ClockSIGNALChecker from '@/model/src/checkers/ClockSIGNALChecker'
export default class ClockSIGNAL extends Item {
    FID_CLOCKSIGNAL_SIGNALNAME = 0
    FID_CLOCKSIGNAL_SIGNALRESET = 1
    FID_CLOCKSIGNAL_TYPE = 2
    FID_CLOCKSIGNAL_LAST = 2

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ClockSIGNAL"}))
        
        this.setEvent(new ClockSIGNALEvent(this))
        this.setChecker(new ClockSIGNALChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.SignalName === undefined) {
        this.properties.SignalName = "NONAME"
    }
    if (this.properties.SignalReset === undefined) {
        this.properties.SignalReset = "0"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ClockSIGNAL"
    }

    getSchema() {
        return {"SignalName": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "SignalReset": {"type": "enum", "targets": ["0", "1"], "multiple": false, "default": "0", "category": "IP", "hidden": false, "readOnly": false}, "type": {"type": "enum", "targets": ["ThrottleSignal", "ShortStopSignal", "EwakeupSignal"], "multiple": false, "category": "IP", "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"SignalName": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "SignalReset": {"type": "enum", "targets": ["0", "1"], "multiple": false, "default": "0", "category": "IP", "hidden": false, "readOnly": false}, "type": {"type": "enum", "targets": ["ThrottleSignal", "ShortStopSignal", "EwakeupSignal"], "multiple": false, "category": "IP", "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setSignalName(SignalName, event=true) {
        if ((SignalName !== null) && (SignalName !== undefined)) {
            if (SignalName.constructor !== String) {
                throw `SignalName(${SignalName}) must be String type`
            }
        }
        const newVal = (SignalName === null || SignalName === undefined) ? undefined : SignalName
        const oldVal = this.properties.SignalName
        this.properties.SignalName = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKSIGNAL_SIGNALNAME, newVal, oldVal)
        }
    }
    getSignalName(pure = false) {
        return this.properties.SignalName
        
    }
    getFidSignalName() {
        return this.FID_CLOCKSIGNAL_SIGNALNAME
    }
    

    setSignalReset(SignalReset, event=true) {
        if ((SignalReset !== null) && (SignalReset !== undefined)) {
            if (SignalReset.constructor !== String) {
                throw `SignalReset(${SignalReset}) must be String type`
            }
        }
        const newVal = (SignalReset === null || SignalReset === undefined) ? undefined : SignalReset
        const oldVal = this.properties.SignalReset
        this.properties.SignalReset = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKSIGNAL_SIGNALRESET, newVal, oldVal)
        }
    }
    getSignalReset(pure = false) {
        return this.properties.SignalReset
        
    }
    getFidSignalReset() {
        return this.FID_CLOCKSIGNAL_SIGNALRESET
    }
    

    setType(type, event=true) {
        if ((type !== null) && (type !== undefined)) {
            if (type.constructor !== String) {
                throw `type(${type}) must be String type`
            }
        }
        const newVal = (type === null || type === undefined) ? undefined : type
        const oldVal = this.properties.type
        this.properties.type = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKSIGNAL_TYPE, newVal, oldVal)
        }
    }
    getType(pure = false) {
        return this.properties.type
        
    }
    getFidType() {
        return this.FID_CLOCKSIGNAL_TYPE
    }
    

    getFidLast() {
        return this.FID_CLOCKSIGNAL_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CLOCKSIGNAL_SIGNALNAME:
                return "SignalName"

            case this.FID_CLOCKSIGNAL_SIGNALRESET:
                return "SignalReset"

            case this.FID_CLOCKSIGNAL_TYPE:
                return "type"

            default:
                return ""
        }
    }


}