/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:34.620490
 */
export default {
    "startOffset": "0x0000",
    "size": "0x800",
    "alignSize": "0x20",
    "sfrs": [
        {
            "name": "CTRL_PLLMUX_EXT",
            "alignOffset": "0x4",
            "description": "Clock Component Extension Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_ENABLE_PWRDOWN",
                    "index": 16,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "MUX Selection Automatic change Control for {{name}}\n0 = MUX Selection Automatic change to 0 by CLINK is Disabled\n1 = MUX Selection Automatic change to 0 by CLINK is Enabled",
                    "initValue": "0x0",
                    "nc": "SFR_ENABLE_PWRDOWN"
                },
                {
                    "name": "SFR_OVERRIDED_PWRDOWN",
                    "index": 24,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "MUX Selection Override Indication for {{name}}\n0 = MUX Selection is Controlled by SFR_SELECT\n1 = MUX Selection is Overrided by PWRDOWN",
                    "initValue": "0x0",
                    "nc": "SFR_OVERRIDED_PWRDOWN"
                },
                {
                    "name": "SFR_OVERRIDED_CUSTOM",
                    "index": 27,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "MUX Selection Override Indication for {{name}}\n0 = MUX Selection is Controlled by SFR_SELECT\n1 = MUX Selection is Overrided by CUSTOM",
                    "initValue": "0x0",
                    "nc": "SFR_OVERRIDED_CUSTOM"
                }
            ]
        }
    ]
}