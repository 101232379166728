/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import UPFPowerSourceEvent from '@/model/src/events/UPFPowerSourceEvent'
import UPFPowerSourceChecker from '@/model/src/checkers/UPFPowerSourceChecker'
export default class UPFPowerSource extends Node {
    FID_UPFPOWERSOURCE_NAME = 100
    FID_UPFPOWERSOURCE_COLOR = 101
    FID_UPFPOWERSOURCE_GROUND = 102
    FID_UPFPOWERSOURCE_ISNEEDELEMENTS = 103
    FID_UPFPOWERSOURCE_LAST = 103

    constructor(obj) {
        super(Object.assign(obj,{itemType:"UPFPowerSource"}))
        
        this.setEvent(new UPFPowerSourceEvent(this))
        this.setChecker(new UPFPowerSourceChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "PS_NONAME"
    }
    if (this.properties.isNeedElements === undefined) {
        this.properties.isNeedElements = false
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "UPFPowerSource"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "PS_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "color": {"type": "color", "label": "Power Output Color", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "ground": {"type": "item", "label": "Ground", "desc": "Ground", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}, "isNeedElements": {"type": "boolean", "label": "Is Need Elements?", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "PS_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "color": {"type": "color", "label": "Power Output Color", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "ground": {"type": "item", "label": "Ground", "desc": "Ground", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}, "isNeedElements": {"type": "boolean", "label": "Is Need Elements?", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "UPFElement", "auto": false, "searchable": true, "title": "Element Info", "headers": [{"title": "Element", "value": "name", "align": "start", "sortable": true}], "category": "IP"}, {"name": "UPFMapperPowerSource", "auto": false, "searchable": false, "title": "Power Supply Info", "headers": [{"title": "Power Supply", "value": "powerSource", "align": "start", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFPOWERSOURCE_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_UPFPOWERSOURCE_NAME
    }
    

    setColor(color, event=true) {
        if ((color !== null) && (color !== undefined)) {
            if (color.constructor !== String) {
                throw `color(${color}) must be String type`
            }
        }
        const newVal = (color === null || color === undefined) ? undefined : color
        const oldVal = this.properties.color
        this.properties.color = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFPOWERSOURCE_COLOR, newVal, oldVal)
        }
    }
    getColor(pure = false) {
        return this.properties.color
        
    }
    getFidColor() {
        return this.FID_UPFPOWERSOURCE_COLOR
    }
    

    setGround(ground, event=true) {
        if ((ground !== null) && (ground !== undefined)) {
            if (!(ground instanceof Item) && (ground.constructor !== String)) {
                throw `ground(${ground}) must be Item or String type`
            }
        }
        const newVal = (ground === null || ground === undefined) ? undefined : (ground.constructor === String) ? ground : ground.getId()
        const oldVal = this.properties.ground
        this.properties.ground = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFPOWERSOURCE_GROUND, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getGround(pure = false) {
        return pure ? this.properties.ground : this.getRefItem(this.properties.ground)
        
    }
    getFidGround() {
        return this.FID_UPFPOWERSOURCE_GROUND
    }
    

    setIsNeedElements(isNeedElements, event=true) {
        if ((isNeedElements !== null) && (isNeedElements !== undefined)) {
            if (isNeedElements.constructor !== Boolean) {
                throw `isNeedElements(${isNeedElements}) must be Boolean type`
            }
        }
        const newVal = (isNeedElements === null || isNeedElements === undefined) ? undefined : isNeedElements
        const oldVal = this.properties.isNeedElements
        this.properties.isNeedElements = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFPOWERSOURCE_ISNEEDELEMENTS, newVal, oldVal)
        }
    }
    getIsNeedElements(pure = false) {
        return this.properties.isNeedElements
        
    }
    getFidIsNeedElements() {
        return this.FID_UPFPOWERSOURCE_ISNEEDELEMENTS
    }
    

    getFidLast() {
        return this.FID_UPFPOWERSOURCE_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_UPFPOWERSOURCE_NAME:
                return "name"

            case this.FID_UPFPOWERSOURCE_COLOR:
                return "color"

            case this.FID_UPFPOWERSOURCE_GROUND:
                return "ground"

            case this.FID_UPFPOWERSOURCE_ISNEEDELEMENTS:
                return "isNeedElements"

            default:
                return ""
        }
    }


    newUPFElement(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFElement", properties, title, domain)
    }
    remUPFElement(obj) {
        return this.removeChild(obj)
    }
    getUPFElements() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFElement")
            return res
        })
    }
    newUPFMapperPowerSource(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFMapperPowerSource", properties, title, domain)
    }
    remUPFMapperPowerSource(obj) {
        return this.removeChild(obj)
    }
    getUPFMapperPowerSources() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFMapperPowerSource")
            return res
        })
    }
}