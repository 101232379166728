import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    if (!color) {
      return;
    }

    const OUTER_PADDING = 1.5;
    const rectW = width - OUTER_PADDING * 2;
    const rectH = height - OUTER_PADDING * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const baseColor = color;

    svg
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", width)
      .attr("height", height)
      .attr("fill", "black");

    svg
      .append("rect")
      .attr("x", OUTER_PADDING)
      .attr("y", OUTER_PADDING)
      .attr("rx", 3)
      .attr("ry", 3)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("fill", baseColor);
  },
};
