/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import ConfigurationElementEvent from '@/model/src/events/ConfigurationElementEvent'
import ConfigurationElementChecker from '@/model/src/checkers/ConfigurationElementChecker'
export default class ConfigurationElement extends Item {
    FID_CONFIGURATIONELEMENT_VALUE = 0
    FID_CONFIGURATIONELEMENT_PROP = 1
    FID_CONFIGURATIONELEMENT_ITEMREF = 2
    FID_CONFIGURATIONELEMENT_LAST = 2

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ConfigurationElement"}))
        
        this.setEvent(new ConfigurationElementEvent(this))
        this.setChecker(new ConfigurationElementChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "ConfigurationElement"
        }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ConfigurationElement"
    }

    getSchema() {
        return {"value": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "prop": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "itemRef": {"type": "item", "label": "item reference", "desc": "item reference", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"value": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "prop": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "itemRef": {"type": "item", "label": "item reference", "desc": "item reference", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setValue(value, event=true) {
        if ((value !== null) && (value !== undefined)) {
            if (value.constructor !== String) {
                throw `value(${value}) must be String type`
            }
        }
        const newVal = (value === null || value === undefined) ? undefined : value
        const oldVal = this.properties.value
        this.properties.value = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CONFIGURATIONELEMENT_VALUE, newVal, oldVal)
        }
    }
    getValue(pure = false) {
        return this.properties.value
        
    }
    getFidValue() {
        return this.FID_CONFIGURATIONELEMENT_VALUE
    }
    

    setProp(prop, event=true) {
        if ((prop !== null) && (prop !== undefined)) {
            if (prop.constructor !== String) {
                throw `prop(${prop}) must be String type`
            }
        }
        const newVal = (prop === null || prop === undefined) ? undefined : prop
        const oldVal = this.properties.prop
        this.properties.prop = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CONFIGURATIONELEMENT_PROP, newVal, oldVal)
        }
    }
    getProp(pure = false) {
        return this.properties.prop
        
    }
    getFidProp() {
        return this.FID_CONFIGURATIONELEMENT_PROP
    }
    

    setItemRef(itemRef, event=true) {
        if ((itemRef !== null) && (itemRef !== undefined)) {
            if (!(itemRef instanceof Item) && (itemRef.constructor !== String)) {
                throw `itemRef(${itemRef}) must be Item or String type`
            }
        }
        const newVal = (itemRef === null || itemRef === undefined) ? undefined : (itemRef.constructor === String) ? itemRef : itemRef.getId()
        const oldVal = this.properties.itemRef
        this.properties.itemRef = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CONFIGURATIONELEMENT_ITEMREF, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getItemRef(pure = false) {
        return pure ? this.properties.itemRef : this.getRefItem(this.properties.itemRef)
        
    }
    getFidItemRef() {
        return this.FID_CONFIGURATIONELEMENT_ITEMREF
    }
    

    getFidLast() {
        return this.FID_CONFIGURATIONELEMENT_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CONFIGURATIONELEMENT_VALUE:
                return "value"

            case this.FID_CONFIGURATIONELEMENT_PROP:
                return "prop"

            case this.FID_CONFIGURATIONELEMENT_ITEMREF:
                return "itemRef"

            default:
                return ""
        }
    }


}