import * as d3 from "d3";

export default {
  render: (
    elem: HTMLElement,
    width: number,
    height: number,
    color: string,
    options: any
  ) => {
    const { configValue } = options;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const baseColor = color;
    const hoverColor = d3.hsl(baseColor);
    hoverColor.l = Math.min(hoverColor.l + 0.05, 1);

    const component = svg
      .append("rect")
      .attr("width", width)
      .attr("height", height)
      .attr("fill", baseColor)
      .attr("rx", 10)
      .attr("ry", 10);

    component
      .on("mouseover", function () {
        d3.select(this).attr("fill", hoverColor.toString());
      })
      .on("mouseout", function () {
        d3.select(this).attr("fill", baseColor);
      });

    const grid = 12;
    const margin = 3;
    const gridHeight = height / grid;
    const gridWidth = width / grid;

    const lineDatas = [
      { x1: 2, y1: 10, x2: 2, y2: 2 },
      { x1: 2, y1: 2, x2: 3, y2: 2 },
      { x1: 3, y1: 2, x2: 3, y2: 10 },
      { x1: 3, y1: 10, x2: 4, y2: 10 },
      { x1: 4, y1: 10, x2: 4, y2: 2 },
      { x1: 4, y1: 2, x2: 5, y2: 2 },
      { x1: 5, y1: 2, x2: 5, y2: 10 },
      { x1: 5, y1: 10, x2: 6, y2: 10 },
      { x1: 6, y1: 10, x2: 6, y2: 2 },
      { x1: 6, y1: 2, x2: 7, y2: 2 },
      { x1: 7, y1: 2, x2: 7, y2: 10 },
      { x1: 7, y1: 10, x2: 8, y2: 10 },
    ];

    lineDatas.forEach((lineData) => {
      svg
        .append("line")
        .attr("x1", gridWidth * lineData.x1)
        .attr("y1", gridHeight * lineData.y1)
        .attr("x2", gridWidth * lineData.x2)
        .attr("y2", gridHeight * lineData.y2)
        .attr("stroke", "black");
    });

    svg
      .append("line")
      .attr("x1", gridWidth * 4 - margin)
      .attr("y1", gridHeight * 3)
      .attr("x2", gridWidth * 5 + margin)
      .attr("y2", gridHeight * 8)
      .attr("stroke", "red");

    svg
      .append("line")
      .attr("x1", gridWidth * 5 + margin)
      .attr("y1", gridHeight * 3)
      .attr("x2", gridWidth * 4 - margin)
      .attr("y2", gridHeight * 8)
      .attr("stroke", "red");

    svg
      .append("line")
      .attr("x1", gridWidth * 8)
      .attr("y1", gridHeight * 6)
      .attr("x2", gridWidth * 11)
      .attr("y2", gridHeight * 6)
      .attr("stroke", "black");

    svg
      .append("text")
      .attr("x", gridWidth * 9.5)
      .attr("y", gridHeight * 4)
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .attr("font-size", "12px")
      .attr("fill", "red")
      .text(configValue?.numerator || "");

    svg
      .append("text")
      .attr("x", gridWidth * 9.5)
      .attr("y", gridHeight * 9)
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .attr("font-size", "12px")
      .attr("fill", "red")
      .text(
        configValue?.denominator === 0 ? "0" : configValue?.denominator || ""
      );
  },
};
