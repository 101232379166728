import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g");

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    const renderWidth = (width / 3) * 2;
    const centerX = renderWidth / 2;
    const centerY = height / 2;

    // Central circle (main hub)
    g.append("circle")
      .attr("cx", (centerX / 4) * 2)
      .attr("cy", (centerY / 4) * 4)
      .attr("r", centerX / 4)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    // Smaller circles (branches)
    const circlePositions = [
      { cx: centerX + centerX / 4.2, cy: (centerY / 4) * 2.3 },
      { cx: centerX + (centerX / 4) * 1.7, cy: (centerY / 4) * 4 },
      { cx: centerX + (centerX / 4) * 1.2, cy: (centerY / 4) * 5.7 },
      { cx: centerX + (centerX / 4) * 2.8, cy: (centerY / 4) * 2.8 },
      { cx: centerX + (centerX / 4) * 2.8, cy: (centerY / 4) * 5.2 },
    ];

    circlePositions.forEach((pos) => {
      g.append("circle")
        .attr("cx", pos.cx)
        .attr("cy", pos.cy)
        .attr("r", centerX / 7.5)
        .attr("stroke", "black")
        .attr("stroke-width", STROKE_WIDTH)
        .attr("fill", color);

      g.append("line")
        .attr("x1", (centerX / 4) * 3)
        .attr("y1", (centerY / 4) * 4)
        .attr("x2", pos.cx - centerX / 7.5)
        .attr("y2", pos.cy)
        .attr("stroke", "black")
        .attr("stroke-width", STROKE_WIDTH * 0.9);
    });

    g.append("text")
      .attr("x", width / 2)
      .attr("y", 15)
      .attr("text-anchor", "middle")
      .attr("font-size", `10px`)
      .attr("fill", "black")
      .text("PMDGRP");
  },
};
