<template>
  <v-dialog v-model="isVisible" persistent max-width="290">
    <v-progress-circular
      :size="250"
      :width="10"
      color="primary"
      indeterminate
      class="mt-10">
      {{
        num > 1
          ? `${num} tasks is in progress...`
          : `${num} task is in progress...`
      }}</v-progress-circular
    >
  </v-dialog>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
const store = useStore();

const isVisible = computed(() =>
  store.getters.GET_NUM_OF_TASKS ? true : false
);
const num = computed(() => store.getters.GET_NUM_OF_TASKS);
</script>

<style lang="sass">
.v-progress-circular__content
    text-align: center
    white-space: nowrap
</style>
