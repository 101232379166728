/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import SDCPhantomEvent from '@/model/src/events/SDCPhantomEvent'
import SDCPhantomChecker from '@/model/src/checkers/SDCPhantomChecker'
export default class SDCPhantom extends Node {
    FID_SDCPHANTOM_CLOCKPOINT = 100
    FID_SDCPHANTOM_PORTS = 101
    FID_SDCPHANTOM_PATH = 102
    FID_SDCPHANTOM_CLOCKGROUP = 103
    FID_SDCPHANTOM_ORIGINITEM = 104
    FID_SDCPHANTOM_LAST = 104

    constructor(obj) {
        super(Object.assign(obj,{itemType:"SDCPhantom"}))
        
        this.setEvent(new SDCPhantomEvent(this))
        this.setChecker(new SDCPhantomChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.ClockPoint === undefined) {
        this.properties.ClockPoint = false
    }
    if (this.properties.ports === undefined) {
        this.properties.ports = false
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "SDCPhantom"
    }

    getSchema() {
        return {"ClockPoint": {"type": "boolean", "label": "is generated clock point?", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "ports": {"type": "boolean", "label": "is get_ports?", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "path": {"type": "string", "label": "SDC Clock Path", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "clockGroup": {"type": "item", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}, "originItem": {"type": "item", "multiple": false, "hidden": true, "category": "IP", "readOnly": false}}
    }

    static getSchema() {
        return {"ClockPoint": {"type": "boolean", "label": "is generated clock point?", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "ports": {"type": "boolean", "label": "is get_ports?", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "path": {"type": "string", "label": "SDC Clock Path", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "clockGroup": {"type": "item", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}, "originItem": {"type": "item", "multiple": false, "hidden": true, "category": "IP", "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setClockPoint(ClockPoint, event=true) {
        if ((ClockPoint !== null) && (ClockPoint !== undefined)) {
            if (ClockPoint.constructor !== Boolean) {
                throw `ClockPoint(${ClockPoint}) must be Boolean type`
            }
        }
        const newVal = (ClockPoint === null || ClockPoint === undefined) ? undefined : ClockPoint
        const oldVal = this.properties.ClockPoint
        this.properties.ClockPoint = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SDCPHANTOM_CLOCKPOINT, newVal, oldVal)
        }
    }
    getClockPoint(pure = false) {
        return this.properties.ClockPoint
        
    }
    getFidClockPoint() {
        return this.FID_SDCPHANTOM_CLOCKPOINT
    }
    

    setPorts(ports, event=true) {
        if ((ports !== null) && (ports !== undefined)) {
            if (ports.constructor !== Boolean) {
                throw `ports(${ports}) must be Boolean type`
            }
        }
        const newVal = (ports === null || ports === undefined) ? undefined : ports
        const oldVal = this.properties.ports
        this.properties.ports = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SDCPHANTOM_PORTS, newVal, oldVal)
        }
    }
    getPorts(pure = false) {
        return this.properties.ports
        
    }
    getFidPorts() {
        return this.FID_SDCPHANTOM_PORTS
    }
    

    setPath(path, event=true) {
        if ((path !== null) && (path !== undefined)) {
            if (path.constructor !== String) {
                throw `path(${path}) must be String type`
            }
        }
        const newVal = (path === null || path === undefined) ? undefined : path
        const oldVal = this.properties.path
        this.properties.path = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SDCPHANTOM_PATH, newVal, oldVal)
        }
    }
    getPath(pure = false) {
        return this.properties.path
        
    }
    getFidPath() {
        return this.FID_SDCPHANTOM_PATH
    }
    

    setClockGroup(clockGroup, event=true) {
        if ((clockGroup !== null) && (clockGroup !== undefined)) {
            if (!(clockGroup instanceof Item) && (clockGroup.constructor !== String)) {
                throw `clockGroup(${clockGroup}) must be Item or String type`
            }
        }
        const newVal = (clockGroup === null || clockGroup === undefined) ? undefined : (clockGroup.constructor === String) ? clockGroup : clockGroup.getId()
        const oldVal = this.properties.clockGroup
        this.properties.clockGroup = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SDCPHANTOM_CLOCKGROUP, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getClockGroup(pure = false) {
        return pure ? this.properties.clockGroup : this.getRefItem(this.properties.clockGroup)
        
    }
    getFidClockGroup() {
        return this.FID_SDCPHANTOM_CLOCKGROUP
    }
    

    setOriginItem(originItem, event=true) {
        if ((originItem !== null) && (originItem !== undefined)) {
            if (!(originItem instanceof Item) && (originItem.constructor !== String)) {
                throw `originItem(${originItem}) must be Item or String type`
            }
        }
        const newVal = (originItem === null || originItem === undefined) ? undefined : (originItem.constructor === String) ? originItem : originItem.getId()
        const oldVal = this.properties.originItem
        this.properties.originItem = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SDCPHANTOM_ORIGINITEM, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getOriginItem(pure = false) {
        return pure ? this.properties.originItem : this.getRefItem(this.properties.originItem)
        
    }
    getFidOriginItem() {
        return this.FID_SDCPHANTOM_ORIGINITEM
    }
    

    getFidLast() {
        return this.FID_SDCPHANTOM_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_SDCPHANTOM_CLOCKPOINT:
                return "ClockPoint"

            case this.FID_SDCPHANTOM_PORTS:
                return "ports"

            case this.FID_SDCPHANTOM_PATH:
                return "path"

            case this.FID_SDCPHANTOM_CLOCKGROUP:
                return "clockGroup"

            case this.FID_SDCPHANTOM_ORIGINITEM:
                return "originItem"

            default:
                return ""
        }
    }


}