/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import UPFIsolationCellEvent from '@/model/src/events/UPFIsolationCellEvent'
import UPFIsolationCellChecker from '@/model/src/checkers/UPFIsolationCellChecker'
export default class UPFIsolationCell extends Node {
    FID_UPFISOLATIONCELL_NAME = 100
    FID_UPFISOLATIONCELL_CELLS = 101
    FID_UPFISOLATIONCELL_DIRECTION = 102
    FID_UPFISOLATIONCELL_LOCATION = 103
    FID_UPFISOLATIONCELL_TYPE = 104
    FID_UPFISOLATIONCELL_ISNEEDELEMENTS = 105
    FID_UPFISOLATIONCELL_LAST = 105

    constructor(obj) {
        super(Object.assign(obj,{itemType:"UPFIsolationCell"}))
        
        this.setEvent(new UPFIsolationCellEvent(this))
        this.setChecker(new UPFIsolationCellChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    if (this.properties.direction === undefined) {
        this.properties.direction = "outputs"
    }
    if (this.properties.type === undefined) {
        this.properties.type = "AND"
    }
    if (this.properties.isNeedElements === undefined) {
        this.properties.isNeedElements = false
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "UPFIsolationCell"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "cells": {"type": "string", "label": "lib_cells", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "direction": {"type": "enum", "targets": ["inputs", "outputs"], "label": "applies_to", "multiple": false, "default": "outputs", "category": "IP", "hidden": false, "readOnly": false}, "location": {"type": "item", "label": "Select Location", "hidden": true, "multiple": false, "category": "IP", "readOnly": false}, "type": {"type": "enum", "targets": ["AND", "OR", "LATCH", "NO"], "multiple": false, "default": "AND", "category": "IP", "hidden": false, "readOnly": false}, "isNeedElements": {"type": "boolean", "label": "Is Need Elements?", "desc": "True: all output or all input", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "cells": {"type": "string", "label": "lib_cells", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "direction": {"type": "enum", "targets": ["inputs", "outputs"], "label": "applies_to", "multiple": false, "default": "outputs", "category": "IP", "hidden": false, "readOnly": false}, "location": {"type": "item", "label": "Select Location", "hidden": true, "multiple": false, "category": "IP", "readOnly": false}, "type": {"type": "enum", "targets": ["AND", "OR", "LATCH", "NO"], "multiple": false, "default": "AND", "category": "IP", "hidden": false, "readOnly": false}, "isNeedElements": {"type": "boolean", "label": "Is Need Elements?", "desc": "True: all output or all input", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "UPFElement", "auto": false, "searchable": true, "title": "Element Info", "headers": [{"title": "Element", "value": "name", "align": "start", "sortable": true}], "category": "IP"}, {"name": "UPFMapperPowerSource", "auto": false, "searchable": false, "title": "Power Supply Info", "headers": [{"title": "Power Supply", "value": "powerSource", "align": "start", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFISOLATIONCELL_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_UPFISOLATIONCELL_NAME
    }
    

    setCells(cells, event=true) {
        if ((cells !== null) && (cells !== undefined)) {
            if (cells.constructor !== String) {
                throw `cells(${cells}) must be String type`
            }
        }
        const newVal = (cells === null || cells === undefined) ? undefined : cells
        const oldVal = this.properties.cells
        this.properties.cells = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFISOLATIONCELL_CELLS, newVal, oldVal)
        }
    }
    getCells(pure = false) {
        return this.properties.cells
        
    }
    getFidCells() {
        return this.FID_UPFISOLATIONCELL_CELLS
    }
    

    setDirection(direction, event=true) {
        if ((direction !== null) && (direction !== undefined)) {
            if (direction.constructor !== String) {
                throw `direction(${direction}) must be String type`
            }
        }
        const newVal = (direction === null || direction === undefined) ? undefined : direction
        const oldVal = this.properties.direction
        this.properties.direction = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFISOLATIONCELL_DIRECTION, newVal, oldVal)
        }
    }
    getDirection(pure = false) {
        return this.properties.direction
        
    }
    getFidDirection() {
        return this.FID_UPFISOLATIONCELL_DIRECTION
    }
    

    setLocation(location, event=true) {
        if ((location !== null) && (location !== undefined)) {
            if (!(location instanceof Item) && (location.constructor !== String)) {
                throw `location(${location}) must be Item or String type`
            }
        }
        const newVal = (location === null || location === undefined) ? undefined : (location.constructor === String) ? location : location.getId()
        const oldVal = this.properties.location
        this.properties.location = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFISOLATIONCELL_LOCATION, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getLocation(pure = false) {
        return pure ? this.properties.location : this.getRefItem(this.properties.location)
        
    }
    getFidLocation() {
        return this.FID_UPFISOLATIONCELL_LOCATION
    }
    

    setType(type, event=true) {
        if ((type !== null) && (type !== undefined)) {
            if (type.constructor !== String) {
                throw `type(${type}) must be String type`
            }
        }
        const newVal = (type === null || type === undefined) ? undefined : type
        const oldVal = this.properties.type
        this.properties.type = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFISOLATIONCELL_TYPE, newVal, oldVal)
        }
    }
    getType(pure = false) {
        return this.properties.type
        
    }
    getFidType() {
        return this.FID_UPFISOLATIONCELL_TYPE
    }
    

    setIsNeedElements(isNeedElements, event=true) {
        if ((isNeedElements !== null) && (isNeedElements !== undefined)) {
            if (isNeedElements.constructor !== Boolean) {
                throw `isNeedElements(${isNeedElements}) must be Boolean type`
            }
        }
        const newVal = (isNeedElements === null || isNeedElements === undefined) ? undefined : isNeedElements
        const oldVal = this.properties.isNeedElements
        this.properties.isNeedElements = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFISOLATIONCELL_ISNEEDELEMENTS, newVal, oldVal)
        }
    }
    getIsNeedElements(pure = false) {
        return this.properties.isNeedElements
        
    }
    getFidIsNeedElements() {
        return this.FID_UPFISOLATIONCELL_ISNEEDELEMENTS
    }
    

    getFidLast() {
        return this.FID_UPFISOLATIONCELL_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_UPFISOLATIONCELL_NAME:
                return "name"

            case this.FID_UPFISOLATIONCELL_CELLS:
                return "cells"

            case this.FID_UPFISOLATIONCELL_DIRECTION:
                return "direction"

            case this.FID_UPFISOLATIONCELL_LOCATION:
                return "location"

            case this.FID_UPFISOLATIONCELL_TYPE:
                return "type"

            case this.FID_UPFISOLATIONCELL_ISNEEDELEMENTS:
                return "isNeedElements"

            default:
                return ""
        }
    }


    newUPFElement(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFElement", properties, title, domain)
    }
    remUPFElement(obj) {
        return this.removeChild(obj)
    }
    getUPFElements() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFElement")
            return res
        })
    }
    newUPFMapperPowerSource(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFMapperPowerSource", properties, title, domain)
    }
    remUPFMapperPowerSource(obj) {
        return this.removeChild(obj)
    }
    getUPFMapperPowerSources() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFMapperPowerSource")
            return res
        })
    }
}