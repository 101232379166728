/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import LabelEvent from '@/model/src/events/LabelEvent'
import LabelChecker from '@/model/src/checkers/LabelChecker'
export default class Label extends Node {
    FID_LABEL_TYPE = 100
    FID_LABEL_NUMCHILD = 101
    FID_LABEL_LABELREFS = 102
    FID_LABEL_LAST = 102

    constructor(obj) {
        super(Object.assign(obj,{itemType:"Label"}))
        
        this.setEvent(new LabelEvent(this))
        this.setChecker(new LabelChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.type === undefined) {
        this.properties.type = "internal"
    }
    if (this.properties.numChild === undefined) {
        this.properties.numChild = 1
    }
    if (this.properties.labelRefs === undefined) {
        this.properties.labelRefs = []
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "Label"
    }

    getSchema() {
        return {"type": {"type": "enum", "targets": ["internal", "external"], "multiple": false, "default": "internal", "category": "IP", "hidden": false, "readOnly": false}, "numChild": {"type": "integer", "label": "Number of Child.", "hidden": false, "default": 1, "category": "IP", "multiple": false, "readOnly": false}, "labelRefs": {"type": "item", "label": "connected reference", "desc": "connected reference", "multiple": true, "default": [], "category": "IP", "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"type": {"type": "enum", "targets": ["internal", "external"], "multiple": false, "default": "internal", "category": "IP", "hidden": false, "readOnly": false}, "numChild": {"type": "integer", "label": "Number of Child.", "hidden": false, "default": 1, "category": "IP", "multiple": false, "readOnly": false}, "labelRefs": {"type": "item", "label": "connected reference", "desc": "connected reference", "multiple": true, "default": [], "category": "IP", "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setType(type, event=true) {
        if ((type !== null) && (type !== undefined)) {
            if (type.constructor !== String) {
                throw `type(${type}) must be String type`
            }
        }
        const newVal = (type === null || type === undefined) ? undefined : type
        const oldVal = this.properties.type
        this.properties.type = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_LABEL_TYPE, newVal, oldVal)
        }
    }
    getType(pure = false) {
        return this.properties.type
        
    }
    getFidType() {
        return this.FID_LABEL_TYPE
    }
    

    setNumChild(numChild, event=true) {
        if ((numChild !== null) && (numChild !== undefined)) {
            if (numChild.constructor !== Number) {
                throw `numChild(${numChild}) must be Number type`
            }
        }
        const newVal = (numChild === null || numChild === undefined) ? undefined : numChild
        const oldVal = this.properties.numChild
        this.properties.numChild = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_LABEL_NUMCHILD, newVal, oldVal)
        }
    }
    getNumChild(pure = false) {
        return this.properties.numChild
        
    }
    getFidNumChild() {
        return this.FID_LABEL_NUMCHILD
    }
    

    setLabelRefs(labelRefs, event=true) {
        if ((labelRefs !== null) && (labelRefs !== undefined)) {
            if (labelRefs.constructor !== Array) {
                throw `labelRefs(${labelRefs}) must be Array type`
            }
        }
        const newVal = (labelRefs === null || labelRefs === undefined) ? [] : labelRefs.map((o) => {
            if (!o || o.constructor === String) {
                return o
            } else if (this.toRaw(o) instanceof Item) {
                return o.getId()
            }
        })
        const oldVal = this.properties.labelRefs
        this.properties.labelRefs = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_LABEL_LABELREFS, newVal.map((id) => this.getRefItem(id)), oldVal.map((id) => this.getRefItem(id)))
        }
    }
    getLabelRefs(pure = false) {
        return pure ? this.properties.labelRefs : this.properties.labelRefs.map((id) => this.getRefItem(id))
        
    }
    getFidLabelRefs() {
        return this.FID_LABEL_LABELREFS
    }
    

    getFidLast() {
        return this.FID_LABEL_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_LABEL_TYPE:
                return "type"

            case this.FID_LABEL_NUMCHILD:
                return "numChild"

            case this.FID_LABEL_LABELREFS:
                return "labelRefs"

            default:
                return ""
        }
    }


}