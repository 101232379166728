import { DockPlugin, DockPresets } from "rete-dock-plugin";
import { AreaPlugin } from "rete-area-plugin";
import { ITDACommon, AreaExtra, ITDACanvasType } from "./types";
import * as ITDANodes from "../nodes";
import ITDACanvas from "../ITDACanvas";
import { DiagramType, NodeType } from "../types";

const data = {
  CMU: [
    () => new ITDANodes.ITDASOURCE(NodeType.ITDASOURCE.getTitle()),
    () => new ITDANodes.ITDAPLLCTRL(NodeType.ITDAPLLCTRL.getTitle()),
    () => new ITDANodes.ITDAREFCLKMUX(NodeType.ITDAREFCLKMUX.getTitle()),
    () => new ITDANodes.ITDAGFMUX(NodeType.ITDAGFMUX.getTitle()),
    () => new ITDANodes.ITDADIVIDER(NodeType.ITDADIVIDER.getTitle()),
    () => new ITDANodes.ITDAGATE(NodeType.ITDAGATE.getTitle()),
    () => new ITDANodes.ITDABUFFER(NodeType.ITDABUFFER.getTitle()),
    () => new ITDANodes.ITDANMDIVIDER(NodeType.ITDANMDIVIDER.getTitle()),
    () => new ITDANodes.ITDAFREQMON(NodeType.ITDAFREQMON.getTitle()),
    () => new ITDANodes.ITDALABEL(NodeType.ITDALABEL.getTitle()),
    () => new ITDANodes.ITDALABELREF(NodeType.ITDALABELREF.getTitle()),
    () => new ITDANodes.ITDADFTOCCBUFFER(NodeType.ITDADFTOCCBUFFER.getTitle()),
  ],
  IP: [
    () => new ITDANodes.ITDALABELREF(NodeType.ITDALABELREF.getTitle()),
    // () => new ITDANodes.ITDAGATE(NodeType.ITDAGATE.getTitle()),
    () => new ITDANodes.ITDAQCH(NodeType.ITDAQCH.getTitle()),
    // () => new ITDANodes.ITDAQCHINFO(NodeType.ITDAQCHINFO.getTitle()),
    () => new ITDANodes.ITDAINSTANCE(NodeType.ITDAINSTANCEIP),
  ],
  PMC: [
    () => new ITDANodes.ITDAINSTANCE(NodeType.ITDAINSTANCEPMD),
    () => new ITDANodes.ITDAPWREN(NodeType.ITDAPWREN.getTitle()),
    () => new ITDANodes.ITDAISOEN(NodeType.ITDAISOEN.getTitle()),
    () => new ITDANodes.ITDAHANDSHAKE(NodeType.ITDAHANDSHAKE.getTitle()),
    () => new ITDANodes.ITDAPCH(NodeType.ITDAPCH.getTitle()),
    () => new ITDANodes.ITDARESET(NodeType.ITDARESET.getTitle()),
    () => new ITDANodes.ITDAUSERDEFOUT(NodeType.ITDAUSERDEFOUT.getTitle()),
    () => new ITDANodes.ITDAUSERDEFIN(NodeType.ITDAUSERDEFIN.getTitle()),
    () => new ITDANodes.ITDAINFORM(NodeType.ITDAINFORM.getTitle()),
    () => new ITDANodes.ITDATIMEOUT(NodeType.ITDATIMEOUT.getTitle()),
    () => new ITDANodes.ITDAPMRCTRL(NodeType.ITDAPMRCTRL.getTitle()),
    () => new ITDANodes.ITDALATCH(NodeType.ITDALATCH.getTitle()),
    () => new ITDANodes.ITDAINTRSFR(NodeType.ITDAINTRSFR.getTitle()),
    () => new ITDANodes.ITDAINTREXT(NodeType.ITDAINTREXT.getTitle()),
    () => new ITDANodes.ITDATIMER(NodeType.ITDATIMER.getTitle()),
    () => new ITDANodes.ITDAPMDGRP(NodeType.ITDAPMDGRP.getTitle()),
  ],
  PMD: [
    () => new ITDANodes.ITDAAPM(NodeType.ITDAAPM.getTitle()),
    () => new ITDANodes.ITDAREFCLKEN(NodeType.ITDAREFCLKEN.getTitle()),
    () => new ITDANodes.ITDAPSW(NodeType.ITDAPSW.getTitle()),
    () => new ITDANodes.ITDAPWREN(NodeType.ITDAPWREN.getTitle()),
    () => new ITDANodes.ITDAISOEN(NodeType.ITDAISOEN.getTitle()),
    () => new ITDANodes.ITDAMEM(NodeType.ITDAMEM.getTitle()),
    () => new ITDANodes.ITDARETENTION(NodeType.ITDARETENTION.getTitle()),
    () => new ITDANodes.ITDAOTP(NodeType.ITDAOTP.getTitle()),
    () => new ITDANodes.ITDAHANDSHAKE(NodeType.ITDAHANDSHAKE.getTitle()),
    () => new ITDANodes.ITDACLINK(NodeType.ITDACLINK.getTitle()),
    () => new ITDANodes.ITDAPCH(NodeType.ITDAPCH.getTitle()),
    () => new ITDANodes.ITDARESET(NodeType.ITDARESET.getTitle()),
    () => new ITDANodes.ITDAUSERDEFOUT(NodeType.ITDAUSERDEFOUT.getTitle()),
    () => new ITDANodes.ITDAUSERDEFIN(NodeType.ITDAUSERDEFIN.getTitle()),
  ],
  SEQ: [
    () => new ITDANodes.ITDASEQWRITE(NodeType.ITDASEQWRITE.getTitle()),
    () => new ITDANodes.ITDASEQREADWAIT(NodeType.ITDASEQREADWAIT.getTitle()),
    () => new ITDANodes.ITDASEQWAIT(NodeType.ITDASEQWAIT.getTitle()),
    () => new ITDANodes.ITDASEQIF(NodeType.ITDASEQIF.getTitle()),
    () => new ITDANodes.ITDASEQGOTO(NodeType.ITDASEQGOTO.getTitle()),
    () => new ITDANodes.ITDASEQCALL(NodeType.ITDASEQCALL.getTitle()),
    () => new ITDANodes.ITDASEQEND(NodeType.ITDASEQEND.getTitle()),
  ],
  SDC: [() => new ITDANodes.ITDAINSTANCE(NodeType.ITDAINSTANCESDC)],
  DUT: [
    () => new ITDANodes.ITDAINSTANCE(NodeType.ITDAINSTANCEBLOCK),
    () => new ITDANodes.ITDAINSTANCE(NodeType.ITDAINSTANCECMU),
    () => new ITDANodes.ITDAINSTANCE(NodeType.ITDAINSTANCEPMC),
    () => new ITDANodes.ITDAINSTANCE(NodeType.ITDAINSTANCEPMIF),
  ],
  UPF: [
    () =>
      new ITDANodes.ITDAUPFLEVELSHIFTER(
        NodeType.ITDAUPFLEVELSHIFTER.getTitle()
      ),
    () => new ITDANodes.ITDAINSTANCE(NodeType.ITDAINSTANCEHARDMACRO),
    () => new ITDANodes.ITDAINSTANCE(NodeType.ITDAINSTANCEPOWERDOMAIN),
  ],
};

export default class ITDADockPlugin extends ITDACanvas {
  constructor(id: string) {
    super(id, ITDACanvasType.Dock);
    this.res = new DockPlugin<ITDACommon.Schemes>();
    const area = this.repo
      .getPlugin(this.id, ITDACanvasType.Area)
      ?.getInstance() as AreaPlugin<ITDACommon.Schemes, AreaExtra>;
    this.res.addPreset(DockPresets.classic.setup({ area, size: 0, scale: 1 }));
  }
  getInstance(): DockPlugin<ITDACommon.Schemes> {
    return this.res as DockPlugin<ITDACommon.Schemes>;
  }

  register(diagramType: DiagramType) {
    this.repo.updateDockState(true);
    const nodes = data[diagramType.getTitle() as keyof typeof data];
    nodes.forEach((o) => this.getInstance().add(o));
    this.repo.updateDockState(false);
  }

  render() {
    const elem = document.querySelector("#dockBox");
    if (elem) {
      elem.innerHTML = "";
      this.getInstance().nodes.forEach((value) => {
        value.element.style.height = "auto";
        elem.appendChild(value.element);
      });
    }
  }
}
