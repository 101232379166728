import EventHandler from "./EventHandler";
import canvas from "./canvas";
import item from "./item";
import Model from "ITDAModel";

export default {
  register: EventHandler.register,
  setupEvents: () => {
    EventHandler.clear();
    Model.item.emit = EventHandler.emit;
    EventHandler.addEvent("nodecreate", canvas.nodecreate);
    EventHandler.addEvent("nodecreated", canvas.nodecreated);
    EventHandler.addEvent("noderemoved", canvas.noderemoved);
    EventHandler.addEvent("connectionpick", canvas.connectionpick);
    EventHandler.addEvent("connectioncreated", canvas.connectioncreated);
    EventHandler.addEvent("connectionremoved", canvas.connectionremoved);
    EventHandler.addEvent("nodepicked", canvas.nodepicked);
    EventHandler.addEvent("nodetranslated", canvas.nodetranslated);
    EventHandler.addEvent("noderesize", canvas.noderesize);
    EventHandler.addEvent("nodedragged", canvas.nodedragged);

    EventHandler.addEvent("nodeimported", canvas.nodeimported);

    EventHandler.addEvent("itemadded", item.itemadded);
    EventHandler.addEvent("itemremoved", item.itemremoved);
    EventHandler.addEvent("itemupdated", item.itemupdated);
  },
};
