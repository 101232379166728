import {
  Item,
  VariantLevel,
  RefItemElement,
  RefItemElementType,
} from "ITDAModelTypes";
import API from "@/api/internal";

export const getMigration = (item: Item): RefItemElement[] => {
  return [
    {
      icon: "mdi-check",
      variant: VariantLevel.LEVEL0,
      disabled: false,
      type: RefItemElementType.CHIP,
      propKey: "migration",
      label: "",
      targets: [
        {
          key: `Update ${API.getCurrentDomain()}`,
          item: item,
          closable: false,
        },
      ],
      event: (item: Item, elem: RefItemElement, value: string | string[]) => {
        console.log("@@@", item, elem, value);
      },
    },
  ];
};
