<template>
  <v-autocomplete
    v-model="model"
    :style="style()"
    :label="props.table ? undefined : Utils.getLabel(props.propKey, props.prop)"
    :disabled="
      Utils.isDisabledAutocomplete(props.item, props.propKey, props.prop)
    "
    :items="Utils.getTargets(props.prop)"
    :multiple="Utils.isMultiple(props.prop)"
    :closable-chips="true"
    chips
    color="primary"
    :error-messages="props.errorMessages"
    :hide-details="!props.errorMessages"
    :variant="
      props.itemRef
        ? props.itemRef.variant
          ? Utils.getVariant(
              RefItemElementType.ENUM,
              VariantLevel[props.itemRef.variant]
            )
          : undefined
        : `underlined`
    "
    @update:model-value="
      props.itemRef
        ? props.itemRef.event(props.item, props.itemRef, $event)
        : Utils.update(props.item, props.propKey, String, $event)
    ">
    <template v-if="!Utils.isMultiple(props.prop)" v-slot:chip="data">
      <v-chip
        v-bind="data.props"
        :item="data.item"
        :text="data.item.value"></v-chip>
    </template>
    <template v-slot:selection="{ props, item }">
      <v-list-item
        v-bind="props"
        :item="item"
        :title="item.value"></v-list-item>
    </template>
  </v-autocomplete>
</template>

<script setup lang="ts">
import { defineProps, ref, reactive } from "vue";
import Utils from "@/components/item/utils";
import {
  ItemPropsType,
  ItemType,
  RefItemElementType,
  VariantLevel,
} from "ITDAModelTypes";
const props = defineProps<ItemPropsType>();
const value = props.itemRef
  ? props.itemRef.model
  : Utils.getValue(props.item, props.propKey);
const model = Array.isArray(value) ? reactive(value) : ref(value);
const style = () => {
  let res = `margin-bottom: 0`;
  switch (props.item.getItemType()) {
    case ItemType.ClockSIGNAL:
      res = `margin-bottom: 15px`;
      break;
    default:
      break;
  }
  return res;
};
</script>

<style lang="scss">
.v-input {
  padding-top: 5px;
}
</style>
