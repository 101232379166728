/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import PowerPSWEvent from '@/model/src/events/PowerPSWEvent'
import PowerPSWChecker from '@/model/src/checkers/PowerPSWChecker'
import ITDA_PMS_PMD_PWRCOMP_PSW from '@/model/gen/sfrSpec/ITDA_PMS_PMD_PWRCOMP_PSW'
export default class PowerPSW extends Node {
    FID_POWERPSW_NAME = 100
    FID_POWERPSW_ISRESETNCLDBOOT = 101
    FID_POWERPSW_MAXNUM = 102
    FID_POWERPSW_LAST = 102

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerPSW"}))
        
        this.setEvent(new PowerPSWEvent(this))
        this.setChecker(new PowerPSWChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "PSW_NONAME"
    }
    if (this.properties.isResetnCLDBoot === undefined) {
        this.properties.isResetnCLDBoot = false
    }
    if (this.properties.maxNum === undefined) {
        this.properties.maxNum = 8
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerPSW"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "PSW_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "isResetnCLDBoot": {"type": "boolean", "label": "Reset at Cold Booting", "desc": "SFR Field is only reset at Cold Booting", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 8, "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "PSW_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "isResetnCLDBoot": {"type": "boolean", "label": "Reset at Cold Booting", "desc": "SFR Field is only reset at Cold Booting", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 8, "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_PMS_PMD_PWRCOMP_PSW
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_PMS_PMD_PWRCOMP_PSW
        }
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPSW_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_POWERPSW_NAME
    }
    

    setIsResetnCLDBoot(isResetnCLDBoot, event=true) {
        if ((isResetnCLDBoot !== null) && (isResetnCLDBoot !== undefined)) {
            if (isResetnCLDBoot.constructor !== Boolean) {
                throw `isResetnCLDBoot(${isResetnCLDBoot}) must be Boolean type`
            }
        }
        const newVal = (isResetnCLDBoot === null || isResetnCLDBoot === undefined) ? undefined : isResetnCLDBoot
        const oldVal = this.properties.isResetnCLDBoot
        this.properties.isResetnCLDBoot = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPSW_ISRESETNCLDBOOT, newVal, oldVal)
        }
    }
    getIsResetnCLDBoot(pure = false) {
        return this.properties.isResetnCLDBoot
        
    }
    getFidIsResetnCLDBoot() {
        return this.FID_POWERPSW_ISRESETNCLDBOOT
    }
    

    setMaxNum(maxNum, event=true) {
        if ((maxNum !== null) && (maxNum !== undefined)) {
            if (maxNum.constructor !== Number) {
                throw `maxNum(${maxNum}) must be Number type`
            }
        }
        const newVal = (maxNum === null || maxNum === undefined) ? undefined : maxNum
        const oldVal = this.properties.maxNum
        this.properties.maxNum = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPSW_MAXNUM, newVal, oldVal)
        }
    }
    getMaxNum(pure = false) {
        return this.properties.maxNum
        
    }
    getFidMaxNum() {
        return this.FID_POWERPSW_MAXNUM
    }
    

    getFidLast() {
        return this.FID_POWERPSW_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERPSW_NAME:
                return "name"

            case this.FID_POWERPSW_ISRESETNCLDBOOT:
                return "isResetnCLDBoot"

            case this.FID_POWERPSW_MAXNUM:
                return "maxNum"

            default:
                return ""
        }
    }


}