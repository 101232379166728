/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import ClockMultiplexerEXTEvent from '@/model/src/events/ClockMultiplexerEXTEvent'
import ClockMultiplexerEXTChecker from '@/model/src/checkers/ClockMultiplexerEXTChecker'
import ITDA_CMU_GFMUX_EXT_PT from '@/model/gen/sfrSpec/ITDA_CMU_GFMUX_EXT_PT'
export default class ClockMultiplexerEXT extends Item {
    FID_CLOCKMULTIPLEXEREXT_THROTTLEMODE = 0
    FID_CLOCKMULTIPLEXEREXT_CUSTOMMODE = 1
    FID_CLOCKMULTIPLEXEREXT_NAME = 2
    FID_CLOCKMULTIPLEXEREXT_LAST = 2

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ClockMultiplexerEXT"}))
        
        this.setEvent(new ClockMultiplexerEXTEvent(this))
        this.setChecker(new ClockMultiplexerEXTChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.throttleMode === undefined) {
        this.properties.throttleMode = false
    }
    if (this.properties.customMode === undefined) {
        this.properties.customMode = false
    }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ClockMultiplexerEXT"
    }

    getSchema() {
        return {"throttleMode": {"type": "boolean", "label": "Use THROTTLE", "desc": "Use Throttle", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "customMode": {"type": "boolean", "label": "Use CUSTOM", "desc": "Use Custom Ctrl", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "name": {"type": "string", "hidden": true, "default": "NONAME", "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"throttleMode": {"type": "boolean", "label": "Use THROTTLE", "desc": "Use Throttle", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "customMode": {"type": "boolean", "label": "Use CUSTOM", "desc": "Use Custom Ctrl", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "name": {"type": "string", "hidden": true, "default": "NONAME", "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "ClockSIGNAL", "interface": "ThrottleSignal", "condition": {"type": "ThrottleSignal"}, "properties": {"type": "ThrottleSignal"}, "auto": false, "searchable": false, "title": "THROTTLE Signal List", "headers": [{"title": "Name", "value": "SignalName", "align": "start", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_CMU_GFMUX_EXT_PT
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_CMU_GFMUX_EXT_PT
        }
    }

    setThrottleMode(throttleMode, event=true) {
        if ((throttleMode !== null) && (throttleMode !== undefined)) {
            if (throttleMode.constructor !== Boolean) {
                throw `throttleMode(${throttleMode}) must be Boolean type`
            }
        }
        const newVal = (throttleMode === null || throttleMode === undefined) ? undefined : throttleMode
        const oldVal = this.properties.throttleMode
        this.properties.throttleMode = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKMULTIPLEXEREXT_THROTTLEMODE, newVal, oldVal)
        }
    }
    getThrottleMode(pure = false) {
        return this.properties.throttleMode
        
    }
    getFidThrottleMode() {
        return this.FID_CLOCKMULTIPLEXEREXT_THROTTLEMODE
    }
    

    setCustomMode(customMode, event=true) {
        if ((customMode !== null) && (customMode !== undefined)) {
            if (customMode.constructor !== Boolean) {
                throw `customMode(${customMode}) must be Boolean type`
            }
        }
        const newVal = (customMode === null || customMode === undefined) ? undefined : customMode
        const oldVal = this.properties.customMode
        this.properties.customMode = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKMULTIPLEXEREXT_CUSTOMMODE, newVal, oldVal)
        }
    }
    getCustomMode(pure = false) {
        return this.properties.customMode
        
    }
    getFidCustomMode() {
        return this.FID_CLOCKMULTIPLEXEREXT_CUSTOMMODE
    }
    

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKMULTIPLEXEREXT_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_CLOCKMULTIPLEXEREXT_NAME
    }
    

    getFidLast() {
        return this.FID_CLOCKMULTIPLEXEREXT_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CLOCKMULTIPLEXEREXT_THROTTLEMODE:
                return "throttleMode"

            case this.FID_CLOCKMULTIPLEXEREXT_CUSTOMMODE:
                return "customMode"

            case this.FID_CLOCKMULTIPLEXEREXT_NAME:
                return "name"

            default:
                return ""
        }
    }


    newThrottleSignal(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockSIGNAL", properties, title, domain)
    }
    remThrottleSignal(obj) {
        return this.removeChild(obj)
    }
    getThrottleSignals() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockSIGNAL")
            res &= (item.properties.type === "ThrottleSignal")
            return res
        })
    }
}