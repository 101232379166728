<template>
  <v-container>
    <v-card hover class="mx-auto mb-8" min-width="328px"
      ><!--disabled-->
      <v-card-title class="text-primary text-center">
        <h3>COPY DB</h3>
      </v-card-title>
      <v-row class="ma-2">
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="targetDesignDB"
            label="Design Name"
            variant="outlined"
            chips
            closable-chips
            color="primary"
            :items="props.designTargets"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="copiedDesignName"
            dense
            label="New Design Name"
            variant="outlined"
            color="primary"
            @update:modelValue="setCopiedDesignName">
            <template v-slot:append-inner>
              <v-icon
                @click="copyDB(copiedDesignName, targetDesignDB, `design`)"
                >mdi-content-save</v-icon
              >
            </template></v-text-field
          >
        </v-col>
      </v-row>
      <v-row class="mx-2">
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="targetConfigDB"
            label="Config Name"
            variant="outlined"
            chips
            closable-chips
            color="primary"
            :items="props.configTargets"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="copiedConfigName"
            dense
            label="New Config Name"
            variant="outlined"
            color="primary"
            @update:modelValue="setCopiedConfigName">
            <template v-slot:append-inner>
              <v-icon
                @click="copyDB(copiedConfigName, targetConfigDB, `config`)"
                >mdi-content-save</v-icon
              >
            </template></v-text-field
          >
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script setup lang="ts">
import { ref, defineProps, defineEmits } from "vue";
import API from "@/api";

const emit = defineEmits(["targetUpdated"]);
const props = defineProps<{
  designTargets: string[];
  configTargets: string[];
}>();

const targetDesignDB = ref(undefined);
const targetConfigDB = ref(undefined);
const copiedDesignName = ref("");
const copiedConfigName = ref("");

const setCopiedDesignName = (value: string) => {
  copiedDesignName.value = value;
};
const setCopiedConfigName = (value: string) => {
  copiedConfigName.value = value;
};
const copyDB = async (
  name: string,
  target: string | undefined,
  type: string
) => {
  try {
    if (!name || !target) {
      return;
    }
    if (
      window.confirm(
        `${type} DB "${name}"" will be copied. Do you want to proceed?`
      )
    ) {
      await API.copy(name, target, type);
      emit("targetUpdated");
    }
  } catch (err) {
    console.error("Error copying DB", err);
  }
};
</script>
<style lang="css" scoped>
.v-card-title {
  background-color: #e3ecf7;
}
</style>
