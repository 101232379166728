/**
 * @param { Item } pmdGrp
 * @param { string } [newValue]
 * @param { number } [targetOutputIdx]
 */
export const updateINTRChildrenTitle = (pmdGrp, newValue, targetOutputIdx) => {
  const intrGrps = pmdGrp.getPowerPMRINTRGRPs();
  if (newValue && targetOutputIdx !== undefined) {
    if (intrGrps.length > 0) {
      const children = [];
      intrGrps.forEach((intrGrp) => {
        children.push(
          ...intrGrp
            .getPowerPMRINTREvents()
            .slice(targetOutputIdx * 2, targetOutputIdx * 2 + 2)
        );
        children.push(
          ...intrGrp
            .getPowerPMRINTRGenBits()
            .slice(targetOutputIdx * 2, targetOutputIdx * 2 + 2)
        );
      });

      children.forEach((child) => {
        const type = child.getTitle().split("__")[1];
        const name = newValue
          ? `SFR_${newValue}__${type}`
          : `SFR_PMD${targetOutputIdx}__${type}`;
        child.setTitle(name);
      });
    }
  } else {
    if (intrGrps.length > 0) {
      const events = [];
      const genBits = [];
      intrGrps.forEach((intrGrp) => {
        events.push(...intrGrp.getPowerPMRINTREvents());
        genBits.push(...intrGrp.getPowerPMRINTRGenBits());
      });

      const pmdNames = [];
      const pmdOutputs = pmdGrp.getOutputs();
      pmdOutputs.forEach((pmdOutput) => {
        pmdNames.push(
          pmdOutput
            .getConnections()?.[0]
            ?.getTargetInput()
            .getParent()
            .getName()
        );
      });

      events.forEach((event, idx) => {
        const type = event.getTitle().split("__")[1];
        const name = pmdNames[Math.floor(idx / 2)]
          ? `SFR_${[pmdNames[Math.floor(idx / 2)]]}__${type}`
          : `SFR_PMD${Math.floor(idx / 2)}__${type}`;
        event.setTitle("", false);
        event.setTitle(name);
      });

      genBits.forEach((getBit, idx) => {
        const type = getBit.getTitle().split("__")[1];
        const name = pmdNames[Math.floor(idx / 2)]
          ? `SFR_${[pmdNames[Math.floor(idx / 2)]]}__${type}`
          : `SFR_PMD${Math.floor(idx / 2)}__${type}`;
        getBit.setTitle("", false);
        getBit.setTitle(name);
      });
    }
  }
};
