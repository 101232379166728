/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import PowerSequenceLabelRefEvent from '@/model/src/events/PowerSequenceLabelRefEvent'
import PowerSequenceLabelRefChecker from '@/model/src/checkers/PowerSequenceLabelRefChecker'
export default class PowerSequenceLabelRef extends Node {
    FID_POWERSEQUENCELABELREF_ITEMLABEL = 100
    FID_POWERSEQUENCELABELREF_LABELS = 101
    FID_POWERSEQUENCELABELREF_LAST = 101

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerSequenceLabelRef"}))
        
        this.setEvent(new PowerSequenceLabelRefEvent(this))
        this.setChecker(new PowerSequenceLabelRefChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.labels === undefined) {
        this.properties.labels = []
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerSequenceLabelRef"
    }

    getSchema() {
        return {"itemLabel": {"type": "item", "label": "Connected LABEL", "desc": "Connected LABEL", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "labels": {"type": "item", "label": "Target LABEL", "desc": "Target LABEL", "multiple": true, "default": [], "category": "IP", "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"itemLabel": {"type": "item", "label": "Connected LABEL", "desc": "Connected LABEL", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "labels": {"type": "item", "label": "Target LABEL", "desc": "Target LABEL", "multiple": true, "default": [], "category": "IP", "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setItemLabel(itemLabel, event=true) {
        if ((itemLabel !== null) && (itemLabel !== undefined)) {
            if (!(itemLabel instanceof Item) && (itemLabel.constructor !== String)) {
                throw `itemLabel(${itemLabel}) must be Item or String type`
            }
        }
        const newVal = (itemLabel === null || itemLabel === undefined) ? undefined : (itemLabel.constructor === String) ? itemLabel : itemLabel.getId()
        const oldVal = this.properties.itemLabel
        this.properties.itemLabel = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERSEQUENCELABELREF_ITEMLABEL, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getItemLabel(pure = false) {
        return pure ? this.properties.itemLabel : this.getRefItem(this.properties.itemLabel)
        
    }
    getFidItemLabel() {
        return this.FID_POWERSEQUENCELABELREF_ITEMLABEL
    }
    

    setLabels(labels, event=true) {
        if ((labels !== null) && (labels !== undefined)) {
            if (labels.constructor !== Array) {
                throw `labels(${labels}) must be Array type`
            }
        }
        const newVal = (labels === null || labels === undefined) ? [] : labels.map((o) => {
            if (!o || o.constructor === String) {
                return o
            } else if (this.toRaw(o) instanceof Item) {
                return o.getId()
            }
        })
        const oldVal = this.properties.labels
        this.properties.labels = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERSEQUENCELABELREF_LABELS, newVal.map((id) => this.getRefItem(id)), oldVal.map((id) => this.getRefItem(id)))
        }
    }
    getLabels(pure = false) {
        return pure ? this.properties.labels : this.properties.labels.map((id) => this.getRefItem(id))
        
    }
    getFidLabels() {
        return this.FID_POWERSEQUENCELABELREF_LABELS
    }
    

    getFidLast() {
        return this.FID_POWERSEQUENCELABELREF_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERSEQUENCELABELREF_ITEMLABEL:
                return "itemLabel"

            case this.FID_POWERSEQUENCELABELREF_LABELS:
                return "labels"

            default:
                return ""
        }
    }


}