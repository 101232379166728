/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import ClockDFTOCCBufferEvent from '@/model/src/events/ClockDFTOCCBufferEvent'
import ClockDFTOCCBufferChecker from '@/model/src/checkers/ClockDFTOCCBufferChecker'
export default class ClockDFTOCCBuffer extends Node {
    FID_CLOCKDFTOCCBUFFER_NAME = 100
    FID_CLOCKDFTOCCBUFFER_SDCPATH = 101
    FID_CLOCKDFTOCCBUFFER_LAST = 101

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ClockDFTOCCBuffer"}))
        
        this.setEvent(new ClockDFTOCCBufferEvent(this))
        this.setChecker(new ClockDFTOCCBufferChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    if (this.properties.SDCPath === undefined) {
        this.properties.SDCPath = "/{{CMU}}/{{NODE}}/$POINT$ITDA_GENERIC_CLKBUF$Y"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ClockDFTOCCBuffer"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "SDCPath": {"type": "string", "hidden": true, "default": "/{{CMU}}/{{NODE}}/$POINT$ITDA_GENERIC_CLKBUF$Y", "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "SDCPath": {"type": "string", "hidden": true, "default": "/{{CMU}}/{{NODE}}/$POINT$ITDA_GENERIC_CLKBUF$Y", "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKDFTOCCBUFFER_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_CLOCKDFTOCCBUFFER_NAME
    }
    

    setSDCPath(SDCPath, event=true) {
        if ((SDCPath !== null) && (SDCPath !== undefined)) {
            if (SDCPath.constructor !== String) {
                throw `SDCPath(${SDCPath}) must be String type`
            }
        }
        const newVal = (SDCPath === null || SDCPath === undefined) ? undefined : SDCPath
        const oldVal = this.properties.SDCPath
        this.properties.SDCPath = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKDFTOCCBUFFER_SDCPATH, newVal, oldVal)
        }
    }
    getSDCPath(pure = false) {
        return this.properties.SDCPath
        
    }
    getFidSDCPath() {
        return this.FID_CLOCKDFTOCCBUFFER_SDCPATH
    }
    

    getFidLast() {
        return this.FID_CLOCKDFTOCCBUFFER_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CLOCKDFTOCCBUFFER_NAME:
                return "name"

            case this.FID_CLOCKDFTOCCBUFFER_SDCPATH:
                return "SDCPath"

            default:
                return ""
        }
    }


}