/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import PowerPMRCoreResetEvent from '@/model/src/events/PowerPMRCoreResetEvent'
import PowerPMRCoreResetChecker from '@/model/src/checkers/PowerPMRCoreResetChecker'
import ITDA_PMS_PMR_CORE_RESET_PT from '@/model/gen/sfrSpec/ITDA_PMS_PMR_CORE_RESET_PT'
export default class PowerPMRCoreReset extends Item {
    FID_POWERPMRCORERESET_NAME = 0
    FID_POWERPMRCORERESET_LAST = 0

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerPMRCoreReset"}))
        
        this.setEvent(new PowerPMRCoreResetEvent(this))
        this.setChecker(new PowerPMRCoreResetChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "PowerPMRCoreReset"
        }
    if (this.properties.name === undefined) {
        this.properties.name = ""
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerPMRCoreReset"
    }

    getSchema() {
        return {"name": {"type": "string", "readOnly": true, "default": "", "category": "IP", "multiple": false, "hidden": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "readOnly": true, "default": "", "category": "IP", "multiple": false, "hidden": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_PMS_PMR_CORE_RESET_PT
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_PMS_PMR_CORE_RESET_PT
        }
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMRCORERESET_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_POWERPMRCORERESET_NAME
    }
    

    getFidLast() {
        return this.FID_POWERPMRCORERESET_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERPMRCORERESET_NAME:
                return "name"

            default:
                return ""
        }
    }


}