import { Context } from "../types";
import Canvas from "@/canvas";
import { ImportConnectionType } from "@/canvas/types";
import utils from "../utils";
import TopUtils from "@/utils";
import { ItemType } from "ITDAModelTypes";
import API from "@/api/internal";

export const itemadded = async (context: Context): Promise<boolean> => {
  const item = context.options.item;
  const cItem = context.options.data;
  const diagram = API.getParentDiagram(cItem);
  const editor = Canvas.getEditor(diagram?.getId());
  const area = Canvas.getAreaPlugin(diagram?.getId());
  switch (item.getItemType()) {
    case ItemType.ClockIP:
      if (cItem.getItemType() === ItemType.ClockQChannelInfo) {
        const numberOfChildren = item.getClockQChannelInfos().length;
        const metaView = item.getNodeMetaView();
        const node = editor?.getNode(item.getNodeID());
        if (node) {
          const cMetaView = cItem.getNodeMetaView();
          cMetaView.setX(metaView.getX() + node.width * numberOfChildren);
          cMetaView.setY(metaView.getY());
        }
      }
      break;
    case ItemType.PowerSequenceConfiguration:
      if (cItem.getItemType() === ItemType.PowerSequenceDiagram) {
        await utils.createDefaultSequence(cItem);
      }
      break;
    case ItemType.PowerSequenceDiagram:
      if (cItem.getItemType() === ItemType.Connection) {
        const output = cItem.getSourceOutput();
        const input = cItem.getTargetInput();
        let seqNode;
        let seq;
        if (output.getParent().getItemType() === ItemType.PowerSequenceLabel) {
          seqNode = output.getParent();
          seq = input.getParent();
        } else if (
          input.getParent().getItemType() === ItemType.PowerSequenceBack
        ) {
          seqNode = input.getParent();
          seq = output.getParent();
        }
        if (seq && seqNode) {
          const node = editor?.getNode(seqNode.getNodeID());
          const nodeView = node?.getNodeView();
          const metaView = seq.getNodeMetaView();
          if (node && nodeView) {
            if (seqNode.getItemType() === ItemType.PowerSequenceLabel) {
              await nodeView.translate(
                metaView.getX() - node.width - 60,
                metaView.getY()
              );
            } else if (seqNode.getItemType() === ItemType.PowerSequenceBack) {
              await nodeView.translate(
                metaView.getX(),
                metaView.getY() + node.height + 40
              );
            }
          }
        }
      }
      break;
    case ItemType.PowerSequenceLabel:
      item.setColor(item.getColor() || "#F2F2F2");
      break;
    case ItemType.PowerAPM:
      if (
        cItem.getItemType() === ItemType.PowerAPMPWRDOWN ||
        cItem.getItemType() === ItemType.PowerAPMPWRUP
      ) {
        const node = editor?.getNode(item.getNodeID());
        node.update();
      }
      break;
    default:
      break;
  }

  if (editor) {
    if (cItem.isNodeType()) {
      const nodeMeta = cItem;
      if (!cItem.getBuild() && !editor.getNode(nodeMeta.getNodeID())) {
        await editor.importNodes([TopUtils.createImportNodeData(cItem)]);
      }
    }

    if (cItem.isConnectionType()) {
      if (!cItem.getBuild() && !editor.getConnection(cItem.getConnectionID())) {
        const importConnectionData: ImportConnectionType | undefined =
          TopUtils.createImportConnectionData(cItem);
        if (importConnectionData) {
          await editor.importConnections([importConnectionData]);
        }
      }
    }
    switch (cItem.getItemType()) {
      case ItemType.ClockIPInput:
      case ItemType.Input:
      case ItemType.Output:
        {
          const node = editor.getNode(item.getNodeID());
          if (node) {
            utils.createNodePorts(node, item).then(() => {
              if (item.getItemType() === ItemType.DUTInstance) {
                utils.updateParentScope(item.getUpper());
              }
            });
          }
        }
        break;
      case ItemType.UPFPowerSource:
        cItem.newOutput({ position: "BOTTOM" });
        await area.translate(
          cItem.getNodeID(),
          utils.getUpPosition(item, cItem)
        );
        break;
      case ItemType.UPFPhantomPowerSource:
        cItem.newInput({ position: "TOP" });
        await area.translate(
          cItem.getNodeID(),
          utils.getUpPosition(item, cItem)
        );
        break;
      case ItemType.UPFGround:
        cItem.newInput({ position: "TOP" });
        await area.translate(
          cItem.getNodeID(),
          utils.getDownPosition(item, cItem)
        );
        break;
      case ItemType.UPFPhantomGround:
        cItem.newOutput({ position: "BOTTOM" });
        await area.translate(
          cItem.getNodeID(),
          utils.getDownPosition(item, cItem)
        );
        break;
      case ItemType.SDCClockGroup:
        {
          cItem.setColor("#FFFFFFFF");
          const metaView = item.getNodeMetaView();
          const node = editor?.getNode(cItem.getNodeID());
          const nodeView = node?.getNodeView();
          await nodeView?.translate(
            metaView.getX() - 180,
            metaView.getY() + (item.getSDCClockGroups().length - 1) * 50
          );
        }
        break;
      default:
        break;
    }
  }
  return true;
};
