/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import DUTModuleEvent from '@/model/src/events/DUTModuleEvent'
import DUTModuleChecker from '@/model/src/checkers/DUTModuleChecker'
export default class DUTModule extends Item {
    FID_DUTMODULE_NAME = 0
    FID_DUTMODULE_LAST = 0

    constructor(obj) {
        super(Object.assign(obj,{itemType:"DUTModule"}))
        
        this.setEvent(new DUTModuleEvent(this))
        this.setChecker(new DUTModuleChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "BLK_NONAME"
        }
    if (this.properties.name === undefined) {
        this.properties.name = "BLK_NONAME"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "DUTModule"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "BLK_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "BLK_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "DUTModuleDiagram", "auto": true, "hidden": false, "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_DUTMODULE_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_DUTMODULE_NAME
    }
    

    getFidLast() {
        return this.FID_DUTMODULE_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_DUTMODULE_NAME:
                return "name"

            default:
                return ""
        }
    }


    newDUTModuleDiagram(properties={}, title = "", domain = "") {
        return this.createChild(this, "DUTModuleDiagram", properties, title, domain)
    }
    remDUTModuleDiagram(obj) {
        return this.removeChild(obj)
    }
    getDUTModuleDiagram() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "DUTModuleDiagram")
            return res
        })
    }
}