<template>
  <n-collapse-item>
    <template #header>
      <div class="n-collapse-header-title">SFR Specification</div>
    </template>
    <template #arrow>
      <n-icon color="gray"><CaretForwardOutline /></n-icon>
    </template>

    <v-list opened="test">
      <div v-for="(sfr, idx) in getSFRInfo" :key="`sfr-${idx}`">
        <v-list-subheader>{{ sfr.name }} ({{ sfr.offset }})</v-list-subheader>
        <v-list-item v-for="(field, idx) in sfr.fields" :key="`field-${idx}`">
          <template v-slot:prepend>
            <v-icon icon="mdi-circle-medium"></v-icon>
          </template>
          <v-list-item-subtitle
            v-text="
              `${field.name}[${field.size + field.index - 1}:${field.index}]`
            "></v-list-item-subtitle>
        </v-list-item>
      </div>
    </v-list>
  </n-collapse-item>
</template>

<script setup lang="ts">
import { defineProps, computed } from "vue";
import { Item } from "ITDAModelTypes";
import API from "@/api/internal";
import { CaretForwardOutline } from "@vicons/ionicons5";
const props = defineProps<{
  item: Item;
  seed: string;
}>();

const getSFRInfo = computed(() => {
  const res = props.item.getMappers().reduce((acc, cur) => {
    if (cur.getSource().getItemType().getKey() === "SFR") {
      const sfr = cur.getSource();
      const obj = { name: sfr.getName(), offset: sfr.getOffset(), fields: [] };
      sfr.getSFRFields().forEach((field) =>
        obj.fields.push({
          name: field.getName(),
          index: field.getIndex(),
          size: field.getSize(),
        })
      );
      acc.push(obj);
    }
    return acc;
  }, []);
  return res;
});
</script>
