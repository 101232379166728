<template>
  <n-collapse-item>
    <template #header>
      <div class="n-collapse-header-title">{{ getTitle() }}</div>
    </template>
    <template #arrow>
      <n-icon color="gray"><CaretForwardOutline /></n-icon>
    </template>
    <v-container>
      <v-row
        v-for="(configElement, idx) in nodeConfigElements(item)"
        :key="`${seed}-${idx}`"
        class="propery text-center">
        <v-col cols="12" overflow="hidden">
          <ItemPropertyItemField v-bind="configElement" />
        </v-col>
      </v-row>
    </v-container>
  </n-collapse-item>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import { Item, ItemConfigSchema } from "ITDAModelTypes";
import API from "@/api/internal";
import ItemPropertyItemField from "../../item/ItemPropertyItemField.vue";
import { CaretForwardOutline } from "@vicons/ionicons5";

const props = defineProps<{
  item: Item;
  seed: string;
}>();
const nodeConfigElements = (item: Item) => {
  const configSchemas: ItemConfigSchema[] = API.getConfigSchema(item);
  const nodeConfigElements = [];
  for (const configSchema of configSchemas) {
    const prop: any = {};
    prop.type = configSchema.type;
    prop.label = configSchema.label;

    nodeConfigElements.push({
      item: item,
      propKey: configSchema.prop,
      prop: prop,
      config: true,
    });
  }
  return nodeConfigElements;
};

const getTitle = (): string | undefined => {
  const item = API.getCurrentItem();
  return item ? API.getConfigurationTitle(item) : undefined;
};
</script>
