/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import PowerPMRTIMERGRPEvent from '@/model/src/events/PowerPMRTIMERGRPEvent'
import PowerPMRTIMERGRPChecker from '@/model/src/checkers/PowerPMRTIMERGRPChecker'
import ITDA_PMS_PMC_TIMER_GRP from '@/model/gen/sfrSpec/ITDA_PMS_PMC_TIMER_GRP'
export default class PowerPMRTIMERGRP extends Node {
    FID_POWERPMRTIMERGRP_NAME = 100
    FID_POWERPMRTIMERGRP_NUMOFTIMERS = 101
    FID_POWERPMRTIMERGRP_MAXNUM = 102
    FID_POWERPMRTIMERGRP_LAST = 102

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerPMRTIMERGRP"}))
        
        this.setEvent(new PowerPMRTIMERGRPEvent(this))
        this.setChecker(new PowerPMRTIMERGRPChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "PowerPMRTIMERGRP"
        }
    if (this.properties.name === undefined) {
        this.properties.name = "TIMER_NONAME"
    }
    if (this.properties.numOfTimers === undefined) {
        this.properties.numOfTimers = "4"
    }
    if (this.properties.maxNum === undefined) {
        this.properties.maxNum = 10
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerPMRTIMERGRP"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "TIMER_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "numOfTimers": {"type": "enum", "targets": ["1", "2", "3", "4", "5", "6", "7", "8"], "multiple": false, "hidden": true, "default": "4", "category": "IP", "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 10, "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "TIMER_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "numOfTimers": {"type": "enum", "targets": ["1", "2", "3", "4", "5", "6", "7", "8"], "multiple": false, "hidden": true, "default": "4", "category": "IP", "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 10, "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "PowerPMRINTRGRP", "auto": false, "searchable": false, "title": "", "headers": [], "hidden": true, "category": "IP"}, {"name": "PowerPMRTIMER", "auto": false, "searchable": false, "title": "", "headers": [], "hidden": true, "category": "IP"}]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_PMS_PMC_TIMER_GRP
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_PMS_PMC_TIMER_GRP
        }
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMRTIMERGRP_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_POWERPMRTIMERGRP_NAME
    }
    

    setNumOfTimers(numOfTimers, event=true) {
        if ((numOfTimers !== null) && (numOfTimers !== undefined)) {
            if (numOfTimers.constructor !== String) {
                throw `numOfTimers(${numOfTimers}) must be String type`
            }
        }
        const newVal = (numOfTimers === null || numOfTimers === undefined) ? undefined : numOfTimers
        const oldVal = this.properties.numOfTimers
        this.properties.numOfTimers = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMRTIMERGRP_NUMOFTIMERS, newVal, oldVal)
        }
    }
    getNumOfTimers(pure = false) {
        return this.properties.numOfTimers
        
    }
    getFidNumOfTimers() {
        return this.FID_POWERPMRTIMERGRP_NUMOFTIMERS
    }
    

    setMaxNum(maxNum, event=true) {
        if ((maxNum !== null) && (maxNum !== undefined)) {
            if (maxNum.constructor !== Number) {
                throw `maxNum(${maxNum}) must be Number type`
            }
        }
        const newVal = (maxNum === null || maxNum === undefined) ? undefined : maxNum
        const oldVal = this.properties.maxNum
        this.properties.maxNum = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMRTIMERGRP_MAXNUM, newVal, oldVal)
        }
    }
    getMaxNum(pure = false) {
        return this.properties.maxNum
        
    }
    getFidMaxNum() {
        return this.FID_POWERPMRTIMERGRP_MAXNUM
    }
    

    getFidLast() {
        return this.FID_POWERPMRTIMERGRP_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERPMRTIMERGRP_NAME:
                return "name"

            case this.FID_POWERPMRTIMERGRP_NUMOFTIMERS:
                return "numOfTimers"

            case this.FID_POWERPMRTIMERGRP_MAXNUM:
                return "maxNum"

            default:
                return ""
        }
    }


    newPowerPMRINTRGRP(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerPMRINTRGRP", properties, title, domain)
    }
    remPowerPMRINTRGRP(obj) {
        return this.removeChild(obj)
    }
    getPowerPMRINTRGRPs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerPMRINTRGRP")
            return res
        })
    }
    newPowerPMRTIMER(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerPMRTIMER", properties, title, domain)
    }
    remPowerPMRTIMER(obj) {
        return this.removeChild(obj)
    }
    getPowerPMRTIMERs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerPMRTIMER")
            return res
        })
    }
}