import * as ITDANodeRenderer from "./renderers";
import { NodeType } from "../types";
import ITDANodeClock from "./ITDANodeClock";

export default class ITDAQCH extends ITDANodeClock {
  constructor(name = "") {
    super(name, NodeType.ITDAQCH);
    this.render = ITDANodeRenderer.ITDANode.render;
  }
}
