/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:34.620490
 */
export default {
    "startOffset": "0x120",
    "size": "0x20",
    "alignSize": "0x4",
    "sfrs": [
        {
            "name": "CTRL_PCH",
            "alignOffset": "0x0",
            "description": "Power Component Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_PSTATE",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "P_WIDTH_DATA",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "P-Channel Control Signal for {{name}}\nSet PSTATE Value",
                    "initValue": "0x0",
                    "nc": "SFR_PSTATE"
                },
                {
                    "name": "SFR_PREQ",
                    "index": 8,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "P-Channel Control Signal for {{name}}\n0 = Set PREQ to 0\n1 = Set PREQ to 1",
                    "initValue": "0x0",
                    "nc": "SFR_PREQ"
                },
                {
                    "name": "SFR_PACCEPT",
                    "index": 12,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "P-Channel Control Signal for {{name}}\n0 = PACCEPT is Low\n1 = PACCEPT is High",
                    "initValue": "0x0",
                    "nc": "SFR_PACCEPT"
                },
                {
                    "name": "SFR_PDENY",
                    "index": 16,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "P-Channel Control Signal for {{name}}\n0 = PDENY is Low\n1 = PDENY is High",
                    "initValue": "0x0",
                    "nc": "SFR_PDENY"
                },
                {
                    "name": "SFR_PACTIVE",
                    "index": 20,
                    "size": 1,
                    "access": "RO",
                    "formula": "P_WIDTH_DATA",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "P-Channel Control Signal for {{name}}\n0 = PACTIVE is Low\n1 = PACTIVE is High",
                    "initValue": "0x0",
                    "nc": "SFR_PACTIVE"
                }
            ]
        }
    ]
}