import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g");

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    const centerX = width / 2;
    const centerY = height / 2;
    const innerStroke = 2;

    g.append("ellipse")
      .attr("cx", centerX)
      .attr("cy", (centerY / 4) * 4.5)
      .attr("rx", centerX / 1.5)
      .attr("ry", centerX / 3)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke * 1.3)
      .attr("fill", color);

    g.append("text")
      .attr("x", centerX)
      .attr("y", (centerY / 4) * 4.65)
      .attr("text-anchor", "middle")
      .attr("font-size", `9px`)
      .attr("font-weight", "bold")
      .attr("fill", "black")
      .text("P-Channel");

    g.append("text")
      .attr("x", centerX)
      .attr("y", 15)
      .attr("text-anchor", "middle")
      .attr("font-size", `10px`)
      .attr("fill", "black")
      .text("P-Channel");
  },
};
