<template>
  <v-text-field
    ref="input"
    v-model="model"
    color="primary"
    :style="style()"
    :label="props.table ? undefined : Utils.getLabel(props.propKey, props.prop)"
    :disabled="Utils.isDisabledTextField(props.item, props.propKey, props.prop)"
    :variant="
      props.itemRef
        ? props.itemRef.variant
          ? Utils.getVariant(
              RefItemElementType.STRING,
              VariantLevel[props.itemRef.variant]
            )
          : variantDefault
        : variantDefault
    "
    :rules="combinedRules"
    :error-messages="props.errorMessages"
    :hide-details="!inputError && !checkError"
    @change="
      inputError
        ? undefined
        : props.itemRef
        ? props.itemRef.event(props.item, props.itemRef, $event.target.value)
        : Utils.change(props.item, props.propKey, String, $event)
    " />
</template>

<script setup lang="ts">
import API from "@/api/internal";
import { computed, defineProps, nextTick, onUpdated, ref, watch } from "vue";
import Utils from "@/components/item/utils";
import {
  ItemPropsType,
  ItemType,
  RefItemElementType,
  VariantLevel,
} from "ITDAModelTypes";
const props = defineProps<ItemPropsType>();
const value = props.itemRef
  ? props.itemRef.model
  : Utils.getValue(props.item, props.propKey);
const style = () => {
  let res = `margin-bottom: 0`;
  if (props.table) {
    switch (props.item.getItemType()) {
      case ItemType.ConfigurationElement:
        if (props.propKey === "name") {
          res = `margin-bottom: 7px`;
        }
        break;
      case ItemType.Configuration:
        if (props.propKey === "name") {
          res = `margin-bottom: 15px`;
        }
        break;
      case ItemType.PowerSequenceDiagram:
        if (props.propKey === "name") {
          if (props.item.getParent().getTitle() === "PMR") {
            res = `margin-bottom: 15px`;
          }
        }
        break;
      case ItemType.PowerDiagram:
        if (props.propKey === "name") {
          res = `margin-bottom: 15px`;
        }
        break;
      case ItemType.ClockSIGNAL:
        if (props.propKey === "SignalName") {
          res = `margin-bottom: 15px`;
        }
        break;
      case ItemType.SDCClockGroup:
        if (props.propKey === "name") {
          res = `margin-bottom: 15px`;
        }
        break;
      default:
        break;
    }
  }
  return res;
};

const model = ref(value);
const input = ref<any>(null);
const inputError = ref(false);
const checkError = ref(false);

const variantDefault = "underlined";

const rules = API.getConfigurationRules(props.item, props.propKey);
const itemRules = API.getItemRules(props.item, props.propKey);

const combinedRules = computed(() => {
  return [...rules, ...itemRules];
});
watch(model, async () => {
  await nextTick();
  const err = await input.value.validate();
  inputError.value = err.length > 0;
});

watch(
  () => props.item,
  (newItem) => {
    model.value = Utils.getValue(newItem, props.propKey);
  }
);

onUpdated(() => {
  checkError.value = !!props.errorMessages;
});
</script>

<style lang="css">
.v-text-field .v-field--no-label input,
.v-text-field .v-field--active input {
  padding-left: 10px;
}
</style>
