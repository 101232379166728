import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const grid = 12;
    const gridHeight = height / grid;
    const gridWidth = width / grid;

    const points = [
      { x: 0, y: 0 },
      { x: gridWidth * 9, y: 0 },
      {
        x: gridWidth * 12,
        y: gridHeight * 6,
      },
      { x: gridWidth * 9, y: gridHeight * 12 },
      { x: 0, y: gridHeight * 12 },
    ];

    const baseColor = color;
    const hoverColor = d3.hsl(baseColor);
    hoverColor.l = Math.min(hoverColor.l + 0.05, 1);

    const component = svg
      .append("polygon")
      .attr("points", points.map((p) => `${p.x},${p.y}`).join(" "))
      .attr("fill", baseColor);

    component
      .on("mouseover", function () {
        d3.select(this).attr("fill", hoverColor.toString());
      })
      .on("mouseout", function () {
        d3.select(this).attr("fill", baseColor);
      });

    svg
      .append("line")
      .attr("x1", gridWidth * 1)
      .attr("y1", gridHeight * 1)
      .attr("x2", gridWidth * 8)
      .attr("y2", gridHeight * 11)
      .attr("stroke", "black")
      .attr("stroke-width", 2);

    svg
      .append("line")
      .attr("x1", gridWidth * 8)
      .attr("y1", gridHeight * 1)
      .attr("x2", gridWidth * 1)
      .attr("y2", gridHeight * 11)
      .attr("stroke", "black")
      .attr("stroke-width", 2);
  },
};
