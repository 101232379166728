<template>
  <div class="node-wrapper" data-testid="node-wrapper">
    <v-tooltip
      v-if="error(API.getItem(props.data.getItemID()))"
      location="start">
      <template v-slot:activator="{ props: data }">
        <ITDANode
          v-bind="data"
          :data="props.data"
          :emit="props.emit"
          :seed="props.seed" />
        <ITDANodeControl
          v-if="!props.data.dock"
          :key="props.data.width"
          :data="props.data.controls"
          :nodeId="props.data.id"
          :emit="props.emit"
          :seed="props.seed"
          :width="props.data.width"
          :height="props.data.height"
          :resizeValue="resizeValue" />
      </template>
      <div v-html="getErrorMsg()"></div>
    </v-tooltip>
    <template v-else>
      <ITDANode :data="props.data" :emit="props.emit" :seed="props.seed" />
      <ITDANodeControl
        v-if="!props.data.dock"
        :key="props.data.width"
        :data="props.data.controls"
        :nodeId="props.data.id"
        :emit="props.emit"
        :seed="props.seed"
        :width="props.data.width"
        :height="props.data.height"
        :resizeValue="resizeValue" />
    </template>

    <div v-if="props.data.dock" class="shadow">{{ props.data.label }}</div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, onUpdated, ref } from "vue";
import ITDANode from "./ITDANode.vue";
import ITDANodeControl from "./ITDANodeControl.vue";
import API from "@/api/internal";
import { Item } from "ITDAModelTypes";

const props = defineProps<{
  data: any;
  emit: (event: string, ...args: any[]) => void;
  seed: any;
}>();

const resizeValue = ref(props.data.getResizeValue());

const getErrorMsg = () => {
  const item = API.getItem(props.data.getItemID());
  return item ? API.getErrorMsg(item) : "";
};

const error = (item?: Item): boolean => {
  return item ? Boolean(item.getAllErrors().length) : false;
};

onUpdated(async () => {
  resizeValue.value = props.data.getResizeValue();
});
</script>

<style lang="sass">
.node-wrapper
  display: flex
  flex-direction: row
  align-items: center
</style>
