/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import UPFGroundEvent from '@/model/src/events/UPFGroundEvent'
import UPFGroundChecker from '@/model/src/checkers/UPFGroundChecker'
export default class UPFGround extends Node {
    FID_UPFGROUND_NAME = 100
    FID_UPFGROUND_LAST = 100

    constructor(obj) {
        super(Object.assign(obj,{itemType:"UPFGround"}))
        
        this.setEvent(new UPFGroundEvent(this))
        this.setChecker(new UPFGroundChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "PS_NONAME"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "UPFGround"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "PS_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "PS_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "UPFMapperPowerSource", "auto": false, "searchable": false, "title": "Ground Info", "headers": [{"title": "Ground", "value": "powerSource", "align": "start", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFGROUND_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_UPFGROUND_NAME
    }
    

    getFidLast() {
        return this.FID_UPFGROUND_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_UPFGROUND_NAME:
                return "name"

            default:
                return ""
        }
    }


    newUPFMapperPowerSource(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFMapperPowerSource", properties, title, domain)
    }
    remUPFMapperPowerSource(obj) {
        return this.removeChild(obj)
    }
    getUPFMapperPowerSources() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFMapperPowerSource")
            return res
        })
    }
}