/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import NodeEvent from '@/model/src/events/NodeEvent'
import NodeChecker from '@/model/src/checkers/NodeChecker'
export default class Node extends Item {
    FID_NODE_NODEID = 0
    FID_NODE_NODETYPE = 1
    FID_NODE_WIDTH = 2
    FID_NODE_HEIGHT = 3
    FID_NODE_PHANTOM = 4
    FID_NODE_BUILD = 5
    FID_NODE_LAST = 5

    constructor(obj) {
        super(obj)
        
        this.setEvent(new NodeEvent(this))
        this.setChecker(new NodeChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.build === undefined) {
        this.properties.build = false
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || true
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "Node"
    }

    getSchema() {
        return {"nodeID": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "nodeType": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "width": {"type": "integer", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "height": {"type": "integer", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "phantom": {"type": "item", "multiple": false, "hidden": true, "category": "IP", "readOnly": false}, "build": {"type": "boolean", "hidden": true, "default": false, "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"nodeID": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "nodeType": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "width": {"type": "integer", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "height": {"type": "integer", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "phantom": {"type": "item", "multiple": false, "hidden": true, "category": "IP", "readOnly": false}, "build": {"type": "boolean", "hidden": true, "default": false, "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "Input", "auto": false, "title": "", "searchable": false, "headers": [], "hidden": true, "category": "IP"}, {"name": "Output", "auto": false, "title": "", "searchable": false, "headers": [], "hidden": true, "category": "IP"}, {"name": "NodeMetaView", "auto": true, "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setNodeID(nodeID, event=true) {
        if ((nodeID !== null) && (nodeID !== undefined)) {
            if (nodeID.constructor !== String) {
                throw `nodeID(${nodeID}) must be String type`
            }
        }
        const newVal = (nodeID === null || nodeID === undefined) ? undefined : nodeID
        const oldVal = this.properties.nodeID
        this.properties.nodeID = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_NODE_NODEID, newVal, oldVal)
        }
    }
    getNodeID(pure = false) {
        return this.properties.nodeID
        
    }
    getFidNodeID() {
        return this.FID_NODE_NODEID
    }
    

    setNodeType(nodeType, event=true) {
        if ((nodeType !== null) && (nodeType !== undefined)) {
            if (nodeType.constructor !== String) {
                throw `nodeType(${nodeType}) must be String type`
            }
        }
        const newVal = (nodeType === null || nodeType === undefined) ? undefined : nodeType
        const oldVal = this.properties.nodeType
        this.properties.nodeType = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_NODE_NODETYPE, newVal, oldVal)
        }
    }
    getNodeType(pure = false) {
        return this.properties.nodeType
        
    }
    getFidNodeType() {
        return this.FID_NODE_NODETYPE
    }
    

    setWidth(width, event=true) {
        if ((width !== null) && (width !== undefined)) {
            if (width.constructor !== Number) {
                throw `width(${width}) must be Number type`
            }
        }
        const newVal = (width === null || width === undefined) ? undefined : width
        const oldVal = this.properties.width
        this.properties.width = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_NODE_WIDTH, newVal, oldVal)
        }
    }
    getWidth(pure = false) {
        return this.properties.width
        
    }
    getFidWidth() {
        return this.FID_NODE_WIDTH
    }
    

    setHeight(height, event=true) {
        if ((height !== null) && (height !== undefined)) {
            if (height.constructor !== Number) {
                throw `height(${height}) must be Number type`
            }
        }
        const newVal = (height === null || height === undefined) ? undefined : height
        const oldVal = this.properties.height
        this.properties.height = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_NODE_HEIGHT, newVal, oldVal)
        }
    }
    getHeight(pure = false) {
        return this.properties.height
        
    }
    getFidHeight() {
        return this.FID_NODE_HEIGHT
    }
    

    setPhantom(phantom, event=true) {
        if ((phantom !== null) && (phantom !== undefined)) {
            if (!(phantom instanceof Item) && (phantom.constructor !== String)) {
                throw `phantom(${phantom}) must be Item or String type`
            }
        }
        const newVal = (phantom === null || phantom === undefined) ? undefined : (phantom.constructor === String) ? phantom : phantom.getId()
        const oldVal = this.properties.phantom
        this.properties.phantom = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_NODE_PHANTOM, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getPhantom(pure = false) {
        return pure ? this.properties.phantom : this.getRefItem(this.properties.phantom)
        
    }
    getFidPhantom() {
        return this.FID_NODE_PHANTOM
    }
    

    setBuild(build, event=true) {
        if ((build !== null) && (build !== undefined)) {
            if (build.constructor !== Boolean) {
                throw `build(${build}) must be Boolean type`
            }
        }
        const newVal = (build === null || build === undefined) ? undefined : build
        const oldVal = this.properties.build
        this.properties.build = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_NODE_BUILD, newVal, oldVal)
        }
    }
    getBuild(pure = false) {
        return this.properties.build
        
    }
    getFidBuild() {
        return this.FID_NODE_BUILD
    }
    

    getFidLast() {
        return this.FID_NODE_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_NODE_NODEID:
                return "nodeID"

            case this.FID_NODE_NODETYPE:
                return "nodeType"

            case this.FID_NODE_WIDTH:
                return "width"

            case this.FID_NODE_HEIGHT:
                return "height"

            case this.FID_NODE_PHANTOM:
                return "phantom"

            case this.FID_NODE_BUILD:
                return "build"

            default:
                return ""
        }
    }


    newInput(properties={}, title = "", domain = "") {
        return this.createChild(this, "Input", properties, title, domain)
    }
    remInput(obj) {
        return this.removeChild(obj)
    }
    getInputs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "Input")
            return res
        })
    }
    newOutput(properties={}, title = "", domain = "") {
        return this.createChild(this, "Output", properties, title, domain)
    }
    remOutput(obj) {
        return this.removeChild(obj)
    }
    getOutputs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "Output")
            return res
        })
    }
    newNodeMetaView(properties={}, title = "", domain = "") {
        return this.createChild(this, "NodeMetaView", properties, title, domain)
    }
    remNodeMetaView(obj) {
        return this.removeChild(obj)
    }
    getNodeMetaView() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "NodeMetaView")
            return res
        })
    }
}