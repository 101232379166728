import ItemType from "../src/ItemType";
import { ChildSpec, ChildTableHeader, Item, Property } from "../types";
import ItemFactory from "../wrapper/ItemFactory";
import NodeType from "../src/NodeType";
import ConfigurationHandler from "../src/ConfigurationHandler";

type ButtonOptions = {
  specName?: string;
  btnType?: string;
};

const toTitleCase = (str: string): string => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
};
export default {
  setItemValue: (item: Item, key: string, val: any) => {
    return item[`set${toTitleCase(key)}`](val);
  },
  getItemValue: (item: Item, key: string): any => {
    return item[`get${toTitleCase(key)}`]();
  },
  getErrorMessages: (item: Item, key: string): string => {
    const fid = item[`getFid${toTitleCase(key)}`]();
    for (const type in item.getErrorMap()) {
      const error = item.getErrorMap()[type][fid];
      if (error) {
        return error.getDescription();
      }
    }
    return "";
  },

  getChildren: (item: Item, key?: string): Item[] => {
    return key
      ? item.getChildren().filter((o: Item) => o.itemType === key)
      : item.getChildren();
  },

  getDiagramChildren: (item: Item): Item[] => {
    const diagrams: Item[] = [];

    function findChildren(item: Item) {
      if (item.isDiagramType()) {
        diagrams.push(item);
      }
      for (const child of item.getChildren()) {
        findChildren(child);
      }
    }
    findChildren(item);
    return diagrams;
  },

  showTable: (item: Item, spec: ChildSpec): boolean => {
    if (!item) {
      return false;
    }
    switch (item.getItemType()) {
      case ItemType.ClockMultiplexerEXT:
      case ItemType.ClockDividerEXT:
        if (spec.name === ItemType.ClockSIGNAL.getKey()) {
          if (spec.interface === "ThrottleSignal") {
            return item.getThrottleMode() ? true : false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      case ItemType.ClockGateEXT:
        if (spec.name === ItemType.ClockSIGNAL.getKey()) {
          if (spec.interface === "ShortStopSignal") {
            return item.getShortStopMode() ? true : false;
          } else if (spec.interface === "EwakeupSignal") {
            return item.getEwakeupMode() ? true : false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      case ItemType.ClockIP:
        if (spec.name === ItemType.ClockQChannelInfo.getKey()) {
          return false;
        } else {
          return true;
        }
      case ItemType.PowerSequenceConfiguration:
        if (spec.name === ItemType.PowerSequenceDiagram.getKey()) {
          return item.getParent(ItemType.PowerPMC) ||
            item.getParent(ItemType.PowerPMD)
            ? true
            : false;
        } else if (spec.name === ItemType.PowerSequenceConfiguration.getKey()) {
          return item.getParent().getItemType() ===
            ItemType.PowerSequenceConfigurationFolder &&
            item.getParent(ItemType.PowerPMC)
            ? true
            : false;
        } else {
          return true;
        }
      case ItemType.UPFGround:
      case ItemType.UPFPowerSource:
        if (spec.name === ItemType.UPFElement.getKey()) {
          return item.getIsNeedElements() ? true : false;
        } else {
          return true;
        }
      case ItemType.UPFIsolationCell:
      case ItemType.UPFLevelShifter:
      case ItemType.UPFEnableLevelShifter:
        if (spec.name === ItemType.UPFElement.getKey()) {
          return item.getIsNeedElements() ? true : false;
        } else {
          return true;
        }
      case ItemType.SDCInstance:
        if (spec.name === ItemType.SDCClockGroup.getKey()) {
          return true;
        }
        return false;

      case ItemType.UPFPowerDomain:
        if (spec.name === ItemType.UPFPowerSource.getKey()) {
          return true;
        } else if (spec.name === ItemType.UPFGround.getKey()) {
          return true;
        } else return false;
      default:
        return true;
    }
  },
  getChildTableTitle: (item: Item, spec: ChildSpec): string => {
    let res: string | undefined = spec.title;
    if (item.getItemType() === ItemType.ConfigurationFolder) {
      res = ConfigurationHandler.getConfigurationTitle(
        item.getParent().getItemType()
      );
    }
    return res ? res : "";
  },
  getChildTableHeaders: (spec: ChildSpec): ChildTableHeader[] => {
    const itemClass = ItemFactory.getConstructor(
      ItemType.getObjByKey(spec.name)
    );
    const schema = itemClass?.getSchema() as { [key: string]: Property };
    return spec && spec.headers
      ? spec.headers.map((header) => {
          if (header.value in schema) {
            return { ...header, valueType: schema[header.value] };
          } else {
            return { ...header, valueType: { type: "string" } };
          }
        })
      : [];
  },
  getChildTableItems: (item: Item, spec: ChildSpec): any[] => {
    const res: any[] = [];
    if (!item) {
      return res;
    }
    const children = spec.interface
      ? item[`get${spec.interface}s`]()
      : item[`get${spec.name}s`]();
    children.forEach((child: Item) => {
      if (spec.hidden) {
        if (item.getItemType() === ItemType.Configuration) {
          res.push(
            Object.assign({}, child.properties, {
              id: child.getId(),
              title: child.title,
              draggable: true,
            })
          );
        }
      } else {
        if (item.getItemType() === ItemType.Project) {
          if (child.getItemType() === ItemType.ClockCMU) {
            res.push(
              Object.assign({}, child.properties, {
                id: child.getId(),
                title: child.title,
                draggable: true,
              })
            );
          }
        } else {
          res.push(
            Object.assign({}, child.properties, {
              id: child.getId(),
              title: child.title,
              draggable: true,
            })
          );
        }
      }
    });
    return res;
  },
  disableTableButton: (
    item: Item,
    readOnly: boolean,
    options?: ButtonOptions
  ): boolean => {
    let res = readOnly;
    if (!item) {
      return res;
    }
    switch (item.getItemType()) {
      case ItemType.PowerAPM:
        if (res) return res;
        if (options?.btnType === "CREATE") {
          if (options?.specName === "PowerAPMPWRDOWN") {
            res =
              item.getPowerAPMPWRDOWNs().length >= item.getMaxNumOfPWRDOWN()
                ? true
                : false;
          } else if (options?.specName === "PowerAPMPWRUP") {
            res =
              item.getPowerAPMPWRUPs().length >= item.getMaxNumOfPWRUP()
                ? true
                : false;
          }
        } else if (options?.btnType === "DELETE") {
          if (options?.specName === "PowerAPMPWRDOWN") {
            res = item.getPowerAPMPWRDOWNs().length === 0 ? true : false;
          } else if (options?.specName === "PowerAPMPWRUP") {
            res = item.getPowerAPMPWRUPs().length === 0 ? true : false;
          }
        }
        break;
      default:
        break;
    }
    return res;
  },
  disableProp: (item: Item, prop?: Property): boolean => {
    let res = Boolean(prop?.readOnly);
    if (!item) {
      return res;
    }

    const sfrBlock = item.getParent(ItemType.SFRBlock);
    if (sfrBlock) {
      res = res || true;
    }
    switch (item.getItemType()) {
      case ItemType.SFRField:
        return res || (prop?.type === "string" ? false : true);
      default:
        return res;
    }
  },
  disablePropTable: (item: Item, spec: ChildSpec): boolean => {
    let res = false;
    switch (item.getItemType()) {
      case ItemType.ConfigurationFolder:
        // res = res || ConfigurationHandler.getCurrentConfigurationDB() ? false : true
        break;
      case ItemType.Configuration:
      case ItemType.PowerSequenceConfigurationFolder:
      case ItemType.SFRBlock:
      case ItemType.UPFHardMacro:
      case ItemType.UPFPowerDomainInstance:
      case ItemType.SDCInstance:
        res =
          res || spec.name === ItemType.SDCClockGroup.getKey() ? false : true;
        break;
      case ItemType.PowerSequenceConfiguration:
        res = res || (item.getParent(ItemType.PowerPMC) ? true : false);
        break;
      case ItemType.UPFPowerSource:
        res =
          res ||
          (spec.name === ItemType.UPFMapperPowerSource.getKey() ? true : false);
        break;
      case ItemType.UPFPowerDomain:
        res =
          res || (spec.name === ItemType.UPFElement.getKey() ? true : false);
        break;
      case ItemType.UPFIsolationCell:
      case ItemType.UPFLevelShifter:
      case ItemType.UPFEnableLevelShifter:
      case ItemType.UPFRetention:
        res =
          res || (spec.name === ItemType.UPFElement.getKey() ? false : true);
        break;
      case ItemType.PowerAPM:
        res = false;
        break;
      default:
        res = res || item.getItemType().getCategory() === NodeType.Power;
        res = res || item.getItemType().getCategory() === NodeType.UPF;
        break;
    }
    return res;
  },
  getParentDiagram: (item: Item): Item | undefined => {
    return getParentDiagram(item);
  },
};

const getParentDiagram = (item: Item): Item | undefined => {
  if (item.isDiagramType()) {
    return item;
  } else {
    return item.getParent() ? getParentDiagram(item.getParent()) : undefined;
  }
};
