<template>
  <v-card class="editor">
    <div id="rete" ref="rete" class="rete" :style="style('rete')">
      <CanvasHeader :itemId="item?.getId()" :width="width" :height="height" />
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { ref, defineEmits, onMounted } from "vue";
import { useStore } from "vuex";
import Canvas from "@/canvas";
import events from "@/events";
import { DiagramMapper } from "@/types";
import Utils from "@/utils";
import CanvasHeader from "./CanvasHeader.vue";
import { ItemType, Module } from "ITDAModelTypes";
import API from "@/api/internal";

const rete = ref<HTMLElement | null>(null);
const style = (className: string) => {
  switch (className) {
    case "rete":
      //   return { flexBasis: `100%` };
      return {};
    case "wave-form":
      return { flexBasis: `15%` };
    default:
      return;
  }
};

// updated values ref width, height
const width = ref<number>(0);
const height = ref<number>(0);
const store = useStore();
const item = API.getCurrentItem();

const createCanvas = async () => {
  if (rete.value) {
    store.commit("SET_IN_PROGRESS", "create canvas");
    Canvas.setImported(false);
    if (item) {
      let diagramType = DiagramMapper.getObjByDiagramItemType(
        item.getItemType()
      )?.getDiagramType();
      if (item.getItemType() === ItemType.PowerDiagram) {
        if (item.getParent(ItemType.PowerPMDFolder)) {
          diagramType = DiagramMapper.getObjByModuleFolderItemType(
            item.getParent(ItemType.PowerPMDFolder).getItemType()
          )?.getDiagramType();
        } else if (item.getParent(ItemType.PowerPMCFolder)) {
          diagramType = DiagramMapper.getObjByModuleFolderItemType(
            item.getParent(ItemType.PowerPMCFolder).getItemType()
          )?.getDiagramType();
        }
      }

      if (diagramType) {
        const editor = Canvas.createEditor(
          item.getId(),
          rete.value,
          diagramType
        );
        width.value = editor.width;
        height.value = editor.height;
        events.register(item.getId());
        // console.log("editor.importNodes", Utils.getEditorNodes(item));
        // console.log(
        //   "editor.importConnections",
        //   Utils.getEditorConnections(item)
        // );
        await editor.importNodes(Utils.getEditorNodes(item), true);
        await editor.importConnections(Utils.getEditorConnections(item));
      }
    }

    Canvas.setImported(true);
    store.commit("SET_DONE", "create canvas");
  }
};

const emit = defineEmits(["mounted"]);
onMounted(async () => {
  await createCanvas();
  emit("mounted");
});
</script>

<style lang="sass" scoped>
.editor
  height: 100%
  .rete
    height: 100%
    width: 100%
</style>
