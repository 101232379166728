/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:34.620490
 */
export default {
    "startOffset": "0x10",
    "size": "0x10",
    "alignSize": "0x4",
    "sfrs": [
        {
            "name": "CTRL_REFCLKEN",
            "alignOffset": "0x0",
            "description": "Power Component Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_REFCLKEN",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Reference Clock Control Signal for {{name}}\n0 = Reference Clock is Disabled\n1 = Reference Clock is Enabled",
                    "initValue": "0x1",
                    "nc": "SFR_REFCLKEN"
                }
            ]
        }
    ]
}