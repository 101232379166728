/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemHandler from "../ItemHandler";
import ItemType from "../ItemType";

export default class ClockGateEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), () => {});
    this.addPropertyListener(
      item.getFidEnableInitValue(),
      this.lstn_enableInitValue
    );
    this.addPropertyListener(item.getFidLast() + 1, this.lstn_update_name);
  }

  lstn_enableInitValue(item) {
    ItemHandler.refreshSFRInfo(item);
  }

  lstn_update_name(item, newVal) {
    item.setName(`SFRIF_GATE_${newVal}`);
  }

  postProcess(item) {
    super.postProcess(item);
    return this.setupPorts(item);
  }

  setupPorts(item) {
    let res = [
      item.newInput({
        key: `CLK_${ItemType.Input.getKey().toUpperCase()}`,
        socket: "CLK",
      }).promise,
      item.newOutput({
        key: `CLK_${ItemType.Output.getKey().toUpperCase()}`,
        socket: "CLK",
      }).promise,
    ];
    if (
      ItemHandler.getTopItem().getAcgMode() &&
      item.getParent().getItemType() === ItemType.ClockIPDiagram
    ) {
      res = [
        ...res,
        item.newOutput({
          key: `QCH_${ItemType.Output.getKey().toUpperCase()}`,
          socket: "QCH",
          position: "TOP",
        }).promise,
      ];
    }
    return res;
  }
}
