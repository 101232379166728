import {
  Item,
  ItemType,
  Property,
  RefItemElementTarget,
  RefItemElementType,
  RefItemElement,
  RefItemEvent,
  VariantLevel,
  Mapper,
} from "ITDAModelTypes";
import API from "@/api/internal";
import VUEX from "@/components/index";

export const getSequencePMDElements = (
  item: Item,
  propKey: string,
  prop: Property
): RefItemElement[] => {
  const model = item.getPMD()?.getName();
  const targets = getPMDTargets(item);
  return [getElement(model, targets, propKey, eventPMDSequence)];
};

const getPMDTargets = (item: Item): RefItemElementTarget[] => {
  const targets: RefItemElementTarget[] = [];
  const pmc = item.getParent(ItemType.PowerPMC);
  pmc
    .getPowerDiagramFolder()
    .getPowerDiagrams()
    .forEach((diagram: Item) => {
      diagram.getPowerPMDInstances().forEach((item: Item) => {
        targets.push({
          key: item.getName(),
          item: item,
          closable: true,
        });
      });
    });
  return targets;
};

const eventPMDSequence = (
  item: Item,
  elem: RefItemElement,
  value: string | string[]
) => {
  const target = elem.targets?.find((obj) => obj.key === value);
  if (target) {
    item.setPMD(target?.item);
  } else {
    item.setPMD(null);
  }

  item.setPWRCOMP(null);
  item.setSFRField(null);
  item.setValue(null);
  VUEX.updateComponent("NAV_RIGHT_PROPERTY");
};

export const getSequencePWRCOMPElements = (
  item: Item,
  propKey: string,
  prop: Property
): RefItemElement[] => {
  const pwrcomp = item.getPWRCOMP();
  const model = pwrcomp?.getName();
  const targets = getPWRCOMPTargets(item);
  return [getElement(model, targets, propKey, eventPWRCOMPSequence)];
};

const getPWRCOMPTargets = (item: Item): RefItemElementTarget[] => {
  const targets: RefItemElementTarget[] = [];

  const isPMR = item.getParent().getItemRef() === undefined;
  if (isPMR && !item.getPMD()) {
    const pmc = item.getParent(ItemType.PowerPMC);
    pmc
      .getPowerDiagramFolder()
      .getPowerDiagrams()
      .forEach((diagram: Item) => {
        diagram.getChildren().forEach((nodeItem: Item) => {
          if (
            nodeItem.getItemType() !== ItemType.Connection &&
            nodeItem.getItemType() !== ItemType.PowerPMDInstance
          ) {
            targets.push({
              key: nodeItem.getName(),
              item: nodeItem,
              closable: true,
            });
          }
        });
      });
  } else {
    const pmd = isPMR
      ? item.getPMD().getModule()
      : item.getParent().getItemRef().getModule();
    pmd
      ?.getPowerDiagramFolder()
      .getPowerDiagrams()
      .forEach((diagram: Item) => {
        diagram.getChildren().forEach((nodeItem: Item) => {
          if (nodeItem.getItemType() !== ItemType.Connection) {
            targets.push({
              key: nodeItem.getName(),
              item: nodeItem,
              closable: true,
            });
          }
        });
      });
  }

  return targets;
};

const eventPWRCOMPSequence = (
  item: Item,
  elem: RefItemElement,
  value: string | string[]
) => {
  const target = elem.targets?.find((obj) => obj.key === value);
  item.setPWRCOMP(target?.item);
  item.setSFRField(null);
  item.setValue(null);
  VUEX.updateComponent("NAV_RIGHT_PROPERTY");
};

export const getSequenceSFRFieldElements = (
  item: Item,
  propKey: string,
  prop?: Property
): RefItemElement[] => {
  const sfrField = item.getSFRField();
  const model = sfrField
    ? `${sfrField.getParent().getTitle()} / ${sfrField.getTitle()}`
    : undefined;
  const targets = getSFRFieldTargets(item);
  return [getElement(model, targets, propKey, eventSFRFieldSequence)];
};

export const getSFRFieldTargets = (item: Item): RefItemElementTarget[] => {
  const targets: RefItemElementTarget[] = [];

  const pwrcomp = item.getPWRCOMP();
  // arg가 새로이 선택 되었을떄, SFRField도 선택 가능한 모든 sfr이 표시되어야함
  if (pwrcomp) {
    pwrcomp.getMappers().forEach((mapper: Mapper) => {
      const source = mapper.getSource();
      if (checkSource(source)) {
        addSourceSFRFieldsToTargets(source, targets);
      } else {
        source.getMappers().forEach((m: Item) => {
          if (source.getId() !== m.getSource().getId()) {
            const mapperSource = m.getSource();
            if (checkSource(mapperSource)) {
              addSourceSFRFieldsToTargets(mapperSource, targets);
            } else {
              mapperSource.getMappers().forEach((m: Item) => {
                const mapperSource = m.getSource();
                if (checkSource(mapperSource)) {
                  addSourceSFRFieldsToTargets(mapperSource, targets);
                }
              });
            }
          }
        });
      }
    });
  } else {
    const diagram = item.getParent(ItemType.PowerSequenceDiagram);
    const configuration = diagram.getParent(
      ItemType.PowerSequenceConfiguration
    );
    if (!diagram || !configuration) return targets;

    if (configuration.getTitle() === "PMR") {
      if (!item.getPMD()) {
        const topItem = API.getTopItem();
        if (topItem && topItem.getPowerPMCFolder) {
          topItem
            .getPowerPMCFolder()
            .getPowerPMCs()
            .forEach((pmc: Item) => {
              targets.push(...getSFRFields(pmc.getSFRBlock()));
            });
        }
      } else {
        const pmd = item.getPMD().getModule();
        if (pmd) {
          targets.push(...getSFRFields(pmd.getSFRBlock()));
        }
      }
    } else if (configuration.getTitle() === "PMD") {
      if (diagram.getItemRef()) {
        const pmd = diagram.getItemRef().getModule();
        if (pmd) {
          targets.push(...getSFRFields(pmd.getSFRBlock()));
        }
      }
    }
  }
  return targets;
};

const getSFRFields = (sfrBlock: Item) => {
  const targets: RefItemElementTarget[] = [];
  sfrBlock?.getChildren().forEach((sfr: Item) => {
    if (!sfr.getItemRef().getParent(ItemType.PowerDiagram)) {
      sfr.getChildren().forEach((sfrField: Item) => {
        targets.push({
          key: `${sfr.getTitle()} / ${sfrField.getTitle()}`,
          item: sfrField,
          closable: true,
        });
      });
    }
  });
  return targets;
};

const checkSource = (source: Item) => {
  return source && source.getItemType() === ItemType.SFR;
};

const addSourceSFRFieldsToTargets = (
  source: Item,
  targets: RefItemElementTarget[]
) => {
  source.getSFRFields().forEach((sfrField: Item) => {
    targets.push({
      key: `${source.getTitle()} / ${sfrField.getTitle()}`,
      item: sfrField,
      closable: true,
    });
  });
};

const eventSFRFieldSequence = (
  item: Item,
  elem: RefItemElement,
  value: string | string[]
) => {
  const target = elem.targets?.find((obj) => obj.key === value);
  item.setSFRField(target?.item);
  item.setValue(null);
  VUEX.updateComponent("NAV_RIGHT_PROPERTY");
};

const getElement = (
  model: string | undefined,
  targets: RefItemElementTarget[],
  propKey: string,
  event: RefItemEvent
): RefItemElement => {
  return {
    variant: VariantLevel.LEVEL0,
    disabled: false,
    type: RefItemElementType.ENUM,
    propKey: propKey,
    label: "",
    multiple: false,
    model: model,
    targets: targets,
    event: event,
  };
};
