<template>
  <v-container>
    <v-card hover class="mx-auto mb-8" min-width="328px"
      ><!--disabled-->
      <v-card-title class="text-primary text-center">
        <h3>LOAD DB</h3>
      </v-card-title>
      <v-row class="ma-2">
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="getDesign"
            label="Design Name"
            variant="outlined"
            chips
            closable-chips
            color="primary"
            :items="props.designTargets"
            @update:modelValue="
              store.commit('SET_DESIGN', $event)
            "></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="getConfig"
            label="Config Name"
            variant="outlined"
            chips
            closable-chips
            color="primary"
            :items="props.configTargets"
            @update:modelValue="
              store.commit('SET_CONFIG', $event)
            "></v-autocomplete>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script setup lang="ts">
import { defineProps, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import API from "@/api";
import { Domain, DomainKeys, Item } from "ITDAModelTypes";
import Canvas from "@/canvas";
import Utils from "@/utils";
import events from "@/events";
import { DiagramType } from "@/canvas/types";

const props = defineProps<{
  designTargets: string[];
  configTargets: string[];
}>();

const store = useStore();
const getDesign = computed(() => {
  return store.getters["GET_DESIGN"];
});
const getConfig = computed(() => {
  return store.getters["GET_CONFIG"];
});

const refreshDesign = async (newLD: string | undefined) => {
  store.commit("LOAD_START");
  if (!API.getTopItem()) {
    const top = await API.createItem(null, "Project");
    store.commit("item/SET_TOP_ITEM", top);
  }
  await API.clearDB("design");
  await refreshConfig(undefined);
  store.commit("SET_CONFIG", undefined);
  store.commit("domain/SET_CURRENT_DOMAIN", "");
  if (newLD) {
    const res = await API.load(newLD, "design");
    if (res) {
      if (res.domain) {
        for (const [domain, data] of Object.entries(res.domain)) {
          await API.importDB(domain as DomainKeys, data, "design");
          if (
            domain === Domain.Clock.getKey() ||
            domain === Domain.SDC.getKey()
          ) {
            await createTempEditors(domain);
          }
        }
      }
      await API.setupTopDesign(res.top);
    }
    store.commit("component/ALTER_HEADER_SYSTEM_DOMAIN_KEY");
  } else {
    const top = API.getTopItem();
    if (top) {
      await API.removeItem(top.getId());
      store.commit("item/SET_TOP_ITEM", null);
      store.commit("item/SET_CURRENT_ITEM", null);
      store.commit("item/SET_CURRENT_NODE_ITEM", null);
      store.commit("component/RESET_HEADER_SYSTEM_DOMAIN_KEY");
    }
  }
  store.commit("LOAD_END");
};
watch(() => store.getters["GET_DESIGN"], refreshDesign);

const refreshConfig = async (newLC: string | undefined) => {
  store.commit("LOAD_START");
  await API.clearDB("config");
  if (newLC) {
    const res = await API.load(newLC, "config");
    if (res && res.domain) {
      for (const [domain, data] of Object.entries(res.domain)) {
        await API.importDB(domain as DomainKeys, data, "config");
      }
    }
  }
  store.commit("LOAD_END");
};
watch(() => store.getters["GET_CONFIG"], refreshConfig);

const createFakeElement = () => {
  const fakeElement = document.createElement("div");
  fakeElement.textContent = "This is a fake element";
  return fakeElement;
};
const fakeElement = createFakeElement();

const createTempEditors = async (domain: string) => {
  const top = API.getTopItem() as Item;
  switch (domain) {
    case Domain.Clock.getKey():
      for (const cmu of top.getClockCMUFolder().getClockCMUs()) {
        const cmuDiagrams = [
          ...cmu.getClockDiagramFolder().getClockDiagrams(),
          cmu.getClockIPDiagram(),
        ];
        for (const diagram of cmuDiagrams) {
          const editor = Canvas.createEditor(
            diagram.getId(),
            fakeElement,
            DiagramType.CMU
          );
          events.register(diagram.getId());
          await editor.importNodes(Utils.getEditorNodes(diagram), true);
          await editor.importConnections(Utils.getEditorConnections(diagram));
          // await Canvas.getDataFlowPlugin(diagram.getId()).getInstance().fetch(temp);
        }
      }
      break;
    case Domain.SDC.getKey():
      for (const sdcModule of top.getSDCModuleFolder().getSDCModules()) {
        const diagram = sdcModule.getSDCDiagram();
        console.log(diagram, diagram.getId());
        const editor = Canvas.createEditor(
          diagram.getId(),
          fakeElement,
          DiagramType.SDC
        );
        events.register(diagram.getId());
        await editor.importNodes(Utils.getEditorNodes(diagram), true);
        await editor.importConnections(Utils.getEditorConnections(diagram));
      }
      break;
    default:
      break;
  }
};
</script>
<style lang="css" scoped>
.v-card-title {
  background-color: #e3ecf7;
}
</style>
