/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import { Domain } from '@/model/types'
import ClockCMUFolderEvent from '@/model/src/events/ClockCMUFolderEvent'
import ClockCMUFolderChecker from '@/model/src/checkers/ClockCMUFolderChecker'
export default class ClockCMUFolder extends Item {
    FID_CLOCKCMUFOLDER_LAST = 0

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ClockCMUFolder"}))
        
        this.setEvent(new ClockCMUFolderEvent(this))
        this.setChecker(new ClockCMUFolderChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "CMU"
        }
    }

    getDomain() {
        return Domain.Clock
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ClockCMUFolder"
    }

    getSchema() {
        return {}
    }

    static getSchema() {
        return {}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "ClockCMU", "auto": false, "searchable": false, "title": "Clock Management Unit List", "headers": [{"title": "Name", "align": "start", "filterable": true, "value": "name", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    getFidLast() {
        return this.FID_CLOCKCMUFOLDER_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            default:
                return ""
        }
    }


    newClockCMU(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockCMU", properties, title, domain)
    }
    remClockCMU(obj) {
        return this.removeChild(obj)
    }
    getClockCMUs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockCMU")
            return res
        })
    }
}