/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import { Domain } from '@/model/types'
import PowerPMCFolderEvent from '@/model/src/events/PowerPMCFolderEvent'
import PowerPMCFolderChecker from '@/model/src/checkers/PowerPMCFolderChecker'
export default class PowerPMCFolder extends Item {
    FID_POWERPMCFOLDER_LAST = 0

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerPMCFolder"}))
        
        this.setEvent(new PowerPMCFolderEvent(this))
        this.setChecker(new PowerPMCFolderChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "PMC"
        }
    }

    getDomain() {
        return Domain.Power
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerPMCFolder"
    }

    getSchema() {
        return {}
    }

    static getSchema() {
        return {}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "PowerPMC", "auto": false, "searchable": false, "title": "Power Management Cluster List", "headers": [{"title": "Name", "align": "start", "filterable": true, "value": "name", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    getFidLast() {
        return this.FID_POWERPMCFOLDER_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            default:
                return ""
        }
    }


    newPowerPMC(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerPMC", properties, title, domain)
    }
    remPowerPMC(obj) {
        return this.removeChild(obj)
    }
    getPowerPMCs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerPMC")
            return res
        })
    }
}