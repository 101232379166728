/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:34.620490
 */
export default {
    "startOffset": "0x100",
    "size": "0x20",
    "alignSize": "0x4",
    "sfrs": [
        {
            "name": "CTRL_CLINK",
            "alignOffset": "0x0",
            "description": "Power Component Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_CCMD",
                    "index": 0,
                    "size": 8,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "CLINK Control Signal for {{name}}\nSet CCMD Value",
                    "initValue": "0x0",
                    "nc": "SFR_CCMD"
                },
                {
                    "name": "SFR_CREQ",
                    "index": 8,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "CLINK Control Signal for {{name}}\n0 = Set CREQ to 0\n1 = Set CREQ to 1",
                    "initValue": "0x0",
                    "nc": "SFR_CREQ"
                },
                {
                    "name": "SFR_CACK",
                    "index": 12,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "CLINK Control Signal for {{name}}\n0 = CACK is Low\n1 = CACK is High",
                    "initValue": "0x0",
                    "nc": "SFR_CACK"
                }
            ]
        }
    ]
}