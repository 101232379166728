/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import ClockQChannelInfoEvent from '@/model/src/events/ClockQChannelInfoEvent'
import ClockQChannelInfoChecker from '@/model/src/checkers/ClockQChannelInfoChecker'
export default class ClockQChannelInfo extends Node {
    FID_CLOCKQCHANNELINFO_NAME = 100
    FID_CLOCKQCHANNELINFO_CONTROLLEDCLOCKS = 101
    FID_CLOCKQCHANNELINFO_LAST = 101

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ClockQChannelInfo"}))
        
        this.setEvent(new ClockQChannelInfoEvent(this))
        this.setChecker(new ClockQChannelInfoChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    if (this.properties.controlledClocks === undefined) {
        this.properties.controlledClocks = []
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ClockQChannelInfo"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "controlledClocks": {"type": "item", "multiple": true, "default": [], "category": "IP", "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "controlledClocks": {"type": "item", "multiple": true, "default": [], "category": "IP", "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKQCHANNELINFO_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_CLOCKQCHANNELINFO_NAME
    }
    

    setControlledClocks(controlledClocks, event=true) {
        if ((controlledClocks !== null) && (controlledClocks !== undefined)) {
            if (controlledClocks.constructor !== Array) {
                throw `controlledClocks(${controlledClocks}) must be Array type`
            }
        }
        const newVal = (controlledClocks === null || controlledClocks === undefined) ? [] : controlledClocks.map((o) => {
            if (!o || o.constructor === String) {
                return o
            } else if (this.toRaw(o) instanceof Item) {
                return o.getId()
            }
        })
        const oldVal = this.properties.controlledClocks
        this.properties.controlledClocks = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKQCHANNELINFO_CONTROLLEDCLOCKS, newVal.map((id) => this.getRefItem(id)), oldVal.map((id) => this.getRefItem(id)))
        }
    }
    getControlledClocks(pure = false) {
        return pure ? this.properties.controlledClocks : this.properties.controlledClocks.map((id) => this.getRefItem(id))
        
    }
    getFidControlledClocks() {
        return this.FID_CLOCKQCHANNELINFO_CONTROLLEDCLOCKS
    }
    

    getFidLast() {
        return this.FID_CLOCKQCHANNELINFO_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CLOCKQCHANNELINFO_NAME:
                return "name"

            case this.FID_CLOCKQCHANNELINFO_CONTROLLEDCLOCKS:
                return "controlledClocks"

            default:
                return ""
        }
    }


}