/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import PowerAPMEvent from '@/model/src/events/PowerAPMEvent'
import PowerAPMChecker from '@/model/src/checkers/PowerAPMChecker'
import ITDA_PMS_PMD_PWRCOMP_APM from '@/model/gen/sfrSpec/ITDA_PMS_PMD_PWRCOMP_APM'
export default class PowerAPM extends Node {
    FID_POWERAPM_NAME = 100
    FID_POWERAPM_MAXNUM = 101
    FID_POWERAPM_MAXNUMOFPWRDOWN = 102
    FID_POWERAPM_MAXNUMOFPWRUP = 103
    FID_POWERAPM_LAST = 103

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerAPM"}))
        
        this.setEvent(new PowerAPMEvent(this))
        this.setChecker(new PowerAPMChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "APM_NONAME"
    }
    if (this.properties.maxNum === undefined) {
        this.properties.maxNum = 1
    }
    if (this.properties.maxNumOfPWRDOWN === undefined) {
        this.properties.maxNumOfPWRDOWN = 16
    }
    if (this.properties.maxNumOfPWRUP === undefined) {
        this.properties.maxNumOfPWRUP = 16
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerAPM"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "APM_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 1, "category": "IP", "multiple": false, "readOnly": false}, "maxNumOfPWRDOWN": {"type": "integer", "hidden": true, "default": 16, "category": "IP", "multiple": false, "readOnly": false}, "maxNumOfPWRUP": {"type": "integer", "hidden": true, "default": 16, "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "APM_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 1, "category": "IP", "multiple": false, "readOnly": false}, "maxNumOfPWRDOWN": {"type": "integer", "hidden": true, "default": 16, "category": "IP", "multiple": false, "readOnly": false}, "maxNumOfPWRUP": {"type": "integer", "hidden": true, "default": 16, "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "PowerAPMPWRDOWN", "auto": false, "searchable": false, "title": "PWRDOWN Signal List", "headers": [{"title": "Name", "value": "name", "align": "start", "sortable": true}], "category": "IP"}, {"name": "PowerAPMPWRUP", "auto": false, "searchable": false, "title": "PWRUP Signal List", "headers": [{"title": "Name", "value": "name", "align": "start", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_PMS_PMD_PWRCOMP_APM
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_PMS_PMD_PWRCOMP_APM
        }
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (name.constructor !== String) {
                throw `name(${name}) must be String type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERAPM_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_POWERAPM_NAME
    }
    

    setMaxNum(maxNum, event=true) {
        if ((maxNum !== null) && (maxNum !== undefined)) {
            if (maxNum.constructor !== Number) {
                throw `maxNum(${maxNum}) must be Number type`
            }
        }
        const newVal = (maxNum === null || maxNum === undefined) ? undefined : maxNum
        const oldVal = this.properties.maxNum
        this.properties.maxNum = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERAPM_MAXNUM, newVal, oldVal)
        }
    }
    getMaxNum(pure = false) {
        return this.properties.maxNum
        
    }
    getFidMaxNum() {
        return this.FID_POWERAPM_MAXNUM
    }
    

    setMaxNumOfPWRDOWN(maxNumOfPWRDOWN, event=true) {
        if ((maxNumOfPWRDOWN !== null) && (maxNumOfPWRDOWN !== undefined)) {
            if (maxNumOfPWRDOWN.constructor !== Number) {
                throw `maxNumOfPWRDOWN(${maxNumOfPWRDOWN}) must be Number type`
            }
        }
        const newVal = (maxNumOfPWRDOWN === null || maxNumOfPWRDOWN === undefined) ? undefined : maxNumOfPWRDOWN
        const oldVal = this.properties.maxNumOfPWRDOWN
        this.properties.maxNumOfPWRDOWN = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERAPM_MAXNUMOFPWRDOWN, newVal, oldVal)
        }
    }
    getMaxNumOfPWRDOWN(pure = false) {
        return this.properties.maxNumOfPWRDOWN
        
    }
    getFidMaxNumOfPWRDOWN() {
        return this.FID_POWERAPM_MAXNUMOFPWRDOWN
    }
    

    setMaxNumOfPWRUP(maxNumOfPWRUP, event=true) {
        if ((maxNumOfPWRUP !== null) && (maxNumOfPWRUP !== undefined)) {
            if (maxNumOfPWRUP.constructor !== Number) {
                throw `maxNumOfPWRUP(${maxNumOfPWRUP}) must be Number type`
            }
        }
        const newVal = (maxNumOfPWRUP === null || maxNumOfPWRUP === undefined) ? undefined : maxNumOfPWRUP
        const oldVal = this.properties.maxNumOfPWRUP
        this.properties.maxNumOfPWRUP = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERAPM_MAXNUMOFPWRUP, newVal, oldVal)
        }
    }
    getMaxNumOfPWRUP(pure = false) {
        return this.properties.maxNumOfPWRUP
        
    }
    getFidMaxNumOfPWRUP() {
        return this.FID_POWERAPM_MAXNUMOFPWRUP
    }
    

    getFidLast() {
        return this.FID_POWERAPM_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERAPM_NAME:
                return "name"

            case this.FID_POWERAPM_MAXNUM:
                return "maxNum"

            case this.FID_POWERAPM_MAXNUMOFPWRDOWN:
                return "maxNumOfPWRDOWN"

            case this.FID_POWERAPM_MAXNUMOFPWRUP:
                return "maxNumOfPWRUP"

            default:
                return ""
        }
    }


    newPowerAPMPWRDOWN(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerAPMPWRDOWN", properties, title, domain)
    }
    remPowerAPMPWRDOWN(obj) {
        return this.removeChild(obj)
    }
    getPowerAPMPWRDOWNs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerAPMPWRDOWN")
            return res
        })
    }
    newPowerAPMPWRUP(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerAPMPWRUP", properties, title, domain)
    }
    remPowerAPMPWRUP(obj) {
        return this.removeChild(obj)
    }
    getPowerAPMPWRUPs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerAPMPWRUP")
            return res
        })
    }
}