import Model from "ITDAModel";
import {
  ChildSpec,
  ChildTableHeader,
  Item,
  Property,
  ItemProperties,
  DomainKeys,
} from "ITDAModelTypes";
export default {
  setTopItem: (item: Item | undefined) => {
    Model.repo.setTopItem(item);
  },
  getTopItem: (): Item | undefined => {
    return Model.repo.getTopItem();
  },
  setCurrentItem: (item: Item | undefined) => {
    Model.repo.setCurrentItem(item);
  },
  getCurrentItem: (): Item | undefined => {
    return Model.repo.getCurrentItem();
  },
  setCurrentNodeItem: (item: Item | undefined) => {
    Model.repo.setCurrentNodeItem(item);
  },
  getCurrentNodeItem: (): Item | undefined => {
    return Model.repo.getCurrentNodeItem();
  },
  getCurrentDomain: (): DomainKeys | undefined => {
    return Model.repo.getCurrentDomain();
  },
  getItem: (id: string): Item | undefined => {
    return Model.repo.getItemByID(id);
  },
  createItem: (
    parent: Item | null,
    type: string,
    properties: ItemProperties = {}
  ): Promise<Item> => {
    return Model.createItem(parent, type, properties);
  },
  removeItem: (id: string): Promise<void> => {
    return Model.removeItem(id);
  },
  setItemValue: (item: Item, key: string, val: any) => {
    return Model.setItemValue(item, key, val);
  },
  getItemValue: (item: Item, key: string): any => {
    return Model.getItemValue(item, key);
  },
  getChildren: (item: Item, key?: string): Item[] => {
    return key
      ? item.getChildren().filter((o: Item) => o.Item === key)
      : item.getChildren();
  },
  getDiagramChildren: (item: Item): Item[] => {
    return Model.getDiagramChildren(item);
  },
  showTable: (item: Item, spec: ChildSpec): boolean => {
    return Model.showTable(item, spec);
  },
  getChildTableTitle: (item: Item, spec: ChildSpec): string => {
    return Model.getChildTableTitle(item, spec);
  },
  getChildTableHeaders: (spec: ChildSpec): ChildTableHeader[] => {
    return Model.getChildTableHeaders(spec);
  },
  getChildTableItems: (item: Item, spec: ChildSpec): any[] => {
    return Model.getChildTableItems(item, spec);
  },
  disableTableButton: (
    item: Item,
    readOnly: boolean,
    options?: { [key: string]: string }
  ): boolean => {
    return Model.disableTableButton(item, readOnly, options);
  },
  disableProp: (item: Item, prop?: Property): boolean => {
    return Model.disableProp(item, prop);
  },
  disablePropTable: (item: Item, spec: ChildSpec): boolean => {
    return Model.disablePropTable(item, spec);
  },
  getItemRules: (item: Item, prop: string) => {
    return Model.getItemRules(item, prop);
  },
};
