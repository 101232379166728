<template>
  <div
    :key="store.getters['component/GET_PROP_SCHEMA']"
    class="property-wrapper">
    <v-row
      v-for="(prop, key) in API.getSchema(props.item)"
      :key="key"
      class="property text-center"
      :style="style(props.item, key as string, prop)">
      <v-col overflow="hidden">
        <ItemPropertyCheckbox
          v-if="Utils.isBooleanType(prop)"
          v-bind="getProps(key, prop)" />
        <ItemPropertyAutocomplete
          v-else-if="Utils.isEnumType(prop)"
          v-bind="getProps(key, prop)" />
        <ItemPropertyTextarea
          v-else-if="Utils.isTextareaType(prop)"
          v-bind="getProps(key, prop)" />
        <ItemPropertyTextField
          v-else-if="Utils.isStringType(prop)"
          v-bind="getProps(key, prop)" />
        <ItemPropertyIntegerField
          v-else-if="Utils.isIntegerType(prop)"
          v-bind="getProps(key, prop)" />
        <ItemPropertyItemField
          v-else-if="Utils.isItemType(prop)"
          v-bind="getProps(key, prop)" />
        <ItemPropertyColorPicker
          v-else-if="Utils.isColorType(prop)"
          v-bind="getProps(key, prop)" />
        <!-- <div v-else>{{ key }}</div> -->
      </v-col>
    </v-row>
    <div
      v-if="API.getChildrenSpec(props.item).length"
      class="property-table-wrapper">
      <n-collapse :expanded-names="[0, 1, 2, 3, 4]">
        <div
          v-for="(spec, idx) in API.getChildrenSpec(props.item)"
          :key="`${idx}-${spec.name}`"
          :seed="store.getters['component/GET_PROP_CHILDREN']">
          <ItemPropertyTable
            v-if="API.showTable(props.item, spec)"
            :item="props.item"
            :spec="spec"
            :name="Number(idx)" />
        </div>
      </n-collapse>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, defineProps } from "vue";
import { useStore } from "vuex";
import Utils from "@/components/item/utils";
import ItemPropertyCheckbox from "./ItemPropertyCheckbox.vue";
import ItemPropertyAutocomplete from "./ItemPropertyAutocomplete.vue";
import ItemPropertyTextarea from "./ItemPropertyTextarea.vue";
import ItemPropertyTextField from "./ItemPropertyTextField.vue";
import ItemPropertyColorPicker from "./ItemPropertyColorPicker.vue";
import ItemPropertyTable from "./ItemPropertyTable.vue";
import ItemPropertyItemField from "./ItemPropertyItemField.vue";
import ItemPropertyIntegerField from "./ItemPropertyIntegerField.vue";
import API from "@/api/internal";
import { Item, ItemPropsType, Property } from "ITDAModelTypes";

const props = defineProps<{
  item: Item;
}>();
const getProps = (key, prop): ItemPropsType => {
  return {
    item: props.item,
    propKey: key.toString(),
    prop: prop,
    errorMessages: API.getErrorMessages(props.item, key),
  };
};

const expanded = ref([0, 1, 2, 3, 4]);
const store = useStore();

const style = (item: Item, propKey: string, prop: Property) => {
  return {
    display: Utils.showProps(item, propKey, prop) ? "block" : "none",
  };
};
</script>
<style lang="sass" scoped>
.property-wrapper
  margin: 0.5rem 1.5rem 1.5rem 1.5rem

.property
  color: black

.property-table-wrapper
  margin-top: 2.5rem
</style>
