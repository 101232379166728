<template>
  <v-container>
    <HomeCreate @target-updated="targetUpdated" />
    <HomeCopy
      :designTargets="designTargets"
      :configTargets="configTargets"
      @target-updated="targetUpdated" />
    <HomeLoad :designTargets="designTargets" :configTargets="configTargets" />
  </v-container>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import HomeCreate from "@/components/home/HomeCreate.vue";
import HomeCopy from "@/components/home/HomeCopy.vue";
import HomeLoad from "@/components/home/HomeLoad.vue";

import API from "@/api";

const designTargets = ref([]);
const configTargets = ref([]);

const targetUpdated = async () => {
  designTargets.value = await API.loadTargets("design");
  configTargets.value = await API.loadTargets("config");
};

onMounted(async () => {
  await targetUpdated();
  await API.loadExternalSpec();
});
</script>
<style lang="css" scoped></style>
