<template>
  <n-collapse-item>
    <template #header>
      <div class="n-collapse-header-title">Properties</div>
    </template>
    <template #arrow>
      <n-icon color="gray"><CaretForwardOutline /></n-icon>
    </template>

    <ItemProperty :key="seed" :item="item" />
    <v-divider v-if="needToChildProp()"></v-divider>
    <ItemProperty v-if="needToChildProp()" :key="seed" :item="getChildItem()" />
  </n-collapse-item>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import { Item } from "ITDAModelTypes";
import ItemProperty from "@/components/item/ItemProperty.vue";
import { CaretForwardOutline } from "@vicons/ionicons5";
const props = defineProps<{
  item: Item;
  seed: string;
}>();

const needToChildProp = () => {
  return props.item
    .getChildren()
    .find(
      (cItem: Item) => cItem.getItemType().getCategory() === "ClockNodeSub"
    );
};

const getChildItem = () => {
  return props.item[`get${props.item.getItemType().getKey()}EXT`]();
};
</script>
