<template>
  <v-container id="dockBox" class="dock"></v-container>
</template>

<script setup lang="ts">
import { onMounted, onUpdated } from "vue";
import { useStore } from "vuex";
import Canvas from "@/canvas";

const store = useStore();
const render = () => {
  const item = store.getters["item/GET_CURRENT_ITEM"];
  if (item) {
    const dock = Canvas.getDockPlugin(item.getId());
    if (dock) {
      dock.render();
    }
  }
};
onMounted(() => render());
onUpdated(() => render());
</script>

<style lang="sass" scoped>
.dock
  display: flex
  flex-direction: column
  max-height: 1050px
</style>
