import Utils from "../utils";
import Canvas from "@/canvas";
import API from "@/api/internal";

import { Context } from "../types";
import { ITDANode } from "@/canvas/nodes";
import { NodeType } from "@/canvas/types";
import { ITDAConnection } from "@/canvas/common";
import { Item, ItemType } from "ITDAModelTypes";

export const connectioncreated = async (context: Context): Promise<boolean> => {
  const editor = Canvas.getEditor();

  const conn: ITDAConnection<ITDANode, ITDANode> = context.options
    .data as ITDAConnection<ITDANode, ITDANode>;
  const source = editor.getNode(conn.source);
  const target = editor.getNode(conn.target);
  if (!source || !target) return false;

  await Utils.addConnection(conn);
  await Utils.runDataFlow(context);

  if (
    source.getType() === NodeType.ITDAPMDGRP &&
    target.getType() === NodeType.ITDAINSTANCEPMD
  ) {
    const pmdgrpItem = API.getItem(source.getItemID());
    const pmdInstItem = API.getItem(target.getItemID());
    if (!pmdgrpItem || !pmdInstItem) {
      return false;
    }
    const outputPortNum = conn.sourceOutput.slice(-1);
    const PMDName = pmdInstItem.getName();
    const targetPmrPmdCtrl = pmdgrpItem.getChildren().filter((child: Item) => {
      return child.getItemType() === ItemType.PowerPMRPMDCTRL;
    })[outputPortNum];
    targetPmrPmdCtrl?.setName(`${pmdgrpItem.getName()}__${PMDName}`);

    API.updateINTRChildrenTitle(pmdgrpItem);
  }

  if (
    source.getType() === NodeType.ITDALABELREF &&
    target.getType() === NodeType.ITDAINSTANCEIP
  ) {
    const connItem = API.getItem(conn.getItemID());
    if (connItem) {
      const sourceOutputItem = connItem.getSourceOutput();
      const targetInputItem = connItem.getTargetInput();

      if (!sourceOutputItem || !targetInputItem) {
        return false;
      }

      if (targetInputItem.getGated()) {
        targetInputItem.getEvent().lstn_gated(targetInputItem, true);
      }
    }
  }

  return true;
};
