import Error from "./Error";
// import store from '@/store/index'
import Repository from "../store";

export default class ErrorHandler {
  static checkerSpec = new Map();

  static addChecker(item, fid, checker, type) {
    if (!this.checkerSpec.has(type)) {
      this.checkerSpec.set(type, {});
    }
    if (!this.checkerSpec.get(type)[item.getId()]) {
      this.checkerSpec.get(type)[item.getId()] = {};
    }
    this.checkerSpec.get(type)[item.getId()][fid] = checker;
  }

  static runChecker(type) {
    if (type) {
      this._runCheckerByType(type);
    } else {
      for (const [type, obj] of this.checkerSpec) {
        console.debug(type, obj);
        this._runCheckerByType(type);
      }
    }
  }

  static _runCheckerByType(type) {
    const specByType = this.checkerSpec.get(type);
    for (const itemId in specByType) {
      const item = Repository.getInstance().getItemByID(itemId);
      if (!item) {
        continue;
      }
      item.remError(type.getName());
      for (const fid in specByType[itemId]) {
        specByType[itemId][fid].bind({
          type: type,
          fid: fid,
          item: item,
          topItem: item.getTopItem(),
          newError: this.newError,
        })(item);
      }
      // if (item.isNodeType() && item.getNode()) {
      //   item.getNode().update();
      // }
    }
  }

  static newError(desc, ref) {
    var error = null;
    var errorByType = this.item.getErrorMap()[this.type.getName()];
    if (errorByType) {
      error = errorByType[this.fid];
      if (!error) {
        error = new Error(this.item, this.type, desc);
        this.item.addError(this.type.getName(), this.fid, error);
      }
    } else {
      error = new Error(this.item, this.type, desc);
      this.item.addError(this.type.getName(), this.fid, error);
    }
    if (ref) {
      error.addReference(ref.getId());
    }
    return error;
  }

  static clearErrors(item) {
    if (item) {
      item.clearErrors();
    } else {
      for (const obj of Object.values(this.checkerSpec)) {
        for (const itemId of Object.keys(obj)) {
          const item = Repository.getInstance().getItemByID(itemId);
          item.clearErrors();
        }
      }
    }
  }

  static getReferences(item, type) {
    var res = [];
    const error = item.getErrors(type.getName());
    for (const id of error.getReferences()) {
      res.push(item.getRefItem(id));
    }
    return res;
  }
}
