import {
  Item,
  VariantLevel,
  RefItemElement,
  RefItemElementType,
  RefItemElementTarget,
  Property,
  ItemType,
} from "ITDAModelTypes";
import API from "@/api";
import Utils from "./utils";

export const getItemLabelElements = (
  item: Item,
  propKey: string,
  prop: Property
): RefItemElement[] => {
  const childrenData: RefItemElementTarget[] = [];
  const cmu = item.getParent(ItemType.ClockCMU);
  const diagrams = cmu.getClockDiagramFolder().getClockDiagrams();
  diagrams.forEach((diagram: Item) => {
    diagram.getChildren((child: Item) => {
      if (child.getItemType() === ItemType.Label) {
        childrenData.push({
          key: API.getTitle(child),
          item: child,
          closable: true,
        });
      }
    });
  });

  const labelItem = item.getItemLabel();
  const model = labelItem
    ? `${labelItem.getParent(ItemType.ClockCMU).getTitle()}:${labelItem
        .getParent()
        .getName()}`
    : "";
  const targets = labelItem
    ? [
        {
          key: model,
          item: labelItem,
          closable: false,
        },
      ]
    : [];
  return targets.length
    ? [
        {
          icon: "mdi-chevron-double-right",
          variant: VariantLevel.LEVEL0,
          disabled: false,
          type: RefItemElementType.CHIP,
          propKey: propKey,
          label: "",
          multiple: false,
          targets: targets,
          event: eventItemLabel,
        },
      ]
    : [];
};

const eventItemLabel = async (item: Item) => {
  Utils.moveToTarget(item, item.getItemLabel());
};

export const getLabelsElements = (
  item: Item,
  propKey: string,
  prop: Property
): RefItemElement[] => {
  const labelItem = item.getItemLabel();
  const model = labelItem ? API.getTitle(labelItem) : undefined;
  return [
    {
      variant: VariantLevel.LEVEL0,
      disabled: false,
      type: RefItemElementType.ENUM,
      propKey: propKey,
      label: "Source Label",
      model: model,
      targets: getLabelTargets(item),
      event: eventLabels,
    },
  ];
};

const getLabelTargets = (item: Item): RefItemElementTarget[] => {
  const cmu = item.getParent(ItemType.ClockCMU);
  return cmu
    ? cmu
        .getClockDiagramFolder()
        .getClockDiagrams()
        .reduce((acc: RefItemElementTarget[], cur: Item) => {
          cur
            .getLabels()
            .filter((label: Item) => label.getType() === "internal")
            .forEach((label: Item) => {
              const name = API.getTitle(label);
              if (name) {
                acc.push({
                  key: name,
                  item: label,
                  closable: true,
                });
              }
            });
          return acc;
        }, [])
    : [];
};

const eventLabels = (
  item: Item,
  elem: RefItemElement,
  value: string | string[]
) => {
  const target = elem.targets?.find((target) => target.key === value);
  if (target) {
    item.setItemLabel(target.item.getId());
  } else {
    item.setItemLabel(null);
  }
};
