import * as load from "./load";
import * as loadSpec from "./loadSpec";
import * as loadTargets from "./loadTargets";
import * as save from "./save";
import * as copy from "./copy";
import * as generator from "./generator";

export default {
  ...load,
  ...loadSpec,
  ...loadTargets,
  ...save,
  ...copy,
  ...generator,
};
