<template>
  <textarea style="overflow-y: scroll; width: 100%; height: 100%" readonly>
    MERONGMERONGMERONGMERONGMERONGMERONGMERONG111111111111111111111111111111111111111111111111
    MERONMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGME
    MERONMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGME
    MERONMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGME
    MERONMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGME
    MERONMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGME
    MERONMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGME
    MERONMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGME
    MERONMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGME
    MERONMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGME
    MERONMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGMERONGME
  </textarea>
</template>
