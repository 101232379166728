/**
 *  Generated by Modeler
 *  timestamp: 2024-11-11 11:35:33.589429
 */
import Item from '@/model/src/Item'
import Node from './Node'
import PowerSequenceEndEvent from '@/model/src/events/PowerSequenceEndEvent'
import PowerSequenceEndChecker from '@/model/src/checkers/PowerSequenceEndChecker'
export default class PowerSequenceEnd extends Node {
    FID_POWERSEQUENCEEND_LAST = 100

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerSequenceEnd"}))
        
        this.setEvent(new PowerSequenceEndEvent(this))
        this.setChecker(new PowerSequenceEndChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerSequenceEnd"
    }

    getSchema() {
        return {}
    }

    static getSchema() {
        return {}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    getFidLast() {
        return this.FID_POWERSEQUENCEEND_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            default:
                return ""
        }
    }


}