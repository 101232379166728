/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemHandler from "../ItemHandler";
import ItemType from "../ItemType";
import SFRHandler from "../SFRHandler";
import InstanceHandler from "../InstanceHandler";
import Utils from "./utils";

export default class PowerPMRPMDGRPEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
    this.addPropertyListener(item.getFidNumOfPMDs(), this.lstn_numOfPMDs);
  }

  lstn_name(item, newVal) {
    const pmdNames = [];
    item.getOutputs().forEach((output) => {
      pmdNames.push(
        output.getConnections().length > 0
          ? output.getConnections()[0].getTargetInput().getParent().getName()
          : `PMD${output.getName().slice(-1)}`
      );
    });

    for (const [idx, pmdCtrl] of item.getPowerPMRPMDCTRLs().entries()) {
      pmdCtrl.setName(`${newVal}__${pmdNames[idx]}`);
    }
  }

  lstn_numOfPMDs(item, newVal, oldVal) {
    const promises = [];

    promises.push(item.getEvent().refreshINTRGRP(item, Number(newVal)));
    promises.push(item.getEvent().refreshPMDCTRL(item, Number(newVal)));

    const pmu = item.getParent(ItemType.PowerPMC);
    pmu.getSFRBlock().setSorted(false);
    pmu.getSFRBlock().setSorted(true);

    if (!ItemHandler.isLoading() && !ItemHandler.isMigrating()) {
      item.getEvent().postProcess(item);
    }

    oldVal = oldVal || 0;
    if (Number(newVal) > Number(oldVal)) {
      for (let i = Number(oldVal); i < Number(newVal); i++) {
        promises.push(InstanceHandler.createPMDGRPPort(item, i));
      }
    } else if (Number(newVal) < Number(oldVal)) {
      for (let i = Number(oldVal) - 1; i >= Number(newVal); i--) {
        promises.push(InstanceHandler.removePMDGRPPort(item, i));
      }
    }
    return Promise.all(promises);
  }

  async refreshINTRGRP(item, num) {
    let promises = [];
    for (const intrGrp of item.getPowerPMRINTRGRPs()) {
      promises.push(ItemHandler.removeItem(intrGrp));
    }
    await Promise.all(promises);

    const { item: intrGrp, promise } = ItemHandler.createItem(
      item,
      ItemType.PowerPMRINTRGRP,
      {
        name: `${item.getName()}`,
      },
      "",
      item.domain
    );
    await promise;
    const sfrBlock = item.getParent(ItemType.PowerPMC).getSFRBlock();

    promises = [];
    let sfrs = null;
    for (var i = 0; i < num; i++) {
      let { item: intrEventR, promise: promiseR } = ItemHandler.createItem(
        intrGrp,
        ItemType.PowerPMRINTREvent,
        { name: intrGrp.getName(), index: i * 2 },
        `SFR_PMD${i}__RISE`,
        item.domain
      );
      promises.push(promiseR);
      let { item: intrEventF, promise: promiseF } = ItemHandler.createItem(
        intrGrp,
        ItemType.PowerPMRINTREvent,
        { name: intrGrp.getName(), index: i * 2 + 1 },
        `SFR_PMD${i}__FALL`,
        item.domain
      );
      promises.push(promiseF);
      if (!sfrs) {
        sfrs = SFRHandler.createSFR(sfrBlock, intrEventR, "EVENT");
      }
      this._createPMUINTREVENTSFRField(
        sfrs,
        intrEventR,
        "EVENT_R",
        intrEventR.getTitle()
      ).forEach((sfrField) => {
        sfrField.setItemRef(intrEventR.getId(), false);
        ItemHandler.createMapper(sfrField.getParent(), sfrField);
      });
      this._createPMUINTREVENTSFRField(
        sfrs,
        intrEventF,
        "EVENT_F",
        intrEventF.getTitle()
      ).forEach((sfrField) => {
        sfrField.setItemRef(intrEventF.getId(), false);
        ItemHandler.createMapper(sfrField.getParent(), sfrField);
      });
    }
    await Promise.all(promises);

    promises = [];
    sfrs = null;
    for (const intrEvent of intrGrp.getPowerPMRINTREvents()) {
      let { item: intrGenBit, promise } = ItemHandler.createItem(
        intrGrp,
        ItemType.PowerPMRINTRGenBit,
        { name: intrGrp.getName(), index: intrEvent.getIndex() },
        intrEvent.getTitle(),
        item.domain
      );
      promises.push(promise);
      if (!sfrs) {
        sfrs = SFRHandler.createSFR(sfrBlock, intrGenBit, "GEN_BIT");
      }
      this._createPMUINTREVENTSFRField(
        sfrs,
        intrGenBit,
        "GEN_BIT",
        intrGenBit.getTitle()
      ).forEach((sfrField) => {
        sfrField.setItemRef(intrGenBit.getId(), false);
        ItemHandler.createMapper(sfrField.getParent(), sfrField);
      });
    }
    await Promise.all(promises);

    Utils.updateINTRChildrenTitle(item);
  }

  _createPMUINTREVENTSFRField(sfrs, intrEvent, specType = "", postfix = "") {
    const res = [];
    const spec = intrEvent.getSFRSpec(specType);
    for (let i = 0; i < sfrs.length; i++) {
      const sfr = sfrs[i];
      const sfrSpec = spec.sfrs[i];
      const index = sfr.getSFRFields().length;
      for (let j = 0; j < sfrSpec.fields.length; j++) {
        const sfrField = SFRHandler.createSFRField(sfr, sfrSpec.fields[j]);
        if (postfix) {
          sfrField.setName(postfix, false);
        }
        sfrField.setIndex(index + j, false);
        sfrField.getEvent().updateFieldLabel(sfrField);
        res.push(sfrField);
      }
      sfr.getEvent().sortSFRFields(sfr);
    }
    return res;
  }

  async refreshPMDCTRL(item, num) {
    let promises = [];
    let pmdNames = [];
    item
      .getPowerPMRPMDCTRLs()
      .forEach((pmdCtrl) => promises.push(ItemHandler.removeItem(pmdCtrl)));
    await Promise.all(promises);

    item.getOutputs().forEach((output) => {
      output.getConnections().forEach((conn) => {
        pmdNames.push(conn.getTargetInput().getParent().getName());
      });
    });

    promises = [];

    for (var i = 0; i < num; i++) {
      const name = pmdNames[i] ? pmdNames[i] : "PMD" + i;
      const promise = ItemHandler.createItem(
        item,
        ItemType.PowerPMRPMDCTRL,
        {
          name: `${item.getName()}__${name}`,
        },
        "",
        item.domain
      ).promise;
      promises.push(promise);
    }
    await Promise.all(promises);
  }
}
