<template>
  <div class="btn-container">
    <v-btn @click="check()">Check</v-btn>
    <v-btn @click="unCheck()">Uncheck</v-btn>
    <v-btn id="saveBtn" ref="saveBtn" @click="save()">Save</v-btn>
    <div v-if="isPrdMode()" class="v-btn">
      <v-btn @click="generateDEVRTL()">Gen RTL</v-btn>
      <v-btn @click="generateDocument()">Gen DOC</v-btn>
    </div>
    <div v-else class="v-btn">
      <div class="v-not-prd">
        <v-btn @click="generateDEVRTL()">Gen DEV RTL</v-btn>
        <v-btn @click="generateUSRRTL()">Gen USER RTL</v-btn>
        <v-btn @click="generatePRDRTL()">Gen PRD RTL</v-btn>
        <v-btn @click="generateDocument()">Gen DOC</v-btn>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import task from "@/events/task";
import API from "@/api";
import { useStore } from "vuex";
import { ConfigurationMapType } from "ITDAModelTypes";

const store = useStore();
const check = async () => {
  store.commit("SET_IN_PROGRESS", "CHECK");
  API.runChecker();
  store.commit("SET_DONE", "CHECK");
};
const unCheck = async () => {
  store.commit("SET_IN_PROGRESS", "UNCHECK");
  API.clearErrors();
  store.commit("SET_DONE", "UNCHECK");
};
const save = async () => {
  store.commit("SET_IN_PROGRESS", "SAVE");

  const date = Date.now();
  API.runChecker();

  const design = API.exportDB(date, "design");
  await API.save(store.getters["GET_DESIGN"], "design", design);

  const config = API.exportDB(date, "config");
  await API.save(store.getters["GET_CONFIG"], "config", config);

  API.clearErrors();
  store.commit("SET_DONE", "SAVE");
};

const generateDEVRTL = async () => {
  await generateRTL("dev");
};
const generateUSRRTL = async () => {
  await generateRTL("user");
};
const generatePRDRTL = async () => {
  await generateRTL("obfuscation");
};
const generateDocument = async () => {
  await generateRTL("doc");
};
const generateRTL = async (type: "dev" | "user" | "obfuscation" | "doc") => {
  store.commit("SET_IN_PROGRESS", "generateRTL");
  const configMap: ConfigurationMapType = await task.createConfigMap();
  if (store.getters["GET_CONFIG"]) {
    await API.generateRTL(
      API.getTopItem()?.getTitle(),
      store.getters["GET_DESIGN"],
      store.getters["GET_CONFIG"],
      configMap,
      type
    );
  } else {
    alert("Please select a config DB.");
  }
  store.commit("SET_DONE", "generateRTL");
};
const isPrdMode = () => {
  return false;
};
</script>
<style lang="sass" scoped>
.v-btn .v-btn--elevated
  display: block !important
.v-not-prd
  display: flex
</style>
<style lang="css" scoped>
@media screen and (max-width: 600px) {
  .v-btn.v-btn--density-default {
    padding: 0;
  }
}

.v-btn.v-btn--elevated {
  border: 1.5px solid lightgray;
  box-shadow: none;
}
</style>
