<template>
  <!-- responsive lg 1280px ~ 1920px -->
  <!-- it can be shrinked until 400px-->
  <!-- https://vuetifyjs.com/en/styles/display/#visibility -->
  <!-- <div class="d-flex d-lg-none">도메인</div> -->
  <div class="d-flex d-lg-none">
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn color="primary" v-bind="props">{{
          store.getters["domain/GET_CURRENT_DOMAIN"]
        }}</v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(domain, i) in domainList" :key="i">
          <v-list-item-title
            @click="(event: PointerEvent) => clicked(domain.getKey(), event)"
            >{{ domain.getKey() }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
  <div class="d-none d-lg-flex">
    <v-stepper width="100%" non-linear class="domain-stepper">
      <v-stepper-header width="100%">
        <v-stepper-items v-for="domain in domainList" :key="domain.getKey()">
          <v-stepper-item
            editable
            edit-icon=""
            :color="clickedDomain === domain.getKey() ? 'primary' : 'grey'"
            @click="(event: PointerEvent) => clicked(domain.getKey(), event)"
            >{{ domain.getKey() }}</v-stepper-item
          >
          <v-divider></v-divider>
        </v-stepper-items>
      </v-stepper-header>
    </v-stepper>
  </div>
</template>
<script setup lang="ts">
import { useStore } from "vuex";
import { ref, onMounted, defineProps } from "vue";
import { Domain } from "ITDAModelTypes";
import Utils from "./utils";

const props = defineProps<{
  keyValue: number | string;
  class: string;
}>();
const store = useStore();
const clickedDomain = ref<string>("");
const clicked = (name: string, event: MouseEvent) => {
  store.commit("domain/SET_CURRENT_DOMAIN", name);
  const target = event.target as Element;

  if (target) {
    clickedDomain.value = Utils.findChildrenByClass(
      target.parentNode,
      "v-stepper-item__content"
    )[0].innerText;
  }
};

const domainList = Domain.getValues();

onMounted(() => {
  document
    .querySelectorAll(".v-stepper-item__avatar")
    .forEach((item, index) => {
      item.innerText = domainList[index].getTitle();
      item.style.fontSize = "0.64rem";
    });
  document.querySelectorAll(".v-stepper-item__content").forEach((item) => {
    item.style.color = "black";
    item.style.fontWeight = "500";
  });

  if (props.keyValue === "0") {
    clickedDomain.value = "";
    store.commit("domain/SET_CURRENT_DOMAIN", "");
  } else {
    clickedDomain.value = "Clock";
    store.commit("domain/SET_CURRENT_DOMAIN", Domain.Clock.getKey());
  }
});
</script>

<style lang="css" scoped>
.domain-stepper {
  width: 30%;
}
.v-stepper-item {
  padding: 0.3125rem 0.9375rem;
}
.v-stepper-header {
  box-shadow: unset !important;
  /* scrollbar-width: none; */
  /* min-width: 31.25rem; */
}
</style>
