import { NodeType, ImportConnectionType, ImportNodeType } from "@/canvas/types";
import { NodeMapper } from "@/types";
import { ItemType, Item } from "ITDAModelTypes";
import { ITDANode } from "@/canvas/nodes";

export const getEditorNodes = (item: Item): ImportNodeType[] => {
  const res: ImportNodeType[] = [];
  item.getChildren().forEach((cItem: Item) => {
    if (isValidTargetNode(cItem)) {
      res.push(createImportNodeData(cItem));
      res.push(...getEditorNodes(cItem));
    }
  });
  return res;
};

const isValidTargetNode = (item: Item): boolean => {
  if (!item.isNodeType()) {
    return false;
  }
  switch (item.getItemType()) {
    case ItemType.ClockQChannelInfo:
      return item.getParent() ? true : false;
    case ItemType.PowerSequenceStart: {
      const labelRef = item
        .getOutputs()[0]
        ?.getConnections()[0]
        ?.getTargetInput()
        ?.getParent();
      return labelRef ? true : false;
    }
    case ItemType.PowerSequenceBack: {
      const seq = item
        .getInputs()[0]
        ?.getConnections()[0]
        ?.getSourceOutput()
        ?.getParent();
      return seq ? true : false;
    }

    case ItemType.PowerSequenceLabel: {
      const seq = item
        .getOutputs()[0]
        ?.getConnections()[0]
        ?.getTargetInput()
        ?.getParent();
      return seq ? true : false;
    }
    case ItemType.PowerSequenceLabelRef: {
      const seq = item
        .getInputs()[0]
        ?.getConnections()[0]
        ?.getSourceOutput()
        ?.getParent();
      return seq ? true : false;
    }
    default:
      return true;
  }
};

export const createImportNodeData = (item: Item): ImportNodeType => {
  const res: ImportNodeType = {
    parent: getNodeParent(item),
    itemID: item.getId(),
    itemOptions: getItemOptions(item),
    type: getNodeType(item),
    width: item.getWidth(),
    height: item.getHeight(),
    view: {
      position: {
        x: item.getNodeMetaView().getX(),
        y: item.getNodeMetaView().getY(),
      },
    },
  };
  return res;
};

const getNodeParent = (item: Item): string | undefined => {
  if (item.getItemType() === ItemType.DUTInstance) {
    return item.getUpper() ? item.getUpper().getNodeID() : undefined;
  }
};

const getItemOptions = (item: Item): Record<string, any> => {
  let originItem = item;
  if (item.getItemType() === ItemType.SDCPhantom) {
    originItem = item.getOriginItem() as Item;
  }

  if (originItem.getName) {
    return { name: originItem.getName() };
  } else {
    return {};
  }
};

export const getNodeType = (item: Item): NodeType => {
  const nodeTypeKey = item.getNodeType();
  if (nodeTypeKey) {
    const nodeType = NodeType.getObjByKey(nodeTypeKey);
    if (!nodeType) {
      throw new Error(`Please check the node meta type. ${nodeTypeKey}`);
    }
    return nodeType;
  }
  const mapper = NodeMapper.getValues().find(
    (o) => o.getItemType() === item.getItemType()
  );
  if (!mapper) {
    throw new Error(
      `node mapper is undefined. item type: ${item.getItemType().getKey()}`
    );
  }
  return mapper.getNodeType();
};

export const getItemType = (node: ITDANode) => {
  const mapper = NodeMapper.getValues().find(
    (o) => o.getNodeType() === node.getType()
  );
  return mapper ? mapper.getItemType() : null;
};

export const getEditorConnections = (diagram: Item): ImportConnectionType[] => {
  return diagram
    .getConnections()
    .reduce((acc: ImportConnectionType[], conn: Item) => {
      const data = createImportConnectionData(conn);
      if (data) {
        acc.push(data);
      }
      return acc;
    }, []);
};

export const createImportConnectionData = (
  conn: Item
): ImportConnectionType | undefined => {
  const sourceOutput = conn.getSourceOutput();
  const targetInput = conn.getTargetInput();
  if (sourceOutput && targetInput) {
    const source = sourceOutput.getParent();
    const target = targetInput.getParent();
    return {
      itemID: conn.getId(),
      source: source?.getNodeID(),
      sourceOutput: sourceOutput.getKey(),
      target: target?.getNodeID(),
      targetInput: targetInput.getKey(),
    };
  }
};
