import Repository from "../store";
import Domain from "./Domain";
import ItemHandler from "./ItemHandler";
import ItemType from "./ItemType";
import SFRHandler from "./SFRHandler";

export default class ItemMigrator {
  static sfrRestoreMap = {};
  // static updateSFR() {
  //   console.time(`migration updateSFR`);
  //   Object.values(store.getters["item/GET_ID_TO_ITEM_MAP"]).forEach((item) => {
  //     if (item.getItemType() === ItemType.SFR) {
  //       this._updateSFRProperties(item);
  //       item.getEvent().sortSFRFields(item);
  //     }
  //   });
  //   console.timeEnd(`migration updateSFR`);
  // }
  // static _updateSFRProperties(sfr) {
  //   let refItem = sfr.getItemRef();
  //   let sfrSpec = this._getSFRSpec(sfr, refItem);
  //   if (sfrSpec) {
  //     sfr.getSFRFields().forEach((sfrField) => {
  //       let fieldSpec = sfrSpec[sfrField.getName()];
  //       if (fieldSpec) {
  //         sfrField.setName(fieldSpec.name ? fieldSpec.name : "", false);
  //         sfrField.setIndex(fieldSpec.index ? fieldSpec.index : 0, false);
  //         sfrField.setAccess(fieldSpec.access ? fieldSpec.access : "RW", false);
  //         // sfrField.setDirectWrite(fieldSpec.directWrite ? fieldSpec.directWrite : false, false)
  //         // sfrField.setNameReset(fieldSpec.nameReset ? fieldSpec.nameReset : "", false)
  //         // sfrField.setInitValue(fieldSpec.initValue ? fieldSpec.initValue : "0x0", false)
  //         // sfrField.setDescription(fieldSpec.description ? fieldSpec.description : "", false)
  //       } else {
  //         fieldSpec = sfrSpec[refItem.getId()];
  //         if (fieldSpec) {
  //           sfrField.setAccess(
  //             fieldSpec.access ? fieldSpec.access : "RW",
  //             false
  //           );
  //           // sfrField.setDescription(fieldSpec.description ? fieldSpec.description : "", false)
  //         }
  //       }
  //     });
  //   }
  // }
  // static _getSFRSpec(sfr, refItem) {
  //   if (!refItem) {
  //     return;
  //   }
  //   let sfrSpec = null;
  //   let intrSFR = false;
  //   switch (refItem.getItemType()) {
  //     case ItemType.PLL:
  //       sfrSpec = Object.assign({}, refItem.getSFRSpec("FULL"));
  //       var pllSpec =
  //         store.getters["sfr/GET_PLL_SPEC"][refItem.getParent().getPlltype()];
  //       sfrSpec.sfrs = sfrSpec.sfrs.concat(...pllSpec.sfrs);
  //       break;
  //     case ItemType.PowerPMRINTREvent:
  //       sfrSpec = refItem.getSFRSpec("EVENT");
  //       intrSFR = true;
  //       break;
  //     case ItemType.PowerPMRINTRGenBit:
  //       sfrSpec = refItem.getSFRSpec("GEN_BIT");
  //       intrSFR = true;
  //       break;
  //     default:
  //       sfrSpec = refItem.getSFRSpec("FULL");
  //       break;
  //   }
  //   sfrSpec = sfrSpec.sfrs.find((o) => o.name === sfr.getType());
  //   return sfrSpec.fields.reduce((acc, cur) => {
  //     if (intrSFR) {
  //       acc[refItem.getId()] = cur;
  //     } else {
  //       acc[cur.name] = cur;
  //     }
  //     return acc;
  //   }, {});
  // }
  static async run(domain) {
    console.time(`migration`);
    const topItem = Repository.getInstance().getTopItem();
    switch (domain) {
      case Domain.Clock.getKey():
        for (const cmu of topItem.getClockCMUFolder().getClockCMUs()) {
          this.refreshAutoItems(cmu);
          await this.resetSFRItems(cmu);
          await this.reCreateSFRItems(cmu);
          this.updateSFRInfo(cmu);
        }
        topItem.getEvent().lstn_acg_mode(topItem, topItem.getAcgMode());
        break;
      case Domain.Power.getKey():
        for (const pmc of topItem.getPowerPMCFolder().getPowerPMCs()) {
          this.refreshAutoItems(pmc);
          await this.resetSFRItems(pmc);
          await this.reCreateSFRItems(pmc);
          this.updateSFRInfo(pmc);
          let sfrBlock = pmc.getSFRBlock();
        }
        for (const pmd of topItem.getPowerPMDFolder().getPowerPMDs()) {
          this.refreshAutoItems(pmd);
          await this.resetSFRItems(pmd);
          await this.reCreateSFRItems(pmd);
          this.updateSFRInfo(pmd);
          let sfrBlock = pmd.getSFRBlock();
        }
        break;
      default:
        break;
    }
    this.postProcess();
    console.timeEnd(`migration`);
  }
  static refreshAutoItems(item) {
    ItemHandler.createChildItems(item.getParent(), item, true);
    for (const childItem of item.getChildren()) {
      this.refreshAutoItems(childItem);
    }
  }
  static resetSFRItems(item) {
    const sfrBlock = item.getSFRBlock();
    const sfrHandlerMap = SFRHandler.getSfrHandlerMap(sfrBlock);
    this._resetSFRHandlerMap(sfrHandlerMap);
    return this._removeSFRItems(sfrBlock);
  }
  static _resetSFRHandlerMap(sfrHandlerMap) {
    for (const obj of sfrHandlerMap) {
      obj[1].initialize();
    }
  }
  static _removeSFRItems(sfrBlock) {
    const promises = [];
    for (const sfr of sfrBlock.getSFRs()) {
      this._updateSFRRestoreSpec(sfr);
      promises.push(ItemHandler.removeItem(sfr));
    }
    return Promise.all(promises);
  }
  static _updateSFRRestoreSpec(sfr) {
    sfr.getSFRFields().forEach((field) => {
      this.sfrRestoreMap[field.getId()] = {
        sfrBlock: sfr.getParent().getId(),
        sfr: sfr.toJSON(),
        field: field.toJSON(),
        refItemType: sfr.getItemRef().getItemType(),
      };
    });
  }
  static async reCreateSFRItems(item) {
    ItemHandler.createItemSFR(item.getParent(), item);
    const promises = [];
    for (const childItem of item.getChildren()) {
      promises.push(this.reCreateSFRItems(childItem));
    }
    await Promise.all(promises);
    await item.getEvent().setupDefaultEvents(item);
  }
  static updateSFRInfo(item) {
    ItemHandler.refreshSFRInfo(item);
    item.getChildren().forEach((childItem) => this.updateSFRInfo(childItem));
  }
  static postProcess() {
    this._restoreSFRMapping();
  }
  static _restoreSFRMapping() {
    const idToItemMap = Repository.getInstance().getAllItemMap();
    Object.values(idToItemMap).forEach((item) => {
      Object.entries(item.properties).forEach(([key, val]) => {
        let restoreSpec = this.sfrRestoreMap[val];
        if (restoreSpec) {
          item.properties[key] = null;
          let sfrBlock = ItemHandler.getRefItem(restoreSpec.sfrBlock);
          let restoreSFRSpec = restoreSpec.sfr.properties;
          let sfr = sfrBlock.getSFRs().find((sfr) => {
            return (
              sfr.getItemRef().getItemType() === restoreSpec.refItemType &&
              sfr.getOffset() === restoreSFRSpec.offset
            );
          });
          if (sfr) {
            let restoreFieldSpec = restoreSpec.field.properties;
            let field = sfr
              .getSFRFields()
              .find(
                (field) =>
                  field.getIndex() === restoreFieldSpec.index &&
                  field.getSize() === restoreFieldSpec.size
              );
            if (field) {
              item.properties[key] = field.getId();
            } else {
              item.properties[key] = null;
            }
          } else {
            item.properties[key] = null;
          }
        }
      });
    });
  }
}
